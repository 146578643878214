export const blogConstants = {
  POST_BLOGS_REQUEST: "POST_BLOGS_REQUEST",
  POST_BLOGS_SUCCESS: "POST_BLOGS_SUCCESS",
  POST_BLOGS_FAILURE: "POST_BLOGS_FAILURE",

  GET_BLOGS_REQUEST: "GET_BLOGS_REQUEST",
  GET_BLOGS_SUCCESS: "GET_BLOGS_SUCCESS",
  GET_BLOGS_FAILURE: "GET_BLOGS_FAILURE",

  GET_SINGLE_BLOG_REQUEST: "GET_SINGLE_BLOG_REQUEST",
  GET_SINGLE_BLOG_SUCCESS: "GET_SINGLE_BLOG_SUCCESS",
  GET_SINGLE_BLOG_FAILURE: "GET_SINGLE_BLOG_FAILURE",

  UPDATE_BLOGS_REQUEST: "UPDATE_BLOGS_REQUEST",
  UPDATE_BLOGS_SUCCESS: "UPDATE_BLOGS_SUCCESS",
  UPDATE_BLOGS_FAILURE: "UPDATE_BLOGS_FAILURE",

  DELETE_BLOGS_REQUEST: "DELETE_BLOGS_REQUEST",
  DELETE_BLOGS_SUCCESS: "DELETE_BLOGS_SUCCESS",
  DELETE_BLOGS_FAILURE: "DELETE_BLOGS_FAILURE",
};
