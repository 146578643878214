import { useQuery } from "react-query";
import { API_ENDPOINTS } from "../helpers";
import { api } from "../helpers";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

const fetchCompanyInvestorUpdate = async (inputdata) => {
    if (inputdata != undefined) {
        const data = await api.get(`${API_ENDPOINTS.COMPNAYINVEPDATE}/getinvestorUpdate/${inputdata.property_id}/${inputdata.company_id}`);
        return data?.data;
    }
    else
        return {}
};
const useCompanyInvestorUpdate = (id) => {
    return useQuery([API_ENDPOINTS.COMPNAYINVEPDATE, id], () => fetchCompanyInvestorUpdate(id));
};
const fetchCompanyInvestorUpdateByID = async (id) => {
    if (id != undefined) {
        const data = await api.get(`${API_ENDPOINTS.COMPNAYINVEPDATE}/getinvestorUpdateById/${id}`);
        return data?.data;
    }
    else
        return {}
};
const useCompanyInvestorUpdateById = (id) => {
    return useQuery([API_ENDPOINTS.COMPNAYINVEPDATE, id], () => fetchCompanyInvestorUpdateByID(id),
        {
            refetchOnWindowFocus: false,
            enabled: !id 
        });
};
const useDeletePropertyMetadataMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (id) => api.delete(`${API_ENDPOINTS.COMPNAYINVEPDATE}/deleteinvestorUpdate/${id}`),
        {
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.COMPNAYINVEPDATE);
            },
        }
    );
};
const useUpdateInvestorUpdateMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { input, params } }) =>
            api.put(`${API_ENDPOINTS.COMPNAYINVEPDATE}/updateinvestorUpdate/${params.id}`, input),
        {
            onSuccess: (result) => {
                if (result.status === 200 || result?.data?.status === 200) {
                    toast.success(result.data.message);
                } else if (result.status === 400) {
                    toast.error(result?.message);
                }
            },
            onSettled: () => {
                // queryClient.invalidateQueries(`${API_ENDPOINTS.COMPNAYINVEPDATE}/getinvestorUpdateById/${localStorage.getItem("investor_update_id")}}`);
            },
            onError: (error) => {
                toast.error(error?.response?.data?.message);
            },
        }
    );
};
const useCreateInvestorUpdateMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { input, params } }) =>
            api.put(`${API_ENDPOINTS.COMPNAYINVEPDATE}/addinvestorUpdate/${params.property_id}/${params.company_id}`, input),
        {
            onSuccess: (result) => {
                if (result.status === 200 || result?.data?.status === 200) {
                    toast.success(result.data.message);
                } else if (result.status === 400) {
                    toast.error(result?.message);
                }
            },
            onSettled: () => {
                // queryClient.invalidateQueries(`${API_ENDPOINTS.COMPNAYINVEPDATE}/getinvestorUpdateById/${localStorage.getItem("investor_update_id")}}`);
            },
            onError: (error) => {
                toast.error(error?.response?.data?.message);
            },
        }
    );
};
export {
    useCompanyInvestorUpdateById,
    useCompanyInvestorUpdate,
    useCreateInvestorUpdateMutation,
    useUpdateInvestorUpdateMutation,
    useDeletePropertyMetadataMutation
};
