import { accountConstants } from "redux/constants/account.constants";

export function postAccountReducer(state = {}, action) {
  switch (action.type) {
    case accountConstants.POST_ACCOUNT_REQUEST:
      return { loading: true };
    case accountConstants.POST_ACCOUNT_SUCCESS:
      return { message: action.payload.message };
    case accountConstants.POST_ACCOUNT_FAILURE:
      return { loading: true };
    default:
      return state;
  }
}
