import { toast } from "react-toastify";
import { percentageConstants } from "../../redux/constants/percentage.constants";
import { percentageService } from "../../redux/services";

export const percentageActions = {
    getPercentages,
    genericPercentages,
    getPropertyPercentages,
    genericPropertyPercentages,
};

function getPercentages() {
    return (dispatch) => {
        dispatch(request());
        percentageService.getPercentages().then(
            (res) => {
                dispatch(success(res));
            },
            (error) => {
                toast.error(error);
                dispatch(failure(error));
            }
        );
    };

    function request() { return { type: percentageConstants.GET_PERCENTAGE_REQUEST } }
    function success(res) { return { type: percentageConstants.GET_PERCENTAGE_SUCCESS, payload: res } }
    function failure(error) { return { type: percentageConstants.GET_PERCENTAGE_FAILURE, error } }
}
function genericPercentages(data, service) {
    return (dispatch) => {
        dispatch(request(data));
        percentageService[service](data).then(
            (res) => {
                dispatch(success(res));
                toast.success(res.message);
            },
            (error) => {
                toast.error(error);
                dispatch(failure(error));
            }
        );
    };

    function request() { return { type: percentageConstants.GET_PERCENTAGE_REQUEST } }
    function success(res) { return { type: percentageConstants.GET_PERCENTAGE_SUCCESS, payload: res } }
    function failure(error) { return { type: percentageConstants.GET_PERCENTAGE_FAILURE, error } }
}
function getPropertyPercentages(property_id) {
    return (dispatch) => {
        dispatch(request());
        percentageService.getPropertyPercentages(property_id).then(
            (res) => {
                dispatch(success(res));
            },
            (error) => {
                toast.error(error);
                dispatch(failure(error));
            }
        );
    };

    function request() { return { type: percentageConstants.GET_PROPERTY_PERCENTAGE_REQUEST } }
    function success(res) { return { type: percentageConstants.GET_PROPERTY_PERCENTAGE_SUCCESS, payload: res } }
    function failure(error) { return { type: percentageConstants.GET_PROPERTY_PERCENTAGE_FAILURE, error } }
}
function genericPropertyPercentages(data, service, property_id) {
    return (dispatch) => {
        dispatch(request(data));
        percentageService[service](data, property_id).then(
            (res) => {
                dispatch(success(res));
                toast.success(res.message);
            },
            (error) => {
                toast.error(error);
                dispatch(failure(error));
            }
        );
    };

    function request() { return { type: percentageConstants.GET_PROPERTY_PERCENTAGE_REQUEST } }
    function success(res) { return { type: percentageConstants.GET_PROPERTY_PERCENTAGE_SUCCESS, payload: res } }
    function failure(error) { return { type: percentageConstants.GET_PROPERTY_PERCENTAGE_FAILURE, error } }
}