import { percentageConstants } from "../../constants/percentage.constants";

export function getPropertyPercentageReducer(state = {}, action) {
    switch (action.type) {
        case percentageConstants.GET_PROPERTY_PERCENTAGE_REQUEST:
            return { loading: true };
        case percentageConstants.GET_PROPERTY_PERCENTAGE_SUCCESS:
            return { propertyPercentages: action.payload.data };
        case percentageConstants.GET_PROPERTY_PERCENTAGE_FAILURE:
            return { error: action.error };
        default:
            return state;
    }
}
