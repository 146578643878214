import NotFound from "components/NotFound/NotFound";
import { useEffect } from "react";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { HiOutlinePlusCircle } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, Col, Container, NavLink, Row, Spinner, Table } from "reactstrap";
import { blogsAction } from "redux/actions/blog.actions";

const BlogList = () => {
    const dispatch = useDispatch();
    const { blogs, loading } = useSelector(
        (state) => state.getBlogsReducer
    );
    useEffect(() => {
        dispatch(blogsAction.getBlogs())
    }, [])
    const handleDelete = (id) => {
        const confirm = window.confirm("Are you sure to Delete?");
        if (confirm) {
            dispatch(blogsAction.deleteBlog(id));
        }
    }
    return (
        <Container className="mt-2" fluid>
            <Row className="justify-content-between">
                <Col>
                    <h2 className="all-page-heading">Blog</h2>
                </Col>
                <Col className="text-right">
                    <Link
                        to={{
                            pathname: `/admin/blog/add`,
                        }}
                        className="btn rz-button-primary"
                    >
                        <HiOutlinePlusCircle className="ml-2" style={{ fontSize: "20px" }} /> Create New Blog
                    </Link>
                </Col>
            </Row>
            <Card className="mt-3">
                <Table responsive>
                    <thead className="rz-thead-style">
                        <tr>
                            <th>BLOG ID</th>
                            <th>TITLE</th>
                            <th>DATE</th>
                            <th>AUTHOR</th>
                            <th>ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr className="text-center">
                                <td colSpan="10">
                                    <Spinner animation="border" role="status" >{null}</Spinner>
                                </td>
                            </tr>
                        ) : (
                            blogs && blogs?.map((blog, i) => {
                                return (
                                    <tr key={i}>
                                        <td

                                            style={{ cursor: 'pointer' }}
                                            className="text-primary">
                                            <NavLink
                                                to={{
                                                    //pathname: `/admin/company/${company?.id}/edit`,

                                                }}
                                            >
                                                #{blog?.id}
                                            </NavLink>
                                        </td>
                                        <td> {blog.title.slice(0, 50)}... </td>
                                        <td >{new Date(blog.date).toDateString()} </td>
                                        <td> {blog.author} </td>
                                        <td className="">
                                            <div className="d-flex justify-content-start align-items-center">
                                                {/* <NavLink
                                                    className="btn btn-sm btn-link bg-transparent"
                                                    to={{
                                                        // pathname: `/admin/listofproperty`,
                                                    }}
                                                    color="primary"
                                                >
                                                    <AiOutlineEye style={{ fontSize: "22px" }} className="ryzer-text-blue" />
                                                </NavLink> */}
                                                <Link
                                                    to={`/admin/blog/${blog?.id}/edit`}
                                                    className="btn btn-sm btn-link bg-transparent"
                                                >
                                                    <FiEdit style={{ fontSize: "20px" }} className="ryzer-text-blue" />
                                                </Link>
                                                <Button
                                                    className="btn btn-sm btn-link ryzer-text-blue bg-transparent border-0"
                                                    onClick={(e) => handleDelete(blog?.id)}
                                                >
                                                    <AiOutlineDelete style={{ fontSize: "22px" }} className="ryzer-text-blue" />
                                                </Button>
                                            </div>

                                        </td>
                                    </tr>
                                );
                            })

                        )}
                    </tbody>
                </Table>
                {blogs?.length <= 0 &&
                    <NotFound
                        message="Blog Not Found."
                    />
                }
                {/* {percentages?.length > 0 && pagesnew && pagesnew.pages.length && (
                <Paginate path="/admin/role" pager={pagesnew} />
            )} */}
            </Card>
        </Container>
    )
}

export default BlogList
