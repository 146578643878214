import { propertyConstants } from '../../constants';

export function savePropertySvgReducer(state = {}, action) {
    switch (action.type) {
        case propertyConstants.SAVE_PROPERTY_SVG_REQUEST:
            return { savingPropertySvg: true };
        case propertyConstants.SAVE_PROPERTY_SVG_SUCCESS:
            return { property: action.payload.data ? action.payload.data : action.payload };
        case propertyConstants.SAVE_PROPERTY_SVG_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}