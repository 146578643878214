import React, { useEffect, useState } from "react";
import DonutChart from "react-donut-chart";
import { Button, Card, Col, Container, Row, Spinner, Table } from "reactstrap";
import { useGetCompanyPropertyById } from "../../../hooks/useCompany";
import NotFound from "components/NotFound/NotFound";

export default function CapTable() {
    const { data } = useGetCompanyPropertyById({
        company_id: localStorage.getItem("company_id"),
        property_id: localStorage.getItem("property_id")
    });
    const [totaln, setTotal] = useState({
        total_token: 0,
        total_allocated: 0,
        total_sell: 0
    });
    useEffect(async () => {
        var allocated, sell, total;
        if (data?.company?.LLPCompanyShare?.filter((share) => share.property_id == localStorage.getItem("property_id"))?.length > 0) {
            allocated = data?.company?.LLPCompanyShare?.filter((share) => share.property_id == localStorage.getItem("property_id"))[0]?.class_allocate_shares
        }
        if (data?.total?.length > 0) {
            sell = data?.total[0]?.total_sqyds_sold
        }
        if (data?.total?.length > 0) {
            total = data?.total[0]?.no_sqyds
        }
        const final = {
            total_token: total ? total : 0,
            total_allocated: allocated ? allocated : 0,
            total_sell: sell ? sell : 0
        };
        setTotal(final);
    }, [data])
    return (
        <>
            <Container>
                <div className="row py-3" >
                    <div className="col-6 px-0">
                        <h1 className="all-page-heading" style={{fontSize:"20px"}}>{localStorage.getItem('property_name')}</h1>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="row">
                            <h3>Tokens For Allocation: </h3>
                            {/* <h3>{localStorage.getItem("property_name")}</h3> */}
                            <Card className="cap-table">
                                    <div class="row">
                                        <div class="col-sm-12 my-3 pl-5">
                                            <DonutChart
                                                data={[
                                                    {
                                                        label: "Total allocated",
                                                        value:
                                                            totaln.total_allocated /
                                                            totaln.total_token,
                                                    },
                                                    {
                                                        label: "Total available",
                                                        value:
                                                            (totaln.total_token -
                                                                totaln.total_allocated) /
                                                            totaln.total_token,
                                                    },
                                                    {
                                                        label: "Total Sold",
                                                        value:
                                                            (totaln.total_sell) /
                                                            totaln.total_token,
                                                    },
                                                ]}
                                                height={250}
                                                width={250}
                                                legend={false}
                                                colors={['#7367F0', '#F96E6F', '#02D3AF']}
                                        />
                                    </div>
                                </div>
                                <div className="raw d-flex flex-row justify-content-center">
                                    <div>
                                        <h4>Available: {totaln.total_token - totaln.total_allocated}</h4>
                                    </div>
                                    <div>
                                        <h4 className="ml-2">Allocation: {totaln.total_allocated || 0}</h4>
                                    </div>
                                </div>
                                <div className="raw d-flex flex-row justify-content-center">
                                    <h4>Token Sold: {totaln.total_sell || 0}</h4>
                                </div>
                                </Card>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <h3>Investor Ownership percentage</h3>
                        <Card className="cap-table-pbar">
                            <div className="p-4">
                                <div className="row">
                                    <div className="col-3">Name</div>
                                    <div className="col-7">
                                        Percentage
                                    </div>
                                    <div className="col-2">
                                        Ownership
                                    </div>
                                </div>
                                <hr></hr>
                                <div className="row mt-2">
                                    {
                                        (data?.investors && data?.investors?.length > 0) ?
                                            data?.investors?.slice(0, 4)?.map((investor) => (
                                            <>
                                                <div className="col-3">{investor?.name}</div>
                                                <div className="col-7">
                                                    <div class="progress">
                                                        <div
                                                            class="progress-bar"
                                                            role="progressbar"
                                                            aria-valuenow="0"
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                            style={{ width: `${investor?.ownership}%` }}
                                                        ></div>
                                                    </div>
                                                </div>
                                                <div className="col-2">
                                                    {investor?.ownership?.toFixed(2)}%
                                                </div>
                                            </>
                                        )) :
                                            <div className="align-items-center text-center">
                                                {data?.investors?.length <= 0 &&
                                                    <NotFound
                                                        message="No one invested into this property."
                                                    />
                                                }
                                            </div>
                                    }
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
                <Card className="my-5">
                    <Table className="align-items-center table-flush" responsive>
                        <thead className="rz-thead-style">
                            <tr>
                                <th scope="col">INVESTOR ID</th>
                                <th scope="col">EMPLOYEE NAME</th>
                                <th scope="col">ROLE</th>
                                <th scope="col">OWNERSHIP %</th>
                                <th scope="col">TOKENS</th>
                                <th scope="col">PROPERTY</th>
                                <th scope="col">CAP CONTRIBUTIONS</th>
                                {/* <th scope="col">Actions</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data?.investors?.map((investor) => (
                                <tr>
                                    <td>
                                        {investor?.id}
                                    </td>
                                    <td>
                                        {" "}
                                        <img width="30" style={{ borderRadius: 15, marginRight: 5 }} src={investor?.avatar} alt="" />
                                        {investor?.name}{" "}
                                    </td>
                                    <td>Investor</td>
                                    <td>{investor?.ownership} %</td>
                                    <td>{investor?.no_sqyds?.toFixed(2)}</td>
                                        <td>{investor?.property_name || localStorage.getItem("property_name")}</td>
                                    <td>{investor?.total_invested}</td>
                                        {/* <td className="text-right">
                                        <Button color="primary" className="btn-sm">
                                            <i className="fas fa-eye"></i>
                                        </Button>
                                        <Button color="primary" className="btn-sm">
                                            <i className="fas fa-edit"></i>
                                        </Button>
                                        <Button color="primary" className="btn-sm">
                                            <i className="fas fa-trash    "></i>
                                        </Button>
                                    </td> */}
                                </tr>
                                )) 
                            }
                        </tbody>
                    </Table>
                    {data?.investors?.length <= 0 &&
                        <NotFound
                            message="No one invested into this property."
                        />
                    }
                </Card>
            </Container>
        </>
    );
}
