import React, { useState } from 'react'
import { Card, CardBody, CardSubtitle, CardTitle, Nav, NavItem } from 'reactstrap';
import { moneyFormat } from 'utils';
import cartIcon from './../../../assets/img/cart-icon.png';
import timeIcon from './../../../assets/img/timeline.png';
import briefcaseIcon from './../../../assets/img/briefcase.png';
import { useLocation } from 'react-router-dom';
import ShareHolderList from './ShareHolderList';
import { useParams } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useGetYearMonthProperty } from 'hooks/useRentalPayout';
import { Link } from 'react-router-dom';
import { formatNumberWithRupeeSymbol } from 'utils';
const styles = {
    cardStyle: {
        display: 'flex',
        marginTop: "3%",
        justifyContent: "space-between"
    },
    textStyle: {
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 500,
        color: '#82868B',
    },
    numberStyle: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
        fontSize: '24px',
        color: '#7367F0',
    }
}
const headingstyle = {
    fontFamily: 'Montserrat',
    color: "#5E5873",
    fontSize: '24px',
    fontWeight: 500,
}
export default function MonthAndYear() {
    const location = useLocation();


    const { month, year } = useParams();
    const property_id =location.state.property_id;
    const [yearVal] = year.split('?');

    const { data, loading ,refetch} = useGetYearMonthProperty({
        month,
        year: yearVal,
        property_id
    });
    
    const total = data?.totals;
    console.log(property_id)
    const ColorCard = ({ color, title, subTitle }) => {
        const iconStyle = {
            fontSize: '36px',
            marginRight: '10px',
            color: `white`,
        };
        const titleStyle = {
            color: 'white',
            fontFamily: 'Montserrat',
            fontWeight: 600,
        };
        const subTitleStyle = {
            color: 'white',
            fontFamily: 'Montserrat',
            fontWeight: 400,
        };
        return (
            <Card style={{ backgroundColor: color, display: 'flex' }}>
                <CardBody style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: "space-between",
                    paddingTop: "25px",
                    paddingBottom: "25px"
                }}>
                    <div>
                        <CardTitle tag="h2" style={titleStyle}>{title}</CardTitle>
                        <CardSubtitle tag="p" style={subTitleStyle}>{subTitle}</CardSubtitle>
                    </div>
                </CardBody>
            </Card>
        );
    };
    const [selectedOption, setSelectedOption] = useState(1);
    const NewCard = () => {
        const handleOptionChange = (option) => {
            setSelectedOption(option);
        };
        return (
            <Card className='p-2'>
                <div className="d-flex justify-content-between">
                    <div className="btn-group" role="group" aria-label="Options">
                        <button
                            type="button"
                            className={`btn mr-3 d-flex align-items-center ${selectedOption === 1 ? 'btn-primary' : 'white'
                                }`}
                            style={{ borderRadius: "7px" }}
                            onClick={() => handleOptionChange(1)}
                        >
                            <img src={cartIcon} />
                            <span style={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 500 }} className='ml-2'> Orders & transactions</span>
                        </button>
                        <button
                            type="button"
                            className={`btn d-flex align-items-center ${selectedOption === 2 ? 'btn-primary' : 'white'
                                }`}
                            style={{ borderRadius: "7px" }}
                            onClick={() => handleOptionChange(2)}
                        >
                            <img src={timeIcon} />
                            <span style={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 500 }} className='ml-2'>Withdraw timeline</span>
                        </button>
                    </div>
                </div>
            </Card>
        );
    };
    return (
        <>
            <div className="px-4">
                <div className="row mx-0">
                    <div className='col-6 pl-2'>
                        <div className='d-flex align-items-center'>
                            <div>
                                <Link style={{ color: "#6E6B7B" }} to='/company/RentalPayOut'>
                                    <AiOutlineArrowLeft style={{ fontSize: "25px", color: "#6E6B7B" }} />
                                </Link>
                            </div>
                            <h3 className='ml-2 mb-0' style={headingstyle}>{month} {yearVal} Payout</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 mt-4">
                        <div className="row">

                            <div className="col-12">
                                <ColorCard
                                    color="#289B96"
                                    title={formatNumberWithRupeeSymbol(total?.totalAmountNotPaid || 0)}
                                    subTitle="Amount to be paid"
                                // icon="fas fa-lock"
                                />
                            </div>
                            <div className="col-12 mt-4">
                                <ColorCard
                                    color="#7C278A"
                                    title={total?.totalUniqueShareholders || 0}
                                    subTitle="No of Shareholders"
                                // icon="fas fa-arrow-up"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 mt-4">
                        <Card className='py-3 px-4' style={{ boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)" }}>
                            <div className="d-flex justify-content-between">
                                <h2 style={{
                                    fontFamily: 'Montserrat',
                                    fontWeight: 500,
                                    fontSize: '18px',
                                    color: '#5E5873',
                                }} tag="h2">Tax Breakup</h2>
                                {/* <p tag="p"
                                    style={{
                                        fontFamily: 'Montserrat',
                                        fontWeight: 400,
                                        fontSize: '12px',
                                        color: '#6E6B7B',
                                    }}
                                >Updated 1 hour ago <img className='ml-2' src={refreshIcon}/></p> */}
                            </div>
                            <div style={styles.cardStyle}>
                                <h2 tag="h2" style={styles.textStyle}>Taxes</h2>
                                <CardSubtitle tag="h3" style={styles.numberStyle}>{formatNumberWithRupeeSymbol(total?.totalTax || 0)}</CardSubtitle>
                            </div>
                            <div style={styles.cardStyle}>
                                <h2 tag="h2" style={styles.textStyle}>Pre-tax deduction</h2>
                                <CardSubtitle tag="h3" style={styles.numberStyle}>{formatNumberWithRupeeSymbol(total?.totalOrders || 0)}</CardSubtitle>
                            </div>
                            <div style={styles.cardStyle}>
                                <h2 tag="h2" style={styles.textStyle}>Post-tax deduction</h2>
                                <CardSubtitle tag="h3" style={styles.numberStyle}>{formatNumberWithRupeeSymbol(total?.totalOrderTransactions || 0)}</CardSubtitle>
                            </div>

                            {/* <div className='mt-5' style={styles.cardStyle}>
                                <h2 tag="h2" style={styles.textStyle}>Last Transaction</h2>
                                <p tag="h3"
                                    style={
                                        {
                                            fontFamily: 'Montserrat',
                                            fontWeight: 600,
                                            fontSize: '16px',
                                            color: '#797979',
                                        }
                                    }>{new Date(data?.data?.[0]?.created_at)?.toDateString()}</p>
                            </div> */}
                        </Card>
                    </div>
                </div>
                {/* <div className='mt-5'>
                    <NewCard />
                </div> */}
                <div className='row pt-3 my-5'>
                    <div className='col-6 d-flex'>
                        <div style={{ width: "50%" }}>
                            <select
                                //value={filtterData.kyc}
                                // onChange={(e) => setFilterData({
                                //     ...filtterData,
                                //     kyc: e.target.value
                                // })}
                                className='form-control fee-filter-select'>
                                <option selected value=''>Select Action</option>
                               
                            </select>
                        </div>
                    </div>
                    <div className='col-6 d-flex'>
                        <div style={{ width: "50%" }}>

                        </div>
                        <div style={{ width: "50%" }}>
                            <select
                                //value={filtterData.kyc}
                                // onChange={(e) => setFilterData({
                                //     ...filtterData,
                                //     kyc: e.target.value
                                // })}
                                className='form-control fee-filter-select'>
                                <option selected value=''>Select Status</option>
                                <option value='Pending'>Pending</option>
                                <option value='Paid<'>Paid</option>
                            </select>
                        </div>

                    </div>
                </div>
                <div className='mt-3'>
                    <ShareHolderList refetch={refetch} data={data?.data} />
                </div>
            </div>
        </>
    )
}
