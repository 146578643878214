import { userConstants } from "../constants";
import { userService } from "../services";
import { toast } from "react-toastify";

export const userActions = {
  login,
  register,
  logout,
  verifyOtp,
  resendOtp,
  updateMe,
  getMe,
  getUsers,
};

function login(data, history) {
  return (dispatch) => {
    dispatch(request(data));

    userService.login(data).then(
      (res) => {
        dispatch(success(res.data));
        // toast(res.message)
        history.replace("/auth/verify", res.data);
      },
      (error) => {
        dispatch(failure(error));
        toast.error(error);
      }
    );
  };

  function request(res) {
    return { type: userConstants.LOGIN_REQUEST, payload: res };
  }
  function success(res) {
    return { type: userConstants.LOGIN_SUCCESS, payload: res };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}
function register(data, history) {
  return (dispatch) => {
    dispatch(request(data));

    userService.register(data).then(
      (res) => {
        dispatch(success(res.data));
        // toast(res.message)
        history.replace("/auth/verify", res.data);
      },
      (error) => {
        dispatch(failure(error));
        toast.error(error);
      }
    );
  };

  function request(res) {
    return { type: userConstants.LOGIN_REQUEST, payload: res };
  }
  function success(res) {
    return { type: userConstants.LOGIN_SUCCESS, payload: res };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}
function logout() {
  return (dispatch) => {
    dispatch({ type: userConstants.LOGOUT });
    userService.logout();
    toast("Logout Successfully!");
  };
}

function verifyOtp(data, history) {
  return (dispatch) => {
    dispatch(request(data));

    userService.verifyOtp(data).then(
      (res) => {
        localStorage.setItem("user", JSON.stringify(res.data));
        localStorage.setItem("kyc", JSON.stringify(res?.data?.kyc || {}));
        if (res.data?.org_id) {
          localStorage.setItem("org_id", res.data?.org_id);
        }
        dispatch(success(res));
        dispatch({ type: userConstants.AUTH_SUCCESS, payload: res });
        dispatch({ type: userConstants.LOGIN_EMPTY });
        window.location.href = "/admin";
      },
      (error) => {
        console.log(error);
        dispatch(failure(error));
        dispatch({ type: userConstants.AUTH_FAILURE });
        toast.error(error);
      }
    );
  };

  function request(res) {
    return { type: userConstants.VERIFY_OTP_REQUEST, payload: res };
  }
  function success(res) {
    return { type: userConstants.VERIFY_OTP_SUCCESS, payload: res };
  }
  function failure(error) {
    return { type: userConstants.VERIFY_OTP_FAILURE, error };
  }
}

function resendOtp(user) {
  return (dispatch) => {
    dispatch(request(user));

    userService.resendOtp(user).then(
      (res) => {
        dispatch(success(res));
        toast.success(res.message)
      },
      (error) => {
        dispatch(failure(error));
        toast.error(error);
      }
    );
  };

  function request(res) {
    return { type: userConstants.RESEND_OTP_REQUEST, payload: res };
  }
  function success(res) {
    return { type: userConstants.RESEND_OTP_SUCCESS, payload: res };
  }
  function failure(error) {
    return { type: userConstants.RESEND_OTP_FAILURE, error };
  }
}

function updateMe(user, step) {
  return (dispatch) => {
    dispatch(request(user));

    userService.updateMe(user, step).then(
      (res) => {
        dispatch(success(res));
        // toast(res.message)
      },
      (error) => {
        dispatch(failure(error));
        toast.error(error);
      }
    );
  };

  function request(res) {
    return { type: userConstants.UPDATE_ME_REQUEST, payload: res };
  }
  function success(res) {
    return { type: userConstants.UPDATE_ME_SUCCESS, payload: res };
  }
  function failure(error) {
    return { type: userConstants.UPDATE_ME_FAILURE, error };
  }
}

function getMe(query) {
  return (dispatch) => {
    dispatch(request());
    userService.getMe(query).then(
      (res) => {
        dispatch(success(res));
        dispatch({ type: userConstants.AUTH_SUCCESS, payload: res });
        // toast(res.message)
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: userConstants.GET_ME_REQUEST };
  }
  function success(res) {
    return { type: userConstants.GET_ME_SUCCESS, payload: res };
  }
  function failure(error) {
    return { type: userConstants.GET_ME_FAILURE, error };
  }
}

// action for get users
function getUsers() {
  return (dispatch) => {
    dispatch(request());
    userService.getUsers().then(
      (res) => {
        dispatch(success(res));

        // toast(res.message)
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: userConstants.GET_USERS_REQUEST };
  }
  function success(res) {
    return { type: userConstants.GET_USERS_SUCCESS, payload: res };
  }
  function failure(error) {
    return { type: userConstants.GET_USERS_FAILURE, error };
  }
}
