import { toast } from "react-toastify";
import { rolesConstants } from "redux/constants/roles.constants";
import { rolesService } from "../services";

export const rolesAction = { postRoles, getRoles, updateRoles, deleteRoles };

// action for get   roles
function getRoles() {
  return (dispatch) => {
    dispatch(request());
    rolesService.getRoles().then(
      (res) => {
        dispatch(success(res));
        // toast.success(res.message);
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };

  function request(res) {
    return {
      type: rolesConstants.GET_ROLES_REQUEST,
    };
  }
  function success(res) {
    return {
      type: rolesConstants.GET_ROLES_SUCCESS,
      payload: res,
    };
  }
  function failure(res) {
    return {
      type: rolesConstants.GET_ROLES_FAILURE,
      payload: res,
    };
  }
}

// action for post   roles
function postRoles(data) {
  return (dispatch) => {
    dispatch(request());
    rolesService.postRoles(data).then(
      (res) => {
        dispatch(success(res));
        toast.success("Role Added Successfully");
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };

  function request(res) {
    return {
      type: rolesConstants.POST_ROLES_REQUEST,
    };
  }
  function success(res) {
    return {
      type: rolesConstants.POST_ROLES_SUCCESS,
      payload: res,
    };
  }
  function failure(res) {
    return {
      type: rolesConstants.POST_ROLES_FAILURE,
      payload: res,
    };
  }
}

// action for update   roles
function updateRoles(data, role_id) {
  return (dispatch) => {
    dispatch(request());
    rolesService.updateRoles(data, role_id).then(
      (res) => {
        dispatch(success(res));
        toast.success(res.message);
        dispatch(rolesAction.getRoles());
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };

  function request(res) {
    return {
      type: rolesConstants.UPDATE_ROLES_REQUEST,
    };
  }
  function success(res) {
    return {
      type: rolesConstants.UPDATE_ROLES_SUCCESS,
      payload: res,
    };
  }
  function failure(res) {
    return {
      type: rolesConstants.UPDATE_ROLES_FAILURE,
      payload: res,
    };
  }
}

// action for delete   roles
function deleteRoles(role_id) {
  return (dispatch) => {
    dispatch(request());
    rolesService.deleteRoles(role_id).then(
      (res) => {
        dispatch(success(res));
        toast.success(res.message);
        dispatch(rolesAction.getRoles());
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };

  function request(res) {
    return {
      type: rolesConstants.DELETE_ROLES_REQUEST,
    };
  }
  function success(res) {
    return {
      type: rolesConstants.DELETE_ROLES_SUCCESS,
      payload: res,
    };
  }
  function failure(res) {
    return {
      type: rolesConstants.DELETE_ROLES_FAILURE,
      payload: res,
    };
  }
}
