import { blogConstants } from "redux/constants/blog.constants";

export function postBlogsReducer(state = {}, action) {
  switch (action.type) {
    case blogConstants.POST_BLOGS_REQUEST:
      return { loading: true };
    case blogConstants.POST_BLOGS_SUCCESS:
      return { message: action.payload.message };
    case blogConstants.POST_BLOGS_FAILURE:
      return { loading: true };
    default:
      return state;
  }
}
