import { api } from "../../helpers";

export const orderService = {
  getOrder,
  getSingleOrder,
  updateOrderStatus,
  updateOrderRefund,
  updateCoverImageLegal
};

function getOrder(page, filter) {
  return api.get(`/order?page=${page}&${filter || ""}`);
}

function getSingleOrder(id) {
  return api.get(`/order/${id}`);
}
function updateOrderStatus(data) {
  return api.put(`/order/status`, data);
}
function updateOrderRefund(data) {
  return api.post(`/me/wallet/withdrawFromOrder`, data);
}
function updateCoverImageLegal(data,order_id){
  return api.post(`/order/addCoverImageOrder/${order_id}`,data);
}
