import React, { useEffect, useState } from 'react'
import {
    Button,
    Card,
    Col,
    Container,
    FormGroup,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from "reactstrap";
import { Link, useLocation } from 'react-router-dom';
import { useUpdateCertificate } from 'hooks/useTokenCertificate';
import { api } from 'helpers';
import { toast } from 'react-toastify';
export default function TokenCertificateDetails() {
    const location = useLocation();
    const { certificate: certificateData } = location.state;
    const { mutate: updateCertificate } = useUpdateCertificate();
    const [selectedDoc, setSelectedDoc] = useState(null);
    const [certificate, setCertificate] = useState(certificateData);
    const [isModalOpen, setIsModalOpen] = useState(false);
    useEffect(() => {
        setCertificate(certificateData)
    }, [certificateData])
    const openFileModal = (e, item) => {
        setIsModalOpen(true);
        setSelectedDoc(item);
    };
    // to handle the user-selected file
    const handleChange = async (e, item) => {
        let data = {}
        if (e?.target.files?.length > 0) {
            if (selectedDoc == "share_certificate") {
                const file = e.target.files[0];
                const formDataNew = new FormData();
                formDataNew.append("file", file)
                try {
                    const res = await api.post("/file",
                        formDataNew
                    );
                    data.share_certificate = res?.url
                } catch (ex) {
                    console.log(ex);
                    return;
                }
            }
            if (selectedDoc == "proof_of_investment") {
                const file = e.target.files[0];
                const formDataNew = new FormData();
                formDataNew.append("file", file)
                try {
                    const res = await api.post("/file",
                        formDataNew
                    );
                    data.proof_of_investment = res?.url
                } catch (ex) {
                    console.log(ex);
                    return;
                }
            }
        }
        await updateCertificate({
            id: certificate?.id,
            ...data
        },
            {
                onSuccess: (res) => {
                    toast.success("Token Updated Successfully")
                    setCertificate(res?.data)
                },
            }
        );
        setIsModalOpen(false);
    };
    const onSubmit = async (status) => {
        await updateCertificate({
            id: certificate?.id,
            status: status
        },
            {
                onSuccess: (res) => {
                    toast.success("Document Updated Successfully")
                    setCertificate(res?.data)
                },
            }
        );
    }
    return (
        <Container>
            <Row>
                <Col>
                    <Card className="p-1">
                        <Row className="justify-content-between">
                            <Col>
                                <table class="table table-borderless">
                                    <tbody>
                                        <tr>
                                            <th scope="col">Certificate Name</th>
                                            <th scope="col">{certificate?.certificate_name}</th>
                                        </tr>
                                        <tr>
                                            <th scope="row">Share Holder Name</th>
                                            <td>
                                                {certificate?.user?.name}{" "}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">Asset name</th>
                                            <td>
                                                <img
                                                    style={{
                                                        borderRadius: 15,
                                                        width: 30,
                                                        height: 30,
                                                        objectFit: "contain",
                                                    }}
                                                    src={certificate?.property?.featured_image} alt=""
                                                />
                                                {certificate?.property?.name}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row className='py-2'>
                <Col md={6}>
                    <Card className="px-1 py-6">
                        <table class="table table-borderless">
                            <tbody>
                                <tr>
                                    <th scope="col"><h3>Details</h3></th>
                                </tr>
                                <tr>
                                    <th scope="col">Share class</th>
                                    <th scope="col">{certificate?.token_class}</th>
                                </tr>
                                <tr>
                                    <th scope="row">No of shares</th>
                                    <td>{certificate?.no_token}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Status</th>
                                    <td>{certificate?.status}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Issued at</th>
                                    <td>{certificate?.created_at}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Capital contribution</th>
                                    <td>{certificate?.total_amount}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card className="p-1">
                        <table class="table table-borderless">
                            <tbody>
                                <tr>
                                    <th scope="col">
                                        <h3>Shareholder details</h3>
                                    </th>
                                </tr>
                                <tr>
                                    <th scope="row">Role</th>
                                    <td>{"Investor"}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Type</th>
                                    <td>{"Individual"}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Country</th>
                                    <td>{"India"}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Card>
                    <Card className="p-1 mt-1">
                        <table class="table table-borderless">
                            <tbody>
                                <tr>
                                    <th scope="col">
                                        <h3>Contribution details</h3>
                                    </th>
                                </tr>
                                <tr>
                                    <th scope="row">IP Contribution</th>
                                    <td>{0}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Debt cancelled</th>
                                    <td>{0}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Others</th>
                                    <td>{0}</td>
                                </tr>

                            </tbody>
                        </table>
                    </Card>
                </Col>
            </Row>
            <Card className="p-4">
                <table className="table border">
                    <thead className="thead-light">
                        <tr>
                            <th>DOCUMENT TYPE</th>
                            <th>UPLOAD</th>
                            <th>Download Old One</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td scope="row">
                                {"Share certificate"}
                                <div>
                                    <small className="text-muted">{".PDF"}</small>
                                </div>
                            </td>
                            <td>
                                <span>
                                    <button
                                        type="button"
                                        className="btn btn-link btn-sm"
                                        onClick={(e) => openFileModal(e, "share_certificate")}
                                    >
                                        <i className="fa fa-upload"></i>
                                    </button>
                                </span>
                            </td>
                            <td>
                                {
                                    certificate?.share_certificate ?
                                        <a href={`${certificate?.share_certificate}`} target="_blank">View Attchement</a>
                                        : "Not Uploaded"
                                }
                            </td>
                        </tr>
                        <tr>
                            <td scope="row">
                                {"Proof of Investment"}
                                <div>
                                    <small className="text-muted">{".DOC"}</small>
                                </div>
                            </td>
                            <td>
                                <span>
                                    <button
                                        type="button"
                                        className="btn btn-link btn-sm"
                                        onClick={(e) => openFileModal(e, "proof_of_investment")}
                                    >
                                        <i className="fa fa-upload"></i>
                                    </button>
                                </span>
                            </td>
                            <td>
                                {
                                    certificate?.proof_of_investment ?
                                        <a href={`${certificate?.proof_of_investment}`} target="_blank">View Attchement</a>
                                        : "Not Uploaded"
                                }
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Card>
            <Row className="justify-content-between">
                <Col>
                    <div className="modal-footer">
                        <Link className="btn btn-primary"
                            to="/company/tokenCertificate">
                            <i className="fa fa-back"></i>
                            Cancle
                        </Link>
                        <button type="button"
                            onClick={() => onSubmit("REVOKE")}
                            className="btn btn-success">Revoke</button>
                        <button type="button"
                            onClick={() => onSubmit("ACTIVATE")}
                            className="btn btn-default">ACTIVATE</button>
                    </div>
                </Col>
            </Row>
            {/* Upload Document File */}
            {selectedDoc && (
                <Modal isOpen={isModalOpen} centered={true}>
                    <ModalHeader
                        close={<i className="fa fa-times"></i>}
                        onClick={() => setIsModalOpen(!isModalOpen)}
                    >
                        Upload Document
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <div className="custom-file">
                                <input
                                    type="file"
                                    className="custom-file-input"
                                    id="customFile"
                                    onChange={(e) => handleChange(e, selectedDoc)}
                                    accept={selectedDoc?.type}
                                />
                                <label className="custom-file-label" for="customFile">
                                    Choose file
                                </label>
                            </div>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => setIsModalOpen(!isModalOpen)}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            )}
        </Container>
    )
}
