import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner,
    Table,
} from "reactstrap";
import { configActions } from "redux/actions";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDeletePopupText } from "hooks/useConfig";
import { useAddPopupText } from "hooks/useConfig";
import { HiOutlinePlusCircle } from "react-icons/hi";
import { FiEdit } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";

const featureSchema = yup.object().shape({
    type: yup.string().required(),
    description: yup.string().required(),
    subtitle: yup.string().required(),
});

const PopupText = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { popuptext, loading } = useSelector(
        (state) => state.getPopuptextReducer
    );
    useEffect(() => {
        dispatch(configActions.getPopuptext());
    }, []);

    const dispatch = useDispatch();
    const { mutate: addPopupText } = useAddPopupText()
    const { mutate: deletePopupText } = useDeletePopupText()
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm({ resolver: yupResolver(featureSchema) });

    const handleAddFeature = () => {
        setIsModalOpen(true);
        reset();
    };

    const handleDelete = async (id) => {
        const sure = window.confirm("Are you Sure to Delete?");
        if (sure) {
            await deletePopupText(
                { id: id },
                {
                    onSuccess: async (res) => {
                        dispatch(configActions.getPopuptext());
                    },
                    onError: (error) => {
                        console.log(error)
                    }
                }
            );
        }
    };

    const handleUpdate = (amenity) => {
        setIsModalOpen(true);
        setValue("type", amenity.type);
        setValue("description", amenity.description);
        setValue("subtitle", amenity.subtitle);
    };

    const onSubmit = async (data) => {
        let dataNew = {
            type: data.type,
            description: data.description,
            subtitle: data.subtitle
        }
        await addPopupText(
            dataNew,
            {
                onSuccess: async (res) => {
                    dispatch(configActions.getPopuptext());
                },
                onError: (error) => {
                    console.log(error)
                }
            }
        );
        reset();
        setIsModalOpen(false);
    };
    const [filterData, setFilterData] = useState([]);
    const [selectedItem, setSelectedItem] = useState();
    useEffect(() => {
        const popuptextFilter = popuptext?.filter((v) => v.type == selectedItem);
        setFilterData(popuptextFilter);
    }, [selectedItem])
    useEffect(() => {
        setFilterData(popuptext?.slice(0, 1))
    }, [popuptext])
    return (
        <Container fluid className="mt-5 px-md-5 px-1 ">
            <hr />
            {loading ? (
                <Row>
                    <Col md="7" xs="12" className="text-center">
                        <div colSpan="10">
                            <Spinner animation="border" role="status" >{null}</Spinner>
                        </div>
                    </Col>
                </Row>
            ) : (

                <Card className="card bg-white p-4  ">
                    <Row className="d-flex my-3  py-1 align-items-center rounded">
                        <Col xs="12" md="6" className="text-md-left text-center">
                            <span className="rz-heading rz-text-18"> Add New PopupText </span>
                        </Col>
                        <Col md="6" className="d-flex align-items-center justify-content-center">
                            <FormGroup>
                                <Label for="exampleSelect">Select</Label>
                                <Input type="select" name="select"
                                    onChange={(e) => setSelectedItem(e?.target?.value)}
                                    id="exampleSelect">
                                    {popuptext?.map((vl) => (
                                        <option
                                            value={vl?.type}
                                        >{vl?.type}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                            <Button
                                onClick={handleAddFeature}
                                className="rz-button-primary ml-3"
                            >
                                <HiOutlinePlusCircle className="ml-2" style={{ fontSize: "20px" }} /> Add New Popup Text
                            </Button>
                        </Col>

                    </Row>
                    <Table responsive>
                        <thead className="rz-thead-style">
                            <tr>
                                <th scope="col"> TYPE </th>
                                <th scope="col"> Subtitle</th>
                                <th scope="col">
                                    Description
                                </th>
                                <th scope="col"> Actions</th>

                            </tr>
                        </thead>
                        <tbody>
                            {filterData?.map((video) => {
                                return (
                                    <tr className="bg-white" key={video.id}>
                                        <td> {video.type} </td>
                                        <td> {video.subtitle} </td>
                                        <td > {video.description} </td>
                                        <td className="d-flex ">
                                            <div
                                                onClick={() => handleUpdate(video)}
                                                data-bs-toggle="modal"
                                                className="mr-3"
                                                data-bs-target="#exampleModal"
                                            >
                                                <FiEdit style={{ fontSize: "20px" }} className="ryzer-text-blue cursor-pointer" />
                                            </div>
                                            <div
                                                onClick={() => handleDelete(video?.id)}

                                            >
                                                <AiOutlineDelete style={{ fontSize: "22px" }} className="ryzer-text-blue cursor-pointer" />
                                            </div>
                                        </td>

                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Card>
            )}

            <Modal isOpen={isModalOpen} centered={true} className="modal-lg">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className="px-4 pt-4 d-flex justify-content-between">
                        <div>
                            <h1 className="rz-heading rz-text-18" id="exampleModalLabel">
                                Add New Video Link
                            </h1>
                        </div>
                        <div>
                            <Button
                                type="button"
                                className="btn-close btn-link bg-white"
                                onClick={() => setIsModalOpen(false)}
                            >
                                <i className="fas fa-times    "></i>
                            </Button>
                        </div>
                    </div>
                    <ModalBody>
                        <FormGroup>
                            <Label className="form-label" htmlFor={`question`}>
                                Type
                            </Label>
                            <input
                                className="form-control mb-3"
                                type="text"
                                placeholder="Feature New"
                                {...register("type", {})}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className="form-label" htmlFor={`subtitle`}>
                                Question
                            </Label>
                            <input
                                className="form-control mb-3"
                                type="text"
                                placeholder="subtitle for new popup"
                                {...register("subtitle", {})}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className="form-label" htmlFor={`description`}>
                                Description
                            </Label>
                            <input
                                className="form-control mb-3"
                                type="text"
                                placeholder="Description for feature New"
                                {...register("description", {})}
                            />
                        </FormGroup>

                    </ModalBody>
                    <ModalFooter>
                        <Button className="rz-button-outline-primary" onClick={() => setIsModalOpen(!isModalOpen)}>
                            Cancel
                        </Button>

                        <Button className="rz-button-primary">Save</Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </Container>
    );
};

export default PopupText;
