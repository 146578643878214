import React, { useState } from 'react'
import ChanelPartnerMyCommission from '../comman/MyCommission/ChanelPartnerMyCommission';
import { useGetAssetSellerMyCommission } from 'hooks/useAssetSeller';
export default function AssetSellerCommission() {
    const { data, isLoading } = useGetAssetSellerMyCommission();
    const total = data?.total;
    return (
        <>
            <div className="px-4">
                <ChanelPartnerMyCommission
                    type={1}
                    total={total}
                    data={data}
                    isLoading={isLoading}
                />
            </div>
        </>
    )
}
