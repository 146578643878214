import {
    Badge,
    Card,
    Media,
    Table,
    Container,
    Row,
    UncontrolledTooltip,
    Button,
    Col,
    Spinner,
} from "reactstrap";
import moment from "moment";
import { moneyFormat } from "utils";
import { useState } from "react";
import NotFound from "components/NotFound/NotFound";
import { useGetPropertyFilter } from "hooks/useReport";
import { PROPERTY_TYPE_VALUE } from "utils";
import exportFromJSON from "export-from-json";

const PropertyList = () => {
    const intialState = {
        status: "",
        dao: "",
        type: ""
    }
    const [filtterData, setFilterData] = useState(intialState)
    const [applyFilter, setApplyFilter] = useState({});

    const { data: properties, isLoading: loading, refetch } = useGetPropertyFilter({
        filter: applyFilter
    }, Object.keys(applyFilter)?.length > 0);

    function getThumbnailFromVideo(value) {
        if (value) {
            let value_arr = value.split("/");
            if (value_arr.length > 0) {
                if (value_arr[3]) {
                    let youtube_id = value_arr[3];
                    let video_url = `https://i1.ytimg.com/vi/${youtube_id}/hqdefault.jpg`;
                    return video_url
                }
            }
        }
    }

    const exportPDF = () => {
        const title = `property-list-report`;
        const report_data = properties?.data?.map((item) => {
            return ({
                "ID": item.id,
                "Property Name": item.name,
                "Company Name": item?.LLPCompany?.name,
                "Value": item?.total_property_value || 0,
                "Total Token": item?.current_month_price,
                "Token Value": item?.total_property_sqyds,
                "Total Token Bought": item?.total_sqyds_sold,
                "Total Bought %": ((item?.total_sqyds_sold / (item?.total_property_sqyds == 0 ? 1 : item?.total_property_sqyds)) * 100)?.toFixed(2),
                "Total Token Pending": item?.total_property_sqyds - item?.total_sqyds_sold,
                "Total Token %": (((item?.total_property_sqyds - item?.total_sqyds_sold) / (item?.total_property_sqyds == 0 ? 1 : item?.total_property_sqyds)) * 100)?.toFixed(2),
                "Created At": moment(item.created_at).format("D-MMM-Y"),
                "Status": item?.status,
                "Type": item?.propertyType?.name,
                "Stage": item?.stage,
                "Token Created Date": moment(item.updated_at).format("D-MMM-Y"),
            });
        })
        exportFromJSON({ data: report_data, fileName: title, exportType: 'csv' });
    };

    return (
        <>
            <div className="row">
                <div className='col-4'>
                    <label>Select Asset Status</label>
                    <select
                        value={filtterData.status}
                        onChange={(e) => setFilterData({
                            ...filtterData,
                            status: e.target.value
                        })}
                        className='form-control'>
                        <option selected value=''>Select Status</option>
                        <option value='Yes'>Active</option>
                        <option value='No'>In-Active</option>
                    </select>
                </div>
                <div className='col-4'>
                    <label>Select DAO Status</label>
                    <select
                        value={filtterData.dao}
                        onChange={(e) => setFilterData({
                            ...filtterData,
                            dao: e.target.value
                        })}
                        className='form-control'>
                        <option value=''>Select Options</option>
                        <option value='Yes'>Yes</option>
                        <option value='No'>No</option>
                    </select>
                </div>
                <div className='col-4'>
                    <label>Select Asset Type</label>
                    <select
                        value={filtterData.type}
                        onChange={(e) => setFilterData({
                            ...filtterData,
                            type: e.target.value
                        })}
                        className='form-control'>
                        <option value=''>Select Asset Type</option>
                        <option value={`${PROPERTY_TYPE_VALUE?.LANDPARCEL}`}>Land Parcel</option>
                        <option value={`${PROPERTY_TYPE_VALUE?.RESIDENTIAL}`}>Residential</option>
                        <option value={`${PROPERTY_TYPE_VALUE?.COMMERCIAL}`}>Commercial</option>
                    </select>
                </div>
            </div>
            <div className="row pb-2 pt-4">
                <div className="col-12">
                    <button
                        onClick={() => setApplyFilter(filtterData)}
                        className="btn rz-button-primary mr-3" type="button">
                        Apply Filter
                    </button>
                    <button
                        disabled={!properties?.data || properties?.data?.length <= 0}
                        onClick={() => exportPDF()}
                        className="btn rz-button-primary mr-3" type="button">
                        Downalod Report
                    </button>
                    <button onClick={() => {
                        setFilterData(intialState)
                        setApplyFilter({})
                    }}
                        className="btn rz-button-primary" type="button">
                        Clear Filter
                    </button>
                </div>
            </div>
            <Row className="mt-3">
                <div className="col">
                    <Card className="shadow">
                        <Table className="align-items-center table-flush mt-2" responsive>
                            <thead className="rz-thead-style">
                                <tr>
                                    <th scope="col">#ID</th>
                                    <th scope="col">Property Name</th>
                                    <th scope="col">Company Name</th>
                                    <th scope="col">Value</th>
                                    <th scope="col">Total Token</th>
                                    <th scope="col">Token Value</th>
                                    <th scope="col">Total Token Bought</th>
                                    <th scope="col">Total Bought %</th>
                                    <th scope="col">Total Token Pending</th>
                                    <th scope="col">Total Pending %</th>
                                    <th scope="col">Created At</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Stage</th>
                                    <th scope="col">Token Created Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {properties && properties?.data?.length > 0 ?
                                    properties?.data?.map((item, i) => (
                                        <tr key={i}>
                                            <th>{item.id}</th>
                                            <th
                                                scope="row">
                                                <Media
                                                    style={{
                                                        "pointer-events": "none"
                                                    }}
                                                    className="align-items-center">
                                                    <div
                                                        className="avatar rounded-circle mr-3"
                                                    >
                                                        <img alt="..." src={item?.featured_image ?
                                                            item?.featured_image : item?.medias ? getThumbnailFromVideo(item?.medias[0]?.url) : null} />
                                                    </div>
                                                    <span className="mb-0 text-sm">
                                                        <div>{item.name}</div>
                                                        <small className="text-muted">
                                                            {item?.location?.address}
                                                        </small>
                                                    </span>
                                                </Media>
                                            </th>
                                            <th>{item?.LLPCompany?.name}</th>
                                            <td>
                                                {moneyFormat(item.total_property_value)}
                                            </td>
                                            <th>{item?.total_property_sqyds}</th>
                                            <th>{item?.current_month_price}</th>
                                            <th>{item?.total_sqyds_sold}</th>
                                            <th>{((item?.total_sqyds_sold / (item?.total_property_sqyds == 0 ? 1 : item?.total_property_sqyds)) * 100)?.toFixed(2)}</th>
                                            <th>{item?.total_property_sqyds - item?.total_sqyds_sold}</th>
                                            <th>{(((item?.total_property_sqyds - item?.total_sqyds_sold) / (item?.total_property_sqyds == 0 ? 1 : item?.total_property_sqyds)) * 100)?.toFixed(2)}</th>
                                            <td>{moment(item.created_at).format("D-MMM-Y")}</td>
                                            <td>{item?.status}</td>
                                            <td>
                                                <Badge
                                                    color=""
                                                    className={`badge badge-pill badge-${item?.propertyType?.id == 1
                                                        ? "success"
                                                        : "primary"
                                                        } mr-4`}
                                                >
                                                    {item?.propertyType?.name}
                                                </Badge>
                                            </td>
                                            <td>{item?.stage}</td>
                                            <td>{moment(item.updated_at).format("D-MMM-Y")}</td>
                                        </tr>
                                    )) : (
                                        <></>
                                    )}
                            </tbody>
                        </Table>
                        {properties?.data?.length <= 0 &&
                            <NotFound
                                message="Property Not Found."
                            />
                        }
                    </Card>
                </div>
            </Row>
        </>
    );
};

export default PropertyList;
