import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Button, Card, Container, Spinner,Badge, Table } from "reactstrap";
import IconEyeImage from "./../../../assets/img/Icon-eye.png"
import { formatNumberWithRupeeSymbol } from "utils";

const PaymentList = ({ data }) => {
    return (
        <>
            <Card className="border-none">
                <Table responsive className="custom-table-div">
                    <thead className="page-thead-style">
                        <tr>
                            <th className="d-flex align-items-center">
                                Month/YEAR
                                <div className="ml-3">
                                    <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                </div>
                            </th>

                            <th>
                                <div className="d-flex align-items-center">
                                    shareholders
                                    <div className="ml-3">
                                        <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </th>

                            <th>
                                <div className="d-flex align-items-center">
                                    AMOUNT Paid
                                    <div className="ml-3">
                                        <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </th>


                            <th>
                                <div className="d-flex align-items-center">
                                    amount pending
                                    <div className="ml-3">
                                        <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="d-flex align-items-center">
                                    status
                                    <div className="ml-3">
                                        <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </th>

                            <th>ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody className="page-tbody-cell">
                        {(
                            data?.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td
                                            style={{ cursor: 'pointer' }}
                                            className="text-primary">
                                            <NavLink
                                                to={{
                                                    pathname: `/company/RentalPayOutMonthandYear/${item?.month}/${item?.year}?property_id=${localStorage.getItem("property_id")}`,
                                                    state:{
                                                        property_id:localStorage.getItem("property_id")
                                                    }
                                                }}
                                            >
                                                {item?.month} {item?.year}
                                            </NavLink>
                                        </td>
                                        <td> {item?.uniqueShareholders} </td>
                                        <td> {formatNumberWithRupeeSymbol(item?.paidSum)} </td>
                                        <td> {formatNumberWithRupeeSymbol(item?.pendingSum)} </td>
                                        <td> {item?.overallStatus == 'Pending' ?
                                            <Badge className="rental-payout-pending">
                                                {item.overallStatus}
                                            </Badge> :
                                            <Badge className="rental-payout-paid">
                                                {item.overallStatus}
                                            </Badge>
                                        } </td>
                                        <td className="text-left">
                                            <NavLink
                                                to={{
                                                    pathname: `/company/RentalPayOutMonthandYear/${item?.month}/${item?.year}?property_id=${localStorage.getItem("property_id")}`,
                                                    state:{
                                                        property_id:localStorage.getItem("property_id")
                                                    }
                                                }}
                                            >
                                                <img src={IconEyeImage} alt="" />
                                            </NavLink>
                                        </td>
                                    </tr>
                                );
                            })
                        )}
                    </tbody>
                </Table>
            </Card>
        </>
    );
};

export default PaymentList;
