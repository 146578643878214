import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { Badge, Button, Col, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import classnames from 'classnames'
import { api } from 'helpers';
import { usePropertyHostedByQuery } from 'hooks/usePropertyHostedBy';
import { useUpdatePropertyHostedByClassMutation } from 'hooks/usePropertyHostedBy';

import { uploadImage } from 'helpers/image.helper';
import { checkNotChannelPartner } from 'utils';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import NotFound from './../../../components/NotFound/NotFound'
import classNames from 'classnames';
import { propertyActions } from 'redux/actions';
import { HiOutlinePlusCircle } from 'react-icons/hi';
import { FaRegTrashAlt } from 'react-icons/fa';
import * as yup from 'yup';
import { FiEdit } from 'react-icons/fi';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAddHighlightMutataion } from 'hooks/usePropertyHostedBy';
import { useDeleteHighlightMutataion } from 'hooks/usePropertyHostedBy';
import { useUpdateHighlightMutataion } from 'hooks/usePropertyHostedBy';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { getFileNameFromUrl } from 'utils';
import { checkAttechment } from 'utils';

const CustomModal = ({ title, content, onClose }) => {
    return (
        <Modal isOpen={true} toggle={onClose}>
            <ModalHeader toggle={onClose}>{title}</ModalHeader>
            <ModalBody>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '20px',
                    }}
                >
                    <i className="fas fa-check-circle" style={{
                        fontSize: '80px',
                        color: "#28C76F"
                    }} />
                    <div>
                        <p className='mt-2'
                            style={{
                                textAlign: "center",
                            }}>
                            {content}
                        </p>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button
                    className="btn btn-outline-primary"
                    onClick={onClose}>
                    Ok
                </Button>
            </ModalFooter>
        </Modal>
    );
};
const HighlightSchema = yup.object().shape({
    header: yup.string().required('Required'),
    body: yup.string().required('Required')
})
const PropertyHostedBy = ({ stepper, type, parentStepper }) => {
    const [modal2, setModal2] = useState(false);
    const [mode, setMode] = useState("ADD");
    const toggle2 = () => setModal2(!modal2);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editId, setEditID] = useState(null);
    const [Id, setId] = useState(null);
    const [selectedDoc, setSelectedDoc] = useState(null);
    const [featurePreview, setFeaturePreview] = useState('');
    const [featureImage, setFeatureImage] = useState(null);
    const [isLastMessageOpen, setisLastMessageOpen] = useState(false);
    const dispatch = useDispatch();
    const property = useSelector(state => state.propertyReducer.property);
    const { data: propertyHostedBy, refetch } = usePropertyHostedByQuery(property?.id)
    const { mutate: updateHostedBy, isLoading: updating } =
        useUpdatePropertyHostedByClassMutation();
    const { mutate: addHighlight, isLoading: addLoading } = useAddHighlightMutataion()
    const { mutate: deleteHighlight, isLoading: deleteLoading } = useDeleteHighlightMutataion()
    const { mutate: updateHighlight, isLoading: updateLoading } = useUpdateHighlightMutataion()
    const { register, handleSubmit, setValue, getValues, formState: { errors } } = useForm({})
    const closeBtn = (
        <button className="close" onClick={toggle2} type="button">
            &times;
        </button>
    );
    const { register: register1,
        handleSubmit: handleSubmit1, watch,
        setValue: setValue1,
        reset: reset1,
        trigger: trigger1,
        formState: { errors: errors1, isValid: isValid1 } } = useForm({})
    const { register: register2,
        handleSubmit: handleSubmit2,
        setValue: setValue2,
        reset: reset2,
        trigger: trigger2,
        formState: { errors: errors2, isValid: isValid2 } } = useForm({
            resolver: yupResolver(HighlightSchema)
        })
    const watchAllFields = watch();
    const [collapse, setCollapse] = useState([]);
    const toggleCollapse = (index) => {
        let collapseCopy = [...collapse];
        collapseCopy[index] = !collapseCopy[index];
        setCollapse(collapseCopy);
    }
    const allFieldsFilled =
        watchAllFields?.name &&
        watchAllFields?.address &&
        watchAllFields?.total_projects &&
        watchAllFields?.phone &&
        watchAllFields?.total_ongoing_projects && featurePreview;

    const onFeatureImageChange = async (e) => {
        if (e.target.files[0]) {
            let file = e.target.files[0]
            const reader = new FileReader()
            reader.onload = () => setFeaturePreview(reader.result);
            reader.readAsDataURL(file)
            setFeatureImage(file);
        } else {
            setFeaturePreview('')
            setFeatureImage('');
        }
    }
    const onSubmit2 = async (data) => {
        data.hosted_by_id = propertyHostedBy?.id;
        if (mode == 'ADD') {
            await addHighlight(data, {
                onSuccess: () => {
                    refetch();
                    toggle2();
                }
            })
        } else if (mode == 'EDIT') {
            await updateHighlight({ data, id: Id }, {
                onSuccess: () => {
                    refetch();
                    toggle2();
                }
            })
        }

    }
    useEffect(() => {
        if (propertyHostedBy) {
            reset1({
                name: propertyHostedBy?.name,
                address: propertyHostedBy?.address,
                total_projects: propertyHostedBy?.total_projects,
                phone: propertyHostedBy?.phone,
                whatsapp_no: propertyHostedBy?.whatsapp_no,
                secondary_address: propertyHostedBy?.secondary_address,
                issuer_profile_description: propertyHostedBy?.issuer_profile_description,
                total_ongoing_projects: propertyHostedBy?.total_ongoing_projects,
            });
            setFeaturePreview(propertyHostedBy?.logo)
        }
        else {
            setFeaturePreview(null)
        }
    }, [propertyHostedBy, property]);

    const onSubmit = async () => {
        let data = {
            document_id: getValues('document_id'),
            document_type: getValues('document_type')
        }
        if (selectedDoc) {
            const formDataNew = new FormData();
            formDataNew.append("file", selectedDoc)
            try {
                const res = await api.post("/file",
                    formDataNew
                );
                data.document_url = res?.url
            } catch (ex) {
                return;
            }
        }
        var documents = [];
        if (propertyHostedBy?.documents) {
            var documentslist = propertyHostedBy?.documents?.filter((d) => (d.document_id) != (data.document_id));
            if (editId) {
                documentslist = propertyHostedBy?.documents?.filter((d) => (d.document_id) != editId);
            }
            documents = [...documentslist]
            documents.push(data)
        }
        else {
            documents = [data]
        }
        documents = {
            documents: documents
        }
        updateHostedBy({
            variables: {
                id: property?.id,
                input: documents,
            },
        });
        setIsModalOpen(!isModalOpen)
        setSelectedDoc(null)
        setEditID(null)
    }
    const onSubmit1 = async (data) => {
        if (featureImage) {
            data.logo = await uploadImage(featureImage)
        }
        updateHostedBy({
            variables: {
                id: property?.id,
                input: data,
            },
        }, {
            onSuccess: () => {
                // if (propertyHostedBy)
                //dispatch(propertyActions.getProperty(property.id))
                toast.success("Hosted By Updated Successfully");
            },
            onError: (error) => {
                toast.error(error?.response?.data?.message);
            },
        });
        if (!propertyHostedBy && !checkNotChannelPartner()) {
            setisLastMessageOpen(true)
        }
    }
    const addEditModal = (faqId) => {
        if (faqId != null) {
            var faq = propertyHostedBy?.documents?.find((id) => id?.document_id == faqId);
            setEditID(faqId)
        }
        setValue('document_id', faq?.document_id)
        setValue('document_type', faq?.document_type)
        setValue('document_url', faq?.document_url)
        setIsModalOpen(!isModalOpen)
    }
    const addEditModal1 = (mode, data = null) => {
        if (mode == 'ADD') {
            setMode("ADD")
            reset2({
                header: '',
                body: ''
            })
            setModal2(true);
        } else if (mode == 'EDIT') {
            setId(data.id);
            setMode('EDIT')
            reset2(
                {
                    header: data.header,
                    body: data.body
                }
            )
            setModal2(true);
        }

    }
    const removePropertyFaq = (id) => {
        if (window.confirm('Are you sure?')) {
            var documentslist = propertyHostedBy?.documents?.filter((d) => (d?.document_id) != (id));
            var documents = {
                documents: documentslist
            }
            updateHostedBy({
                variables: {
                    id: property?.id,
                    input: documents,
                },
            });
        }
    }
    const removeHighlight = async (id) => {
        if (window.confirm('Are you sure? Yuo wants to delete highlight')) {
            await deleteHighlight({
                highlight_id: id,
                hosted_by_id: propertyHostedBy?.id
            }, {
                onSuccess: () => {
                    refetch();
                }
            })
        }
    }
    return (
        <Fragment>
            <div className='content-header'>
                <h3 className='mb-0 rz-heading rz-text-18'>{property?.share_type == 'NCD' ? 'Issuer Details' : 'Asset Hosted By'}</h3>
            </div>
            <Form onSubmit={handleSubmit1(onSubmit1)}>
                <Row>
                    <FormGroup tag={Col} md='6'>
                        <Label className='form-label' htmlFor={`name`}>
                            Name <span className='required-star'>*</span>
                        </Label>
                        <input
                            id={`name`}
                            placeholder='eg. Urbanyards LLP'
                            {...register1(`name`)}
                            className={classnames({ 'is-invalid': errors1.name, 'form-control': true })}
                        />
                    </FormGroup>
                    <FormGroup tag={Col} md='6'>
                        <Label className='form-label' htmlFor={`address`}>
                            Address <span className='required-star'>*</span>
                        </Label>
                        <input
                            id={`address`}
                            placeholder='eg. Hyderabad, Telangana, India '
                            {...register1(`address`)}
                            className={classnames({ 'is-invalid': errors1.address, 'form-control': true })}
                        />
                    </FormGroup>
                    <FormGroup tag={Col} md='6'>
                        <Label className='form-label' htmlFor={`total_projects`}>
                            Total Project <span className='required-star'>*</span>
                        </Label>
                        <input
                            id={`total_projects`}
                            placeholder='20'
                            {...register1(`total_projects`)}
                            className={classnames({ 'is-invalid': errors1.total_projects, 'form-control': true })}
                        />
                    </FormGroup>
                    <FormGroup tag={Col} md='6'>
                        <Label className='form-label' htmlFor={`on_going_projects`}>
                            On-going projects <span className='required-star'>*</span>
                        </Label>
                        <input
                            id={`total_ongoing_projects`}
                            placeholder='2'
                            {...register1(`total_ongoing_projects`)}
                            className={classnames({ 'is-invalid': errors1.total_ongoing_projects, 'form-control': true })}
                        />
                    </FormGroup>
                    <FormGroup tag={Col} md='6'>
                        <Label className='form-label' >
                            Phone Number <span className='required-star'>*</span>
                        </Label>
                        <input

                            placeholder='1234567890'
                            {...register1(`phone`)}
                            className={classnames({ 'is-invalid': errors1.phone, 'form-control': true })}
                        />
                    </FormGroup>
                    <FormGroup tag={Col} md='6'>
                        <Label className='form-label' >
                            Whatsapp Number <span className='required-star'>*</span>
                        </Label>
                        <input

                            placeholder='1234567890'
                            {...register1(`whatsapp_no`)}
                            className={classnames({ 'is-invalid': errors1.whatsapp_no, 'form-control': true })}
                        />
                    </FormGroup>
                    <FormGroup tag={Col} md='6'>
                        <Label className='form-label' htmlFor={`secondary_address`}>
                            Prime Location  <span className='required-star'>*</span>
                        </Label>
                        <input
                            id={`secondary_address`}
                            placeholder='Enter prime location'
                            {...register1(`secondary_address`)}
                            className={classnames({ 'is-invalid': errors1.secondary_address, 'form-control': true })}
                        />
                    </FormGroup>
                    <FormGroup tag={Col} md='6' className='d-flex flex-column justify-content-start'  >

                        <div>
                            <Label className='form-label'>Hosted By <span className='required-star'>*</span></Label>

                            <div className="custom-file">
                                <input type="file" {...register(`logo`,
                                    { onChange: (e) => onFeatureImageChange(e) })}
                                    className={classnames({ 'custom-file-input': true })} id="customFile" accept="image/*" />
                                <label className="custom-file-label" htmlFor="customFile">{'Choose file'}</label>
                            </div>
                            <small className='text-muted'>Required image resolution 200x200</small>
                        </div>
                        <div className='mt-3'>
                           {
                            checkAttechment(featurePreview)?<a  href={featurePreview} target='_blank'>Attachment</a>:null
                           }
                        </div>
                        {/* {featurePreview && (
                            <div className='pl-5 mt-3 mb-2'>
                                <img src={featurePreview} className="img-fluid mr-4 rounded" style={{ width: '100px' }} />
                            </div>
                        )} */}
                    </FormGroup>
                    <FormGroup tag={Col} md='12'>
                        <Label >Issuer Profile Description  <span className='required-star'>*</span></Label>
                        <textarea
                            className={classnames({ 'is-invalid': errors.issuer_profile_description, 'form-control': true })}
                            {...register1(`issuer_profile_description`)}
                            rows="4"
                            placeholder="Enter Description" />
                    </FormGroup>

                </Row>
                {/* <Row>
                    <Col>
                    
                    </Col>
                    <FormGroup className="mt-3" tag={Col} md='12'>
                        <Label htmlFor="about-property">More Info </Label>
                        <textarea
                            className='form-control'
                            rows="4"
                        />
                    </FormGroup>
                </Row> */}
                <div className={`d-flex justify-content-${checkNotChannelPartner() ? "end" : "between"
                    } mb-4`}>
                    {
                        !checkNotChannelPartner() &&
                        <Button color='primary' className='btn-prev' onClick={() => stepper.previous()}>
                            <i className='fa fa-chvron-left align-middle mr-sm-25 mr-0'></i>
                            <i className='fa fa-chevron-left'></i>
                            <span className='align-middle d-sm-inline-block d-none'>Previous</span>
                        </Button>
                    }
                    <Button
                        type='submit'
                        disabled={!allFieldsFilled}
                        className='rz-button-primary'>
                        <span className='align-middle d-sm-inline-block d-none'>Submit</span>
                    </Button>
                </div>
            </Form>
            {
                property?.share_type == 'NCD' &&
                <>
                    <div className='content-header border-top py-4 d-flex justify-content-between align-items-center'>
                        <h3 className='mb-0 all-page-heading rz-text-16'>Issuer Profile Highlights</h3>
                        <button disabled={!propertyHostedBy?.id} onClick={() => addEditModal1("ADD")} className="btn rz-button-primary" type="button">
                            <HiOutlinePlusCircle className="mr-1" style={{ fontSize: "20px" }} /> Add
                        </button>
                    </div>
                    <Row>
                        <Col>
                            {propertyHostedBy?.PropertyHostedByHighlight && propertyHostedBy.PropertyHostedByHighlight.length > 0 && (
                                <div className="accordion">
                                    {
                                        propertyHostedBy.PropertyHostedByHighlight.map((item, i) => {
                                            return (
                                                <div className="card" key={i}>
                                                    <div className="card-header p-1 d-flex justify-content-between" id={`heading-${i}`}>
                                                        <h2 className="mb-0">
                                                            <button onClick={() => toggleCollapse(i)} key={i} className="text-dark btn btn-link btn-block text-left all-page-heading rz-text-14" type="button">
                                                                {item.header}
                                                            </button>
                                                        </h2>
                                                        <div className='d-flex align-items-center'>
                                                            <button onClick={() => removeHighlight(item.id)} key={i} className="text-muted btn-sm btn btn-link" type="button">
                                                                <FaRegTrashAlt style={{ color: "#6E6B7B", fontSize: "18px" }} />
                                                            </button>
                                                            <button onClick={() => addEditModal1("EDIT", item)} className="text-muted btn-sm btn btn-link" type="button">
                                                                <FiEdit style={{ color: "#6E6B7B", fontSize: "18px" }} />
                                                            </button>
                                                            <button onClick={() => toggleCollapse(i)} className="text-muted btn-sm btn btn-link" type="button">
                                                                <i style={{ color: "#6E6B7B", fontSize: "16px" }} className={`fas fa-chevron-${collapse[i] ? 'up' : 'down'}`}></i>
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div id={`collapse-${i}`} className={`collapse  ${collapse[i] ? 'show' : ''}`}>
                                                        <div className="card-body all-page-heading rz-text-14">
                                                            {item.body}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )}
                        </Col>
                    </Row>
                </>
            }
            {checkNotChannelPartner() && property?.share_type != 'NCD' &&
                <>
                    <div className='content-header border-top py-4 d-flex justify-content-between align-items-center'>
                        <h3 className='mb-0 rz-heading rz-text-18'>Documents List</h3>
                        <button
                            disabled={!propertyHostedBy}
                            onClick={() => addEditModal()} className="btn rz-button-primary" type="button">
                            <HiOutlinePlusCircle className="mr-2" style={{ fontSize: "20px" }} /> Add Documents
                        </button>
                    </div>
                    <Row>
                        <Col>
                            {propertyHostedBy?.documents && (
                                <div className="accordion">
                                    {
                                        propertyHostedBy?.documents.map((item, i) => {
                                            return (
                                                <div className="card" key={i}>
                                                    <div className="d-flex justify-content-between
                                            allign-item-center
                                            pl-2
                                            mt-4">
                                                        <div>
                                                            <h3>{item.document_type}</h3>
                                                            <h4>{item.document_id}</h4>
                                                        </div>
                                                        <div>
                                                            <div className='d-flex align-items-center'>
                                                                <button onClick={() => removePropertyFaq(item.document_id)} key={i} className="text-muted btn-sm btn btn-link" type="button">
                                                                    <i className='fa fa-trash'></i>
                                                                </button>
                                                                <button onClick={() => addEditModal(item.document_id)} className="text-muted btn-sm btn btn-link" type="button">
                                                                    <i className='fa fa-edit'></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )}
                            {propertyHostedBy?.documents?.length <= 0 &&
                                <NotFound
                                    message="Add First Document"
                                />
                            }
                        </Col>
                    </Row>
                </>
            }
            <div className='d-flex align-items-center justify-content-between mt-5'>
                {
                    checkNotChannelPartner() && (
                        <>
                            <Button type='button' color='success' onClick={() => stepper.next()} className='btn-next' >
                                <span className='align-middle d-sm-inline-block d-none'>Skip</span>
                                <i className='fa fa-fast-forward align-middle mt-1 mr-sm-25 mr-0'></i>
                            </Button>
                            <div className='d-flex align-items-center justify-content-end'>
                                <Link to='/admin/property'>
                                    <Button type='button' className='rz-button-outline-primary'>
                                        Cancel
                                    </Button>
                                </Link>
                                <Button className='rz-button-outline-primary mx-3' onClick={() => stepper.previous()}>
                                    <BsArrowLeftShort style={{ fontSize: "22px" }} />
                                    <span className='align-middle d-sm-inline-block d-none'>Back</span>
                                </Button>
                                <Button type='submit' className='rz-button-primary'
                                    onClick={() => stepper.next()}>
                                    <BsArrowRightShort style={{ fontSize: "22px" }} />
                                    <span className='align-middle d-sm-inline-block d-none'>Next</span>
                                </Button>
                            </div>
                        </>
                    )
                }
            </div>

            {isLastMessageOpen && (
                <CustomModal
                    title="Added successfully"
                    content="You asset has been onboarded successfully to our platform. Please wait for 24-48 hours as our sales team will be reaching to you before the asset is listed on our Ryzer app."
                    onClose={() => setisLastMessageOpen(false)}
                />
            )}
            <Modal isOpen={isModalOpen} centered={true} className='modal-lg'>
                <Form>
                    <ModalHeader>
                        Upload Documents
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label className='form-label' htmlFor={`question`}>
                                Type
                            </Label>
                            <input
                                {...register(`document_type`)}
                                className={classnames({ 'is-invalid': errors.document_type, 'form-control': true })}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className='form-label' htmlFor={`question`}>
                                Document ID
                            </Label>
                            <input
                                {...register(`document_id`)}
                                className={classnames({ 'is-invalid': errors.document_id, 'form-control': true })}
                            />
                        </FormGroup>
                        <FormGroup>
                            <div className="custom-file">
                                <input
                                    type="file"
                                    className="custom-file-input"
                                    id="customFile"
                                    onChange={(e) => setSelectedDoc(e.target.files[0])}
                                />
                                <label className="custom-file-label" for="customFile">
                                    {selectedDoc ? selectedDoc.name : "Choose file"}
                                </label>
                                {getValues("document_url") && getValues("document_url") != "" &&
                                    <a href={`${getValues("document_url")}`} target="_blank">View Attchement</a>
                                }
                            </div>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => setIsModalOpen(!isModalOpen)}>
                            Cancel
                        </Button>
                        {' '}
                        <Button
                            onClick={() => onSubmit()}
                            color="primary"
                        >
                            {'Save'}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
            <Modal centered={true} isOpen={modal2} toggle={toggle2} className='recall-modal' style={{ width: "700px", maxWidth: "100%" }}>

                <ModalHeader toggle={toggle2} close={closeBtn}>
                    <h1 className="mt-1 all-page-heading rz-text-18"  >Add Issuer Profile Highlight</h1>
                </ModalHeader>
                <form onSubmit={handleSubmit2(onSubmit2)}>
                    <ModalBody className='py-0'>
                        <div>
                            <label>
                                Header *
                            </label>
                            <div className=''>
                                <input
                                    className="form-control"
                                    type="text"
                                    {...register2('header')}
                                    placeholder='Enter header'
                                />
                            </div>
                        </div>
                        <div className='mt-3'>
                            <label>
                                Body *
                            </label>
                            <textarea placeholder='Enter body' rows={3} cols={10} {...register2('body')} className='form-control rz-text-primary p-3' />
                        </div>


                    </ModalBody>
                    <ModalFooter>
                        <div className='d-flex justify-content-end'>
                            <div className='d-flex align-items-center'>

                                <Button type='button' onClick={toggle2} className='rz-button-outline-primary'>
                                    Cancel
                                </Button>
                                <Button type='submit' className='btn-next ml-3 d-flex justify-content-center align-items-center rz-button-primary'>

                                    <span className='align-middle d-sm-inline-block d-none' >
                                        Save
                                    </span>
                                </Button>

                            </div>
                        </div>
                    </ModalFooter>
                </form>



            </Modal>
        </Fragment>
    )
}

export default PropertyHostedBy;