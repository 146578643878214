import {
    Badge,
    Card,
    Media,
    Table,
    Container,
    Row,
    UncontrolledTooltip,
    Button,
    Col,
    Spinner,
} from "reactstrap";
import moment from "moment";
import { moneyFormat } from "utils";
import { useState } from "react";
import NotFound from "components/NotFound/NotFound";
import { useGetPropertyFilter } from "hooks/useReport";
import { PROPERTY_TYPE_VALUE } from "utils";
import exportFromJSON from "export-from-json";
import { useGetAchivePropertyFilter } from "../../hooks/useReport";

const ArchivePropertyList = () => {
    const [filtterData, setFilterData] = useState({
        status: "",
        dao: "",
        type: ""
    })
    const { data: properties, isLoading: loading, refetch } = useGetAchivePropertyFilter({
        // filter: filtterData
    });
    const exportPDF = () => {
        const title = `property-list-report`;
        const report_data = properties?.data?.map((item) => {
            return ({
                "ID": item.id,
                "Property Name": item.name,
                "Created At": moment(item.created_at).format("D-MMM-Y"),
                "Status": item?.status,
                "Token Created Date": moment(item.updated_at).format("D-MMM-Y"),
                "Stage": item?.stage
            });
        })

        exportFromJSON({ data: report_data, fileName: title, exportType: 'csv' });
    };

    return (
        <>
            {/* <div className="row">
                <div className='col-4'>
                    <label>Select Property Status</label>
                    <select
                        value={filtterData.status}
                        onChange={(e) => setFilterData({
                            ...filtterData,
                            status: e.target.value
                        })}
                        className='form-control'>
                        <option selected value=''>Select Status</option>
                        <option value='Yes'>Active</option>
                        <option value='No'>In-Active</option>
                    </select>
                </div>
                <div className='col-4'>
                    <label>Select DAO Status</label>
                    <select
                        value={filtterData.dao}
                        onChange={(e) => setFilterData({
                            ...filtterData,
                            dao: e.target.value
                        })}
                        className='form-control'>
                        <option value=''>Select Options</option>
                        <option value='Yes'>Yes</option>
                        <option value='No'>No</option>
                    </select>
                </div>
                <div className='col-4'>
                    <label>Select Asset Type</label>
                    <select
                        value={filtterData.type}
                        onChange={(e) => setFilterData({
                            ...filtterData,
                            type: e.target.value
                        })}
                        className='form-control'>
                        <option value=''>Select Asset Type</option>
                        <option value={`${PROPERTY_TYPE_VALUE?.LANDPARCEL}`}>Land Parcel</option>
                        <option value={`${PROPERTY_TYPE_VALUE?.RESIDENTIAL}`}>Residential</option>
                        <option value={`${PROPERTY_TYPE_VALUE?.COMMERCIAL}`}>Commercial</option>
                    </select>
                </div>
            </div> */}
            <div className="row pb-2 pt-4">
                <div className="col-12">
                    <button
                        onClick={() => exportPDF()}
                        className="btn rz-button-primary mr-3" type="button">
                        Generate Report
                    </button>
                    <button onClick={() => {
                        setFilterData({
                            status: "",
                            dao: "",
                            type: ""
                        });
                    }}
                        className="btn  rz-button-primary" type="button">
                        Clear Selection
                    </button>
                </div>
            </div>
            <Row className="mt-3">
                <div className="col">
                    <Card className="shadow">
                        <Table className="align-items-center table-flush mt-3" responsive>
                            <thead className="rz-thead-style">
                                <tr>
                                    <th scope="col">#ID</th>
                                    <th scope="col">Property Name</th>
                                    <th scope="col">Created At</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Stage</th>
                                </tr>
                            </thead>
                            <tbody>
                                {properties && properties?.data?.length > 0 ?
                                    properties?.data?.map((item, i) => (
                                        <tr key={i}>
                                            <th>{item.id}</th>
                                            <th
                                                scope="row">
                                                <Media
                                                    style={{
                                                        "pointer-events": "none"
                                                    }}
                                                    className="align-items-center">
                                                    <div
                                                        className="avatar rounded-circle mr-3"
                                                    >
                                                        <img alt="..." src={item?.featured_image ?
                                                            item?.featured_image : null} />
                                                    </div>
                                                </Media>
                                            </th>
                                            <td>{moment(item.created_at).format("D-MMM-Y")}</td>
                                            <td>{item?.status}</td>
                                            <td>{item?.stage}</td>
                                            <td>{moment(item.updated_at).format("D-MMM-Y")}</td>
                                        </tr>
                                    )) : (
                                        <></>
                                    )}
                            </tbody>
                        </Table>
                        {properties?.data?.length <= 0 &&
                            <NotFound
                                message="Property Not Found."
                            />
                        }
                    </Card>
                </div>
            </Row>
        </>
    );
};

export default ArchivePropertyList;
