import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useGetTokenSellerMyCommissionById } from 'hooks/useTokenSellect';
import MyCommissionById from '../comman/MyCommissionById/MyCommissionById';
export default function TokenSellerMyCommissionById() {
    const location = useLocation();
    const { asset = null,type } = location?.state;
    const { data } = useGetTokenSellerMyCommissionById({
        property_id: asset?.property_id
    })
    const total = data?.total
    return (
        <>
            <div className="px-4">
                <MyCommissionById
                    total={total}
                    asset={asset}
                    data={data}
                    type={type}
                />
            </div>
        </>
    )
}
