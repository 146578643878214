import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Badge, Button, Col, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import classnames from 'classnames'
import NotFound from 'components/NotFound/NotFound';
import { HiOutlinePlusCircle } from 'react-icons/hi';
import { usePropertyActivityQuery } from 'hooks/usePropertyActivity';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import {
    useCreatePropertyActivityClassMutation,
    useUpdatePropertyActivityClassMutation,
    useDeletePropertyActivityMutation
} from 'hooks/usePropertyActivity';

const AssetUpdate = ({ stepper, type, parentStepper }) => {
    const { property } = useSelector(state => state.propertyReducer);
    const { data: propertyActivity } = usePropertyActivityQuery(
        localStorage.getItem("property_id") ? localStorage.getItem("property_id") : 0
    );
    const { mutate: createActivity, isLoading: creating } =
        useCreatePropertyActivityClassMutation();
    const { mutate: updateActivity, isLoading: updating } =
        useUpdatePropertyActivityClassMutation();
    const { mutate: deleteActivity } = useDeletePropertyActivityMutation();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm({})
    const [isModalOpen, setIsModalOpen] = useState(false);
    const onSubmit = async (data) => {
        if (data?.id) {
            console.log({
                id: data?.id,
                input: {
                    property_id: property?.id,
                    type: data?.type,
                    message: data?.message
                },
            })
            updateActivity({
                variables: {
                    id: data?.id,
                    input: {
                        property_id: property?.id,
                        type: data?.type,
                        message: data?.message
                    },
                },
            });
        } else {
            createActivity({
                variables: {
                    input: {
                        property_id: property?.id,
                        type: data?.type,
                        message: data?.message
                    },
                }
            });
        }

        setIsModalOpen(!isModalOpen)
    }

    const onPropertyStatusChange = (id, e) => {
        e?.preventDefault()
        updateActivity({
            variables: {
                id: id,
                input: {
                    status: e.target.checked ? true : false,
                },
            },
        });
    };
    const addEditModal = (faqId) => {
        var faq = propertyActivity?.find((id) => id?.id == faqId);
        setValue('message', faq?.message)
        setValue('type', faq?.type)
        setValue('id', faq?.id)
        setIsModalOpen(!isModalOpen)
    }

    const removePropertyFaq = (id) => {
        if (window.confirm('Are you sure?')) {
            deleteActivity(id);
        }
    }

    return (
        <Fragment>
            <div className='content-header py-4 d-flex justify-content-between align-items-center'>
                <h3 className='mb-0 rz-heading rz-text-16'>Property Updates List</h3>
                <button onClick={() => addEditModal()} className="btn rz-button-primary" type="button">
                    <HiOutlinePlusCircle className="mr-2" style={{ fontSize: "20px" }} /> Add Property Updates
                </button>
            </div>
            <Row>
                <Col>
                    <div className="accordion">
                        {
                            propertyActivity?.map((item, i) => {
                                return (
                                    <div className="card" key={i}>
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between mt-4">
                                                <div>
                                                    <h3>{item?.type}</h3>
                                                    <p>{item?.message}</p>
                                                </div>
                                                <div>
                                                    <div className='d-flex align-items-center'>
                                                        <button onClick={() => removePropertyFaq(item.id)} key={i} className="text-muted btn-sm btn btn-link" type="button">
                                                            <i className='fa fa-trash'></i>
                                                        </button>
                                                        <button onClick={() => addEditModal(item.id)} className="text-muted btn-sm btn btn-link" type="button">
                                                            <i className='fa fa-edit'></i>
                                                        </button>
                                                        <div className="custom-control custom-switch">
                                                            <input
                                                                type="checkbox"
                                                                checked={item.status == true ? true : false}
                                                                name={`property_activity-status`}
                                                                onChange={(e) =>
                                                                    onPropertyStatusChange(item.id, e)
                                                                }
                                                                className="custom-control-input"
                                                                id={`property-activity-status-${item.id}`}
                                                            />
                                                            <label
                                                                className="custom-control-label"
                                                                htmlFor={`property-activity-status-${item.id}`}
                                                            >
                                                                <Badge
                                                                    color=""
                                                                    className={`badge badge-pill badge-${item.status == true
                                                                        ? "success"
                                                                        : "primary"
                                                                        } mr-4`}
                                                                >
                                                                </Badge>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </Col>
            </Row>
            {propertyActivity?.length <= 0 &&
                <NotFound
                    message="No Property Risks Found."
                />
            }
            <Modal isOpen={isModalOpen} centered={true} className='modal-lg'>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader>
                        <h1 className='rz-heading rz-text-18'>Add Property Update</h1>
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label className='form-label' htmlFor={`question`}>
                                Type
                            </Label>
                            <input
                                {...register(`type`)}
                                className={classnames({ 'is-invalid': errors.name, 'form-control': true })}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className='form-label' htmlFor={`question`}>
                                Description
                            </Label>
                            <textarea
                                rows='4'
                                {...register(`message`)}
                                className={classnames({ 'is-invalid': errors.message, 'form-control': true })}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button className='rz-button-outline-primary' onClick={() => setIsModalOpen(!isModalOpen)}>
                            Cancel
                        </Button>
                        {' '}
                        <Button
                            className='rz-button-primary'
                        >
                            {'Save'}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>

            <div className='d-flex align-items-center justify-content-between mt-4'>
                <div>
                    <Button type='button' color='success' onClick={() => stepper.next()} className='btn-next mr-3' >
                        <span className='align-middle d-sm-inline-block d-none'>Skip</span>
                        <i className='fa fa-fast-forward align-middle mt-1 mr-sm-25 mr-0'></i>
                    </Button>
                </div>
                <div className='d-flex align-items-center justify-content-end'>
                    <Button className='rz-button-outline-primary mr-2 ml-3' onClick={() => stepper.previous()}>
                        <BsArrowLeftShort style={{ fontSize: "22px" }} />
                        <span className='align-middle d-sm-inline-block d-none'>Back</span>
                    </Button>

                    <Button type='submit' className='rz-button-primary' onClick={() => stepper.next()}>
                        <BsArrowRightShort style={{ fontSize: "22px" }} />
                        <span className='align-middle d-sm-inline-block d-none'>Next</span>
                    </Button>
                </div>
            </div>
        </Fragment>
    )
}

export default AssetUpdate;