import React, { useEffect, useRef, useState } from 'react'
import { Card, CardBody, CardSubtitle, Form, CardTitle, Nav, NavItem, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { moneyFormat } from 'utils';
import tickIcon from './../../../assets/img/tick.svg';
import cartIcon from './../../../assets/img/cart-icon.png';
import timeIcon from './../../../assets/img/timeline.png';
import briefcaseIcon from './../../../assets/img/briefcase.png';
import dollorIcon from './../../../assets/img/dollor.png';
import { useGetPlatformById } from 'hooks/useFeeManagement ';
import { useLocation } from 'react-router-dom';
import AssetOrderList from './AssetOrderList';
import { AiOutlineDownload } from "react-icons/ai";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useAssetListById, useGetAccount, useGetWithdrawTimeline, useMutateAssetWiseWithdraw } from '../../../hooks/useFeeManagement ';
import { useDispatch, useSelector } from 'react-redux';
import { accountAction } from 'redux/actions';
import { AiOutlineShoppingCart } from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import WithdrawTimeline from './WithdrawTimeline';
import { formatNumberWithRupeeSymbol } from 'utils';
//import PlatformOrder from './PlatformOrder';
const styles = {
    cardStyle: {
        display: 'flex',
        marginTop: "3%",
        justifyContent: "space-between"
    },
    textStyle: {
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 500,
        color: '#82868B',
    },
    numberStyle: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
        fontSize: '24px',
        color: '#7367F0',
    }
}
const spanStyle = {
    color: "#5E5873",
    fontFamily: 'Montserrat',
    fontWeight: 400,
}
const headingstyle = {
    fontFamily: 'Montserrat',
    color: "#5E5873",
    fontSize: '24px',
    fontWeight: 500,
}

const textStyle = {
    color: "#5E5873",
    fontFamily: 'Montserrat',
    fontSize: '15px',
    lineHeight: '24px',
    fontWeight: 500,
}
export default function AssetWiseFee() {
    const formRef = useRef()
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [modal1, setModal1] = useState(false);
    const toggle1 = () => setModal1(!modal1);
    const [modal2, setModal2] = useState(false);
    const toggle2 = () => setModal2(!modal2);
    const { mutate: withdraw } = useMutateAssetWiseWithdraw();
    const dispatch = useDispatch();
    const [tab, setTab] = useState(1);
    const { register, handleSubmit, reset,
        getValues,
        setValue, control, watch, trigger, formState: { errors } } = useForm({
            // resolver: yupResolver(Yup.object().shape({
            //     additional_information: Yup.string().required('Required')
            // }))
        })
    const closeBtn = (
        <button className="close" onClick={toggle} type="button">
            &times;
        </button>
    );
    const location = useLocation();
    const { fee = null, property_id = null } = location?.state;

    const { data } = useAssetListById({
        fee_type: fee?.name,
        property_id: property_id || localStorage.getItem("property_id")
    });
   
    useEffect(() => {
        dispatch(accountAction.getAccount(localStorage.getItem('company_id')))
    }, [])
    const { accounts = [], loading } = useSelector(
        (state) => state.getAccountReducer
    );
    const withdrawData = useGetWithdrawTimeline({
        property_id: localStorage.getItem('property_id'),
        fees_name: fee?.name
    })

    const total = data?.data?.counts;
    const ColorCard = ({ color, title, subTitle, icon }) => {
        const iconStyle = {
            fontSize: '36px',
            marginRight: '10px',
            color: `white`,
        };
        const titleStyle = {
            color: 'white',
            fontFamily: 'Montserrat',
            fontWeight: 600,
        };
        const subTitleStyle = {
            color: 'white',
            fontFamily: 'Montserrat',
            fontWeight: 400,
        };
        return (
            <Card style={{ backgroundColor: color, display: 'flex' }}>
                <CardBody style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: "space-between",
                    paddingTop: "40px",
                    paddingBottom: "40px"
                }}>
                    <div>
                        <CardTitle tag="h2" style={titleStyle}>{moneyFormat(title)}</CardTitle>
                        <CardSubtitle tag="p" style={subTitleStyle}>{subTitle}</CardSubtitle>
                    </div>
                    {icon && (<div style={{
                        marginLeft: 2
                    }}>
                        <img src={icon} />
                    </div>)}
                </CardBody>
            </Card>
        );
    };
    const [selectedOption, setSelectedOption] = useState(1);
    const NewCard = () => {
        const handleOptionChange = (option) => {
            setSelectedOption(option);
        };
        return (
            <Card className='p-2'>
                <div className="d-flex justify-content-between">
                    <div className="btn-group" role="group" aria-label="Options">
                        <button
                            type="button"
                            className={`btn mr-3 d-flex align-items-center ${selectedOption === 1 ? 'btn-primary' : 'white'
                                }`}
                            style={{ borderRadius: "7px" }}
                            onClick={() => handleOptionChange(1)}
                        >
                            <img src={cartIcon} />
                            <span style={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 500 }} className='ml-2'> Orders & transactions</span>
                        </button>
                        <button
                            type="button"
                            className={`btn d-flex align-items-center ${selectedOption === 2 ? 'btn-primary' : 'white'
                                }`}
                            style={{ borderRadius: "7px" }}
                            onClick={() => handleOptionChange(2)}
                        >
                            <img src={timeIcon} />
                            <span style={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 500 }} className='ml-2'>Withdraw timeline</span>
                        </button>
                    </div>
                </div>
            </Card>
        );
    };

    const onSubmit = async (values) => {
        values.fees_name = fee?.name;
        values.company_id = localStorage.getItem('company_id');
        values.property_id = localStorage.getItem('property_id');
        await withdraw(values, {
            onSuccess: (res) => {
                toggle();
                toggle2();
                withdrawData.refetch()
            }
        })
    }
    return (
        <>
            <div className="px-4">
                <div className="row mx-0">
                    <div className='col-6 pl-2'>
                        <h3 style={headingstyle}>{fee?.name}</h3>
                    </div>
                    <div className='col-6 d-flex justify-content-end'>
                        <button className='btn rz-button-primary' onClick={() => { toggle() }}>
                            <AiOutlineDownload style={{ fontSize: "20px" }} /> Withdraw amount
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 mt-4">
                        <div className="row">
                            <div className="col-6">
                                <ColorCard
                                    color="#E9573E"
                                    title={total?.totalCollectedFees}
                                    subTitle="Total collected"
                                    icon={briefcaseIcon}
                                />
                            </div>
                            <div className='col-6'>
                                <ColorCard
                                    color="#289B96"
                                    title={total?.totalWithdrawn || 0}

                                    subTitle="Total Withdrawn"
                                    icon={dollorIcon}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 mt-4">
                        <Card className='py-3 px-4' style={{ boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)" }}>
                            <div className="d-flex justify-content-between">
                                <h2 style={{
                                    fontFamily: 'Montserrat',
                                    fontWeight: 500,
                                    fontSize: '18px',
                                    color: '#5E5873',
                                }} tag="h2">Fee Breakup</h2>
                                {/* <p tag="p"
                                    style={{
                                        fontFamily: 'Montserrat',
                                        fontWeight: 400,
                                        fontSize: '12px',
                                        color: '#6E6B7B',
                                    }}
                                >Updated 1 hour ago <img className='ml-2' src={refreshIcon}/></p> */}
                            </div>

                            <div style={styles.cardStyle}>
                                <h2 tag="h2" style={styles.textStyle}>Total Orders</h2>
                                <CardSubtitle tag="h3" style={styles.numberStyle}>{total?.totalOrders}</CardSubtitle>
                            </div>

                            <div style={styles.cardStyle}>
                                <h2 tag="h2" style={styles.textStyle}>Total Customers</h2>
                                <CardSubtitle tag="h3" style={styles.numberStyle}>{total?.uniqueUserCount}</CardSubtitle>
                            </div>
                            {/* <div className='mt-5' style={styles.cardStyle}>
                                <h2 tag="h2" style={styles.textStyle}>Last Transaction</h2>
                                <p tag="h3"
                                    style={
                                        {
                                            fontFamily: 'Montserrat',
                                            fontWeight: 600,
                                            fontSize: '16px',
                                            color: '#797979',
                                        }
                                    }>{new Date(data?.data?.[0]?.created_at)?.toDateString()}</p>
                            </div> */}
                        </Card>
                    </div>
                </div>
                {/* <div className='mt-5'>
                    <NewCard />
                </div> */}
                <div className='d-flex pt-5'>
                    <Button
                        type="button cursor-pointer"
                        className={`rz-shadow-none ${tab === 1 ? 'rz-button-primary' : 'white'
                            }`}
                        onClick={() => setTab(1)}
                    >
                        <AiOutlineShoppingCart style={{ color: tab == 1 ? 'white' : '', fontSize: "20px" }} />
                        <span className=''> Orders & transactions</span>
                    </Button>
                    <Button
                        type="button"
                        className={`rz-shadow-none button cursor-pointer ${tab === 2 ? 'rz-button-primary' : 'white'
                            }`}
                        onClick={() => setTab(2)}
                    >
                        <BiTimeFive style={{ color: tab == 2 ? 'white' : '', fontSize: "20px" }} />
                        <span >Withdraw timeline</span>
                    </Button>
                </div>
                <div className='mt-3'>
                    {tab == 1 && <AssetOrderList name={fee?.name} data={data?.data} />}
                    {!withdrawData.isLoading && tab == 2 && <WithdrawTimeline data={withdrawData?.data} />}
                </div>
            </div>

            <Modal centered={true} isOpen={modal} toggle={toggle} className='recall-modal' style={{ width: "700px", maxWidth: "100%" }}>

                <ModalHeader toggle={toggle} close={closeBtn}>
                    <h1 className="mt-1" style={{ ...headingstyle, fontSize: "22px" }}>Request for amount withdraw</h1>
                </ModalHeader>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <ModalBody className='py-0'>

                        <div>
                            <label>
                                Enter amount *:
                            </label>
                            <div className=' d-flex align-items-center'>
                                <span class="currency-symbol" ><span>₹</span></span>
                                <input
                                    className="form-control expense-field"
                                    type="number"
                                    readOnly
                                    value={total?.totalCollectedFees}
                                    placeholder='Enter amount'
                                    {...register('amount')}
                                />
                            </div>
                        </div>
                        {/* <div className='mt-3'>
                            <label>
                                Select Company * :
                            </label>
                            <select className='form-control'>
                                <option value=''>Ryzer</option>
                            </select>
                        </div> */}
                        <div className='mt-3'>
                            <label>
                                Select Bank account * :
                            </label>
                            <select {...register('account_id')} className='form-control'>
                                {
                                    accounts && accounts.length != 0 &&
                                    accounts.map((item) => (
                                        <option value={item.id}>{item.metadata.bank.BANK}</option>
                                    ))
                                }

                            </select>
                        </div>
                        <div className='mt-3'>
                            <label>
                                Additional information :
                            </label>
                            <textarea placeholder='Enter additional information' {...register('additional_information')} rows={3} cols={10} className='form-control rz-text-primary p-3'></textarea>
                        </div>


                    </ModalBody>
                    <ModalFooter>
                        <div className='d-flex justify-content-end'>
                            <div className='d-flex align-items-center'>

                                <Button type='button' outline onClick={toggle} className='rz-button-outline-primary'>
                                    Cancel
                                </Button>
                                <Button type='submit' className='btn-next ml-3 d-flex justify-content-center align-items-center rz-button-primary'>

                                    <span className='align-middle d-sm-inline-block d-none' >
                                        Proceed
                                    </span>
                                </Button>
                                {/* onClick={() => { setModal(false); setModal1(true) }}  */}
                            </div>
                        </div>
                    </ModalFooter>
                </form>



            </Modal>

            <Modal centered={true} isOpen={modal1} toggle={toggle1} className='recall-modal' style={{ width: "700px", maxWidth: "100%" }}>

                <ModalHeader toggle={toggle1} close={closeBtn}>
                    <h1 className="mt-1" style={{ ...headingstyle, fontSize: "18px" }}>Confirm amount withdraw request </h1>
                </ModalHeader>
                <ModalBody className='py-0'>
                    <p style={textStyle}>
                        Please confirm the details and submit for request Amount
                    </p>
                    <div ><span style={spanStyle}>Amount :</span>  ₹ 14,09,000</div>
                    <div> <span style={spanStyle}>Company : </span>Jungle Bells</div>
                    <div> <span style={spanStyle}> LLP Bank Account : </span>ICICI Bank [*******1345]</div>


                </ModalBody>
                <ModalFooter>
                    <Button type="button" onClick={() => { setModal1(false); setModal(true) }} className='rz-button-outline-primary' >
                        Go Back
                    </Button>
                    <Button for='request' onClick={() => {
                        setModal1(false); setModal2(true)
                    }} className='rz-button-primary'>
                        Confirm
                    </Button>

                </ModalFooter>
            </Modal>


            <Modal centered={true} isOpen={modal2} toggle={toggle2} className='recall-modal' style={{ width: "700px", maxWidth: "100%" }}>

                <ModalHeader toggle={toggle2} close={closeBtn}>
                    <h1 className="mt-1" style={{ ...headingstyle, fontSize: "18px" }}>Approve </h1>
                </ModalHeader>
                <ModalBody className='py-0'>

                    <div className='px-5'>
                        <div className='d-flex justify-content-center'>
                            <img src={tickIcon} />
                        </div>
                        <p className='text-center mt-2 mb-0' style={{ ...styles.numberStyle, color: "#4B4B4B", fontSize: "18px" }}>Request is submitted successfully</p>
                        <p className='text-center mt-1' style={textStyle}>
                            You shall get a confirmation mail once the request is
                            verified by the Ryzer super admin.
                        </p>
                    </div>


                </ModalBody>
                <ModalFooter>
                    <Button type="button" onClick={toggle2} className='rz-button-primary' >
                        OK
                    </Button>

                </ModalFooter>
            </Modal>
        </>
    )
}
