import { employeeConstants } from "../../constants";

export function updateEmployeeReducer(state = {}, action) {
  switch (action.type) {
    case employeeConstants.UPDATE_EMPLOYEE_REQUEST:
      return { loading: true };
    case employeeConstants.UPDATE_EMPLOYEE_SUCCESS:
      return { employee: action.payload.data };
    case employeeConstants.UPDATE_EMPLOYEE_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
