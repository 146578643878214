import { useQuery, useMutation } from "react-query";
import { api, API_ENDPOINTS } from "../helpers";
const useCreateProposal = () => {
    return useMutation(
        async (data) => {
            return await api.post(`/${API_ENDPOINTS.DAO}/proposal`, data);
        }
    );
};
const useUploadImageProposal = () => {
    return useMutation(
        async (data) => {
            return await api.put(`/${API_ENDPOINTS.DAO}/proposal/gallary/${data.id}`, data.data);
        }
    );
};
const useDeleteImageProposal = () => {
    return useMutation(
        async (data) => {
            return await api.delete(`/${API_ENDPOINTS.DAO}/proposal/gallary/${data.id}`);
        }
    );
};
const useUpdateProposal = () => {
    return useMutation(
        async (data) => {
            const id = data?.id;
            delete data?.id;
            return await api.put(`/${API_ENDPOINTS.DAO}/proposal/${id}`, data);
        }
    );
};

const useProposalDelete = () => {
    return useMutation(
        async (data) => {
            if (data?.id)
                await api.delete(`/${API_ENDPOINTS.DAO}/proposal/${data?.id}`);
        }
    );
};
const useDAOSetting = () => {
    return useMutation(
        async (data) => {
            return await api.post(`/${API_ENDPOINTS.DAO}/setting`, data);
        }
    );
};
const useGetProposal = (options = {}) => {
    return useQuery([`${API_ENDPOINTS.DAO}/proposal_${options?.property_id}`, options],
        async ({ queryKey }) => {
            if (queryKey[1]?.property_id) {
                const { data } = await api.get(`/${API_ENDPOINTS.DAO}/proposal/${queryKey[1]?.property_id}?is_admin=true`);
                return data;
            }
            else return {};

        }
        , {
            keepPreviousData: true,
        });
}
const useGetProposalSingle = (options = {}) => {
    return useQuery([`${API_ENDPOINTS.DAO}/proposal_single_${options?.id}`, options],
        async ({ queryKey }) => {
            if (queryKey[1]?.id) {
                const { data } = await api.get(`/${API_ENDPOINTS.DAO}/proposal/single/${queryKey[1]?.id}`);
                return data;
            }
            else return {};

        }
        , {
            keepPreviousData: true,
        });
}
const useGetTreasury = (options = {}) => {
    return useQuery([`${API_ENDPOINTS.DAO}/treasury_${options?.property_id}`, options],
        async ({ queryKey }) => {
            if (queryKey[1]?.property_id) {
                const { data } = await api.get(`/${API_ENDPOINTS.DAO}/treasury/${queryKey[1]?.property_id}`);
                return data;
            }
            else return {};

        }
        , {
            keepPreviousData: true,
        });
}
const useGetGeneral = (options = {}) => {
    return useQuery([`${API_ENDPOINTS.DAO}/general_${options?.property_id}`, options],
        async ({ queryKey }) => {
            if (queryKey[1]?.property_id) {
                const { data } = await api.get(`/${API_ENDPOINTS.DAO}/general/${queryKey[1]?.property_id}`);
                return data;
            }
            else return {};
        }
        , {
            keepPreviousData: true,
        });
}
const useGetDAOSetting = (options = {}) => {
    return useQuery([`${API_ENDPOINTS.DAO}/setting_${options?.property_id}`, options],
        async ({ queryKey }) => {
            if (queryKey[1]?.property_id) {
                const { data } = await api.get(`/${API_ENDPOINTS.DAO}/setting/${queryKey[1]?.property_id}`);
                return data;
            }
            else return {};
        }
        , {
            keepPreviousData: true,
        });
}
export {
    useCreateProposal,
    useUploadImageProposal,
    useUpdateProposal,
    useProposalDelete,
    useGetProposal,
    useGetTreasury,
    useGetGeneral,
    useGetDAOSetting,
    useDAOSetting,
    useDeleteImageProposal,
    useGetProposalSingle
};
