import React from "react";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import { Button, Card, Col, Container, Form, Modal, ModalBody, ModalFooter, FormGroup, Label, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { employeeActions } from "redux/actions";
import { useEffect, useState } from "react";
import { useGetRoleData } from "../../hooks/useMeQuery";
import { ErrorMessage } from "@hookform/error-message";
import { useHistory } from 'react-router-dom';
import modal_close from './../../assets/img/modal-close.png';
import classNames from "classnames";
import { uploadImage } from "helpers/image.helper";
import { useGetCompanies } from "hooks/useCompany";
import { accountAction } from "redux/actions/account.actions";
// yup schema

const ViewAccount = () => {
    const { data: companyList } = useGetCompanies();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { id } = useParams();
    const history = useHistory();
    const { account } = useSelector(
        (state) => state.viewAccountReducer
    );
    const dispatch = useDispatch();
    const accountSchema = yup.object().shape({
        holder_name: yup.string().required("Name is Required."),
        account_number: yup.number().required("Number is Required."),
        //bank_name: yup.string().required('Bank Name Required'),
        ifsc_number: yup.string().required('IFSC code required'),
        category: yup.string().required('category required'),
        company_id: yup.string().required('company required'),
        additional_information: yup.string()
    });
    const handleAccountDelete = (id) => {
        setIsModalOpen(false);
        dispatch(accountAction.deleteAccount(id, history));

    };
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        resolver: yupResolver(accountSchema),
    });

    const onSubmit = async (data) => {

    };
    useEffect(() => {
        dispatch(accountAction.viewAccount(id));
    }, []);
    useEffect(() => {
        if (account?.id) {
            reset({
                holder_name: account?.holder_name,
                account_number: account?.account_number,
                ifsc_number: account?.ifsc_number,
                category: account?.category,
                company_id: account?.company_id,
                additional_information: account?.additional_information
            });
        }
    }, [account]);
    return (
        <>
            <Container fluid className="mt-5">
                <Row className="justify-content-between">
                    <Col className="">
                        <h2 className="page-heading">
                            View account details
                        </h2>
                    </Col>
                    <Col className="text-right">
                        <Button onClick={() => setIsModalOpen(true)}
                            className="btn btn-danger"
                        >
                            <i className="fas fa-trash mr-1"></i> Delete account
                        </Button>
                    </Col>
                </Row>
                <Card className="mt-3 p-4">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col className="my-2" md="6" xs="12">
                                <Label> Account holder name  *</Label>
                                <input
                                    className="form-control styled-input"
                                    type="text"
                                    placeholder="Enter Name"
                                    {...register("holder_name", {})}
                                    disabled
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="name"
                                    render={({ message }) => <p style={{ color: "#f14D5D" }}>{message}</p>}
                                />
                            </Col>
                            <Col className="my-2" md="6" xs="12">
                                <Label> Account number *</Label>
                                <input
                                    className="form-control styled-input"
                                    type="number"
                                    noValidate
                                    placeholder="Enter number"
                                    disabled
                                    {...register("account_number")}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="account_number"
                                    render={({ message }) => <p style={{ color: "#f14D5D" }}>{message}</p>}
                                />
                            </Col>
                            {/* <Col className="my-2" md="6" xs="12">
                                <Label> Select Bank *</Label>
                                <select disabled className="form-control" {...register("bank_name")}>
                                    
                                    <option value='1'>ICICI Bank</option>
                                    <option value='2'>HDFC Bank</option>
                                </select>
                            </Col> */}
                            <Col className="my-2" md="6" xs="12">
                                <Label> IFSC Code  *</Label>
                                <input
                                    className="form-control styled-input"
                                    type="text"
                                    disabled
                                    placeholder="Enter IFSC code"
                                    {...register("ifsc_number")}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="ifsc_code"
                                    render={({ message }) => <p style={{ color: "#f14D5D" }}>{message}</p>}
                                />
                            </Col>
                            <Col className="my-2" md="6" xs="12">
                                <Label> Category *</Label>
                                <select disabled className="form-control styled-menu" {...register("category")}>

                                    <option value='brokerage'>Brokerage</option>
                                </select>
                            </Col>
                            <Col className="my-2" md="6" xs="12">
                                <Label>Select Company</Label>
                                <select className="form-control styled-menu"
                                    disabled
                                    {...register("company_id")}>
                                    {companyList && companyList?.length > 0 ?
                                        companyList?.map((company) => (
                                            <option key={company?.id}
                                                value={company?.id}>{company?.name}</option>
                                        )) :
                                        null
                                    }
                                </select>
                            </Col>
                            <Col className="my-2" md="12" xs="12">
                                <FormGroup>
                                    <Label className='form-label' htmlFor={`additional_information`}> Additional information </Label>
                                    <textarea
                                        disabled
                                        rows='4'
                                        id={`additional_information`}
                                        className="form-control styled-area"
                                        {...register('additional_information')}
                                        placeholder="Enter details"
                                    />

                                </FormGroup>
                            </Col>
                            <Col className="mt-5 d-flex justify-content-end text-center" md="12" xs="12">
                                <div>
                                    <Button
                                        outline
                                        onClick={() => history.push("/admin/account_managament")}
                                        className="mt-3" color="primary">
                                        Go back
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Card >
                <Modal isOpen={isModalOpen} centered={true} className="modal-lg">
                    <div className="px-4 pt-4 d-flex position-relative justify-content-between">
                        <div>
                            <h3 className="confirm-modal-title montserrat-medium " id="exampleModalLabel">
                                Confirmation
                            </h3>
                        </div>
                        <div>
                            <Button
                                type="button"
                                className="btn modal-close"
                                onClick={() => setIsModalOpen(false)}
                            >
                                <img src={modal_close} />
                            </Button>
                        </div>
                    </div>
                    <ModalBody className="px-4">
                        <p>Are you sure want to delete the bank account added earlier?</p>
                    </ModalBody>
                    <ModalFooter className="pt-0">
                        <Button type="button" outline color="primary" onClick={() => setIsModalOpen(false)}>
                            No
                        </Button>
                        <Button type="button" onClick={() => handleAccountDelete(id)} color="primary" className="ml-4">Yes</Button>
                    </ModalFooter>
                </Modal>
            </Container >
        </>
    )
}
export default ViewAccount;