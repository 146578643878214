import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Badge, Button, Col, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { propertyActions } from 'redux/actions';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import classnames from 'classnames'
import { HiOutlinePlusCircle } from 'react-icons/hi';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const Faq = ({ stepper, type, parentStepper }) => {
    const dispatch = useDispatch()
    const { property } = useSelector(state => state.propertyReducer);
    const { isPropertyLoading } = useSelector(state => state.propertyReducer);
    const [collapse, setCollapse] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFinalModalOpen, setIsFinalModalOpen] = useState(false);
    const [terms, setTerms] = React.useState("")

    const FaqSchema = yup.object().shape({
        question: yup.string().required(),
        answer: yup.string().required(),
    })

    const TcSchema = yup.object().shape({
        terms_conditions: yup.string().required('Terms & conditions is required'),
    })

    const { register, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(FaqSchema)
    })

    const { register: register1, handleSubmit: handleSubmit1, setValue: setValue1, trigger: trigger1, formState: { errors: errors1, isValid: isValid1 } } =
        useForm({
            resolver: yupResolver(TcSchema),
            mode: "onChange"
        })
    useEffect(() => {
        // setValue1('terms_conditions', property?.terms_conditions)
        setTerms(property?.terms_conditions)
        setIsModalOpen(false)
        setIsFinalModalOpen(false)
    }, [property]);

    const onSubmit = (data) => {
        data.property_id = property.id;
        dispatch(propertyActions.storeAdditionalFourFaq(data))
    }

    const submitFaqFinal = () => {
        let data = {
            terms_conditions: terms,
            property_id: property.id
        }
        dispatch(propertyActions.storeAdditionalFour(data, stepper, parentStepper))
    }

    const toggleCollapse = (index) => {
        let collapseCopy = [...collapse];
        collapseCopy[index] = !collapseCopy[index];
        setCollapse(collapseCopy);
    }

    const addEditModal = (faqId) => {
        if (faqId != null)
            var faq = property?.propertyFaq.find(({ id }) => id == faqId);
        setValue('question', faq?.question)
        setValue('answer', faq?.answer)
        setValue('faq_id', faq?.id)
        setIsModalOpen(!isModalOpen)
    }

    const removePropertyFaq = (faqId) => {
        if (window.confirm('Are you sure?'))
            dispatch(propertyActions.deleteAdditionalFourFaq(faqId))
    }
    return (
        <Fragment>
            <div className='content-header'>
                <h3 className='mb-0 rz-heading rz-text-18'>Terms & Conditions</h3>
            </div>
            <Row>
                <FormGroup tag={Col}>
                    <Label className='form-label' htmlFor={`terms_conditions`}> T&C </Label>
                    <textarea
                        rows='4'
                        id={`terms_conditions`}
                        value={terms}
                        onChange={e => setTerms(e.target.value)}
                        className={classnames({ 'is-invalid': errors1.terms_conditions, 'form-control': true })}
                    />

                </FormGroup>
            </Row>
            <div className='content-header border-top py-4 d-flex justify-content-between align-items-center'>
                <h3 className='mb-0 rz-heading rz-text-18'>Frequently Asked Question</h3>
                <button onClick={() => addEditModal()} className="btn rz-button-primary" type="button">
                    <HiOutlinePlusCircle className="mr-2" style={{ fontSize: "20px" }} /> Add
                </button>
            </div>
            <Row>
                <Col>
                    {property?.propertyFaq && (
                        <div className="accordion">
                            {
                                property?.propertyFaq.map((item, i) => {
                                    return (
                                        <div className="card" key={i}>
                                            <div className="card-header p-1 d-flex justify-content-between" id={`heading-${i}`}>
                                                <h2 className="mb-0">
                                                    <button onClick={() => toggleCollapse(i)} key={i} className="text-dark btn btn-link btn-block text-left" type="button">
                                                        {item.question}
                                                    </button>
                                                </h2>
                                                <div className='d-flex align-items-center'>
                                                    <button onClick={() => removePropertyFaq(item.id)} key={i} className="text-muted btn-sm btn btn-link" type="button">
                                                        <i className='fa fa-trash'></i>
                                                    </button>
                                                    <button onClick={() => addEditModal(item.id)} className="text-muted btn-sm btn btn-link" type="button">
                                                        <i className='fa fa-edit'></i>
                                                    </button>
                                                    <button onClick={() => toggleCollapse(i)} className="text-muted btn-sm btn btn-link" type="button">
                                                        <i className={`fas fa-chevron-${collapse[i] ? 'up' : 'down'}`}></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div id={`collapse-${i}`} className={`collapse  ${collapse[i] ? 'show' : ''}`}>
                                                <div className="card-body">
                                                    {item.answer}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )}
                </Col>
            </Row>
            <div className='d-flex align-items-center justify-content-between mt-5'>
                <Button type='button' color='success' onClick={() => stepper.next()} className='btn-next' >
                    <span className='align-middle d-sm-inline-block d-none'>Skip</span>
                    <i className='fa fa-fast-forward align-middle mt-1 mr-sm-25 mr-0'></i>
                </Button>
                <div className='d-flex align-items-center justify-content-end'>
                    <Link to='/admin/property'>
                        <Button type='button' className='rz-button-outline-primary'>
                            Cancel
                        </Button>
                    </Link>

                    <Button className='rz-button-outline-primary mr-2 ml-3' onClick={() => stepper.previous()}>
                        <BsArrowLeftShort style={{ fontSize: "22px" }} />
                        <span className='align-middle d-sm-inline-block d-none'>Back</span>
                    </Button>

                    <Button type='submit' color='primary' className='rz-button-primary' onClick={() => submitFaqFinal()}>
                        <BsArrowRightShort style={{ fontSize: "22px" }} />
                        <span className='align-middle d-sm-inline-block d-none'>Next</span>
                    </Button>
                </div>
            </div>

            <Modal isOpen={isModalOpen} centered={true} className='modal-lg'>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader >
                        <h1 className='rz-heading rz-text-18'>FAQ</h1>
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label className='form-label' htmlFor={`question`}>
                                Question
                            </Label>
                            <input
                                {...register(`question`)}
                                className={classnames({ 'is-invalid': errors.question, 'form-control': true })}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className='form-label' htmlFor={`answer`}>
                                Answer
                            </Label>
                            <textarea
                                rows='4'
                                {...register(`answer`)}
                                className={classnames({ 'is-invalid': errors.answer, 'form-control': true })}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button className='rz-button-outline-primary' onClick={() => setIsModalOpen(!isModalOpen)}>
                            Cancel
                        </Button>
                        {' '}
                        <Button
                            className='rz-button-primary'
                        >
                            {isPropertyLoading ? 'Saving...' : 'Save'}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </Fragment>
    )
}

export default Faq;