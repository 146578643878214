import React, { useEffect, useState } from "react";
import { NavItem, NavLink, Container, Nav, Row, Col, Card } from "reactstrap";
import InvestorList from "../investors/InvestorList";
import General from "./General";
import Settings from "./Settings";
import Treasury from "./Treasury";
import VoatingList from "./voating/VoatingList";

const DAO = () => {
    const daoNavOptions = ["Treasury", "General", "Investors List", "Proposal", "Settings"]
    const [activeTab, setActiveTab] = useState("Treasury");
    useEffect(() => {
        if (localStorage.getItem("dao_redirect") == 4) {
            setActiveTab("Proposal");
            localStorage.removeItem("dao_redirect")
        }
    }, [localStorage.getItem("dao_redirect")])
    const getDaoNavOptions = () => {
        return (
            <Card className='p-2'>
                <div className="d-flex justify-content-between">
                    <div className="btn-group" role="group" aria-label="Options">
                        {daoNavOptions.map((v, i) =>
                            <button
                                type="button"
                                className={
                                    `btn rz-shadow-none cursor-pointer ${activeTab === v ? 'rz-button-primary' : 'white'
                                    }`
                                }
                                style={{
                                    borderRadius: 5
                                }}
                                onClick={() => setActiveTab(v)}
                            >
                                {v}
                            </button>
                        )}
                    </div>
                </div>
            </Card>
        );
    };
    return (
        <Container className="mt-2" fluid>
            <h3 className="ml-1 rz-heading rz-text-18 mb-2">DAO Managemeent</h3>
            <Row>
                <Col>
                    {getDaoNavOptions()}
                    <div className="py-2 mt-2">
                        {activeTab == "Treasury" ?
                            <Treasury /> :
                            activeTab == "General" ?
                                <General /> :
                                activeTab == "Investors List" ?
                                    <InvestorList dao={true} /> :
                                    activeTab == "Proposal" ?
                                        <VoatingList /> :
                                        activeTab == "Settings" ?
                                            <Settings /> :
                                            <></>
                        }
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default DAO;
