import { api } from "../../helpers";

export const employeeService = {
  getEmployees,
  addEmployees,
  updateEmployees,
  deleteEmployees,
};

function getEmployees(query) {
  if (query)
    return api.get(`/employee${query}`);
  return api.get(`/employee`);
}

function addEmployees(data) {
  return api.post(`/employee`, data);
}

function updateEmployees(data, id) {
  return api.put(`/employee/${id}`, data);
}

function deleteEmployees(id) {
  return api.delete(`/employee/${id}`);
}
