import { propertyConstants } from '../../constants';

export function getPropertiesPartnerReducer(state = {}, action) {
    switch (action.type) {
        case propertyConstants.GET_PROPERTIES_PARTNER_REQUEST:
            return { gettingPropertyPartner: true };
        case propertyConstants.GET_PROPERTIES_PARTNER_SUCCESS:
            return { properties_partner: action.payload.data, pager: action.payload.pager };
        case propertyConstants.GET_PROPERTIES_PARTNER_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}