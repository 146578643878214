export const rolesConstants = {
  POST_ROLES_REQUEST: "POST_ROLES_REQUEST",
  POST_ROLES_SUCCESS: "POST_ROLES_SUCCESS",
  POST_ROLES_FAILURE: "POST_ROLES_FAILURE",

  GET_ROLES_REQUEST: "GET_ROLES_REQUEST",
  GET_ROLES_SUCCESS: "GET_ROLES_SUCCESS",
  GET_ROLES_FAILURE: "GET_ROLES_FAILURE",

  UPDATE_ROLES_REQUEST: "UPDATE_ROLES_REQUEST",
  UPDATE_ROLES_SUCCESS: "UPDATE_ROLES_SUCCESS",
  UPDATE_ROLES_FAILURE: "UPDATE_ROLES_FAILURE",

  DELETE_ROLES_REQUEST: "DELETE_ROLES_REQUEST",
  DELETE_ROLES_SUCCESS: "DELETE_ROLES_SUCCESS",
  DELETE_ROLES_FAILURE: "DELETE_ROLES_FAILURE",
};
