import { rolesConstants } from "redux/constants/roles.constants";

export function deleteRolesReducer(state = {}, action) {
  switch (action.type) {
    case rolesConstants.DELETE_ROLES_REQUEST:
      return { loading: true };
    case rolesConstants.DELETE_ROLES_SUCCESS:
      return { roles: action.payload.data };
    case rolesConstants.DELETE_ROLES_FAILURE:
      return { loading: true };
    default:
      return state;
  }
}
