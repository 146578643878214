import { Button, Card, Col, Container, Form, FormGroup, Label, Row } from "reactstrap"
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './../../assets/css/quill-editor.css';
import { Controller, useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { api } from "helpers";
import { useDispatch, useSelector } from "react-redux";
import { blogsAction } from "redux/actions/blog.actions";
const AddEditBlog = () => {
    const { register, handleSubmit, control, reset, setValue } = useForm({
        // defaultValues: {

        // },
        //resolver: yupResolver(documentSchema),
    });
    const { pathname } = useLocation();
    const { blog_id } = useParams()
    const isEdit = pathname.includes('edit');
    const customColors = ['#45485F', '#627C89', '#5b7bfb'];
    const customFontSizes = ['16px', '18px', '20px'];
    const [featurePreview, setFeaturePreview] = useState(null)
    const [featureImage, setFeatureImage] = useState(null);
    const modules = {
        toolbar: {
            container: [
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'color': customColors }],
                [{ 'align': [] }],
                ['link', 'image'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['blockquote', 'code-block'],
                [{ 'size': ['small', false, 'large', 'huge'] }]

            ],

        },
    };
    const { blog = {}, loading } = useSelector(
        (state) => state.getSingleBlogReducer
    );
    useEffect(() => {
        if (Object.keys(blog).length > 0) {
            reset({
                title: blog?.title,
                content: blog?.content,
                author: blog?.author,
                date: blog?.date.split("T")[0],
                metadata: blog?.metadata
            })
            setFeaturePreview(blog?.thumbnail)
        }
    }, [blog])
    useEffect(() => {
        if (isEdit && blog_id) {
            dispatch(blogsAction.getSingleBlog(blog_id))
        }
    }, [isEdit, blog_id])
    const history = useHistory()
    const dispatch = useDispatch();
    const fileChangeHandler = (e) => {
        if (e.target.files[0]) {
            let file = e.target.files[0]
            const reader = new FileReader()
            reader.onload = () => setFeaturePreview(reader.result);
            reader.readAsDataURL(file)
            setFeatureImage(file);
        } else {
            setFeaturePreview(null)
            setFeatureImage(null);
        }
    }
    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike',
        'color', 'align',
        'link', 'image', 'size', 'list', 'blockquote', 'code-block'
    ];
    const onSubmit = async (values) => {
        if (featureImage) {
            const formDataNew = new FormData();
            formDataNew.append("file", featureImage)
            try {
                const res = await api.post("/file",
                    formDataNew
                );
                values.thumbnail = res?.url
            } catch (ex) {
                toast.error('image upload failed')
                return;
            }
        } else {
            if (!isEdit) {
                toast.error('thumbnail image is required')
                return false;
            }
        }
        if (isEdit) {
            dispatch(blogsAction.updateBlogs(values, blog_id, history))
        } else {
            dispatch(blogsAction.postBlogs(values, history))
        }


    }
    return (
        <>
            <Container fluid>
                <Row className="justify-content-between">
                    <Col>
                        <h2 className="all-page-heading">Add Blog</h2>
                    </Col>

                </Row>
                <Card className="p-4">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col className="mt-4" md={6}>
                                <FormGroup>
                                    <Label>Title :</Label>
                                    <textarea
                                        {...register('title')}
                                        className="form-control mt-2"
                                        type="text"
                                        placeholder="Enter title"
                                    />
                                </FormGroup>
                            </Col>
                            <Col className="mt-4" md={6}>
                                <FormGroup>
                                    <Label>Author :</Label>
                                    <input
                                        {...register('author')}
                                        className="form-control mt-2"
                                        type="text"
                                        placeholder="Enter author name"
                                    />
                                </FormGroup>
                            </Col>
                            <Col className="mt-4" md={6}>
                                <FormGroup>
                                    <Label>Date :</Label>
                                    <input
                                        {...register('date')}
                                        className="form-control mt-2"
                                        type="date"
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <Row className="mt-4  justify-content-between">
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Thumbnail Image :</Label>
                                            <div className="custom-file cursor-pointer">
                                                <input className='custom-file-input' onChange={fileChangeHandler} type="file" />
                                                <label className="custom-file-label" htmlFor="customFile">{'Choose file'}</label>
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col md={5}>
                                        <div>
                                            {
                                                featurePreview &&
                                                <img style={{ width: "80%", objectFit: "contain" }} src={featurePreview} />
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="mt-4" md={12}>
                                <FormGroup>
                                    <Label className="mb-2">
                                        Content :
                                    </Label>
                                    <Controller
                                        name="content"
                                        control={control}
                                        render={({ field }) => (
                                            <ReactQuill
                                                theme="snow"
                                                modules={modules}
                                                formats={formats}
                                                value={field.value}
                                                onChange={(value) => field.onChange(value)}
                                            />
                                        )}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col md={12}>
                                <h1 className="rz-heading rz-text-18">Metadata For SEO :</h1>
                            </Col>
                            <Col className="mt-3">
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Title :</Label>
                                            <input
                                                {...register('metadata.title')}
                                                className="form-control mt-2"
                                                type="text"
                                                placeholder="Title"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Description :</Label>
                                            <input
                                                {...register('metadata.description')}
                                                className="form-control mt-2"
                                                type="text"
                                                placeholder="Description"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className='mt-5 align-item-center d-flex justify-content-end'>
                                    <div className='d-flex align-item-center justify-content-end'>
                                        <Link to='/admin/blog'>
                                            <Button type='button' className='rz-button-outline-primary'>
                                                Cancel
                                            </Button>
                                        </Link>
                                        <Button type="submit" className=' ml-3 rz-button-primary'>
                                            <span className='align-middle d-sm-inline-block d-none'>{isEdit ? 'UPDATE' : 'ADD'}</span>
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </Form>
                </Card>
            </Container>
        </>
    )
}

export default AddEditBlog
