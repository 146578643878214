import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Button, Card, Container, Spinner, Table } from "reactstrap";
import IconEyeImage from "./../../../assets/img/Icon-eye.png"
import { formatNumberWithRupeeSymbol } from "utils";
import { AiOutlineEye } from "react-icons/ai";

const FeeList = ({ data }) => {
  return (
    <>
      <Card className="border-none">
        <Table responsive className="custom-table-div">
          <thead className="page-thead-style">
            <tr>
              <th className="d-flex align-items-center">
                Fee type
                <div className="ml-3">
                  <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </div>
              </th>

              <th>Assets</th>

              <th>
                <div className="d-flex align-items-center">
                  AMOUNT collected
                  <div className="ml-3">
                    <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
              </th>


              <th>
                <div className="d-flex align-items-center">
                  total withdrawn
                  (Gross)
                  <div className="ml-3">
                    <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Partners
                  <div className="ml-3">
                    <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
              </th>

              <th>ACTIONS</th>
            </tr>
          </thead>
          <tbody className="page-tbody-cell">
            {(
              data?.fees?.map((order, i) => {
                return (
                  <tr key={i}>
                    <td
                      style={{ cursor: 'pointer' }}
                      className="text-primary">
                      <NavLink
                        to={{
                          pathname: `/admin/FeeManagement/assetWiseFee/${order?.name}`,
                          state: {
                            fee: order
                          }
                        }}
                      >
                        {order?.name}
                      </NavLink>
                    </td>
                    <td> {order?.asset} </td>
                    <td> {formatNumberWithRupeeSymbol(order?.totalAmount)} </td>
                    <td> {formatNumberWithRupeeSymbol(0)} </td>
                    <td> {order?.partners}</td>
                    <td className="text-center">
                      <NavLink
                        to={{
                          pathname: `/admin/FeeManagement/assetWiseFee/${order?.name}`,
                          state: {
                            fee: order
                          }
                        }}
                        className="ml-3"
                      >
                        <AiOutlineEye className="ryzer-text-blue cursor-pointer" style={{ fontSize: "22px" }} />
                      </NavLink>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </Table>
      </Card>
    </>
  );
};

export default FeeList;
