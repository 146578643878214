import { configConstants } from "../../constants";

export function postAmenityReducer(state = {}, action) {
  switch (action.type) {
    case configConstants.POST_AMENITY_REQUEST:
      return { loading: true };
    case configConstants.POST_AMENITY_SUCCESS:
      return { amenity: action.payload };
    case configConstants.POST_AMENITY_FAILURE:
      return { loading: true };
    default:
      return state;
  }
}
