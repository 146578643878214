import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Col,
    FormFeedback,
    Label,
} from "reactstrap";
import { PopupButton } from "react-calendly";

const Booking = () => {
    return (
        <div className="p-5 schedule-wrapper  text-center">
            <div className="d-flex flex-column justify-content-center align-items-center text-muted mb-4 w-25">
                <img
                    style={{
                        width: 50,
                        height: 50,
                        borderRadius: 50,
                        objectFit: "contain"
                    }}
                    alt="..."
                    src={require("./../../assets/img/brand/playstore-icon.png").default}
                />
                <h3 className="mt-2">Welcome to Ryzer! 👋</h3>
                <small className="mb-3">Please sign-in to your account and start the adventure</small>
                <PopupButton
                    className="btn btn-primary"
                    url={`${process.env.REACT_APP_CALENDLY_URL}`}
                    rootElement={document.getElementById("root")}
                    text="Click here to schedule!"
                />
            </div>
        </div>
    );
};

export default Booking;
