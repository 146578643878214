import { useQuery, useQueryClient, useMutation } from "react-query";
import { api } from "../helpers";
const useGetLocationFeature = (options = {}) => {
    return useQuery([`locatioFeatureList${options?.property_type_id}`, options],
        async ({ queryKey }) => {
            if (queryKey[1]?.property_type_id) {
                const { data } = await api.get(`/config/locationfeature/${queryKey[1].property_type_id}`);
                return data;
            }
            else return {};
        }
        , {
            keepPreviousData: true,
        });
};
const useGetAminities = (options = {}) => {
    return useQuery([`useGetAminities${options?.property_type_id}`, options],
        async ({ queryKey }) => {
            if (queryKey[1]?.property_type_id) {
                const { data } = await api.get(`/config/amenity/${queryKey[1].property_type_id}`);
                return data;
            }
            else return {};
        }
        , {
            keepPreviousData: true,
        });
};
const useAddLocationFeature = () => {
    return useMutation(
        async (data) => {
            await api.post(`/config/locationfeature`, data);
        },
        {
            onSuccess: (res) => {
                console.log('Update', res)
            },
            onSettled: () => {
            },
        }
    );
};
const useUpdateLocationFeature = () => {
    return useMutation(
        async (data) => {
            const id = data?.id;
            delete data?.id
            console.log(data);
            await api.put(`/config/locationfeature/${id}`, data);
        },
        {
            onSuccess: (res) => {
                console.log('Update', res)
            },
            onSettled: () => {
            },
        }
    );
};
const useDeleteLocationFeature = () => {
    return useMutation(
        async (data) => {
            const id = data?.id;
            delete data?.id
            console.log(data);
            await api.delete(`/config/locationfeature/${id}`, data);
        },
        {
            onSuccess: (res) => {
                console.log('Update', res)
            },
            onSettled: () => {
            },
        }
    );
};
const useAddVideoLink = () => {
    return useMutation(
        async (data) => {
            await api.post(`/config/videolink`, data);
        },
        {
            onSuccess: (res) => {
            },
            onSettled: () => {
            },
        }
    );
};
const useDeleteVideoLink = () => {
    return useMutation(
        async (data) => {
            const id = data?.id;
            await api.delete(`/config/videolink/${id}`);
        },
        {
            onSuccess: (res) => {
            },
            onSettled: () => {
            },
        }
    );
};
const useAddPopupText = () => {
    return useMutation(
        async (data) => {
            await api.post(`/config/popuptext`, data);
        },
        {
            onSuccess: (res) => {
            },
            onSettled: () => {
            },
        }
    );
};
const useDeletePopupText = () => {
    return useMutation(
        async (data) => {
            const id = data?.id;
            await api.delete(`/config/popuptext/${id}`);
        },
        {
            onSuccess: (res) => {
            },
            onSettled: () => {
            },
        }
    );
};
const useUpdateLocationIcon = () => {
    return useMutation(
        async (data) => {
            const id = data?.city;
            await api.put(`/config/location/${id}`, data);
        },
        {
            onSuccess: (res) => {
            },
            onSettled: () => {
            },
        }
    );
};
export {
    useAddLocationFeature,
    useUpdateLocationFeature,
    useGetLocationFeature,
    useGetAminities,
    useDeleteLocationFeature,
    useAddVideoLink,
    useDeleteVideoLink,
    useAddPopupText,
    useDeletePopupText,
    useUpdateLocationIcon
};
