import { accountConstants } from "redux/constants/account.constants";

export function getAccountReducer(state = {}, action) {
  switch (action.type) {
    case  accountConstants.GET_ACCOUNT_REQUEST:
      return {accounts:[] ,loading: true };
    case  accountConstants.GET_ACCOUNT_SUCCESS:
      return { accounts: action.payload.data,loading:false };
    case  accountConstants.GET_ACCOUNT_FAILURE:
      return {accounts:[], loading: true };
    default:
      return state;
  }
}
