import { Container } from "reactstrap";
import Wizard from "./../../../components/Wizard";
import { useEffect, useRef, useState } from "react";
import BasicDetails from "./BasicDetails";
import PlotDetails from "./PlotDetails";
import InvestmentDetails from "./InvestmentDetails";
import LoadingIcon from "components/Icons/LoadingIcon";
import { useSelector } from "react-redux";
import { checkNotChannelPartner } from "utils";
import IncomeExpense from "../additional/IncomeExpense";
import InvenstorCashFlow from "./InvenstorCashFlow";

const Index = ({ parentStepper }) => {
  const ref = useRef(null);
  const [stepper, setStepper] = useState(null);
  const property = useSelector((state) => state.propertyReducer.property);
  var sc = property?.step_completed;
  const steps = [
    {
      id: "basic-step-1",
      title: "Basic Details",
      subtitle: sc >= 1.1 ? "Completed" : "In Progress",
      content: (
        <BasicDetails
          parentStepper={parentStepper}
          stepper={stepper}
          type="wizard-vertical"
        />
      ),
    }
  ];
  
  if (checkNotChannelPartner()) {
    steps.push({
      id: "plot-step-2",
      title: "Price details",
      subtitle:
        sc == 1.1 ? "In Progress" : sc >= 1.2 ? "Completed" : "Not Completed",
      content: (
        <PlotDetails
          parentStepper={parentStepper}
          stepper={stepper}
          type="wizard-vertical"
        />
      ),
    }
     

    )
  }
  if(property?.stage=='Under Construction'){
    
  }else{
    steps.push(
      {
        id: "investment-step-3",
        title: "Investment",
        subtitle:
          sc == 1.2 ? "In Progress" : sc >= 1.3 ? "Completed" : "Not Completed",
        content: (
          <InvestmentDetails
            parentStepper={parentStepper}
            stepper={stepper}
            type="wizard-vertical"
          />
        ),
      },
      {
        id: "investment-step-4",
        title: "Income and Expense",
        subtitle:
          sc == 1.2 ? "In Progress" : sc >= 1.3 ? "Completed" : "Not Completed",
        content: (
          <IncomeExpense
            parentStepper={parentStepper}
            stepper={stepper}
            type="wizard-vertical"
          />
        ),
      }
    )
  }
  if (property?.share_type == 'NCD') {
    steps.push({
      id: "investment-step-5",
      title: "Investor cash flows",
      subtitle:
        sc == 1.2 ? "In Progress" : sc >= 1.3 ? "Completed" : "Not Completed",
      content: (
        <InvenstorCashFlow
          parentStepper={parentStepper}
          stepper={stepper}
          type="wizard-vertical"
        />
      ),
    })
  }
  
  useEffect(() => {

  }, [property]);

  return (
    <div>
      <Wizard
        type="modern-vertical"
        ref={ref}
        steps={steps}
        className="shadow-none"
        options={{
          linear: false,
        }}
        contentclassName="shadow-none border-left rounded-0"
        instance={(el) => setStepper(el)}
      />
    </div>
  );
};

export default Index;
