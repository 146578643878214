import React, { useEffect, useState } from "react";
import {
    useMutateAdvisorCompany,
    useMutateAdvisorDirector
} from "hooks/useCompany";
import {
    Badge,
    Button, Card, Col,
    Container, Form, Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row, Table
} from "reactstrap";
import { useForm } from "react-hook-form";
import NotFound from "components/NotFound/NotFound";
import { QueryClient } from "react-query";
import { toast } from "react-toastify";
import { useDeleteDirectorById } from "hooks/useCompany";
import { api } from "helpers";
import { checkAttechment } from "../../utils";
import { FiEdit } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { Link } from "react-router-dom";

export default function BoardMember({
    company, stepper
}) {
    const { mutate: addDirector } = useMutateAdvisorDirector();
    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
        },
    });
    const [isModalDirector, setIsModalDirector] = useState(false);
    const [LLPBoardMembers, setLLPBoardMembers] = useState([]);
    const [attachement2, setAttachement2] = React.useState("")
    const [attachement2Link, setAttachement2Link] = React.useState("")
    const [provide_customer_support, setProvide_customer_support] = React.useState("No")
    useEffect(() => {
        if (company?.LLPBoardMembers)
            setLLPBoardMembers(company?.LLPBoardMembers)
    }, [company])

    const onSubmit = async () => {
        const formData = {
            name: getValues("name"),
            email: getValues("email"),
            title: getValues("title"),
            permission_level: getValues("permission_level"),
            status: getValues("status") == "Active" ? true : false,
            dsc_din: getValues("dsc_din") == "Yes" ? true : false,
            note: getValues("note"),
            company_id: company?.id,
            provide_customer_support: provide_customer_support,
            whatsapp_number: getValues("whatsapp_number")
        };
        if (attachement2) {
            const formDataNew = new FormData();
            formDataNew.append("file", attachement2)
            try {
                const res = await api.post("/file",
                    formDataNew
                );
                formData.llp_document = res?.url
            } catch (ex) {
                console.log(ex);
                return;
            }
        }
        await addDirector(formData, {
            onSuccess: (res) => {
                toast.success(res?.message);
                reset();
                setLLPBoardMembers(res?.data || [])
                setIsModalDirector(false);
            }
        });
    };
    const { mutate: deleteAdvisor } = useDeleteDirectorById()
    const handleDelete = async (id) => {
        const sure = window.confirm("Are you Sure to Delete?");
        if (sure) {
            await deleteAdvisor(
                {
                    id: id,
                    company_id: company?.id
                },
                {
                    onSuccess: async (res) => {
                        toast.success("Boeard memeber Deleted Successfully.");
                        setLLPBoardMembers(res?.data || [])
                    },
                    onError: (error) => {
                        console.log(error)
                    }
                }
            );
        }
    };

    const onPropertyStatusChange = async (board_id, e) => {
        let data = {
            id: board_id,
            status: e.target.checked,
            company_id: company?.id
        };
        await addDirector(data, {
            onSuccess: (res) => {
                toast.success(res?.message);
                setLLPBoardMembers(res?.data || [])
            }
        });
    };

    const addEditModal = (settingId) => {
        var member = LLPBoardMembers?.find(({ id }) => id == settingId);
        setValue('name', member?.name)
        setValue('email', member?.email)
        setValue('title', member?.title)
        setValue('permission_level', member?.permission_level)
        setValue('status', member?.status == true ? "Active" : "InActive")
        setValue('dsc_din', member?.dsc_din == true ? "Yes" : "No")
        setValue('note', member?.note)
        setValue('whatsapp_number', member?.whatsapp_number)
        setAttachement2Link(member?.llp_document)
        setProvide_customer_support(member?.provide_customer_support)
        setIsModalDirector(true);
    }
    return (
        <>
            <Container>
                <Card className="p-3 mb-5">
                    <Row className="mb-4 align-items-center">
                        <Col className="text-left">
                            <h3 className="rz-heading rz-text-18">Board members</h3>
                        </Col>
                        <Col className="text-right">
                            <button
                                type="button"
                                className="btn rz-button-primary"
                                onClick={() => setIsModalDirector(!isModalDirector)}>
                                Add New Member
                            </button>
                        </Col>
                    </Row>
                    <Table className="align-items-center  table-flush" responsive>
                        <thead className="rz-thead-style">
                            <tr>
                                <th>Name</th>
                                <th>Title</th>
                                <th>Permissions</th>
                                <th>Status</th>
                                <th>Updated On</th>
                                <th>ACTIONS</th>
                            </tr>
                        </thead>

                        <tbody>
                            {LLPBoardMembers?.map((board) => (
                                <tr>
                                    <td>{board?.name}</td>
                                    <td>{board?.title}</td>
                                    <td>{board?.permission_level}</td>
                                    <td>
                                        <div className="custom-control custom-switch">
                                            <input
                                                type="checkbox"
                                                checked={board?.status}
                                                name={`company_board_status`}
                                                onChange={(e) =>
                                                    onPropertyStatusChange(board?.id, e)
                                                }
                                                className="custom-control-input"
                                                id={`company_board-status-${board?.id}`}
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor={`company_board-status-${board?.id}`}
                                            >
                                                <Badge
                                                    color=""
                                                    className={`badge badge-pill badge-${board?.status == true
                                                        ? "success"
                                                        : "primary"
                                                        } mr-4`}
                                                >
                                                    {board?.status}
                                                </Badge>
                                            </label>
                                        </div>
                                    </td>
                                    <td>{board?.updated_at}</td>
                                    <td>
                                        <div className="d-flex align-items-center justify-content-end">
                                            <div
                                                className="mr-3"
                                                onClick={() => addEditModal(board?.id)}
                                            >
                                                <FiEdit style={{ fontSize: "20px" }} className="ryzer-text-blue cursor-pointer" />
                                            </div>
                                            <div
                                                onClick={() => handleDelete(board?.id)}
                                            >
                                                <AiOutlineDelete style={{ fontSize: "22px" }} className="ryzer-text-blue cursor-pointer" />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    {LLPBoardMembers?.length <= 0 &&
                        <NotFound
                            message="Create first board member for this property."
                        />
                    }
                </Card>
                <div className='mt-5 align-item-center d-flex justify-content-between'>
                    <div>
                        <Button type='button' color='success' onClick={() => stepper.next()} className='btn-next' >
                            <span className='align-middle d-sm-inline-block d-none'>Skip</span>
                            <i className='fa fa-fast-forward align-middle mt-1 mr-sm-25 mr-0'></i>
                        </Button>
                    </div>
                    <div className='d-flex align-item-center justify-content-end'>
                        <Link to='/admin/company'>
                            <Button type='button' className='rz-button-outline-primary'>
                                Cancel
                            </Button>
                        </Link>
                        <Button color='primary' className=' ml-3 mr-2  rz-button-outline-primary' onClick={() => stepper.previous()}>
                            <BsArrowLeftShort style={{ fontSize: "22px" }} />
                            <span className='align-middle d-sm-inline-block d-none'>Back</span>
                        </Button>
                        <Button type='button' onClick={() => stepper.next()} className='btn-next rz-button-primary'>
                            <BsArrowRightShort style={{ fontSize: "22px" }} />
                            <span className='align-middle d-sm-inline-block d-none'>Next</span>
                        </Button>
                    </div>
                </div>
            </Container>
            <Modal isOpen={isModalDirector} centered={true} className="container modal-lg">
                <div>
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="rz-heading rz-text-18">Add New Director</div>
                            <button type="button" className="close" data-dismiss="modal" onClick={() => setIsModalDirector(!isModalDirector)}>
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            <from onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-sm-6 form-group">
                                        <label>Name *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            {...register(`name`)}
                                            placeholder="Full Name"
                                        />
                                    </div>
                                    <div className="col-sm-6 form-group">
                                        <label>Email *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            {...register(`email`)}
                                            placeholder="Email"
                                        />
                                    </div>
                                    <div className="col-sm-6 form-group">
                                        <label>Title *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            {...register(`title`)}
                                            placeholder="First Name"
                                        />
                                    </div>
                                    <div className="col-sm-6 form-group">
                                        <label>Permission Level *</label>
                                        <select className="form-control"
                                            {...register("permission_level")}
                                        >
                                            <option>Manager</option>
                                        </select>
                                    </div>
                                    <div className="col-sm-6 form-group">
                                        <label>Status</label>
                                        <select className="form-control"
                                            {...register("status")}
                                        >
                                            <option>Active</option>
                                            <option>InActive</option>
                                        </select>
                                    </div>
                                    <div className="col-sm-6 form-group">
                                        <label>Have DSC & DIN?</label>
                                        <select className="form-control"
                                            {...register("dsc_din")}
                                        >
                                            <option>Yes</option>
                                            <option>No</option>
                                        </select>
                                    </div>
                                    <div className='col-sm-12 form-group'>
                                        <label className='form-label'>Relevant document </label>
                                        <input className='form-control' type="file" onChange={e => setAttachement2(e.target.files[0])} />
                                        {checkAttechment(attachement2Link) && <a href={`${attachement2Link}`} target="_blank">View Attachement</a>}
                                    </div>
                                    <div className="col-sm-6 form-group">
                                        <label>Provides customer support?</label>
                                        <select className="form-control"
                                            value={provide_customer_support}
                                            onChange={(e) => setProvide_customer_support(e.target.value)}
                                        >
                                            <option value={"Yes"}>Yes</option>
                                            <option value={"No"}>No</option>
                                        </select>
                                    </div>
                                    <div className="col-sm-6 form-group">
                                        <label>Whatsapp number</label>
                                        <input
                                            disabled={provide_customer_support == "No"}
                                            type="text"
                                            className="form-control"
                                            {...register(`whatsapp_number`)}
                                            placeholder="Whatsapp Number"
                                        />
                                    </div>
                                    <div className="col-sm-12 form-group">
                                        Admin notes
                                        <textarea
                                            {...register("note")}
                                            className="form-control"
                                            rows="5"
                                            placeholder="Note"
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn rz-button-outline-primary"
                                        data-dismiss="modal"
                                        onClick={() => setIsModalDirector(!isModalDirector)}>
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => onSubmit()}
                                        className="btn ml-3 rz-button-primary"
                                        data-dismiss="modal"
                                    >
                                        Add
                                    </button>

                                </div>
                            </from>
                        </div>
                    </div>
                </div>

            </Modal>
        </>
    );
}
