import { api } from '../../helpers';

export const propertyService = {
    getProperties,
    aboutOne,
    getProperty,
    savePropertySvg,
    saveAboutTwoBlock,
    saveAboutBlukAddBlock,
    saveAboutTwo,
    saveTimeLimit,
    saveAboutThreeGrowth,
    saveAboutThree,
    storeMediaOne,
    storeMediaOneGallary,
    deleteMediaOneGallary,
    storeMediaTwoDocs,
    deleteMediaTwoDocs,
    storeMediaTwo,
    saveAboutFive,
    storeAdditionalOne,
    storeAdditionalTwo,
    storeAdditionalThree,
    storeAdditionalFourFaq,
    storeAdditionalFour,
    deleteAdditionalFourFaq,
    storeAdditionalOneAwait,
    softDeleteProperty,
    forceDeleteProperty,
    updatePropertyStatus,
    companyCreate,
    updateCompany,
    updateKeyHighLight,
    addCompanyBankAccount,
    updateMediaTwoDocs,
    getPartnerProperties,
    getTokenSellerProperties,
    getTokenSellerMyAsset,
    getTokenSellerSuperAdmin
};

function updatePropertyStatus(data) {
    return api.put(`property/status/${data.property_id}`, data);
}

function forceDeleteProperty(id) {
    return api.delete(`property/force/${id}`);
}

function softDeleteProperty(id) {
    return api.delete(`/property/${id}`);
}

function deleteAdditionalFourFaq(id) {
    return api.delete(`/property/additional/4/faq/${id}`);
}

function storeAdditionalFour(data) {
    return api.post('/property/additional/4', data);
}

function storeAdditionalFourFaq(data) {
    if (data.faq_id === undefined)
        return api.post('/property/additional/4/faq', data);
    return api.put(`/property/additional/4/faq/${data.faq_id}`, data);
}

function storeAdditionalThree(data) {
    return api.post('/property/additional/3', data);
}

function storeAdditionalTwo(data) {
    return api.post('/property/additional/2', data);
}

function storeAdditionalOne(data) {
    return api.post('/property/additional/1', data);
}
async function storeAdditionalOneAwait(data) {
    return await api.post('/property/additional/1', data);
}

function storeMediaTwo(data) {
    return api.post('/property/media/2', data);
}

function deleteMediaTwoDocs(doc_id) {
    return api.delete(`/property/media/2/docs/${doc_id}`);
}
function updateMediaTwoDocs(data) {
    return api.put(`/property/media/2/docs`, data);
}
function updateKeyHighLight(id, data) {
    return api.put(`/property/about/updateProperty/${id}`, data);
}
function storeMediaTwoDocs(data) {
    return api.post('/property/media/2/docs', data, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
}

function deleteMediaOneGallary(media_id) {
    return api.delete(`/property/media/1/gallary/${media_id}`);
}

function storeMediaOneGallary(data) {
    return api.post('/property/media/1/gallary', data, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
}

function storeMediaOne(data) {
    return api.post('/property/media/1', data, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
}

function saveAboutThree(data) {
    return api.post('/property/about/3', data);
}
function saveAboutFive(data) {
    return api.post('/property/investorCashflow/add', data);
}
function saveAboutThreeGrowth(data) {
    return api.post('/property/about/3/growth', data);
}

function saveAboutTwo(data) {
    return api.post('/property/about/2', data);
}
function saveTimeLimit(data) {
    return api.post('/property/timing', data);
}
function saveAboutTwoBlock(data) {
    return api.post('/property/about/2/plot', data);
}
function saveAboutBlukAddBlock(data) {
    return api.post('/property/addBlukPlot', data);
}

function savePropertySvg(data) {
    return api.post('/property/about/2/svg', data, {
        headers: {
            "Content-Type": "multipart/form-data",
        }
    });
}

function aboutOne(data) {
    return api.post('/property/about/1', data);
}

function getProperty(id) {
    return api.get(`/property/${id}`);
}

function getProperties({ page }) {
    let np = page !== undefined ? "?page=" + page + "&limit=10" : '';
    return api.get(`/property${np}`);
}
function getPartnerProperties({ page }) {
    let np = page !== undefined ? "?page=" + page + "&limit=10" : '';
    return api.get(`/property/chanel${np}`);
}
function getTokenSellerProperties({ page }) {
    let np = page !== undefined ? "?page=" + page + "&limit=10" : '';
    return api.get(`/token-seller${np}`);
}
function getTokenSellerMyAsset({ page }) {
    let np = page !== undefined ? "?page=" + page + "&limit=10" : '';
    return api.get(`/token-seller/myasset${np}`);
}
function getTokenSellerSuperAdmin({ page }) {
    let np = page !== undefined ? "?page=" + page + "&limit=10" : '';
    return api.get(`/token-seller/superadmin${np}`);
}
function companyCreate(data) {
    return api.post('/company/create', data);
}

function updateCompany(data, company_id) {
    return api.put(`/company/${company_id}`, data);
}


function addCompanyBankAccount(data, company_id) {
    return api.put(`/company/addBankAccount/${company_id}`, data);
}