import { blogConstants } from "redux/constants/blog.constants";


export function deleteBlogsReducer(state = {}, action) {
  switch (action.type) {
    case blogConstants.DELETE_BLOGS_REQUEST:
      return { loading: true };
    case blogConstants.DELETE_BLOGS_SUCCESS:
      return { roles: action.payload.data };
    case blogConstants.DELETE_BLOGS_FAILURE:
      return { loading: true };
    default:
      return state;
  }
}
