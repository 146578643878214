import { useDAOSetting } from 'hooks/useDAO';
import { useGetDAOSetting } from 'hooks/useDAO';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { Button, Card, Col, Media, Row } from 'reactstrap'

export default function Settings() {
  const { data } = useGetDAOSetting({
    property_id: localStorage.getItem("property_id")
  });

  const { mutate: updateDAOSetting } = useDAOSetting()
  const [paymentDay, setpaymentDay] = React.useState("7");
  const [paymentHour, setPaymentHour] = React.useState("0");
  const [paymentMinute, setPaymentMinute] = React.useState("0");

  useEffect(() => {
    if (data) {
      let payment_time_arr;
      if (!data?.voating_deadline_time)
        payment_time_arr = "7:00:00"?.split(":")
      else
        payment_time_arr = data?.voating_deadline_time?.split(":")

      if (payment_time_arr.length > 0) {
        setpaymentDay(parseInt(payment_time_arr[0]))
        setPaymentHour(parseInt(payment_time_arr[1]))
        setPaymentMinute(parseInt(payment_time_arr[2]))
      }
      setState({
        support: data?.support,
        minimum_approval: data?.minimum_approval,
        super_admin: data?.super_admin
      })
    }
  }, [data])
  const [state, setState] = useState({
    support: 50,
    minimum_approval: 50,
    super_admin: ""
  });
  const onSaveSetting = async () => {
    if (state.support < 50) {
      toast.error("Support Must be Greater Then 50%");
      return;
    }
    if (state.minimum_approval < 50) {
      toast.error("Minimum Approval Must be Greater Then 50%");
      return;
    }
    if (paymentDay || paymentHour || paymentMinute) {
      if (!paymentDay) {
        toast.error("Number of Days is Required.");
        return;
      }
      if (!paymentHour) {
        toast.error("Number of Hour is Required.");
        return;
      }
      if (!paymentMinute) {
        toast.error("Number of Minute is Required.");
        return;
      }
      if (paymentHour > 24) {
        toast.error("Number of Hour must be less than 24");
        return;
      }
      if (paymentMinute > 60) {
        toast.error("Number of Minute must be less than 60");
        return;
      }
      var payment_time = paymentDay.toString() + ":" + paymentHour.toString() + ":" + paymentMinute.toString()

    }
    await updateDAOSetting({
      support: state.support,
      minimum_approval: state.minimum_approval,
      property_id: localStorage.getItem("property_id"),
      voating_deadline_time: payment_time
    }, {
      onSuccess: (res) => {
        console.log("res", res)
        if (res?.type == "success") {
          toast.success(res?.message);
        }
      }
    })
  }
  return (
    <Card className="my-2 py-3 d-flex flex-cloumn bg-white">
      <Card className="p-3 m-3 shadow-lg bg-white  rounded align-items-center  justify-content-between"
        style={{
          borderRadius: 6
        }}
      >
        <div>
          <h3>Organization Address</h3>
          <p>
            {`Support: This refers to the mechanism by which members of the DAO can request assistance or propose changes to the DAO. Support can be implemented in various ways, such as through a dedicated forum, a chat platform, or an email address.\n`}
          </p>
          <p>
            {`Minimum Approval %: This setting determines the minimum percentage of votes required to approve a proposal or make a decision. For example, if the minimum approval % is set at 50%, then a proposal must receive at least 50% of the votes to be approved.\n
            `}
          </p>
          <p>
            {`Super Admin: This refers to a special role in the DAO that has additional privileges and authority. A Super Admin may have the power to make changes to the DAO's settings, modify the smart contracts, or access certain sensitive information. The Super Admin role is typically assigned to a trusted member or group of members who have demonstrated a high level of commitment and expertise in managing the DAO.`}
          </p>
          <Media
            className="align-items-center">
            <img alt="..."
              style={{
                height: 40,
                borderRadius: 40,
                width: 40
              }}
              src="https://picsum.photos/180/180"
            />
            <h3 className="mb-0 b ml-2">{state.super_admin}</h3>
          </Media>
        </div>
      </Card>
      {/* <Card className="p-3 m-3 shadow-lg bg-white  rounded  justify-content-between"
        style={{
          width: "97%",
        }}
      >
        <div>
          <h3>Organization Address</h3>
          <Row>
            <Col>
              <Media
                className="align-items-center">
                <img alt="..."
                  style={{
                    height: 40,
                    borderRadius: 40,
                    width: 40
                  }}
                  src="https://picsum.photos/180/180"
                />
                <h3 className="mb-0 b ml-2">
                  7a6ffed9-4252-427e-af7d-3dcaaf2db2df</h3>

              </Media>
            </Col>
            <Col>
              <Media
                className="align-items-center">
                <img alt="..."
                  style={{
                    height: 40,
                    borderRadius: 40,
                    width: 40
                  }}
                  src="https://picsum.photos/180/180"
                />
                <h3 className="mb-0 b ml-2">
                  7a6ffed9-4252-427e-af7d-3dcaaf2db2df</h3>

              </Media>
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col>
              <Media
                className="align-items-center">
                <img alt="..."
                  style={{
                    height: 40,
                    borderRadius: 40,
                    width: 40
                  }}
                  src="https://picsum.photos/180/180"
                />
                <h3 className="mb-0 b ml-2">
                  7a6ffed9-4252-427e-af7d-3dcaaf2db2df</h3>

              </Media>
            </Col>
            <Col>
              <Media
                className="align-items-center">
                <img alt="..."
                  style={{
                    height: 40,
                    borderRadius: 40,
                    width: 40
                  }}
                  src="https://picsum.photos/180/180"
                />
                <h3 className="mb-0 b ml-2">
                  7a6ffed9-4252-427e-af7d-3dcaaf2db2df</h3>

              </Media>
            </Col>
          </Row>
        </div>
      </Card> */}

      <Card className="py-3 px-4 mx-3 mb-2"
      >
        <label for="customRange3" class="form-label">Support {state.support}%</label>
        <input type="range" class="form-range"
          min="0"
          max="100"
          step="10"
          value={state.support}
          style={{
            marginBottom: 20
          }}
          onChange={(e) => setState({
            ...state,
            support: e.target.value
          })}
          id="customRange3" />
        <label for="customRange4"
          className='pt-4'
          class="form-label">Minimum Approval {state.minimum_approval} %</label>
        <input type="range" class="form-range"
          min="0"
          max="100"
          step="10"
          value={state.minimum_approval}
          onChange={(e) => setState({
            ...state,
            minimum_approval: e.target.value
          })}
          id="customRange4" />
        <div class="mt-4">
          <label for="customRange4"
            className='pt-4'
            class="form-label">Vote duration</label>
          <div className="d-flex">
            <div class="form-group pr-3">
              <label className="text-muted FW-12 mb-1">Day(s)</label>
              <input value={parseInt(paymentDay)} onChange={(e) => setpaymentDay(e.target.value)} required type="number" class="form-control" />
            </div>
            <div class="form-group pr-3">
              <label className="text-muted FW-12 mb-1">Hours</label>
              <input value={parseInt(paymentHour)} onChange={(e) => setPaymentHour(e.target.value)} required type="number" class="form-control" />
            </div>
            <div class="form-group">
              <label className="text-muted FW-12 mb-1">Minutes</label>
              <input value={parseInt(paymentMinute)} onChange={e => setPaymentMinute(e.target.value)} required type="number" class="form-control" />
            </div>
          </div>
          <p className="text-muted FW-14">The support and minimum approval thresholds are strict requirements, such that votes will only pass if they achieve approval percentages greater than these thresholds. </p>
        </div>
      </Card>
      <div className='d-flex justify-content-end mr-4'>
        {/* <Button type='button' color='success' onClick={() => console.log("Hello")} className='btn-next' >
          <span className='align-middle d-sm-inline-block d-none'>Skip</span>
          <i className='fa fa-fast-forward align-middle mt-1 mr-sm-25 mr-0'></i>
        </Button> */}
        <Button
          onClick={() => onSaveSetting()}
          color='primary' className='btn-next'>
          <span className='align-middle d-sm-inline-block d-none'>Save Setting</span>
        </Button>
      </div>
    </Card>
  )
}
