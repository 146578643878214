import { useQuery } from "react-query";
import { API_ENDPOINTS } from "../helpers";
import { api } from "../helpers";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

const fetchPropertyMetadataQuery = async (id) => {
    if (id != undefined) {
        const data = await api.get(`${API_ENDPOINTS.PROPERTYMETADATA}/${id}`);
        return data?.data;
    }
    else
        return []
};
const usePropertyMetadataQuery = (id) => {
    return useQuery([API_ENDPOINTS.PROPERTYMETADATA, id],
        () => fetchPropertyMetadataQuery(id),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false
        }
    );
};
const useDeletePropertyMetadataMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (id) => api.delete(`${API_ENDPOINTS.PROPERTYMETADATA}/${id}`),
        {
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.PROPERTYMETADATA);
            },
        }
    );
};
const useUpdatePropertyMetadataClassMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { id, input } }) =>
            api.put(`${API_ENDPOINTS.PROPERTYMETADATA}/${id}`, input)
        ,
        {
            onSuccess: () => {
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.PROPERTYMETADATA);
            },
            onError: (error) => {
                toast.error(error?.response?.data?.message);
            },
        }
    );
};
const useCreatePropertyMetadataClassMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { input } }) =>
            api.post(API_ENDPOINTS.PROPERTYMETADATA, input),
        {
            onSuccess: (result) => {
                if (result.status === 200 || result?.data?.status === 200) {
                    toast.success(result.data.message);
                } else if (result.status === 400) {
                    toast.error(result?.message);
                }
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.PROPERTYMETADATA);
            },
            onError: (error) => {
                toast.error(error?.response?.data?.message);
            },
        }
    );
};
export {
    usePropertyMetadataQuery,
    useCreatePropertyMetadataClassMutation,
    useUpdatePropertyMetadataClassMutation,
    useDeletePropertyMetadataMutation
};
