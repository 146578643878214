import { orderConstants } from "../../constants/order.contstants";

export function getSingleOrderReducer(state = {}, action) {
  switch (action.type) {
    case orderConstants.GET_SINGLE_ORDERS_REQUEST:
      return { loading: true };
    case orderConstants.GET_SINGLE_ORDERS_SUCCESS:
      return {
        singleOrder: action.payload.data,
        feedback: action.payload.feedback
      };
    case orderConstants.GET_SINGLE_ORDERS_FAILURE:
      return { loading: true };
    default:
      return state;
  }
}
