import React from "react";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import { Button, Card, Col, Container, Form, FormGroup, Label, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { employeeActions } from "redux/actions";
import { useEffect, useState } from "react";
import { useGetRoleData } from "../../hooks/useMeQuery";
import { ErrorMessage } from "@hookform/error-message";
import { useHistory } from 'react-router-dom';
import classNames from "classnames";
import { uploadImage } from "helpers/image.helper";
// yup schema
const employeeSchema = yup.object().shape({
  name: yup.string("Name is Required.")
    .required("Name is Required."),
  email: yup.string().email()
    .required("Email is Required."),
  work_email: yup.string("Work Email is Required.")
    .email()
    .required("Work Email is Required.").nullable(),
  phone: yup.string().required("Phone is required.")
    .length(10, "Phone must be at least 10 numbers")
    .nullable(),
  country_code: yup.string()
    .required("Country Code is Required.").nullable(),
  whatsapp_no: yup.string()
    .required("Whatsapp No is Required.")
    .length(10, "Phone must be at least 10 numbers")
    .nullable(),
  role: yup.string().required("Role is Required.").nullable(),
  join_date: yup.string().required("Join Date is Required.").nullable(),
  status: yup.string().required("Status is Required.").nullable(),
});

const AddEditEmployee = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { employee } = location.state;
  const [updateAble, setUpdateAble] = useState(true);
  const [address, setAddress] = React.useState("");
  const [featurePreview, setFeaturePreview] = useState('');
  const [featureImage, setFeatureImage] = useState(null);
  const { data: roleList } = useGetRoleData();
  console.log("roleList", roleList)
  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    resolver: yupResolver(employeeSchema),
  });
  const onFeatureImageChange = async (e) => {
    if (e.target.files[0]) {
      let file = e.target.files[0]
      const reader = new FileReader()
      reader.onload = () => setFeaturePreview(reader.result);
      reader.readAsDataURL(file)
      setFeatureImage(file);
    } else {
      setFeaturePreview('')
      setFeatureImage('');
    }
  }


  const onSubmit = async (data) => {
    if (featureImage) {
      data.avatar = await uploadImage(featureImage)
    }
    if (updateAble) {
      data["address"] = address
      dispatch(employeeActions.updateEmployees(data, employee?.id, history));
    } else {
      data["address"] = address
      dispatch(employeeActions.addEmployees(data, history));
    }
    // reset();
  };

  useEffect(() => {
    if (employee?.id) {
      setUpdateAble(true);
      reset({
        name: employee?.name,
        email: employee?.email,
        work_email: employee?.work_email,
        country_code: employee?.country_code,
        phone: employee?.phone,
        whatsapp_no: employee?.whatsapp_no,
        role: employee?.role,
        status: employee?.status,
        language: employee?.language,
        meeting_link: employee?.meeting_link,
        join_date: new Date(employee?.join_date).toISOString().slice(0, 10)
      });
      setAddress(employee?.address)
      setFeaturePreview(employee?.avatar)
    } else {
      setUpdateAble(false);
    }
  }, [updateAble]);

  return (
    <>
      <Container fluid className="mt-5">
        <Row className="justify-content-between">
          <Col>
            <h2 className="rz-heading rz-text-20">
              {employee?.id ? `EDIT DETAILS` : "ADD EMPLOYEE DETIALS"}
            </h2>
          </Col>
          <Col className="text-right">
            {employee?.id && <NavLink to={{
              pathname: `/admin/employee/${employee?.id}/reviews`,
              state: {
                employee: {},
              },
            }}
              className="btn rz-button-primary">View Reviews</NavLink>}
          </Col>
        </Row>



        <Card className="p-4">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col className="my-2" md="6" xs="12">
                <Label> Full Name *</Label>
                <input
                  className="form-control"
                  type="text"
                  //   placeholder="name"
                  {...register("name", {})}
                />
                <ErrorMessage
                  errors={errors}
                  name="name"
                  render={({ message }) => <p style={{ color: "#f14D5D" }}>{message}</p>}
                />
              </Col>
              <Col className="my-2" md="6" xs="12">
                <Label> Personal Email Address *</Label>
                <input
                  className="form-control"
                  type="text"
                  noValidate
                  //   placeholder="personalEmail"
                  {...register("email")}
                />
                <ErrorMessage
                  errors={errors}
                  name="email"
                  render={({ message }) => <p style={{ color: "#f14D5D" }}>{message}</p>}
                />
              </Col>
              <Col className="my-2" md="6" xs="12">
                <Label> Work Email Address *</Label>
                <input
                  className="form-control"
                  type="text"
                  //   placeholder="workEmail"
                  {...register("work_email")}
                />
                <ErrorMessage
                  errors={errors}
                  name="work_email"
                  render={({ message }) => <p style={{ color: "#f14D5D" }}>{message}</p>}
                />
              </Col>
              <Col className="my-2" md="6" xs="12">
                <Label> Country Code *</Label>
                <input
                  className="form-control"
                  type="number"
                  //   placeholder="country code"
                  {...register("country_code", {})}
                />
                <ErrorMessage
                  errors={errors}
                  name="country_code"
                  render={({ message }) => <p style={{ color: "#f14D5D" }}>{message}</p>}
                />
              </Col>
              <Col className="my-2" md="6" xs="12">
                <Label> Contact Number *</Label>
                <input
                  maxLength={10}
                  className="form-control"
                  type="number"
                  //   placeholder="phone"
                  {...register("phone", {})}
                />
                <ErrorMessage
                  errors={errors}
                  name="phone"
                  render={({ message }) => <p style={{ color: "#f14D5D" }}>{message}</p>}
                />
              </Col>
              <Col className="my-2" md="6" xs="12">
                <Label> Whatsapp Number *</Label>
                <input
                  className="form-control"
                  maxLength={10}
                  type="number"
                  //   placeholder="whatsApp"
                  {...register("whatsapp_no", {})}
                />
                <ErrorMessage
                  errors={errors}
                  name="whatsapp_no"
                  render={({ message }) => <p style={{ color: "#f14D5D" }}>{message}</p>}
                />
              </Col>
              <Col className="my-2" md="6" xs="12">
                <Label> Role *</Label>
                <select className="form-control" {...register("role")}>
                  {(roleList && roleList?.length > 0)
                    &&
                    roleList?.map((role) => (
                      <option key={role?.id} value={role?.id}>{role?.name}</option>
                    ))
                  }
                </select>
              </Col>
              <Col className="my-2" md="6" xs="12">
                <Label> Status *</Label>
                <select className="form-control" {...register("status")}>
                  <option value="Active">Active</option>
                  <option value="Inactive">InActive</option>
                </select>
                <ErrorMessage
                  errors={errors}
                  name="status"
                  render={({ message }) => <p style={{ color: "#f14D5D" }}>{message}</p>}
                />
              </Col>
              <Col className="my-2" md="6" xs="12">
                <Label> Joined On *</Label>
                <input
                  className="form-control"
                  type="date"
                  //   placeholder="joinDate"
                  {...register("join_date", {})}
                />
                <ErrorMessage
                  errors={errors}
                  name="join_date"
                  render={({ message }) => <p style={{ color: "#f14D5D" }}>{message}</p>}
                />
              </Col>
              <Col className="my-2" md="6" xs="12">
                <Label> Address </Label>
                <input value={address}
                  onChange={e => setAddress(e.target.value)}
                  className="form-control"
                  placeholder="Enter Address">
                </input>
              </Col>
              <Col className='d-flex justify-content-start'  >
                {featurePreview && (
                  <div>
                    <img src={featurePreview} className="img-fluid mr-4 rounded" style={{ width: '100px' }} />
                  </div>
                )}
                <div className=" ml-2">
                  <h5 className='mb-0 text-muted'>Select Image:</h5>
                  <small className='text-muted'>Required image resolution 200x200</small>
                  <div className="custom-file">
                    <input type="file"
                      onChange={(e) => onFeatureImageChange(e)}
                      className={classNames({ 'custom-file-input': true })}
                      id="customFile" accept="image/*" />
                    <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                  </div>
                </div>
              </Col>
              <Col className="my-2" md="6" xs="12">
                <Label> Languages</Label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="English, Hindi.."
                  {...register("language")}
                />
                <ErrorMessage
                  errors={errors}
                  name="language"
                  render={({ message }) => <p style={{ color: "#f14D5D" }}>{message}</p>}
                />
              </Col>
              <Col className="my-2" md="12" xs="12">
                <Label> Meeting Link</Label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="https://calendly.com/surya-gedela"
                  {...register("meeting_link")}
                />
              </Col>
            </Row>
            <div className='d-flex justify-content-end pt-5'>
              <div className='d-flex align-items-center'>
                <Link to='/admin/employee'>
                  <Button type='button' className='rz-button-outline-primary'>
                    Cancel
                  </Button>
                </Link>
                <Button type="submit" className='btn-next ml-3 d-flex justify-content-center align-items-center rz-button-primary'>

                  <span className='align-middle d-sm-inline-block d-none'>
                    Save
                  </span>
                </Button>
              </div>
            </div>
            {/* <input
              type="number"
              placeholder="totalProperty"
              {...register("totalProperty", {})}
            /> */}

            {/* <input
              type="number"
              placeholder="pan number"
              {...register("panNumber", {})}
            /> */}
          </Form>
        </Card>
      </Container>
    </>
  );
};

export default AddEditEmployee;
