import Paginate from "components/Paginate";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { Button, Card, Container, Spinner, Table } from "reactstrap";
import { orderActions } from "redux/actions";
import { moneyFormat } from "utils";
import IconEdit from "../../assets/img/Icon-edit.png"
import VerticalMenuIcon from "../../assets/img/vertical-menu-icon.png"
import queryString from 'query-string';
import NotFound from "components/NotFound/NotFound";
import { useGetOrderFilter } from "hooks/useProperty";
import { redirectToPropertyDao } from "utils";
import moment from "moment";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
const OrdersList = () => {

  const { orders, pager, loading } = useSelector(
    (state) => state.getOrdersReducer
  );
  const { data: filterDb, isLoading } = useGetOrderFilter()
  const { search } = useLocation();
  const [queryParams, setQueryParams] = useState("")
  const [filtterData, setFilterData] = useState({
    user_id: "",
    property_id: "",
    status: ""
  })
  useEffect(() => {
    let where = ``;
    if (filtterData.user_id && filtterData.user_id != "")
      where += `user_id=${filtterData.user_id}`;

    if (filtterData.property_id && filtterData.property_id != "") {
      if (where == "")
        where += `property_id=${filtterData.property_id}`;
      else
        where += `&property_id=${filtterData.property_id}`;
    };
    if (filtterData.status && filtterData.status != "") {
      if (where == "")
        where += `status=${filtterData.status}`;
      else
        where += `&status=${filtterData.status}`;
    };
    setQueryParams(where)
  }, [filtterData])

  const page = queryString.parse(search)?.page;

  const dispatch = useDispatch();

  useEffect(() => {
    if (queryParams == "")
      dispatch(orderActions.getOrder(page || 1));
    else
      dispatch(orderActions.getOrder(page || 1, queryParams));
  }, [page]);

  return (
    <>
      <Container fluid className="mt-2">
        <div className='d-flex justify-content-between align-items-center mb-2'>
          <div className='d-flex flex-row pr-2 w-100 justify-content-between'>
            <div className='col-3'>
              <select
                onChange={(e) => setFilterData({
                  ...filtterData,
                  status: e.target.value
                })}
                value={filtterData.status}
                className='form-control'
                id="status">
                <option selected value=''>Select Status</option>
                <option value="Booked">Booked</option>
                <option value="Awaiting 100% Investment">Awaiting 100% Investment</option>
                <option value="Document signature Pending">Document signature Pending</option>
                <option value="Awaiting payment confirmation">Awaiting payment confirmation</option>
                <option value="Token transfer successful">Token transfer successful </option>
                <option value="Token transfer failed">Token transfer failed </option>
                <option value="Token Burn Failed">Token Burn Failed </option>
                <option value="Relist requested">Relist requested</option>
                <option value="Awaiting 100% Investment">Approve Relist</option>
                <option value="Token Burn & Refunded">Token Burn & Refunded</option>
                <option value="Cancelled">Cancelled</option>
              </select>
            </div>
            <div className='col-3'>
              <select
                value={filtterData.user_id}
                onChange={(e) => setFilterData({
                  ...filtterData,
                  user_id: e.target.value
                })}
                className='form-control'>
                <option selected value=''>Select User</option>
                {filterDb && filterDb?.users?.map((user) => (
                  <option key={user?.user_id} value={user?.user_id}>{user?.name}</option>
                ))
                }
              </select>
            </div>
            <div className='col-3'>
              <select
                value={filtterData.property_id}
                onChange={(e) => setFilterData({
                  ...filtterData,
                  property_id: e.target.value
                })}
                className='form-control'>
                <option value=''>Select Property</option>
                {filterDb && filterDb?.properties?.map((user) => (
                  <option key={user?.property_id} value={user?.property_id}>{user?.name}</option>
                ))
                }
              </select>
            </div>

            <button onClick={() => {
              if (queryParams != "") {
                dispatch(orderActions.getOrder(page || 1, queryParams))
              }
            }
            }
              disabled={queryParams == ""}
              className="btn rz-button-primary" type="button">
              Apply Filter
            </button>
            <button onClick={() => {
              if (queryParams != "") {
                dispatch(orderActions.getOrder(page || 1));
                setFilterData({
                  user_id: "",
                  property_id: "",
                  status: ""
                });
              }
            }}
              className="btn rz-button-primary" type="button">
              Clear Filter
            </button>
          </div>
        </div>
        <Card className="border-none mt-5">
          <Table responsive className="custom-table-div">
            <thead className="rz-thead-style">
              <tr>
                <th className="d-flex align-items-center">
                  ORDER ID

                </th>

                <th>STATUS</th>

                <th>
                  <div className="d-flex align-items-center">
                    CUSTOMER NAME

                  </div>
                </th>

                <th>
                  <div className="d-flex align-items-center">
                    Asset Name

                  </div>
                </th>

                <th>
                  <div className="d-flex align-items-center">
                    PLOT ID

                  </div>
                </th>

                <th>
                  <div className="d-flex align-items-center">
                    NO OF SQYD

                  </div>
                </th>
                {/* <th>
                  <div className="d-flex align-items-center">
                    Asset Type
                    <div className="ml-3">
                      <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                      <i class="fa fa-chevron-down" aria-hidden="true"></i>
                    </div>
                  </div>
                </th> */}

                <th>
                  <div className="d-flex align-items-center">
                    TOTAL VALUE

                  </div>
                </th>

                <th>
                  <div className="d-flex align-items-center">
                    LAST ACTIVITY

                  </div>
                </th>

                <th>ACTIONS</th>

              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr className="text-center">
                  <td colSpan="10">
                    <Spinner animation="border" role="status" >{null}</Spinner>
                  </td>
                </tr>
              ) : (
                orders?.map((order, i) => {
                  return (
                    <tr key={i}>
                      <td
                        style={{ cursor: 'pointer' }}
                        className="text-primary">
                        <NavLink
                          to={{
                            pathname: `/admin/order/${order?.id}`,
                            state: {
                              order: order,
                              editable: false,
                            },
                          }}
                        >
                          #{order?.id}
                        </NavLink>
                      </td>
                      <td> {order?.status} </td>
                      <td style={{ cursor: 'pointer' }}>
                        <NavLink to={`/admin/customer/${order?.user_id}`}>
                          {order?.user_name}
                        </NavLink>
                      </td>
                      <td style={{ cursor: 'pointer' }}>
                        <NavLink
                          onClick={() => {
                            redirectToPropertyDao({
                              id: order?.property_id,
                              name: order?.property_name,
                              sto_sale_address: order?.sto_sale_address,
                              blockchain_metadata: order?.blockchain_metadata
                            })
                          }}
                          to="/company/dashboard">
                          {order?.property_name}
                        </NavLink>
                      </td>
                      <td>{order?.plot_id}</td>
                      <td> {order?.no_sqyds} </td>
                      {/* <td> {order?.investment_type} </td> */}
                      <td> {moneyFormat(order?.total_amountp)} </td>
                      <td>{moment(order?.created_at).format("DD-MM-YYYY")}</td>
                      <td className="text-right">
                        <NavLink
                          to={{
                            pathname: `/admin/order/${order?.id}`,
                            state: {
                              order: order,
                              editable: false,
                            },
                          }}
                        >
                          <FiEdit style={{ fontSize: "20px" }} className="ryzer-text-blue" />

                        </NavLink>
                        <NavLink
                          to={{
                            pathname: `/admin/order/${order?.id}`,
                            state: {
                              order: order,
                              editable: false,
                            },
                          }}
                          className="ml-3"
                        >
                          <BsThreeDotsVertical style={{ fontSize: "18px" }} className="ryzer-text-blue cursor-pointer" />

                        </NavLink>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </Table>
          {orders?.length <= 0 &&
            <NotFound
              message="No One Invested, Order List is Blank."
            />
          }
          {orders?.length > 0 && pager && pager.pages.length && (
            <Paginate path="/admin/order" pager={pager} />
          )}
        </Card>
      </Container>
    </>
  );
};

export default OrdersList;
