import { propertyConstants } from '../../constants';

export function getPropertiesReducer(state = {}, action) {
    switch (action.type) {
        case propertyConstants.GET_PROPERTIES_REQUEST:
            return { gettingProperty: true };
        case propertyConstants.GET_PROPERTIES_SUCCESS:
            return { properties: action.payload.data, pager: action.payload.pager };
        case propertyConstants.GET_PROPERTIES_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}