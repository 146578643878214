import Paginate from "components/Paginate";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Card, FormGroup, Label, Spinner, Table } from "reactstrap";
import { moneyFormat } from "utils";
import IconEdit from "./../../assets/img/Icon-edit.png"
import VerticalMenuIcon from "../../assets/img/vertical-menu-icon.png"
import NotFound from "components/NotFound/NotFound";
import { useGetOrderFilter } from "hooks/useProperty";
import { redirectToPropertyDao } from "utils";
import { DatePicker } from "reactstrap-date-picker";
import moment from "moment";
import exportFromJSON from "export-from-json";
import { useGetOrderReportFilter } from "hooks/useReport";

const OrderList = () => {

  const { data: filterDb, isLoading } = useGetOrderFilter()
  const intialState = {
    user_id: "",
    property_id: "",
    status: "",
    from_date: new Date().toISOString(),
    to_date: new Date().toISOString(),
  }
  const [filtterData, setFilterData] = useState(intialState)
  const [applyFilter, setApplyFilter] = useState({});

  const { data: orders, isLoading: loading } = useGetOrderReportFilter({
    filter: applyFilter
  }, Object.keys(applyFilter)?.length > 0);

  function handleChange(value) {
    setFilterData({
      ...filtterData,
      from_date: value
    })
  }

  function handleChangeTo(value) {
    setFilterData({
      ...filtterData,
      to_date: value
    })
  }

  const exportPDF = () => {
    const title = `order-report-${filtterData?.from_date}-${filtterData?.to_date}`;

    const report_data = orders?.data?.map((order) => {
      return ({
        "ORDER ID": order?.id,
        "STATUS": order?.status,
        "CUSTOMER NAME": order?.user_name,
        "PROPERTY Name": order?.property_name,
        "PLOT ID": order?.plot_id,
        "NO OF SQYD": order?.no_sqyds,
        "Asset Type": order?.investment_type,
        "TOTAL VALUE": order?.total_amountp,
        "LAST ACTIVITY": moment(order?.created_at).format("DD-MM-YYYY")
      })
    })

    exportFromJSON({ data: report_data, fileName: title, exportType: 'csv' });
  };

  return (
    <>
      <div className="row">
        <div className='col-6'>
          <label>Select Status</label>
          <select
            onChange={(e) => setFilterData({
              ...filtterData,
              status: e.target.value
            })}
            value={filtterData.status}
            className='form-control'
            id="status">
            <option selected value=''>Select Status</option>
            <option value="Booked">Booked</option>
            <option value="Awaiting 100% Investment">Awaiting 100% Investment</option>
            <option value="Document signature Pending">Document signature Pending</option>
            <option value="Awaiting document verificationg">Awaiting document verificationg</option>
            <option value="Awaiting payment confirmation">Awaiting payment confirmation</option>
            <option value="Full payment pending">Full payment pending</option>
            <option value="Full payment failed">Full payment failed</option>
            <option value="Wallet connect pending">Wallet connect pending</option>
            <option value="Awaiting Token transfer">Awaiting Token transfer</option>
            <option value="Token transfer successful">Token transfer successful </option>
            <option value="Token transfer failed">Token transfer failed </option>
            <option value="Relist requested">Relist requested</option>
            <option value="Cancelled">Cancelled</option>
            <option value="Token Burn Failed">Token Burn Failed </option>
            <option value="Token Burn & Refunded">Token Burn & Refunded</option>
          </select>
        </div>
        <div className='col-6'>
          <label>Select User</label>
          <select
            value={filtterData.user_id}
            onChange={(e) => setFilterData({
              ...filtterData,
              user_id: e.target.value
            })}
            className='form-control'>
            <option value='all'>All</option>
            {filterDb && filterDb?.users?.map((user) => (
              <option key={user?.user_id} value={user?.user_id}>{user?.name}</option>
            ))
            }
          </select>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-6">
          <FormGroup>
            <Label>Transaction - From Date</Label>
            <DatePicker
              size="md"
              id="from-date"
              value={filtterData.from_date}
              onChange={(v, f) => handleChange(v, f)} />
          </FormGroup>
        </div>
        <div className="col-6">
          <FormGroup>
            <Label>Transaction - To Date</Label>
            <DatePicker
              id="to-date"
              value={filtterData.to_date}
              onChange={(v, f) => handleChangeTo(v, f)} />
          </FormGroup>
        </div>
      </div>
      <div className="row">
        <div className='col-6'>
          <label>Select Asset</label>
          <select
            value={filtterData.property_id}
            onChange={(e) => setFilterData({
              ...filtterData,
              property_id: e.target.value
            })}
            className='form-control'>
            <option value='all'>All</option>
            {filterDb && filterDb?.properties?.map((user) => (
              <option key={user?.property_id} value={user?.property_id}>{user?.name}</option>
            ))
            }
          </select>
        </div>
      </div>
      <div className="row pb-2 my-2 pt-4 mx-1">
        <button
          onClick={() => setApplyFilter(filtterData)}
          className="btn rz-button-primary mr-3" type="button">
          Apply Filter
        </button>
        <button
          disabled={!orders?.data || orders?.data?.length <= 0}
          onClick={() => exportPDF()}
          className="btn rz-button-primary mr-3" type="button">
          Downalod Report
        </button>
        <button onClick={() => {
          setFilterData(intialState)
          setApplyFilter({})
        }}
          className="btn rz-button-primary" type="button">
          Clear Filter
        </button>
      </div>
      <Card className="border-none">
        <Table responsive className="custom-table-div mt-3">
          <thead className="rz-thead-style">
            <tr>
              <th className="d-flex align-items-center">
                ORDER ID

              </th>

              <th>STATUS</th>

              <th>
                <div className="d-flex align-items-center">
                  CUSTOMER NAME

                </div>
              </th>

              <th>
                <div className="d-flex align-items-center">
                  PROPERTY Name

                </div>
              </th>

              <th>
                <div className="d-flex align-items-center">
                  PLOT ID

                </div>
              </th>

              <th>
                <div className="d-flex align-items-center">
                  NO OF SQYD

                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Asset Type

                </div>
              </th>

              <th>
                <div className="d-flex align-items-center">
                  TOTAL VALUE

                </div>
              </th>

              <th>
                <div className="d-flex align-items-center">
                  LAST ACTIVITY

                </div>
              </th>

              <th>ACTIONS</th>

            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr className="text-center">
                <td colSpan="10">
                  <Spinner animation="border" role="status" >{null}</Spinner>
                </td>
              </tr>
            ) : (
              orders?.data?.map((order, i) => {
                return (
                  <tr key={i}>
                    <td
                      style={{ cursor: 'pointer' }}
                      className="text-primary">
                      <NavLink
                        to={{
                          pathname: `/admin/order/${order?.id}`,
                          state: {
                            order: order,
                            editable: false,
                          },
                        }}
                      >
                        #{order?.id}
                      </NavLink>
                    </td>
                    <td> {order?.status} </td>
                    <td style={{ cursor: 'pointer' }}>
                      <NavLink to={`/admin/customer/${order?.user_id}`}>
                        {order?.user_name}
                      </NavLink>
                    </td>
                    <td style={{ cursor: 'pointer' }}>
                      <NavLink
                        onClick={() => {
                          redirectToPropertyDao({
                            id: order?.property_id,
                            name: order?.property_name,
                            sto_sale_address: order?.sto_sale_address,
                            blockchain_metadata: order?.blockchain_metadata
                          })
                        }}
                        to="/company/dashboard">
                        {order?.property_name}
                      </NavLink>
                    </td>
                    <td>{order?.plot_id}</td>
                    <td> {order?.no_sqyds} </td>
                    <td> {order?.investment_type} </td>
                    <td> {moneyFormat(order?.total_amountp)} </td>
                    <td>{moment(order?.created_at).format("DD-MM-YYYY")}</td>
                    <td className="text-right">
                      <NavLink
                        to={{
                          pathname: `/admin/order/${order?.id}`,
                          state: {
                            order: order,
                            editable: false,
                          },
                        }}
                        color="primary"
                      >
                        <img src={IconEdit} alt="" />

                      </NavLink>
                      <NavLink
                        to={{
                          pathname: `/admin/order/${order?.id}`,
                          state: {
                            order: order,
                            editable: false,
                          },
                        }}
                        color="primary"
                        className="ml-3"
                      >
                        <img src={VerticalMenuIcon} alt="" />

                      </NavLink>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </Table>
        {orders?.data?.length <= 0 &&
          <NotFound
            message="Please Refine Your Filter"
          />
        }
      </Card>
    </>
  );
};
export default OrderList;
