import { useMutation } from "react-query";
import { api } from "../helpers";
const useUpdateCertificate = () => {
    return useMutation(
        async (data) => {
            const id = data?.id;
            delete data?.id;
            return await api.put(`/company/certificate/update/${id}`, data);
        },
        {
            onSuccess: (res) => {
            },
            onSettled: () => {
            },
        }
    );
};

export {
    useUpdateCertificate
};
