import { api } from "../../helpers";

export const notificationService = {
  getAdminNotifications,
};

function getAdminNotifications({ page }) {
  let np = page !== undefined ? "?page=" + page + "&limit=10" : '';
  return api.get(`/admin/notification${np}`);
}
