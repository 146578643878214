import { Link } from "react-router-dom";
import { Pagination, CardFooter, PaginationItem } from "reactstrap";

const Paginate = (props) => {
    const { pager, isFirstLast = false, showNumber = true, path: pathname } = props;
    return (
        <CardFooter className="py-4">
            <nav aria-label="...">
                <Pagination className="pagination justify-content-end mb-0" listClassName="justify-content-end mb-0" >
                    {isFirstLast &&
                        <PaginationItem className={`${pager.currentPage == 1 ? 'disabled' : ''}`}>
                            <Link to={{pathname: pathname ,search:`?page=1`}} className='page-link'>
                                First
                            </Link>
                        </PaginationItem>
                    }
                    <PaginationItem className={`${pager.currentPage == 1 ? 'disabled' : ''}`}>
                        <Link to={{pathname: pathname ,search: `?page=${parseInt(pager.currentPage) - 1}`}} className='page-link'>
                            <i className="fas fa-angle-left" />
                            <span className="sr-only">Previous</span>
                        </Link>
                    </PaginationItem>
                    {showNumber && 
                        pager.pages.map(page =>
                            <PaginationItem key={page} className={`page-item number-item ${pager.currentPage == page ? 'rz-active-page' : ''}`}>
                                <Link to={{pathname: pathname ,search: `?page=${page}`}} className='page-link'>
                                    {page}
                                </Link>
                            </PaginationItem>
                        )
                    }
                    <PaginationItem className={`${pager.currentPage == pager.totalPages ? 'disabled' : ''}`}>
                        <Link to={{pathname: pathname ,search: `?page=${parseInt(pager.currentPage) + 1}`}} className='page-link'>
                            <i className="fas fa-angle-right" />
                            <span className="sr-only">Next</span>
                        </Link>
                    </PaginationItem>
                    {isFirstLast &&
                        <PaginationItem className={`${pager.currentPage == pager.totalPages ? 'disabled' : ''}`}>
                            <Link to={{pathname: pathname ,search: `?page=${pager.totalPages}`}} className='page-link'>
                                Last
                            </Link>
                        </PaginationItem>
                    }
                </Pagination>
            </nav>
        </CardFooter>
    );
};

export default Paginate;
