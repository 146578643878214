import { useMutation, useQuery } from "react-query";
import { api, API_ENDPOINTS } from "../helpers";

const useRentalPayout = (options = {}) => {
    return useQuery([`useRentalPayout`, options],
        async () => {
            const { data } = await api.get(`/superadmin/rental_payout/getByProperty/${options?.property_id}`);
            return data;
        }
        , {
            keepPreviousData: true,
        });
}
const useGetYearMonthProperty = (options = {}) => {
    return useQuery([`useGetYearMonthProperty`, options],
        async () => {
            const { data } = await api.post(`/superadmin/rental_payout/getPropertyYearAndMonth`, options);
            console.log(data, "hooks", options)
            return data;

        }
        , {
            keepPreviousData: true,
        });
}
const useUpdatePayout = () => {
    return useMutation(
        async (data) => {
            return await api.patch(`/superadmin/rental_payout/updatePayout`, data);
        },
        {
            onSuccess: (res) => {
                console.log(res)
            },
            onSettled: () => {
            },
            onError: (err) => {
                console.log(err)
            }
        }
    );
};
export {
    useRentalPayout, useGetYearMonthProperty,useUpdatePayout
};
