import { useQuery, useQueryClient, useMutation } from "react-query";
import { api } from "../helpers";
import moment from "moment";
import { serializeParams } from "helpers/functions";
const useGetOrderReportFilter = (options = {}) => {
    return useQuery([`order_list_filter`, options],
        async ({ queryKey }) => {
            const filter = queryKey[1]?.filter;
            filter.to_date = moment(filter.to_date).format("YYYY-MM-DD")
            filter.from_date = moment(filter.from_date).format("YYYY-MM-DD")
            const query = serializeParams(filter)
            const data = await api.get(`/report/order?${query}`);
            return data;
        }
        , {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        });
};
const useGetCustomerFilter = (options = {}, enabled = false) => {
    return useQuery([`customer_list_filter`, options],
        async ({ queryKey }) => {
            const filter = queryKey[1]?.filter;
            filter.to_date = moment(filter.to_date).format("YYYY-MM-DD")
            filter.from_date = moment(filter.from_date).format("YYYY-MM-DD")
            const query = serializeParams(filter)
            const data = await api.get(`/report/users?${query}`);
            return data;
        }
        , {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            enabled: enabled
        });
};
const useGetOwnerShipReportFilter = (options = {}, enabled = false) => {
    return useQuery([`useGetOwnerShipReportFilter`, options],
        async ({ queryKey }) => {
            const filter = queryKey[1]?.filter;
            filter.to_date = moment(filter.to_date).format("YYYY-MM-DD")
            filter.from_date = moment(filter.from_date).format("YYYY-MM-DD")
            const query = serializeParams(filter)
            const data = await api.get(`/report/ownership?${query}`);
            return data;
        }
        , {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            enabled: enabled
        });
};
const useGetPaymentReportFilter = (options = {}, enabled = false) => {
    return useQuery([`useGetPaymentReportFilter`, options],
        async ({ queryKey }) => {
            const filter = queryKey[1]?.filter;
            filter.to_date = moment(filter.to_date).format("YYYY-MM-DD")
            filter.from_date = moment(filter.from_date).format("YYYY-MM-DD")
            const query = serializeParams(filter)
            const data = await api.get(`/report/payment?${query}`);
            return data;
        }
        , {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            enabled: enabled
        });
};
const useGetAchivePropertyFilter = (options = {}) => {
    return useQuery([`property_archive_list_filter`, options],
        async ({ queryKey }) => {
            const filter = queryKey[1]?.filter;
            const query = serializeParams(filter)
            console.log("query", query)
            const data = await api.get(`/property/archive/list?${query}`);
            return data;
        }
        , {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        });
};
const useGetPropertyFilter = (options = {}) => {
    return useQuery([`property_list_filter`, options],
        async ({ queryKey }) => {
            const filter = queryKey[1]?.filter;
            const query = serializeParams(filter)
            console.log("query", query)
            const data = await api.get(`/report/property?${query}`);
            return data;
        }
        , {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        });
};
const useGetPropertyWaitlistReportFilter = (options = {}, enabled = false) => {
    return useQuery([`property_waitlist_list_filter`, options],
        async ({ queryKey }) => {
            const filter = queryKey[1]?.filter;
            const property_id = filter?.property_id;
            if (property_id) {
                //delete filter?.property_id;
                filter.to_date = moment(filter.to_date).format("YYYY-MM-DD")
                filter.from_date = moment(filter.from_date).format("YYYY-MM-DD")
                const query = serializeParams(filter);
                const data = await api.get(`/property/waitlist/${property_id}?${query}`);
                return data;
            }
        }
        , {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        }, enabled);
};
export {
    useGetOrderReportFilter,
    useGetCustomerFilter,
    useGetOwnerShipReportFilter,
    useGetPaymentReportFilter,
    useGetPropertyFilter,
    useGetAchivePropertyFilter,
    useGetPropertyWaitlistReportFilter
};
