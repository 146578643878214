import React from "react";
import { NavLink } from "react-router-dom";
import { Badge, Card, Container, Spinner, Table } from "reactstrap";
import { useGetReviews, useUpdateReviewStatus } from "../../hooks/useReviews";
import { RESOURCE, PERMISSIONS, CheckPermission } from "../../utils";
import { useQueryClient } from "react-query";
import NotFound from "components/NotFound/NotFound";

const Reviews = () => {
    const { data, isLoading: loading } = useGetReviews();
    const { mutate } = useUpdateReviewStatus();
    const queryClient = useQueryClient();
    const onPercentageStatusChange = async (id, e) => {
        let data = {
            review_id: id,
            status: e.target.checked ? true : false,
        };
        await mutate(data, {
            onSuccess: (res) => {
                queryClient.invalidateQueries('reviewsList')
            }
        });

    };
    return (
        <>
            <Container className="mt-2" fluid>
                <div className='content-header mb-3'>
                    <h3 className='mb-0 rz-heading rz-text-22'>Reviews</h3>
                </div>
                <h3 className="ml-1 rz-heading rz-text-18">Property Reviews</h3>
                <Card>
                    <Table responsive className="custom-table-div">
                        <thead className="rz-thead-style">
                            <tr>
                                <th className="d-flex align-items-center">
                                    REVIEW ID

                                </th>
                                <th>
                                    <div className="d-flex align-items-center">
                                        PROPERTY ID

                                    </div>
                                </th>
                                <th>
                                    <div className="d-flex align-items-center">
                                        REVIEWER NAME

                                    </div>
                                </th>
                                <th>
                                    <div className="d-flex align-items-center">
                                        STATUS

                                    </div>
                                </th>
                                <th>
                                    <div className="d-flex align-items-center">
                                        COMMENT

                                    </div>
                                </th>
                                <th>
                                    <div className="d-flex align-items-center">
                                        CREATED

                                    </div>
                                </th>

                            </tr>
                        </thead>
                        {loading ? (
                            <tr className="text-center">
                                <td colSpan="10">
                                    <Spinner animation="border" role="status" >{null}</Spinner>
                                </td>
                            </tr>
                        ) : (
                            <tbody>
                                {data?.data?.property_review?.map((review) => {
                                    return (
                                        <tr className="" key={review?.id}>
                                            <td> {review?.id} </td>
                                            <td> {review?.commentable_id} </td>
                                            <td>
                                                <NavLink to={{
                                                    pathname: `/admin/customer/${review?.user_id}`,
                                                }}
                                                    color="primary">
                                                    <div className="d-flex align-items-center">
                                                        <img
                                                            className="mx-2"
                                                            width="30"
                                                            src={review?.user?.avatar}
                                                            alt=""
                                                        />
                                                        <div className="d-flex flex-column">
                                                            <span className="customer-name">{review?.user?.name}</span>
                                                        </div>
                                                    </div>
                                                </NavLink>
                                            </td>
                                            <td title={review.value}>
                                                <div className="custom-control custom-switch">
                                                    <input
                                                        type="checkbox"
                                                        disabled={!CheckPermission(PERMISSIONS?.CREATE, RESOURCE?.Reviews)}
                                                        checked={review.status == true ? true : false}
                                                        name={`property_status`}
                                                        onChange={(e) =>
                                                            onPercentageStatusChange(review.id, e)
                                                        }
                                                        className="custom-control-input"
                                                        id={`property-status-${review.id}`}
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor={`property-status-${review.id}`}
                                                    >
                                                        <Badge
                                                            color=""
                                                            className={`badge badge-pill badge-${review.status == true
                                                                ? "success"
                                                                : "primary"
                                                                } mr-4`}
                                                        >
                                                            {review.status}
                                                        </Badge>
                                                    </label>
                                                </div>
                                            </td>
                                            <td>
                                                {review?.comment}
                                            </td>
                                            <td>
                                                {new Date(review?.updated_at).toDateString()} <br />
                                                {new Date(review?.updated_at).toLocaleTimeString()}
                                            </td>

                                        </tr>
                                    );
                                })}
                            </tbody>
                        )}
                    </Table>
                    {data?.data?.property_review?.length <= 0 &&
                        <NotFound
                            message="Property Review not posted yet."
                        />
                    }
                </Card>
            </Container>
            <Container className="mt-5" fluid>
                <h3 className="ml-1 rz-heading rz-text-18">Manager Reviews</h3>
                <Card>
                    <Table responsive className="custom-table-div">
                        <thead className="rz-thead-style">
                            <tr>
                                <th className="d-flex align-items-center">
                                    REVIEW ID

                                </th>
                                <th>
                                    <div className="d-flex align-items-center">
                                        MANAGER ID

                                    </div>
                                </th>
                                <th>
                                    <div className="d-flex align-items-center">
                                        REVIEWER NAME

                                    </div>
                                </th>
                                <th>
                                    <div className="d-flex align-items-center">
                                        STATUS

                                    </div>
                                </th>
                                <th>
                                    <div className="d-flex align-items-center">
                                        COMMENT

                                    </div>
                                </th>
                                <th>
                                    <div className="d-flex align-items-center">
                                        CREATED

                                    </div>
                                </th>

                            </tr>
                        </thead>
                        {loading ? (
                            <tr className="text-center">
                                <td colSpan="10">
                                    <Spinner animation="border" role="status" >{null}</Spinner>
                                </td>
                            </tr>
                        ) : (
                            <tbody>
                                {data?.data?.user_review?.map((review) => {
                                    return (
                                        <tr className="" key={review?.id}>
                                            <td> {review?.id} </td>
                                            <td> {review?.commentable_id} </td>
                                            <td>
                                                <NavLink to={{
                                                    pathname: `/admin/customer/${review?.user_id}`,
                                                }}
                                                    color="primary">
                                                    <div className="d-flex align-items-center">
                                                        <img
                                                            className="mx-2"
                                                            width="30"
                                                            src={review?.user?.avatar}
                                                            alt=""
                                                        />
                                                        <div className="d-flex flex-column">
                                                            <span className="customer-name">{review?.user?.name}</span>
                                                        </div>
                                                    </div>
                                                </NavLink>
                                            </td>
                                            <td title={review.value}>
                                                <div className="custom-control custom-switch">
                                                    <input
                                                        type="checkbox"
                                                        disabled={!CheckPermission(PERMISSIONS?.CREATE, RESOURCE?.Reviews)}
                                                        checked={review.status == true ? true : false}
                                                        name={`property_status`}
                                                        onChange={(e) =>
                                                            onPercentageStatusChange(review.id, e)
                                                        }
                                                        className="custom-control-input"
                                                        id={`property-status-${review.id}`}
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor={`property-status-${review.id}`}
                                                    >
                                                        <Badge
                                                            color=""
                                                            className={`badge badge-pill badge-${review.status == true
                                                                ? "success"
                                                                : "primary"
                                                                } mr-4`}
                                                        >
                                                            {review.status}
                                                        </Badge>
                                                    </label>
                                                </div>
                                            </td>
                                            <td>
                                                {review?.comment}
                                            </td>
                                            <td>
                                                {new Date(review?.updated_at).toDateString()} <br />
                                                {new Date(review?.updated_at).toLocaleTimeString()}
                                            </td>

                                        </tr>
                                    );
                                })}
                            </tbody>
                        )}
                    </Table>
                    {data?.data?.user_review?.length <= 0 &&
                        <NotFound
                            message="Property Manager review not posted yet."
                        />
                    }
                </Card>
            </Container>
            <Container className="mt-5" fluid>
                <h3 className="ml-1 rz-heading rz-text-18">Property Seller/HostedBy</h3>
                <Card>
                    <Table responsive className="custom-table-div">
                        <thead className="rz-thead-style">
                            <tr>
                                <th className="d-flex align-items-center">
                                    REVIEW ID

                                </th>
                                <th>
                                    <div className="d-flex align-items-center">
                                        Property Seller ID

                                    </div>
                                </th>
                                <th>
                                    <div className="d-flex align-items-center">
                                        REVIEWER NAME

                                    </div>
                                </th>
                                <th>
                                    <div className="d-flex align-items-center">
                                        STATUS

                                    </div>
                                </th>
                                <th>
                                    <div className="d-flex align-items-center">
                                        COMMENT

                                    </div>
                                </th>
                                <th>
                                    <div className="d-flex align-items-center">
                                        CREATED

                                    </div>
                                </th>

                            </tr>
                        </thead>
                        {loading ? (
                            <tr className="text-center">
                                <td colSpan="10">
                                    <Spinner animation="border" role="status" >{null}</Spinner>
                                </td>
                            </tr>
                        ) : (
                            <tbody>
                                {data?.data?.property_seller?.map((review) => {
                                    return (
                                        <tr className="" key={review?.id}>
                                            <td> {review?.id} </td>
                                            <td> {review?.commentable_id} </td>
                                            <td>
                                                <NavLink to={{
                                                    pathname: `/admin/customer/${review?.user_id}`,
                                                }}
                                                    color="primary">
                                                    <div className="d-flex align-items-center">
                                                        <img
                                                            className="mx-2"
                                                            width="30"
                                                            src={review?.user?.avatar}
                                                            alt=""
                                                        />
                                                        <div className="d-flex flex-column">
                                                            <span className="customer-name">{review?.user?.name}</span>
                                                        </div>
                                                    </div>
                                                </NavLink>
                                            </td>
                                            <td title={review.value}>
                                                <div className="custom-control custom-switch">
                                                    <input
                                                        type="checkbox"
                                                        disabled={!CheckPermission(PERMISSIONS?.CREATE, RESOURCE?.Reviews)}
                                                        checked={review.status == true ? true : false}
                                                        name={`property_status`}
                                                        onChange={(e) =>
                                                            onPercentageStatusChange(review.id, e)
                                                        }
                                                        className="custom-control-input"
                                                        id={`property-status-${review.id}`}
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor={`property-status-${review.id}`}
                                                    >
                                                        <Badge
                                                            color=""
                                                            className={`badge badge-pill badge-${review.status == true
                                                                ? "success"
                                                                : "primary"
                                                                } mr-4`}
                                                        >
                                                            {review.status}
                                                        </Badge>
                                                    </label>
                                                </div>
                                            </td>
                                            <td>
                                                {review?.comment}
                                            </td>
                                            <td>
                                                {new Date(review?.updated_at).toDateString()} <br />
                                                {new Date(review?.updated_at).toLocaleTimeString()}
                                            </td>

                                        </tr>
                                    );
                                })}
                            </tbody>
                        )}
                    </Table>
                    {data?.data?.user_review?.length <= 0 &&
                        <NotFound
                            message="Property Seller/HostedBy review not posted yet."
                        />
                    }
                </Card>
            </Container>
        </>
    );
};

export default Reviews;
