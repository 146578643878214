import {
    Badge,
    Card,
    Media,
    Table,
    Container,
    Row,
    UncontrolledTooltip,
    Button,
    Col,
    Spinner,
    NavItem,
    Nav,
    NavLink
} from "reactstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { propertyActions } from "redux/actions";
import moment from "moment";
import { moneyFormat } from "utils";
import { useEffect, useState } from "react";
import { CheckPermission, PERMISSIONS, RESOURCE } from "../../../utils";
import queryString from 'query-string';
import NotFound from "components/NotFound/NotFound";
import { api } from "helpers";
import IconEyeImage from "../../../assets/img/Icon-eye.png"

const TokenSellerMyAssetList = () => {
    const dispatch = useDispatch();
    const daoNavOptions = ["Awaiting", "Approved", "Rejected"]
    const [activeTab, setActiveTab] = useState("Awaiting");
    const [propertiesData, setPropertiesData] = useState([]);
    const { search } = useLocation();
    const page = queryString.parse(search)?.page
    const getDaoNavOptions = () => {
        return (
            <Card className='p-2'>
                <div className="d-flex justify-content-between">
                    <div className="btn-group" role="group" aria-label="Options">
                        {daoNavOptions.map((v, i) =>
                            <button
                                type="button"
                                className={
                                    `btn ${activeTab === v ? 'btn-primary' : 'white'
                                    }`
                                }
                                style={{
                                    borderRadius: 5
                                }}
                                onClick={() => setActiveTab(v)}
                            >
                                {v}
                            </button>
                        )}
                    </div>
                </div>
            </Card>
        );
    };
    const { properties_token_seller_myasset, pager, getTokenSellerMyAsset } = useSelector(
        (state) => state.getTokenSellerMyAsset
    );
    useEffect(() => "do something when id changes", [page]);
    useEffect(() => {
        localStorage.removeItem("property_id")
        localStorage.removeItem("amenities")
        localStorage.removeItem("locationfeature")
        localStorage.removeItem("channel")
    });
    const onInit = () => {
        dispatch(propertyActions.getTokenSellerMyAsset({ page: page ? page : 1 }));
    }

    useEffect(() => {
        onInit()
    }, [page]);


    useEffect(() => {
        let data = []
        if (activeTab == "Awaiting") {
            data = properties_token_seller_myasset?.filter((d) =>
                (d.status == "Pending" || d.status == "Inactive"));
        }
        if (activeTab == "Hold") {
            data = properties_token_seller_myasset?.filter((d) =>
                (d.status == "Hold"));
        }
        if (activeTab == "Rejected") {
            data = properties_token_seller_myasset?.filter((d) =>
                (d.status == "Rejected"));
        }
        if (activeTab == "Approved") {
            data = properties_token_seller_myasset?.filter((d) =>
                (d.status == "Active"));
        }
        setPropertiesData(data || [])
    }, [activeTab, properties_token_seller_myasset])


    const onRemoveProperty = (id) => {
        let confirm = window.confirm("Are sure you want to trash this property?");
        if (confirm)
            dispatch(propertyActions.genericPropertyAction("softDeleteProperty", id, onInit));
    };

    const onForceRemoveProperty = (id) => {
        let confirm = window.confirm(
            "Are sure you want to remove this property permanently?"
        );
        if (confirm)
            dispatch(
                propertyActions.genericPropertyAction("forceDeleteProperty", id, onInit)
            );
    };
    const onPropertyBookingChange = (property_id, e) => {
        let data = {
            property_id,
            is_booking_amount_required: e.target.checked ? true : false,
        };
        dispatch(
            propertyActions.genericPropertyAction("updatePropertyStatus", data, onInit)
        );
    };

    const setLocalStorage = async (item) => {
        localStorage.setItem("property_id", item.id)
        localStorage.setItem("channel", true);
    }
    return (
        <>
            <Container fluid>
                {getDaoNavOptions()}
                <Row className="mt-3">
                    <div className="col">
                        <Card className="shadow">
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Property Name</th>
                                        <th scope="col">Created At</th>
                                        <th scope="col">Value</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getTokenSellerMyAsset && (
                                        <tr className="text-center">
                                            <td colSpan="10">
                                                <Spinner animation="border" role="status" >{null}</Spinner>
                                            </td>
                                        </tr>
                                    )}

                                    {propertiesData && propertiesData.length > 0 ?
                                        propertiesData.map((item, i) => (
                                            <tr key={item?.id}>
                                                <th
                                                    scope="row">
                                                    <Media
                                                        style={
                                                            !CheckPermission(PERMISSIONS?.CREATE, RESOURCE?.Property) ?
                                                                {
                                                                    "pointer-events": "none"
                                                                } : {}}
                                                        className="align-items-center">
                                                        <Link
                                                            onClick={() => setLocalStorage(item)}
                                                            to={`/admin/property/${item.id}/edit`}
                                                            className="avatar rounded-circle mr-3"
                                                        >
                                                            <img alt="..." src={item?.featured_image} />
                                                        </Link>
                                                        <Link
                                                            onClick={() => setLocalStorage(item)}
                                                            to={`/admin/property/${item.id}/edit`}>
                                                            <span className="mb-0 text-sm">
                                                                <div>{item.name}</div>
                                                                <small className="text-muted">
                                                                    {item?.location?.address}
                                                                </small>
                                                            </span>
                                                        </Link>
                                                    </Media>
                                                </th>
                                                <td>{moment(item.created_at).format("D-MMM-Y")}</td>
                                                <td>
                                                    <span id={`tooltip-${item.id}`}>
                                                        {moneyFormat(item.total_property_value)}
                                                    </span>
                                                    <UncontrolledTooltip target={`tooltip-${item.id}`}>
                                                        Current Month
                                                    </UncontrolledTooltip>
                                                </td>
                                                <td>
                                                    <Badge
                                                        color=""
                                                        className={`badge badge-pill badge-${item.status == "Active"
                                                            ? "success"
                                                            : "primary"
                                                            } mr-4`}
                                                    >
                                                        {item.status}
                                                    </Badge>
                                                </td>
                                                <td>
                                                    <Link
                                                        onClick={() => setLocalStorage(item)}
                                                        to={`/admin/property/${item.id}/edit`}
                                                    >
                                                        <img src={IconEyeImage} alt="" />
                                                    </Link>
                                                </td>
                                            </tr>
                                        )) : (
                                            <></>
                                        )}
                                </tbody>
                            </Table>
                            {propertiesData?.length <= 0 &&
                                <NotFound
                                    message={`No ${activeTab} Property Found.`}
                                />
                            }
                            {/* {((pager && pager.pages.length > 0) && (propertiesData && propertiesData.length > 0)) && (
                                <Paginate path="/admin/property" pager={pager} />
                            )} */}
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default TokenSellerMyAssetList;
