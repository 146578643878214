export const propertyConstants = {

    PROPERTY_REQUEST: 'PROPERTY_REQUEST',
    PROPERTY_SUCCESS: 'PROPERTY_SUCCESS',
    PROPERTY_FAILURE: 'PROPERTY_FAILURE',
    PROPERTY_EMPTY: 'PROPERTY_EMPTY',

    ADD_EDIT_PROPERTY_REQUEST: 'ADD_EDIT_PROPERTY_REQUEST',
    ADD_EDIT_PROPERTY_SUCCESS: 'ADD_EDIT_PROPERTY_SUCCESS',
    ADD_EDIT_PROPERTY_FAILURE: 'ADD_EDIT_PROPERTY_FAILURE',

    GET_PROPERTY_REQUEST: 'GET_PROPERTY_REQUEST',
    GET_PROPERTY_SUCCESS: 'GET_PROPERTY_SUCCESS',
    GET_PROPERTY_FAILURE: 'GET_PROPERTY_FAILURE',

    SAVE_PROPERTY_SVG_REQUEST: 'SAVE_PROPERTY_SVG_REQUEST',
    SAVE_PROPERTY_SVG_SUCCESS: 'SAVE_PROPERTY_SVG_SUCCESS',
    SAVE_PROPERTY_SVG_FAILURE: 'SAVE_PROPERTY_SVG_FAILURE',

    GET_PROPERTIES_REQUEST: 'GET_PROPERTIES_REQUEST',
    GET_PROPERTIES_SUCCESS: 'GET_PROPERTIES_SUCCESS',
    GET_PROPERTIES_FAILURE: 'GET_PROPERTIES_FAILURE',

    GET_PROPERTIES_PARTNER_REQUEST: 'GET_PROPERTIES_PARTNER_REQUEST',
    GET_PROPERTIES_PARTNER_SUCCESS: 'GET_PROPERTIES_PARTNER_SUCCESS',
    GET_PROPERTIES_PARTNER_FAILURE: 'GET_PROPERTIES_PARTNER_FAILURE',

    GET_PROPERTIES_TOKEN_SELLER_REQUEST: 'GET_PROPERTIES_TOKEN_SELLER_REQUEST',
    GET_PROPERTIES_TOKEN_SELLER_SUCCESS: 'GET_PROPERTIES_TOKEN_SELLER_SUCCESS',
    GET_PROPERTIES_TOKEN_SELLER_FAILURE: 'GET_PROPERTIES_TOKEN_SELLER_FAILURE',

    GET_PROPERTIES_TOKEN_SELLER_MYASSET_REQUEST: 'GET_PROPERTIES_TOKEN_SELLER_MYASSET_REQUEST',
    GET_PROPERTIES_TOKEN_SELLER_MYASSET_SUCCESS: 'GET_PROPERTIES_TOKEN_SELLER_MYASSET_SUCCESS',
    GET_PROPERTIES_TOKEN_SELLER_MYASSET_FAILURE: 'GET_PROPERTIES_TOKEN_SELLER_MYASSET_FAILURE',

    GET_PROPERTIES_TOKEN_SELLER_SUPERADMIN_REQUEST: 'GET_PROPERTIES_TOKEN_SELLER_SUPERADMIN_REQUEST',
    GET_PROPERTIES_TOKEN_SELLER_SUPERADMIN_SUCCESS: 'GET_PROPERTIES_TOKEN_SELLER_SUPERADMIN_SUCCESS',
    GET_PROPERTIES_TOKEN_SELLER_SUPERADMIN_FAILURE: 'GET_PROPERTIES_TOKEN_SELLER_SUPERADMIN_FAILURE',
};