export const theme = {
    colors: {
        // Add new color
        primary: {
            100: '#FAF9FF',
            200: '#CFC7F3',
            300: '#ADA3ED',
            400: '#8A7DE9',
            500: '#725AEC',
            600: '#725AEC',
        },
        neutral: {
            100: '#F8F9FB',
            200: '#EEF1F6',
            300: '#E4E8EE',
            400: '#CDD3DD',
            500: '#A8B1BD',
            600: '#6A7381',
            700: '#49505A',
            800: '#1F2329',
            900: '#121417',
        },
        dark: {
            400: '#36324A',
            500: '#262337',
        },
        success: {
            100: '#DDFDED',
            200: '#0AC295',
            300: '#09A57F',
            400: '#078364',
            500: '#027357',
        },
        error: {
            100: '#FCD2CF',
            300: '#DF320C',
            400: '#C61A0B',
            500: '#AE0A0A',
        },
        bgcolor: {
            400: '#fff',
            500: '#EEF1F6',
        }
    },
    fontConfig: {
        Gilroy: {
            200: {
                normal: 'Gilroy-Thin',
            },
            300: {
                normal: 'Gilroy-Light',
            },
            400: {
                normal: 'Gilroy-Medium',
            },
            500: {
                normal: 'Gilroy-Medium',
            },
            600: {
                normal: 'Gilroy-SemiBold',
            },
            700: {
                normal: 'Gilroy-Bold',
            },
        },
    },
    fonts: {
        heading: 'Gilroy',
        body: 'Gilroy',
        mono: 'Gilroy',
    }
}