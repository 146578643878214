import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container, Row, Col, CardBody } from "reactstrap";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";
import myImage from './../assets/img/mainImage.png'

import routes from "routes.js";

const Auth = (props) => {
    const mainContent = React.useRef(null);
    const location = useLocation();

    React.useEffect(() => {
        document.body.classList.add("bg-secondary");
        return () => {
            document.body.classList.remove("bg-secondary");
        };
    }, []);
    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainContent.current.scrollTop = 0;
    }, [location]);

    React.useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => (document.body.style.overflow = "scroll");
    });
    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === "/auth") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    };

    return (
        <>
            <Row style={{maxWidth:"100vw"}}>
                <img
                    style={{
                        zIndex: 1,
                        position: "absolute",
                        top: 10,
                        left: 50,
                        width: 100,
                        height: 100,
                        objectFit: "contain"
                    }}
                    className="ryzer-logo"
                    alt="..."
                    src={require("./../assets/img/brand/logo.png").default}
                />
                <Col md={6}>
                    <img
                        src={myImage}
                        alt="myImage"
                        style={{
                            width: "100%",
                            height: "100vh",
                            objectFit:"cover"
                        }}
                        className="img-fluid auth-image w-100" />
                </Col>
                <Col md={6} className="auth-section">
                    <div className="main-content" ref={mainContent}>
                        <Row
                            className="justify-content-center main-content-row align-items-center">
                            <Switch>
                                {getRoutes(routes)}
                                <Redirect from="*" to="/auth/login" />
                            </Switch>
                        </Row>
                    </div>
                </Col>
            </Row>
            {/* </Container> */}
            {/* <div className="main-content" ref={mainContent}>
                <Container className="mt--8 pb-5">
                    <Row className="justify-content-center">
                        <Switch>
                            {getRoutes(routes)}
                            <Redirect from="*" to="/auth/login" />
                        </Switch>
                    </Row>
                </Container>
            </div> */}
            {/* <AuthFooter /> */}
        </>
    );
};

export default Auth;
