import { toast } from "react-toastify";
import { orderConstants } from "redux/constants/order.contstants";
import { orderService } from "../services";

export const orderActions = {
  getOrder,
  getSingleOrder,
  updateOrderStatus,
  updateOrderRefund,
  updateCoverImage
};

// action for get  order
function getOrder(page, filter) {
  return (dispatch) => {
    dispatch(request());
    orderService.getOrder(page, filter).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };

  function request(res) {
    return {
      type: orderConstants.GET_ORDERS_REQUEST,
    };
  }
  function success(res) {
    return {
      type: orderConstants.GET_ORDERS_SUCCESS,
      payload: res,
    };
  }
  function failure(res) {
    return {
      type: orderConstants.GET_ORDERS_FAILURE,
      payload: res,
    };
  }
}

// action for get single  order
function getSingleOrder(id) {
  return (dispatch) => {
    dispatch(request());
    orderService.getSingleOrder(id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };

  function request(res) {
    return {
      type: orderConstants.GET_SINGLE_ORDERS_REQUEST,
    };
  }
  function success(res) {
    return {
      type: orderConstants.GET_SINGLE_ORDERS_SUCCESS,
      payload: res,
    };
  }
  function failure(res) {
    return {
      type: orderConstants.GET_SINGLE_ORDERS_FAILURE,
      payload: res,
    };
  }
}

// action for update   order status
function updateOrderStatus(data, orderId) {
  return (dispatch) => {
    dispatch(request());
    orderService.updateOrderStatus(data).then(
      (res) => {
        dispatch(success(res));
        dispatch(orderActions.getSingleOrder(orderId));
        toast.success("Order Status Updated");
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };

  function request(res) {
    return {
      type: orderConstants.UPDATE_ORDERS_STATUS_REQUEST,
    };
  }
  function success(res) {
    return {
      type: orderConstants.UPDATE_ORDERS_STATUS_SUCCESS,
      payload: res,
    };
  }
  function failure(res) {
    return {
      type: orderConstants.UPDATE_ORDERS_STATUS_FAILURE,
      payload: res,
    };
  }
}
// action for update   order status
function updateOrderRefund(data, orderId) {
  return (dispatch) => {
    dispatch(request());
    orderService.updateOrderRefund(data).then(
      (res) => {
        dispatch(success(res));
        dispatch(orderActions.getSingleOrder(orderId));
        toast.success("Order Status Updated");
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };

  function request(res) {
    return {
      type: orderConstants.REFUND_ORDERS_STATUS_REQUEST,
    };
  }
  function success(res) {
    return {
      type: orderConstants.REFUND_ORDERS_STATUS_SUCCESS,
      payload: res,
    };
  }
  function failure(res) {
    return {
      type: orderConstants.REFUND_ORDERS_STATUS_FAILURE,
      payload: res,
    };
  }
}
// this below function is used to update image
function updateCoverImage(data, order) {
  return (dispatch) => {
    dispatch(request());
    orderService.updateCoverImageLegal(data,order?.id).then(
      (res) => {
        order.cover_image_pdf = res.data.cover_image_pdf
        dispatch(success(order));
        // dispatch(orderActions.getSingleOrder(order?.id));
        toast.success("Order Status Updated");
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };

  function request(res) {
    return {
      type: orderConstants.UPDATE_ORDERS_STATUS_REQUEST,
    };
  }
  function success(res) {
    return {
      type: orderConstants.UPDATE_ORDERS_STATUS_SUCCESS,
      payload: res,
    };
  }
  function failure(res) {
    return {
      type: orderConstants.UPDATE_ORDERS_STATUS_FAILURE,
      payload: res,
    };
  }
}