import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Container, Spinner, Table } from "reactstrap";
import { moneyFormat } from "utils";
import NotFound from "components/NotFound/NotFound";
import { Link, useLocation, useParams } from "react-router-dom";

const TransectionList = ({
  loading,
  orders,
  type
}) => {
  return (
    <>
      <Card className="border-none">
        <Table responsive className="custom-table-div">
          <thead className="page-thead-style">
            <tr>
              <th className="d-flex align-items-center">
                asset name
                <div className="ml-3">
                  <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </div>
              </th>

              <th>asset type</th>

              <th>
                <div className="d-flex align-items-center">
                  commission %
                  <div className="ml-3">
                    <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
              </th>

              <th>
                <div className="d-flex align-items-center">
                  commission fee
                  <div className="ml-3">
                    <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
              </th>

              <th>
                <div className="d-flex align-items-center">
                  total orders
                  <div className="ml-3">
                    <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
              </th>

              <th>
                <div className="d-flex align-items-center">
                  payment status
                  <div className="ml-3">
                    <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="page-tbody-cell">
            {loading ? (
              <tr className="text-center">
                <td colSpan="10">
                  <Spinner animation="border" role="status" >{null}</Spinner>
                </td>
              </tr>
            ) : (
              orders?.map((order, i) => {
                return (
                  <tr key={i}>
                    <td>
                      <Link
                        to={{
                          pathname: `/admin/${type == 1 ? "commission_view" : "tokenseller_commission_view"}/${order?.property_id}`,
                          state: {
                            asset: order,
                            type
                          },
                        }}>
                        <span className="mb-0 text-sm">
                          {order?.property_name}
                        </span>
                      </Link>
                    </td>
                    <td>{order?.name}</td>
                    <td> {order?.fees} </td>
                    <td> {moneyFormat(order?.commission)} </td>
                    <td> {(order?.total_orders)} </td>
                    <td>
                      <span
                        style={{ fontSize: "11px" }}
                        className={`badge badge-pill badge-${!order?.withdraw ? "danger" : "success"} mr-4`}

                      >
                        {!order?.withdraw ? "Not Withdrawn" : ""}
                      </span>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </Table>
        {orders?.length <= 0 &&
          <NotFound
            message="No One Invested, Order List is Blank."
          />
        }
      </Card>
    </>
  );
};
export default TransectionList;
