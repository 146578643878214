import { api } from "../../helpers";

export const percentageService = {
    getPercentages,
    storePercentages,
    updatePercentages,
    deletePercentages,
    getPropertyPercentages,
    storePropertyPercentages,
    updatePropertyPercentages,
    deletePropertyPercentages,
    syncPropertyPercentages
};

function getPercentages() {
    return api.get(`/percentage/all`);
}

function storePercentages(data) {
    return api.post(`/percentage`, data);
}

function updatePercentages(data) {
    return api.put(`/percentage/${data.id}`, data);
}

function deletePercentages(id) {
    return api.delete(`/percentage/${id}`);
}

function getPropertyPercentages(property_id) {
    return api.get(`/percentage/property/${property_id}`);
}

function storePropertyPercentages(data) {
    return api.post(`/percentage/property`, data);
}

function updatePropertyPercentages(data, property_id) {
    return api.put(`/percentage/property/${data.id}?property_id=${property_id}`, data);
}
function syncPropertyPercentages(property_id) {
    return api.get(`/percentage/property_sync/${property_id}`);
}
function deletePropertyPercentages(id, property_id) {
    return api.delete(`/percentage/property/${id}?property_id=${property_id}`);
}