import { useQuery, useQueryClient, useMutation } from "react-query";
import { api } from "../helpers";
import moment from "moment";
import { serializeParams } from "helpers/functions";
const useGetPropertyById = (options = {}) => {
    return useQuery([`user_property_${options?.property_id}`, options],
        async ({ queryKey }) => {
            if (queryKey[1]?.property_id) {
                const { data } = await api.get(`/property/${queryKey[1]?.property_id}`);
                return data;
            }
            else return {};

        }
        , {
            keepPreviousData: true,
        });
}
const useGetOrderById = (options = {}) => {
    return useQuery([`user_order${options?.order_id}`, options],
        async ({ queryKey }) => {
            if (queryKey[1]?.order_id) {
                const { data } = await api.get(`/order/${queryKey[1]?.order_id}`);
                return data;
            }
            else return {};

        }
        , {
            keepPreviousData: true,
        });
}
const useGetPropertyNearPlaceById = (options = {}, enabled) => {
    return useQuery(
        [`useGetPropertyNearPlaceById${options?.property_id}`, options],
        async ({ queryKey }) => {
            if (queryKey[1]?.property_id) {
                const data = await api.get('/property/nearbypropertyadmin/' + queryKey[1]?.property_id);
                return data;
            } else return [];
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            enabled: enabled
        },
    );
};
export const useUpdateProperty = () => {
    return useMutation(
        async (data) => {
            await api.post('/property/about/2/plot', data);
        }
    );
};
const useClearProperty = () => {
    return useMutation(
        async (data) => {
            await api.post('/property/about/clearall', data);
        }
    );
};
const useUpdatePropertySto = () => {
    return useMutation(
        async (data) => {
            const property_id = data?.property_id;
            delete data?.property_id;
            await api.put(`/property/sto/${property_id}`, data);
        }
    );
};
const useUpdatePropertyINDR = () => {
    return useMutation(
        async (data) => {
            const property_id = data?.property_id;
            delete data?.property_id;
            await api.put(`/property/indr/${property_id}`, data);
        }
    );
};
const useMutatePropertyCompany = () => {
    return useMutation(
        async (data) => {
            const property_id = data?.property_id;
            delete data?.property_id;
            await api.put(`/property/step4/company/update/${property_id}`, data);
        }
    );
};
const useMutatePropertyCompanyAmity = () => {
    return useMutation(
        async (data) => {
            return await api.post(`/property/additional/2/amity`, data);
        }
    );
};
const useMutatePropertyCompanyLocationFeatureStatus = () => {
    return useMutation(
        async (data) => {
            return await api.post(`/property/additional/2/locationfeature`, data);
        }
    );
};
const useMutatePropertyCompanyLocationFeatureAdd = () => {
    return useMutation(
        async (data) => {
            return await api.post(`/property/additional/2/locationfeature/add`, data);
        }
    );
};
const useMutatePropertyNearByData = () => {
    return useMutation(
        async (data) => {
            return await api.post(`/property/nearbyproperty`, data);
        }
    );
};
const useMutatePropertyupdateEscalation = () => {
    return useMutation(
        async (data) => {
            return await api.post(`/property/about/updateEscalation`, data);
        }
    );
};
const useMutatePropertyNearByUpdateData = () => {
    return useMutation(
        async (data) => {
            return await api.post(`/property/nearbypropertyupdate`, data);
        }
    );
};
const useGetProperty = (options = {}) => {
    return useQuery([`propertyList`, options],
        async ({ queryKey }) => {
            const { data } = await api.get('/property');
            return data;
        }
        , {
            keepPreviousData: true,
        });
};
const useGetOrderFilter = (options = {}) => {
    return useQuery([`user_order_filter`, options],
        async ({ queryKey }) => {
            const { data } = await api.get(`/order/filter`);
            return data;
        }
        , {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        });
}
const useGetAllPropertyFilter = (options = {}) => {
    return useQuery([`all_property_filter`, options],
        async ({ queryKey }) => {
            const { data } = await api.get(`/property/getAllProperty/list`);
            return data;
        }
        , {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        });
}
const useMutatePropertyKYCSync = () => {
    return useMutation(
        async (data) => {
            return await api.post(`/property/addIntoKycList`, data);
        }
    );
};
const useMutatePropertyDocumentProtectedUpdate = () => {
    return useMutation(
        async (data) => {
            return await api.put(`/property/media/2/docs`, data);
        }
    );
};
const useMutatePropertyDueDiligence = () => {
    return useMutation(
        async (data) => {
            return await api.post(`/property/property_due_diligence`, data);
        }
    );
};
const fetchPropertyHostedByQuery = async (id) => {
    if (id > 0 && id != undefined) {
        const data = await api.get(`/property/location/${id}`);
        return data?.data;
    }
    else
        return undefined
};
const usePropertyLocationById = (id) => {
    return useQuery(["usePropertyLocationById_" + id, id],
        () => fetchPropertyHostedByQuery(id), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        enabled: false
    });
};
const useUpdatePitchDeck = () => {
    return useMutation(
        async (data) => {
            await api.put(`/property/about/updateProperty/${data.property_id}`, { pitch_deck: data.pitch_deck });
        },
        {
            onSuccess: () => {

            },
            onSettled: () => {

            },
            onError: (error) => {

            },
        }
    );
};
export {
    useGetPropertyById,
    useGetOrderById,
    useUpdatePropertySto,
    useGetProperty,
    useUpdatePitchDeck,
    useGetOrderFilter,
    useMutatePropertyCompany,
    useMutatePropertyCompanyAmity,
    useMutatePropertyCompanyLocationFeatureStatus,
    useMutatePropertyCompanyLocationFeatureAdd,
    useMutatePropertyNearByData,
    useMutatePropertyNearByUpdateData,
    useGetPropertyNearPlaceById,
    useClearProperty,
    useMutatePropertyKYCSync,
    useMutatePropertyupdateEscalation,
    useUpdatePropertyINDR,
    useMutatePropertyDocumentProtectedUpdate,
    useMutatePropertyDueDiligence,
    usePropertyLocationById,
    useGetAllPropertyFilter
};
