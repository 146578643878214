import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner,
    Table,
} from "reactstrap";
import { configActions } from "redux/actions";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useUpdateLocationIcon } from "hooks/useConfig";
import { api } from "helpers";
import { FiEdit } from "react-icons/fi";

const featureSchema = yup.object().shape({
    image: yup.mixed(),
});

const Citylocation = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { citylocation, loading } = useSelector(
        (state) => state.getCityLocationReducer
    );
    useEffect(() => {
        dispatch(configActions.getLocationCityWise());
    }, []);
    const [filterData, setFilterData] = useState([]);
    const [selectedItem, setSelectedItem] = useState();
    useEffect(() => {
        const citylocationFilter = citylocation?.filter((v) => v.city == selectedItem);
        setFilterData(citylocationFilter);
    }, [selectedItem])
    useEffect(() => {
        setFilterData(citylocation?.slice(0, 1))
    }, [citylocation])
    const dispatch = useDispatch();
    const { mutate: addPopupText } = useUpdateLocationIcon()
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm({ resolver: yupResolver(featureSchema) });

    const handleUpdate = (amenity) => {
        setIsModalOpen(true);
        setValue("city", amenity.city);
    };

    const onSubmit = async (data) => {
        let dataNew = {
            city: data.city,
        }
        if (data?.image[0]) {
            const formDataNew = new FormData();
            formDataNew.append("file", data?.image[0])
            try {
                const res = await api.post("/file",
                    formDataNew
                );
                dataNew.photo = res?.url
            } catch (ex) {
                console.log(ex);
                return;
            }
        }
        console.log(dataNew)
        await addPopupText(
            dataNew,
            {
                onSuccess: async (res) => {
                    dispatch(configActions.getLocationCityWise());
                },
                onError: (error) => {
                    console.log(error)
                }
            }
        );
        setIsModalOpen(false);
        reset();
    };

    return (
        <>
            <Container fluid className="mt-5">
                <Card className="card bg-white py-4">
                    {loading ? (
                        <Row>
                            <Col md="7" xs="12" className="text-center">
                                <div colSpan="10">
                                    <Spinner animation="border" role="status" >{null}</Spinner>
                                </div>
                            </Col>
                        </Row>
                    ) : (

                        <Container fluid className="">
                            <Row className="d-flex my-3  py-1 align-items-center rounded">
                                <Col xs="12" md="6" className="text-md-left text-center">
                                    <span className="rz-heading rz-text-18"> Upload Image For City </span>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="exampleSelect">Select</Label>
                                        <Input type="select" name="select"
                                            onChange={(e) => setSelectedItem(e?.target?.value)}
                                            id="exampleSelect">
                                            {citylocation?.map((vl) => (
                                                <option
                                                    value={vl?.city}
                                                >{vl?.city}</option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Table responsive>
                                <thead className="rz-thead-style">
                                    <tr>
                                        <th scope="col"> CITY </th>
                                        <th scope="col"> ICON</th>
                                        <th className="text-right" scope="col"> ACTION</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filterData?.map((video) => {
                                        return (
                                            <tr className="bg-white" key={video.id}>
                                                <td> {video.city} </td>
                                                <td>
                                                    <img width="30" src={video?.photo} alt="" />
                                                </td>
                                                <td className="d-flex justify-content-end">
                                                    <div
                                                        onClick={() => handleUpdate(video)}
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal"
                                                    >
                                                        <FiEdit style={{ fontSize: "20px" }} className="ryzer-text-blue cursor-pointer" />
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Container>

                    )}

                    <Modal isOpen={isModalOpen} centered={true} className="modal-lg">
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className="px-4 pt-4 d-flex justify-content-between">
                                <div>
                                    <h1 className="rz-heading rz-text-18" id="exampleModalLabel">
                                        Add New Video Link
                                    </h1>
                                </div>
                                <div>
                                    <Button
                                        type="button"
                                        className="btn-close btn-link bg-white"
                                        onClick={() => setIsModalOpen(false)}
                                    >
                                        <i className="fas fa-times    "></i>
                                    </Button>
                                </div>
                            </div>
                            <ModalBody>
                                <FormGroup>
                                    <Label className="form-label" htmlFor={`answer`}>
                                        Attachment <br />
                                        <small> (Allowed PNG format only) </small>
                                    </Label>

                                    <div className="custom-file">
                                        <input
                                            className="custom-file-input"
                                            id="customFile"
                                            type="file"
                                            placeholder="image"
                                            accept="image/*"
                                            {...register("image", {})}
                                        />
                                        <label className="custom-file-label" for="customFile">
                                            Choose file
                                        </label>
                                    </div>
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter>
                                <Button className="rz-button-outline-primary" onClick={() => setIsModalOpen(!isModalOpen)}>
                                    Cancel
                                </Button>

                                <Button className="rz-button-primary ml-3">Save</Button>
                            </ModalFooter>
                        </Form>
                    </Modal>
                </Card>
            </Container>
        </>
    );
};

export default Citylocation;
