import {
    Badge,
    Card,
    Media,
    Table,
    Container,
    Row,
    UncontrolledTooltip,
    Button,
    Col,
    Spinner,
} from "reactstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { propertyActions } from "redux/actions";
import moment from "moment";
import { moneyFormat } from "utils";
import { useContext, useEffect } from "react";
import BadgeImage from "./../../assets/img/Badge.png"
import BadgeDelayImage from "./../../assets/img/Badge-delay.png"
import Paginate from "components/Paginate";
import { CheckPermission, PERMISSIONS, RESOURCE, checkNotChannelPartner } from "../../utils";
import queryString from 'query-string';
import NotFound from "components/NotFound/NotFound";
import { api } from "helpers";
import IconEyeImage from "../../assets/img/Icon-eye.png"
import { HiOutlinePlusCircle } from "react-icons/hi";

const PropertyList = () => {
    const dispatch = useDispatch();

    const { search } = useLocation();
    const page = queryString.parse(search)?.page

    const user = JSON.parse(localStorage.getItem("user") || {})
    const { properties, pager, gettingProperty } = useSelector(
        (state) => state.getPropertiesReducer
    );
    const onInit = () => {
        dispatch(propertyActions.getProperties({
            page: page ? page : 1
        }));
    }
    useEffect(() => "do something when id changes", [page]);
    useEffect(() => {
        localStorage.removeItem("property_id")
        localStorage.removeItem("amenities")
        localStorage.removeItem("locationfeature")
        localStorage.removeItem("channel")
        // OneSignal.init({ appId: `${process.env.REACT_APP_ONESIGNAL_APP}` });
    });
    useEffect(() => {
        onInit()
    }, [page]);

    const onRemoveProperty = (id) => {
        let confirm = window.confirm("Are sure you want to trash this property?");
        if (confirm)
            dispatch(propertyActions.genericPropertyAction("softDeleteProperty", id, onInit));
    };

    const onForceRemoveProperty = (id) => {
        let confirm = window.confirm(
            "Are sure you want to remove this property permanently?"
        );
        if (confirm)
            dispatch(
                propertyActions.genericPropertyAction("forceDeleteProperty", id, onInit)
            );
    };

    const onPropertyStatusChange = (property_id, e) => {
        let data = {
            property_id,
            status: e.target.checked ? "Active" : "Inactive",
        };
        dispatch(
            propertyActions.genericPropertyAction("updatePropertyStatus", data, onInit)
        );
    };
    const onPropertyBookingChange = (property_id, e) => {
        let data = {
            property_id,
            is_booking_amount_required: e.target.checked ? true : false,
        };
        dispatch(
            propertyActions.genericPropertyAction("updatePropertyStatus", data, onInit)
        );
    };
    const onPropertyJoinChange = (property_id, e) => {
        let data = {
            property_id,
            join_waitlist: e.target.checked ? true : false,
        };
        dispatch(
            propertyActions.genericPropertyAction("updatePropertyStatus", data, onInit)
        );
    };
    function getThumbnailFromVideo(value) {

        if (value) {
            let value_arr = value.split("/");
            if (value_arr.length > 0) {
                if (value_arr[3]) {
                    let youtube_id = value_arr[3];
                    let video_url = `https://i1.ytimg.com/vi/${youtube_id}/hqdefault.jpg`;
                    return video_url
                }
            }
        }
    }
    const setLocalStorage = async (item) => {
        const { data: locationfeature } = await api.get(`/config/locationfeature/${item?.propertyType?.id}`);
        const { data: amenities } = await api.get(`/config/amenity/${item?.propertyType?.id}`);
        localStorage.setItem("property_id", item.id)
        localStorage.setItem("amenities", JSON.stringify(amenities))
        localStorage.setItem("locationfeature", JSON.stringify(locationfeature))
    }
    return (
        <>
            <Container fluid>
                <Row className="mt-md-2 justify-content-between mt-4 mb-4">
                    <Col>
                        <h2 className="all-page-heading">Asset List</h2>
                    </Col>
                    {CheckPermission(PERMISSIONS?.CREATE, RESOURCE?.Property) &&
                        <Col className="d-flex justify-content-end">
                            <Link className="btn rz-button-primary" to="/admin/property/create">
                                <HiOutlinePlusCircle className="mr-2" style={{ fontSize: "20px" }} />  Add Asset
                            </Link>
                        </Col>
                    }
                </Row>

                <Row className="mt-3">
                    <div className="col">
                        <Card className="shadow">
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="rz-thead-style">
                                    <tr>
                                        <th scope="col">ASSET ID</th>
                                        <th scope="col">ASSET Name</th>
                                        {
                                            checkNotChannelPartner() && (
                                                <>
                                                    <th scope="col">Created By</th>
                                                </>
                                            )
                                        }
                                        <th scope="col">Created On</th>
                                        <th scope="col">Value</th>
                                        {
                                            checkNotChannelPartner() &&
                                            (
                                                <>
                                                    <th scope="col">Tokens</th>
                                                    <th scope="col">DAO</th>
                                                    <th scope="col">ASSET Type</th>
                                                    <th scope="col">ASSET Category</th>
                                                    <th scope="col">ASSET Stage</th>
                                                </>
                                            )
                                        }
                                        <th scope="col">Status</th>
                                        {
                                            checkNotChannelPartner() && (
                                                <>
                                                    <th scope="col">Join Waitlist</th>
                                                    <th scope="col">Booking Amount Check</th>
                                                </>
                                            )
                                        }
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {gettingProperty && (
                                        <tr className="text-center">
                                            <td colSpan="10">
                                                <Spinner animation="border" role="status" >{null}</Spinner>
                                            </td>
                                        </tr>
                                    )}

                                    {properties && properties.length > 0 ?
                                        properties.map((item, i) => (
                                            <tr key={i}>
                                                <th>
                                                    <Link
                                                        onClick={() => setLocalStorage(item)}
                                                        to={`/admin/property/${item.id}/edit`}>
                                                        <span className="mb-0 text-sm">
                                                            #{item.id}
                                                        </span>
                                                    </Link>
                                                </th>
                                                <th
                                                    scope="row">
                                                    <Media
                                                        style={
                                                            !CheckPermission(PERMISSIONS?.CREATE, RESOURCE?.Property) ?
                                                                {
                                                                    "pointer-events": "none"
                                                                } : {}}
                                                        className="align-items-center">
                                                        <Link
                                                            onClick={() => setLocalStorage(item)}
                                                            to={`/admin/property/${item.id}/edit`}
                                                            className="avatar rounded-circle mr-3"
                                                        >
                                                            <img alt="..." src={item?.featured_image} />
                                                        </Link>
                                                        <Link
                                                            onClick={() => setLocalStorage(item)}
                                                            to={`/admin/property/${item.id}/edit`}>
                                                            <span className="mb-0 text-sm">
                                                                <div style={{ color: "#5E5873" }}>{item.name}</div>
                                                                <small className="text-muted">
                                                                    {item?.location?.address?.substring(0, 10)}
                                                                </small>
                                                            </span>
                                                        </Link>
                                                    </Media>
                                                </th>
                                                {
                                                    checkNotChannelPartner() &&
                                                    (<td>
                                                        {item?.createdBy?.name}
                                                    </td>)
                                                }
                                                <td>{moment(item.created_at).format("D-MMM-Y")}</td>
                                                <td>
                                                    <span id={`tooltip-${item.id}`}>
                                                        {moneyFormat(item.total_property_value)}
                                                    </span>
                                                    <UncontrolledTooltip target={`tooltip-${item.id}`}>
                                                        Current Month
                                                    </UncontrolledTooltip>
                                                </td>
                                                {
                                                    checkNotChannelPartner() &&
                                                    (
                                                        <>
                                                            <td>{item?.total_property_sqyds ? item?.total_property_sqyds : 0}</td>
                                                            <td className="text-capitalize">
                                                                {(item?.sto_sale_address == null || item?.sto_sale_address == "")
                                                                    ? <img src={BadgeDelayImage} alt="" />
                                                                    : <img src={BadgeImage} alt="" />}
                                                            </td>
                                                            <td>
                                                                <Badge
                                                                    color=""
                                                                    className={`badge badge-pill badge-${item?.propertyType?.id == 1
                                                                        ? "success"
                                                                        : "primary"
                                                                        } mr-4`}
                                                                >
                                                                    {item?.propertyType?.name}
                                                                </Badge>
                                                            </td>
                                                            <td>
                                                                <Badge
                                                                    color=""
                                                                    className={`badge badge-pill badge-${item?.asset_category == "Tower"
                                                                        ? "success"
                                                                        : "primary"
                                                                        } mr-4`}
                                                                >
                                                                    {item?.asset_category}
                                                                </Badge>
                                                            </td>
                                                            <td>{item?.propertyType?.id != 1 ? item?.stage : ""}</td>
                                                        </>
                                                    )
                                                }
                                                <td>
                                                    {
                                                        !user?.org_id ? (
                                                            <div className="custom-control custom-switch">
                                                                <input
                                                                    type="checkbox"
                                                                    disabled={!CheckPermission(PERMISSIONS?.CREATE, RESOURCE?.Property)}
                                                                    checked={item.status == "Active" ? true : false}
                                                                    name={`property_status`}
                                                                    onChange={(e) =>
                                                                        onPropertyStatusChange(item.id, e)
                                                                    }
                                                                    className="custom-control-input"
                                                                    id={`property-status-${item.id}`}
                                                                />
                                                                <label
                                                                    className="custom-control-label"
                                                                    htmlFor={`property-status-${item.id}`}
                                                                >
                                                                    <Badge
                                                                        color=""
                                                                        className={`badge badge-pill badge-${item.status == "Active"
                                                                            ? "success"
                                                                            : "primary"
                                                                            } mr-4`}
                                                                    >
                                                                        {""}
                                                                    </Badge>
                                                                </label>
                                                            </div>
                                                        ) :
                                                            <>
                                                                <Badge
                                                                    color=""
                                                                    className={`badge badge-pill badge-${item.status == "Active"
                                                                        ? "success" :
                                                                        item.status == "Rejected" ? "danger" :
                                                                            (item.status == "Hold" || item.status == "Pending") ? "warning" :
                                                                                "primary"
                                                                        } 
                                                                        mr-4`}
                                                                >
                                                                    {item?.status}
                                                                </Badge>
                                                            </>
                                                    }
                                                </td>
                                                {
                                                    checkNotChannelPartner() &&
                                                    (
                                                        <>
                                                            <td>
                                                                <div className="custom-control custom-switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        disabled={!CheckPermission(PERMISSIONS?.CREATE, RESOURCE?.Property)}
                                                                        checked={item?.join_waitlist == true ? true : false}
                                                                        name={`property_join_whitelist`}
                                                                        onChange={(e) =>
                                                                            onPropertyJoinChange(item.id, e)
                                                                        }
                                                                        className="custom-control-input"
                                                                        id={`property-join_whitelist-${item.id}`}
                                                                    />
                                                                    <label
                                                                        className="custom-control-label"
                                                                        htmlFor={`property-join_whitelist-${item.id}`}
                                                                    >
                                                                        <Badge
                                                                            color=""
                                                                            className={`badge badge-pill badge-${item?.join_waitlist == true
                                                                                ? "success"
                                                                                : "primary"
                                                                                } mr-4`}
                                                                        >
                                                                            {""}
                                                                        </Badge>
                                                                    </label>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="custom-control custom-switch">
                                                                    <input
                                                                        type="checkbox"
                                                                        disabled={!CheckPermission(PERMISSIONS?.CREATE, RESOURCE?.Property)}
                                                                        checked={item?.is_booking_amount_required == true ? true : false}
                                                                        name={`property_booking`}
                                                                        onChange={(e) =>
                                                                            onPropertyBookingChange(item.id, e)
                                                                        }
                                                                        className="custom-control-input"
                                                                        id={`property-booking-${item.id}`}
                                                                    />
                                                                    <label
                                                                        className="custom-control-label"
                                                                        htmlFor={`property-booking-${item.id}`}
                                                                    >
                                                                        <Badge
                                                                            color=""
                                                                            className={`badge badge-pill badge-${item?.is_booking_amount_required == true
                                                                                ? "success"
                                                                                : "primary"
                                                                                } mr-4`}
                                                                        >
                                                                            {""}
                                                                        </Badge>
                                                                    </label>
                                                                </div>
                                                            </td>
                                                        </>
                                                    )
                                                }
                                                {
                                                    checkNotChannelPartner() ? (
                                                        <td>
                                                            {CheckPermission(PERMISSIONS?.CREATE, RESOURCE?.Property)
                                                                &&
                                                                <div className="d-flex justify-content-start align-items-center">
                                                                    <Button
                                                                        onClick={() => onRemoveProperty(item.id)}
                                                                        className="btn btn-sm btn-link text-danger bg-transparent border-0"
                                                                    >
                                                                        Soft Delete <i className="fa fa-trash"></i>
                                                                    </Button>
                                                                    <Button
                                                                        onClick={() => onForceRemoveProperty(item.id)}
                                                                        className="btn btn-sm btn-link text-danger bg-transparent border-0"
                                                                    >
                                                                        Hard Delete <i className="fa fa-trash"></i>
                                                                    </Button>
                                                                </div>
                                                            }
                                                        </td>) :
                                                        <>
                                                            <td>
                                                                <Link
                                                                    onClick={() => setLocalStorage(item)}
                                                                    to={`/admin/property/${item.id}/edit`}
                                                                >
                                                                    <img src={IconEyeImage} alt="" />
                                                                </Link>
                                                            </td>
                                                        </>
                                                }
                                            </tr>
                                        )) : (
                                            <></>
                                        )}
                                </tbody>
                            </Table>
                            {properties?.length <= 0 &&
                                <NotFound
                                    message="Property Not Found."
                                />
                            }
                            {((pager && pager.pages.length > 0) && (properties && properties.length > 0)) && (
                                <Paginate path="/admin/property" pager={pager} />
                            )}
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default PropertyList;
