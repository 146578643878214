import { Container } from "reactstrap";
import Wizard from '../../components/Wizard'
import { useRef, useState } from 'react'
import Company from './index'
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { appTitle } from "utils";

const AddEditCompany = () => {
    const { company_id } = useParams();
    const { isPropertyLoading, property } = useSelector(state => state.propertyReducer);
   
    const ref = useRef(null)
    const [stepper, setStepper] = useState(null)
    appTitle(company_id ? 'Edit Company' : 'Add Company')
    const steps = [
        {
            id: 'step-1',
            title: 'Create Company',
            subtitle: "Create New LLP Company",
            icon: <i className='fa fa-home'></i>,
            content: <Company parentStepper={stepper}
                company_id={company_id}
            />,
        }
    ]
    return (
        <Container className="mt-5" fluid>
            <div id="card-overlay" className={isPropertyLoading ? 'd-block' : 'd-none'}>
                <div className="h-100 d-flex justify-content-center align-items-center">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
            <Wizard
                type='modern-horizontal'
                ref={ref}
                steps={steps}
                className='shadow-none'
                options={{
                    linear: false
                }}
                instance={el => setStepper(el)}
            />
        </Container>
    )
}

export default AddEditCompany;