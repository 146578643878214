import React, { useState } from 'react'
import { Card, CardBody, CardSubtitle, CardTitle } from 'reactstrap';
import { formatNumberWithRupeeSymbol } from 'utils';
import PaymentList from './PaymentList';
import { useRentalPayout } from 'hooks/useRentalPayout';
const headingstyle = {
    fontFamily: 'Montserrat',
    color: "#5E5873",
    fontSize: '24px',
    fontWeight: 500,
}

export default function Dashboard() {
    const [filtterData, setFilterData] = useState({
        kyc: "",
        wallet: "",
        role: 2,
        from_date: new Date().toISOString(),
        to_date: new Date().toISOString(),
    });
    const { data, isLoading } = useRentalPayout({
        property_id: localStorage.getItem("property_id")
    });
    console.log(data);
    const ColorCard = ({ color, title, subTitle, icon }) => {

        const titleStyle = {
            color: '#5E5873',
            fontFamily: 'Montserrat',
            fontWeight: 600,
            fontSize:"24px"
        };
        const subTitleStyle = {
            color: '#6E6B7B',
            fontFamily: 'Montserrat',
            fontWeight: 400,
            fontSize:"14px"
        };

        return (
            <Card style={{ backgroundColor: color, display: 'flex' }}>
                <CardBody style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: "start",
                    padding: "40px 20px",
                    boxShadow: "0px 10px 15px -15px rgba(0,0,0,0.1)"
                }}>
                    <div>
                        <CardTitle tag="h2" style={titleStyle}>{title}</CardTitle>
                        <CardSubtitle tag="p" style={subTitleStyle}>{subTitle}</CardSubtitle>
                    </div>
                </CardBody>
            </Card>
        );
    };
    //     const cardStyle = {
    //         display: 'flex',
    //         marginBottom: '10px',
    //     };

    //     const rectangleStyle = {
    //         width: '12px',
    //         height: '12px',
    //         alignSelf: "start",
    //         marginTop: '9%',
    //         backgroundColor: color,
    //         marginRight: '15px',
    //         borderRadius: '2px',
    //     };

    //     const titleStyle = {
    //         fontFamily: 'Montserrat',
    //         fontSize: '24px',
    //         color: '#5E5873',
    //         display: 'flex',
    //         alignItems: 'center',
    //     };

    //     const subTitleStyle = {
    //         fontFamily: 'Montserrat',
    //         fontSize: '12px',
    //         color: '#6E6B7B',
    //         fontWeight: 400
    //     };

    //     return (
    //         <div style={cardStyle}>
    //             <div style={{ display: 'flex', alignItems: 'center' }}>
    //                 <div style={rectangleStyle}></div>
    //                 <div>
    //                     <h2 style={titleStyle}>
    //                         {title}
    //                     </h2>
    //                     <p style={subTitleStyle}>
    //                         {subTitle}
    //                     </p>
    //                 </div>
    //             </div>
    //         </div>
    //     );
    // };
    return (

        <div className='px-4'>
            <div className="row mx-0 mt-2">
                <div className='col-6 pl-2'>
                    <h3 style={headingstyle}>Rental Payout</h3>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-xl-3 mt-md-0 mt-4 col-md-6" style={{ padding: "0px 10px" }}>
                    <ColorCard
                        color="white"
                        title={data?.totalUniqueShareholders || 0}
                        subTitle="Total Shareholders"
                    />
                </div>
                <div className="col-xl-3 mt-md-0 mt-4 col-md-6" style={{ padding: "0px 10px" }}>
                    <ColorCard
                        color="white"
                        title={data?.counts?.totalBalanceInEscrow || 0}
                        subTitle="Excluded Shareholders"
                    />
                </div>
                <div className="col-xl-3 mt-xl-0 mt-4 col-md-6" style={{ padding: "0px 10px" }}>
                    <ColorCard
                        color="white"
                        title={formatNumberWithRupeeSymbol(data?.totalRentAmountPendingSum || 0)}
                        subTitle="Shareholders with taxes with held"
                    />
                </div>
                <div className="col-xl-3   mt-xl-0 mt-4 col-md-6" style={{ padding: "0px 10px" }}>
                    <ColorCard
                        color="white"
                        title={formatNumberWithRupeeSymbol(data?.totalRentAmountPaidSum  || 0)}
                        subTitle="Total rent amount paid"
                    />
                </div>
            </div>
            <div className='mt-5'>
                <PaymentList data={data?.monthlyData} />
            </div>
        </div>

    )
}
