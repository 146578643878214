export const accountConstants = {
    POST_ACCOUNT_REQUEST: "POST_ACCOUNT_REQUEST",
    POST_ACCOUNT_SUCCESS: "POST_ACCOUNT_SUCCESS",
    POST_ACCOUNT_FAILURE: "POST_ACCOUNT_FAILURE",
  
    GET_ACCOUNT_REQUEST: "GET_ACCOUNT_REQUEST",
    GET_ACCOUNT_SUCCESS: "GET_ACCOUNT_SUCCESS",
    GET_ACCOUNT_FAILURE: "GET_ACCOUNT_FAILURE",
  
    VIEW_ACCOUNT_REQUEST: "VIEW_ACCOUNT_REQUEST",
    VIEW_ACCOUNT_SUCCESS: "VIEW_ACCOUNT_SUCCESS",
    VIEW_ACCOUNT_FAILURE: "VIEW_ACCOUNT_FAILURE",
  
    DELETE_ACCOUNT_REQUEST: "DELETE_ACCOUNT_REQUEST",
    DELETE_ACCOUNT_SUCCESS: "DELETE_ACCOUNT_SUCCESS",
    DELETE_ACCOUNT_FAILURE: "DELETE_ACCOUNT_FAILURE",
  };