import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Button, Card, Container, Spinner, Table } from "reactstrap";
import { formatNumberWithRupeeSymbol } from "utils";

const AssetOrderList = ({ data }) => {
  return (
    <>
      <Card className="border-none mt-5">
        <Table responsive className="custom-table-div">
          <thead className="page-thead-style">
            <tr>
              <th className="d-flex align-items-center">
                ORDER NO
                <div className="ml-3">
                  <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </div>
              </th>

              <th>customer name</th>

              <th>
                <div className="d-flex align-items-center">
                  order value
                  <div className="ml-3">
                    <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
              </th>


              <th>
                <div className="d-flex align-items-center">
                  fee %
                  <div className="ml-3">
                    <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
              </th>
              {/* <th>
                <div className="d-flex align-items-center">
                order placed on
                  <div className="ml-3">
                    <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
              </th> */}
              <th>
                <div className="d-flex align-items-center">
                  fee amount
                  <div className="ml-3">
                    <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="page-tbody-cell">
            {(
              data?.fees?.map((order, i) => {
                return (
                  <tr key={i}>
                    <td
                      style={{ cursor: 'pointer' }}
                      className="text-primary">
                      <NavLink
                        to={{
                          pathname: `/admin/order/${order?.id}`,

                        }}
                      >
                        {order?.id}
                      </NavLink>
                    </td>
                    <td> {order?.user?.name}</td>
                    <td>{order?.total_amount}</td>
                    <td> {order?.commission} %</td>
                    <td>{formatNumberWithRupeeSymbol(order?.commission_fees)}</td>
                    {/* <td className="text-right">
                      <NavLink
                        to={{
                          pathname: `/admin/order`,
                          state: {
                            order: order,
                            editable: false,
                          },
                        }}
                        color="primary"
                        className="ml-3"
                      >
                        <img src={IconEyeImage} alt="" />
                      </NavLink>
                    </td> */}
                  </tr>
                );
              })
            )}
          </tbody>
        </Table>
      </Card>
    </>
  );
};

export default AssetOrderList;
