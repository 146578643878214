export const employeeConstants = {
  GET_EMPLOYEE_REQUEST: "GET_EMPLOYEE_REQUEST",
  GET_EMPLOYEE_SUCCESS: "GET_EMPLOYEE_SUCCESS",
  GET_EMPLOYEE_FAILURE: "GET_EMPLOYEE_FAILURE",

  POST_EMPLOYEE_REQUEST: "POST_EMPLOYEE_REQUEST",
  POST_EMPLOYEE_SUCCESS: "POST_EMPLOYEE_SUCCESS",
  POST_EMPLOYEE_FAILURE: "POST_EMPLOYEE_FAILURE",

  UPDATE_EMPLOYEE_REQUEST: "UPDATE_EMPLOYEE_REQUEST",
  UPDATE_EMPLOYEE_SUCCESS: "UPDATE_EMPLOYEE_SUCCESS",
  UPDATE_EMPLOYEE_FAILURE: "UPDATE_EMPLOYEE_FAILURE",

  DELETE_EMPLOYEE_REQUEST: "DELETE_EMPLOYEE_REQUEST",
  DELETE_EMPLOYEE_SUCCESS: "DELETE_EMPLOYEE_SUCCESS",
  DELETE_EMPLOYEE_FAILURE: "DELETE_EMPLOYEE_FAILURE",
};
