/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import { useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

import { checkNotChannelPartner } from "utils";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Collapse,
    DropdownMenu,
    DropdownItem,
    Dropdown,
    UncontrolledDropdown,
    DropdownToggle,
    FormGroup,
    Form,
    Input,
    InputGroupText,
    InputGroup,
    Media,
    NavbarBrand,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Container,
    Row,
    Col,
} from "reactstrap";
import { userService } from "../../redux/services";
import { CheckPermission, PERMISSIONS, RESOURCE } from "../../utils";
import { useAccount, useDisconnect } from "wagmi";
import { useWeb3Modal } from "@web3modal/react";

const Sidebar = (props) => {
    const [collapseOpen, setCollapseOpen] = useState();
    // verifies if routeName is the one active (in browser input)
    const activeRoute = (routeName) => {
        return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
    };
    // toggles collapse between opened and closed (true/false)
    const toggleCollapse = () => {
        setCollapseOpen((data) => !data);
    };
    // closes the collapse
    const closeCollapse = () => {
        setCollapseOpen(false);
    };
    // creates the links that appear in the left menu / Sidebar
    const createLinksOld = (routes) => {
        return routes.map((prop, key) => {
            return (
                prop.sidebar && (
                    <NavItem key={key}>
                        <NavLink
                            to={prop.layout + prop.path}
                            tag={NavLinkRRD}
                            onClick={closeCollapse}
                            activeClassName="active"
                        >
                            <i className={prop.icon} />
                            {prop.name}
                        </NavLink>
                    </NavItem>
                )
            );
        });
    };
    const createLinks = (routes) => {
        return routes.map((prop, key) => {
            if (prop.sidebar) {
                return (
                    <NavItem key={key}>
                        {CheckPermission(PERMISSIONS?.MANAGE, prop?.permissions_name) &&
                            <NavLink
                                to={prop.layout + prop.path}
                                tag={NavLinkRRD}
                                onClick={closeCollapse}
                                activeClassName="active"
                            >
                                <i className={prop.icon} />
                                {prop.name}
                            </NavLink>
                        }
                    </NavItem>
                );
            }
        });
    };

    const { isConnected, address } = useAccount()
    const { open } = useWeb3Modal()
    const { disconnect } = useDisconnect()
    const loginIntoWeb3 = async () => {
        if (!isConnected)
            await open()
        else
            await disconnect();
    }

    const { bgColor, routes, logo } = props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
        navbarBrandProps = {
            to: logo.innerLink,
            tag: Link,
        };
    } else if (logo && logo.outterLink) {
        navbarBrandProps = {
            href: logo.outterLink,
            target: "_blank",
        };
    }
    const kyc = JSON.parse(localStorage.getItem("kyc"))
    const org_id = localStorage.getItem("org_id");
    const user = JSON.parse(localStorage.getItem("user") || {})
    return (
        <Navbar
            className="navbar-vertical fixed-left navbar-light bg-white"
            expand="md"
            id="sidenav-main"
        >
            <Container fluid>
                {/* Toggler */}
                <button
                    className="navbar-toggler"
                    type="button"
                    onClick={toggleCollapse}
                >
                    <span className="navbar-toggler-icon" />
                </button>
                {/* Brand */}
                {logo ? (
                    <NavbarBrand className="pt-0" {...navbarBrandProps}>
                        <img
                            alt={logo.imgAlt}
                            className="navbar-brand-img"
                            src={logo.imgSrc}
                        />
                    </NavbarBrand>
                ) : null}
                {/* User */}
                <Nav className="align-items-center d-md-none">
                    <UncontrolledDropdown nav>
                        <DropdownToggle nav className="nav-link-icon">
                            <i className="ni ni-bell-55" />
                        </DropdownToggle>
                        <DropdownMenu
                            aria-labelledby="navbar-default_dropdown_1"
                            className="dropdown-menu-arrow"
                            right
                        >
                            <DropdownItem onClick={() => loginIntoWeb3()}>
                                {isConnected ?
                                    `Disconnect (${address})` :
                                    "Connect Wallet"}
                            </DropdownItem>
                        </DropdownMenu>

                    </UncontrolledDropdown>
                    <UncontrolledDropdown nav>
                        <DropdownToggle nav>
                            <Media className="align-items-center">
                                {/* <span className="avatar avatar-sm rounded-circle">
                                    <img
                                        alt="..."
                                        src={
                                            require("../../assets/img/theme/team-1-800x800.jpg")
                                                .default
                                        }
                                    />
                                </span> */}
                            </Media>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" right>
                            {/* <DropdownItem className="noti-title" header tag="div">
                                <h6 className="text-overflow m-0">Welcome!</h6>
                            </DropdownItem> */}
                            {/* <DropdownItem to="/admin/user-profile" tag={Link}>
                                <i className="ni ni-single-02" />
                                <span>My profile</span>
                            </DropdownItem>
                            <DropdownItem to="/admin/user-profile" tag={Link}>
                                <i className="ni ni-settings-gear-65" />
                                <span>Settings</span>
                            </DropdownItem>
                            <DropdownItem to="/admin/user-profile" tag={Link}>
                                <i className="ni ni-calendar-grid-58" />
                                <span>Activity</span>
                            </DropdownItem>
                            <DropdownItem to="/admin/user-profile" tag={Link}>
                                <i className="ni ni-support-16" />
                                <span>Support</span>
                            </DropdownItem>
                            <DropdownItem divider /> */}
                            <DropdownItem href="/auth/login" onClick={() => userService.logout()}>
                                <i className="ni ni-user-run" />
                                <span>Logout</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown nav>
                        <DropdownToggle nav className="nav-link-icon">
                            <div>
                                <p className="mr-2 d-none user-name d-lg-inline">
                                    {user ? user?.firstname + " " + user?.lastname : "UnNamed"}
                                </p>
                                {!checkNotChannelPartner() && (
                                    <p className="user-role">
                                        Channel Partner
                                    </p>
                                )}
                            </div>
                            <img
                                style={{
                                    width: "38px",
                                    borderRadius: "50%",
                                    height: "38px"
                                }}
                                src={user?.avatar || avtar}
                            />
                        </DropdownToggle>
                        <DropdownMenu
                            aria-labelledby="navbar-default_dropdown_1"
                            className="dropdown-menu-arrow"
                            right
                        >
                            <DropdownItem href="/auth/login" onClick={() => userService.logout()}>
                                <i className="ni ni-user-run" />
                                <span>Logout</span>
                            </DropdownItem>
                        </DropdownMenu>

                    </UncontrolledDropdown>
                </Nav>
                {/* Collapse */}
                <Collapse navbar
                    style={{
                        backgroundColor:
                            (org_id > 0 && kyc.status != "auto_approved") ?
                                "#725AEC" : "#fff"
                    }}
                    isOpen={collapseOpen}>
                    {/* Collapse header */}
                    <div className="navbar-collapse-header d-md-none">
                        <Row>
                            {logo ? (
                                <Col className="collapse-brand" xs="6">
                                    {logo.innerLink ? (
                                        <Link to={logo.innerLink}>
                                            <img alt={logo.imgAlt} src={logo.imgSrc} />
                                        </Link>
                                    ) : (
                                        <a href={logo.outterLink}>
                                            <img alt={logo.imgAlt} src={logo.imgSrc} />
                                        </a>
                                    )}
                                </Col>
                            ) : null}
                            <Col className="collapse-close" xs="6">
                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    onClick={toggleCollapse}
                                >
                                    <span />
                                    <span />
                                </button>
                            </Col>
                        </Row>
                    </div>
                    {/* Form */}
                    <Form className="mt-4 mb-3 d-md-none">
                        <InputGroup className="input-group-rounded input-group-merge">
                            <Input
                                aria-label="Search"
                                className="form-control-rounded form-control-prepended"
                                placeholder="Search"
                                type="search"
                            />
                                <InputGroupText>
                                    <span className="fa fa-search" />
                            </InputGroupText>
                        </InputGroup>
                    </Form>
                    {/* Navigation */}
                    {
                        (org_id > 0 && kyc.status != "auto_approved") ?
                            <>
                                <Row>
                                    <Container style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginTop: "80%",
                                    }}>
                                        <img
                                            alt={""}
                                            style={{
                                                height: 300,
                                                width: 300,
                                                objectFit: "contain"
                                            }}
                                            className="navbar-brand-img"
                                            src={require("./../../assets/img/kyc.png").default}
                                        />
                                        <h1 style={{
                                            color: "white"
                                        }}>Almost there!</h1>
                                        <p style={{
                                            textAlign: "center",
                                            color: "white"
                                        }}>
                                            We just need few minutes of your time to review your credentials before we provide you with
                                            full access.
                                        </p>
                                    </Container>
                                </Row>
                            </>
                            :
                            <>
                                <Nav navbar>{createLinks(routes)}</Nav>
                                {/* Divider */}
                                <hr className="my-3" />
                                {/* Heading */}
                                <h6 className="navbar-heading text-muted">Documentation</h6>
                                {/* Navigation */}
                                <Nav className="mb-md-3" navbar>
                                    <NavItem>
                                        <NavLink href="https://docs.ryzer.app/">
                                            <i className="ni ni-spaceship" />
                                            Getting started
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </>
                    }
                </Collapse>
            </Container>
        </Navbar>
    );
};

Sidebar.defaultProps = {
    routes: [{}],
};

Sidebar.propTypes = {
    // links that will be displayed inside the component
    routes: PropTypes.arrayOf(PropTypes.object),
    logo: PropTypes.shape({
        // innerLink is for links that will direct the user within the app
        // it will be rendered as <Link to="...">...</Link> tag
        innerLink: PropTypes.string,
        // outterLink is for links that will direct the user outside the app
        // it will be rendered as simple <a href="...">...</a> tag
        outterLink: PropTypes.string,
        // the image src of the logo
        imgSrc: PropTypes.string.isRequired,
        // the alt for the img
        imgAlt: PropTypes.string.isRequired,
    }),
};

export default Sidebar;
