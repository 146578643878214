import { getExplorer } from 'helpers/networks'
import { useGetTreasury } from 'hooks/useDAO'
import React, { useState } from 'react'
import { Table, Card, CardImg, CardBody, CardTitle, CardText } from 'reactstrap'
import { theme } from 'utils/theme'
import rupee from "./../../../assets/img/rupee.png"
export default function Treasury() {
  const { data, isLoading } = useGetTreasury({
    property_id: localStorage.getItem("property_id")
  });
  const property = data?.property;
  return (
    <>
      <div className="d-flex">
        <Card
          className="my-2 py-3 d-flex flex-row w-0">
          <img className="mx-4"
            alt="Card image cap"
            src={property ? property?.featured_image : "https://picsum.photos/180/180"}
            style={{
              height: 100,
              width: 100
            }}
            top
          />
          <div style={{ color: "black" }} className=" mr-4 text-black d-flex flex-column w-100">
            <div style={{ color: theme.colors.primary[500] }}>{property?.name}</div>
            <div className="text-xs"
              style={{
                color: theme.colors.neutral[600],
              }}
            ><i class="fas fa-map-marker-alt"></i>{" "}{property?.location?.address}</div>
            <div className="d-flex justify-content-between mt-4">
              <span>
                <div style={{
                  fontSize: 10,
                  color: theme.colors.neutral[600],
                  lineHeight: "12px"
                }}>
                  Asset value
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    color: theme.colors.neutral[800],
                    lineHeight: "16px",
                    fontWeight: 600
                  }}
                >{property?.total_property_value}</div>
              </span>
              <span className='ml-2'>
                <div style={{
                  fontSize: 10,
                  color: theme.colors.neutral[600],
                  lineHeight: "12px"
                }}>Total Tokens</div>
                <div style={{
                  fontSize: "14px",
                  color: theme.colors.neutral[800],
                  lineHeight: "16px",
                  fontWeight: 600
                }}>{property?.total_property_sqyds}</div>
              </span>
            </div>
          </div>
        </Card>
        <Card style={{ color: "black" }} className="my-2 mx-4 py-3 d-flex flex-row align-items-center">
          <div className=" ml-4 text-black d-flex flex-column">
            <div className="text-lg mt-1"
              style={{
                color: "#5E5873",
              }}>{data?.total_amount || 0} INDR</div>
            <div className="text-xs text-muted mt-1">Total Balance</div>
            <div className="text-md mt-1"
              style={{
                color: "#5E5873",
              }}
            >{property?.LLPCompany?.wallet_address} {" "}<i class="far fa-clone"></i></div>
          </div>
          <div className="mx-4 text-xl">
            <img
              src={rupee}
              className="rounded"
              style={{ width: '50px' }} />
          </div>
        </Card>
      </div>
      <Card className="mt-2">
        <Table responsive className="custom-table-div">
          <thead className="thead-light">
            <tr>
              {/* <th>#</th> */}
              <th>
                <div className="d-flex align-items-center">
                  User
                </div>
              </th>

              <th className="d-flex align-items-center">
                WALLET ID
                <div className="ml-3">
                  <i className="fa fa-chevron-up d-block" aria-hidden="true"></i>
                  <i className="fa fa-chevron-down" aria-hidden="true"></i>
                </div>
              </th>

              <th>
                <div className="d-flex align-items-center">
                  TOKENS
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Transition Hash
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  EXECUTED DATE
                  <div className="ml-3">
                    <i className="fa fa-chevron-up d-block" aria-hidden="true"></i>
                    <i className="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.orders?.map((order, index) => {
              return (
                <>
                  <tr data-toggle="collapse"
                    data-target="#transaction_details"
                    className="accordion-toggle" key={index}>
                    {/* <td onClick={() => setOpenRow(!openRow)}>
                      <i className={"custom-transition " + (openRow ? 'fa fa-chevron-down' : 'fa fa-chevron-right')}></i>
                    </td> */}
                    <td>
                      {" "}
                      <img width="30" style={{ borderRadius: 15, marginRight: 5 }} src={order?.user?.avatar} alt="" />
                      {order?.user?.name}{" "}
                    </td>
                    <td>
                      <span>{order?.user?.wallet_address}</span>
                    </td>
                    <td> {order?.no_sqyds}</td>
                    <td>
                      <a
                        href={`${JSON.parse(localStorage.getItem("blockchain_metadata"))?.blockExplorers?.default?.url}/tx/${order?.transaction_hash?.filter((hash) => hash.transaction == "Token Transferred")[0]?.hash}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {order?.transaction_hash?.filter((hash) => hash.transaction == "Token Transferred")[0]?.hash?.substring(0, 20)}..
                      </a>
                    </td>
                    <td> {order?.created_at} </td>
                  </tr>
                  {/* {openRow &&
                    <tr>
                      <td></td>
                      <td colSpan={3}>
                        <div className="accordian-body collapse" id="transaction_details">
                          <div class="row">
                            <div class="col-2">label</div>
                            <div class="col-6">value</div>
                          </div>
                        </div>
                      </td>
                    </tr>} */}
                </>
              );
            })
            }
          </tbody>
        </Table>
      </Card>
    </>
  )
}
