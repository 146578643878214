import { notificationConstants } from "./../../constants";

export function getAdminNotifications(state = {}, action) {
    switch (action.type) {
        case notificationConstants.GET_ADMIN_NOTIFICATION_REQUEST:
            return { loading: true };
        case notificationConstants.GET_ADMIN_NOTIFICATION_SUCCESS:
            return {
                admin_notification: action.payload.data,
                pager: action.payload.pager
            };
        case notificationConstants.GET_ADMIN_NOTIFICATION_FAILURE:
            return { error: action.error };
        default:
            return state;
    }
}
