import React, { useEffect, useState } from "react";
import { Container, Nav, Row, Col, Card } from "reactstrap";
import PropertyList from "./PropertyList";
import OrderList from "./OrderList";
import CustomerList from "./CustomerList";
import PaymentList from "./PaymentList";
import OwnerShipList from "./OwnerShipList";
import WaitingList from "./WaitingList";
import ArchivePropertyList from "./ArchivePropertyList";

const Report = () => {
    const daoNavOptions = ["Property", "Customer", "Ownership", "Order", "Payment",
        "Waitting List", "Archive Properties"
    ]
    const [activeTab, setActiveTab] = useState("Property");
    const getDaoNavOptions = () => {
        return (
            <Card className='p-2 mb-4'>
                <div className="d-flex justify-content-between">
                    <div className="btn-group" role="group" aria-label="Options">
                        {daoNavOptions.map((v, i) =>
                            <button
                                type="button"
                                className={
                                    `btn rz-shadow-none button cursor-pointer  ${activeTab === v ? 'rz-button-primary' : 'white'
                                    }`
                                }
                                style={{
                                    borderRadius: 5
                                }}
                                onClick={() => setActiveTab(v)}
                            >
                                {v}
                            </button>
                        )}
                    </div>
                </div>
            </Card>
        );
    };
    return (
        <Container className="mt-2" fluid>
            <div className='content-header mb-3'>
                <h3 className='mb-0 rz-heading rz-text-22'>Report</h3>
            </div>
            <Row>
                <Col>
                    {getDaoNavOptions()}
                    <div className="py-2 mt-2">
                        {activeTab == "Order" ?
                            <OrderList /> :
                            activeTab == "Customer" ?
                                <CustomerList /> :
                                activeTab == "Ownership" ?
                                    <OwnerShipList /> :
                                    activeTab == "Property" ?
                                        <PropertyList /> :
                                        activeTab == "Payment" ?
                                            <PaymentList /> :
                                            activeTab == "Waitting List" ?
                                                <WaitingList /> :
                                                activeTab == "Archive Properties" ?
                                                    <ArchivePropertyList /> :

                                                    <></>
                        }
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Report;
