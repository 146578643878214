import React, { useEffect, useState } from 'react'
import {
    Button,
    Card,
    Container,
    Row
} from "reactstrap";
import { IoMdCheckmark } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { useLocation } from 'react-router-dom';
import { useUpdateCertificate } from 'hooks/useTokenCertificate';
import { useUpdateTokenSellerDetails } from 'hooks/useTokenSellect';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
export default function TokenSellerDetails() {
    const location = useLocation();
    const { certificate: certificateData } = location.state;
    const { mutate: addTokenSeller } = useUpdateTokenSellerDetails();
    const [certificate, setCertificate] = useState(certificateData);
    useEffect(() => {
        setCertificate(certificateData)
    }, [certificateData])

    const history = useHistory();
    const onSubmitChannel = async (status, reason = "") => {
        let data = {
            id: certificate?.id,
            status: status
        }
        await addTokenSeller(data, {
            onSuccess: (res) => {
                toast.success(res?.message);
            },
            onError: (err) => {
                toast.error(err)
            },
            onFailure: (err) => {
                toast.error('Something went wrong')
            }
        })
    };
    return (
        <Container>
            <Card className="px-1 py-6">
                <table class="table table-borderless">
                    <tbody>
                        <tr>
                            <th scope="col"><h3>Details</h3></th>
                        </tr>
                        <tr>
                            <th scope="col">Full Name</th>
                            <th scope="col">{certificate?.user?.name}</th>
                        </tr>
                        <tr>
                            <th scope="row">Total experience as a Real estate broker</th>
                            <td>{certificate?.seller?.total_experience}</td>
                        </tr>
                        <tr>
                            <th scope="row">Primary City & State of work</th>
                            <td>{certificate?.seller?.city}</td>
                        </tr>
                        <tr>
                            <th scope="row">When did u start working as a Real estate broker</th>
                            <td>{certificate?.seller?.start_working_date}</td>
                        </tr>
                        <tr>
                            <th scope="row">Current state of Employment</th>
                            <td>{certificate?.seller?.current_state_employment}</td>
                        </tr>
                        <tr>
                            <th scope="row">Where have you worked before</th>
                            <td>{certificate?.seller?.work_before}</td>
                        </tr>
                    </tbody>
                </table>
                <div className="d-flex mt-5 justify-content-end align-items-center mr-2">
                    <Button className='rz-button-outline-primary '
                        onClick={() => history.push('/admin/token-seller')}
                    >
                        <span className='align-middle d-sm-inline-block d-none'>Back</span>
                    </Button>
                    {
                        certificate.status == 'Pending' ?
                            <>
                                <button type=" btn"
                                    onClick={() => onSubmitChannel("Rejected")}
                                    // onClick={handleRejectedOpen}
                                    className="btn ml-3 mr-3 rz-button-outline-danger">Reject</button>
                                <button type="button"
                                    onClick={() => onSubmitChannel("Active")}
                                    // onClick={handleApproveOpen}
                                    className="btn rz-button-success">Accept</button>
                            </> :
                            <>
                                {
                                    certificate.status == 'Active' && <div style={{ color: "#28C76F" }} className='Montserrat-500 ml-3 d-flex align-items-center'>
                                        <IoMdCheckmark style={{ fontSize: "20px" }} className='mr-1' />  Accepted
                                    </div>
                                }
                                {
                                    certificate.status == 'Rejected' && <div style={{ color: "#EA5455" }} className='Montserrat-500 ml-3 d-flex align-items-center'>
                                        <RxCross2 className='mr-1' style={{ fontSize: "20px" }} />  Rejected
                                    </div>
                                }
                            </>
                    }
                </div>
            </Card>
        </Container>
    )
}
