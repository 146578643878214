import React, { useEffect, useState } from "react";
import DonutChart from "react-donut-chart";
import { Button, Card, Col, Container, Form, Label, Row } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import MenuIcon from "../../../assets/img/menu-icon.png"
import { useGetCompanyPropertyById, usePropertyRecall } from "../../../hooks/useCompany";
import { BsArrowLeftShort } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
const headingstyle = {
  fontFamily: 'Montserrat',
  color: "#5E5873",
  fontSize: '24px',
  fontWeight: 500,
}
const textStyle = {
  color: "#5E5873",
  fontFamily: 'Montserrat',
  fontSize: '15px',
  lineHeight: '24px',
  fontWeight: 500,
}
export default function CompanyDashboard() {
  const [total, setTotal] = useState({
    total_token: 0,
    total_allocated: 0,
    total_sell: 0
  });
  const { mutate: recallProperty } = usePropertyRecall();
  const { data } = useGetCompanyPropertyById({
    company_id: localStorage.getItem("company_id"),
    property_id: localStorage.getItem("property_id")
  });

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const { register, handleSubmit, reset,
    getValues,
    setValue, control, watch, trigger, formState: { errors } } = useForm({
      resolver: yupResolver(Yup.object().shape({
        answer: Yup.string().required('Required')
      }))
    })
  const onSubmit = async (values) => {
    trigger();
    values.property_id = localStorage.getItem("property_id");
    if (values.refund_to_all_order == true) {
      await recallProperty(values, {
        onSuccess: (res) => {
          setModal(false);
          toast.success(res?.message)
        },
        onError: (err) => {
          toast.error(err)
        },
        onFailure: (err) => {
          toast.error('Something went wrong')
        }
      })
    } else {
      return false;
    }
  }
  useEffect(async () => {
    var allocated, sell, total;
    if (data?.company?.LLPCompanyShare?.filter((share) => share.property_id == localStorage.getItem("property_id"))?.length > 0) {
      allocated = data?.company?.LLPCompanyShare?.filter((share) => share.property_id == localStorage.getItem("property_id"))[0]?.class_allocate_shares
    }
    if (data?.total?.length > 0) {
      sell = data?.total[0]?.total_sqyds_sold
    }
    if (data?.total?.length > 0) {
      total = data?.total[0]?.no_sqyds
    }
    const final = {
      total_token: total ? total : 0,
      total_allocated: allocated ? allocated : 0,
      total_sell: sell ? sell : 0
    };

    setTotal(final);
  }, [data])

  const closeBtn = (
    <button className="close" onClick={toggle} type="button">
      &times;
    </button>
  );
  return (
    <>

      <Container>
        <div className="row py-4 justify-content-between">
          <div className="col-4">
            <h1 className="d-flex align-items-center" style={headingstyle}><Link to='/admin/company'><BsArrowLeftShort className="ml-2" style={{fontSize:"30px",color:"#5E5873",fontWeight:"bold"}}/></Link> Company overview</h1>
          </div>
          <div className="col-3 d-flex align-items-center justify-content-end">
            <Button
              onClick={toggle}
              style={{
                color: "#EA5455",
                border: "1px solid #EA5455"
              }}
              outline
            >
              Recall Asset
            </Button>
          </div>
        </div>
        <Card className="company-dash">
          <div className="aligncenter">Welcome back {data?.company?.name}</div>
          <div className="aligncenter-txt">
            You were here 5 days ago. You are yet to complete the settings.
          </div>
        </Card>
        <div className="row pt-4">
          <div className="col-12">
            <h1 style={{...headingstyle,  fontSize: "20px" }}>{localStorage.getItem('property_name')}</h1>
          </div>
        </div>
        <div class="row my-5">
          <div class="col-xl-3 col-sm-6 col-12">
            <div class="card">
              <div class="card-content">
                <div class="card-body"
                  style={{ borderRadius: 14 }}
                >
                  <div class="media d-flex">
                    <div class="media-body text-left">
                      <h3 class="danger">{total.total_token}</h3>
                      <span>Total Shared</span>
                    </div>
                    <div class="align-self-center"
                      style={{
                        borderRadius: "50%",
                        backgroundColor: '#7367F0',
                        opacity: "80%",
                        width: 50,
                        height: 50,
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <i style={{
                        fontSize: "1.5em",
                        color: "white",
                        paddingLeft: 15,
                        paddingTop: 12
                      }} class="fa fa-arrow-up"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 col-12">
            <div class="card">
              <div class="card-content">
                <div class="card-body"
                  style={{ borderRadius: 14 }}
                >
                  <div class="media d-flex">
                    <div class="media-body text-left">
                      <h3 class="success">{total.total_allocated}</h3>
                      <span>Issued Shares</span>
                    </div>
                    <div class="align-self-center"
                      style={{
                        borderRadius: "50%",
                        backgroundColor: '#28C76F',
                        opacity: "80%",
                        width: 50,
                        height: 50,
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <i style={styles?.cardStyleIcon} class="fas fa-user-clock"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-3 col-sm-6 col-12">
            <div class="card">
              <div class="card-content">
                <div class="card-body"
                  style={{ borderRadius: 14 }}
                >
                  <div class="media d-flex">
                    <div class="media-body text-left">
                      <h3 class="warning">{total.total_token - total.total_allocated}</h3>
                      <span>Available Shares</span>
                    </div>
                    <div class="align-self-center"
                      style={{
                        borderRadius: "50%",
                        backgroundColor: '#00CFE8',
                        opacity: "80%",
                        width: 50,
                        height: 50,
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <i style={{
                        fontSize: "1.5em",
                        color: "white",
                        paddingLeft: 15,
                        paddingTop: 12
                      }} class="fa fa-arrow-down"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-sm-6 col-12">
            <div class="card">
              <div class="card-content">
                <div class="card-body"
                  style={{ borderRadius: 14 }}
                >
                  <div class="media d-flex">
                    <div class="media-body text-left">
                      <h3 class="primary">{data?.investors?.length ? data?.investors?.length : 0}</h3>
                      <span>No of Shareholders</span>
                    </div>
                    <div class="align-self-center"
                      style={{
                        borderRadius: "50%",
                        backgroundColor: '#28C76F',
                        opacity: "80%",
                        width: 50,
                        height: 50,
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <i
                        style={styles?.cardStyleIcon}
                        class="fas fa-users"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Card className="shadow">
              <div class="row">
                <div class="col-sm-5 my-3 pl-5">
                  <div class="col-xs-4">
                    <div className="form-group">
                      <label>Allocated shares</label>
                    </div>
                  </div>
                  <div class="col-xs-4">
                    <div className="form-group">
                      <i
                        className="fas fa-circle pl-1"
                        style={{ color: "#7367F0" }}
                      ></i>
                      <label className="timeline-p pl-1">Tokens allocated</label>
                      <p>{total.total_allocated}</p>
                    </div>
                  </div>
                  <div class="col-xs-4">
                    <div className="form-group">
                      <label>
                        <i
                          className="fas fa-circle pl-1"
                          style={{ color: "#02D3AF" }}
                        ></i> Tokens available
                      </label>
                      <p className="timeline-p">
                        {total.total_token -
                          total.total_allocated}
                      </p>
                    </div>
                  </div>
                  <div class="col-xs-4">
                    <div className="form-group">
                      <label>
                        <i className="fas fa-circle pl-1" style={{ color: "#F96E6F" }} /> Tokens Sold
                      </label>
                      <p className="timeline-p">{0}</p>
                    </div>
                  </div>
                  <div class="col-xs-4">
                    <div className="form-group">
                      <label>authorization by</label>
                      <p className="timeline-p">{data?.company?.LLPCompanyShare[0]?.authorization}</p>
                    </div>
                    <div className="form-group">
                      <label>authorization date</label>
                      <p className="timeline-p">
                        {
                          data?.company?.LLPCompanyShare[0]?.created_at?.split(
                            "T"
                          )[0]
                        }
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="text-right mt-5">
                  </div>
                  <DonutChart
                    data={[
                      {
                        label: "Total allocated",
                        value:
                          total.total_allocated /
                          total.total_token,
                      },
                      {
                        label: "Total available",
                        value:
                          (total.total_token -
                            total.total_allocated) /
                          total.total_token,
                      },
                      {
                        label: "Total Sell",
                        value:
                          total.total_sell /
                          total.total_token,
                      },
                    ]}
                    height={250}
                    width={250}
                    legend={false}
                    colors={['#7367F0', '#F96E6F', '#02D3AF']}
                  />
                </div>
              </div>
            </Card>
          </div>
          <div className="col-lg-6">
            <Card className="p-3 user-timeline-div"
            >
              <div className="d-flex align-items-center">
                <img src={MenuIcon} alt="" />
                <h2 className="mb-0 ml-3">Compnay Timeline</h2>
              </div>
              <div className="row">
                <div className="col-1 pr-0 dots-div">
                  <div className="blue-dot dot"></div>
                  <div className="lower-line"></div>
                </div>
                <div className="col-11 mt-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="order-status mb-1">Login </p>
                    <span className="order-time">{new Date().toDateString()}</span>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </Container>
      <Modal centered={true} isOpen={modal} toggle={toggle} className='recall-modal' style={{ width: "1000px", maxWidth: "100%" }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader toggle={toggle} close={closeBtn}>
            <h1 className="mt-1" style={{ ...headingstyle, fontSize: "18px" }}>Recall Asset</h1>
          </ModalHeader>
          <ModalBody>
            <p style={textStyle}>Are you sure you recall the Asset Lakeview Property from this company?
            </p>
            <p style={textStyle}>Please make sure you enter a proper reason so the existing investor will get a proper clarity. Note: This reason will be shown to the investor and will be asked to update their bank account to initiate bank transfer to their respective accounts</p>
            <div>
              <label>
                Answer :
              </label>
              <textarea {...register('answer')} rows={7} cols={10} className='form-control p-3 mt-3'></textarea>
            </div>
            <div className="d-flex pt-4   justify-content-center">
              <div className="mr-3 d-flex ">
                {/* <Controller
                  name="refund_to_all_order"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <input
                      className="mr-3 d-flex "
                      style={{ width: "20px", height: "20px", cursor: "pointer" }}
                      type="checkbox"
                      {...field}
                    />
                  )}
                /> */}
                <input
                  className="mr-3 d-flex "
                  style={{ width: "20px", height: "20px", cursor: "pointer" }}
                  type="checkbox"
                  {...register('refund_to_all_order')}
                />
              </div>
              <p>Initiate Refund of Investment amount to Investors (Money will be successfully credited to them once bank account is added in the app)</p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="primary" outline onClick={toggle}>
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Submit
            </Button>

          </ModalFooter>
        </form>
      </Modal>
    </>
  );
}
const styles = {
  cardStyle: {
    borderRadius: "50%",
    backgroundColor: '#00CFE8',
    opacity: "80%",
    width: 50,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center'
  },
  cardStyleIcon: {
    fontSize: "1.5em",
    color: "white",
    paddingLeft: 10,
    paddingTop: 10
  }
}
