import { useQuery } from "react-query";
import { API_ENDPOINTS } from "../helpers";
import { api } from "../helpers";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

const fetchCompanyInvestorUpdateTemplate = async (inputdata) => {
    if (inputdata != undefined) {
        const data = await api.get(`${API_ENDPOINTS.COMPNAYINVEPDATE}/update/template_property/${inputdata.property_id}`);
        return data?.data;
    }
    else
        return {}
};
const useCompanyInvestorUpdateTemplate = (id) => {
    return useQuery([API_ENDPOINTS.COMPNAYINVEPDATE + "TEMPLATE", id], () => fetchCompanyInvestorUpdateTemplate(id));
};
const fetchCompanyInvestorUpdateTemplateByID = async (id) => {
    if (id != undefined) {
        const data = await api.get(`${API_ENDPOINTS.COMPNAYINVEPDATE}/update/template/${id}`);
        return data?.data;
    }
    else
        return {}
};
const useCompanyInvestorUpdateTemplateById = (id) => {
    return useQuery([API_ENDPOINTS.COMPNAYINVEPDATE + "TEMPLATE", id], () => fetchCompanyInvestorUpdateTemplateByID(id),
        {
            refetchOnWindowFocus: false,
            enabled: !id
        });
};
const useDeleteUpdateTemplateMetadataMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (id) => api.delete(`${API_ENDPOINTS.COMPNAYINVEPDATE}/update/template/${id}`),
        {
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.COMPNAYINVEPDATE);
            },
        }
    );
};
const useUpdateInvestorUpdateTemplateMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { input, params } }) =>
            api.put(`${API_ENDPOINTS.COMPNAYINVEPDATE}/update/template/${params.id}`, input),
        {
            onSuccess: (result) => {
                if (result.status === 200 || result?.data?.status === 200) {
                    toast.success(result.data.message);
                } else if (result.status === 400) {
                    toast.error(result?.message);
                }
            },
            onSettled: () => {
            },
            onError: (error) => {
                toast.error(error?.response?.data?.message);
            },
        }
    );
};
const useCreateInvestorUpdateTemplateMutation = () => {
    return useMutation(
        ({ variables: { input, params } }) =>
            api.post(`${API_ENDPOINTS.COMPNAYINVEPDATE}/update/template/${params.property_id}`, input),
        {
            onSuccess: (result) => {
                if (result.status === 200 || result?.data?.status === 200) {
                    toast.success(result.data.message);
                } else if (result.status === 400) {
                    toast.error(result?.message);
                }
            },
            onSettled: () => {
            },
            onError: (error) => {
                toast.error(error?.response?.data?.message);
            },
        }
    );
};
export {
    useCompanyInvestorUpdateTemplateById,
    useCompanyInvestorUpdateTemplate,
    useCreateInvestorUpdateTemplateMutation,
    useUpdateInvestorUpdateTemplateMutation,
    useDeleteUpdateTemplateMetadataMutation
};
