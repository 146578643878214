import {
    Badge,
    Card,
    Media,
    Table,
    Container,
    Row,
    UncontrolledTooltip,
    Button,
    Col,
    Spinner,
    NavItem,
    Nav,
    NavLink
} from "reactstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { propertyActions } from "redux/actions";
import moment from "moment";
import { moneyFormat } from "utils";
import { useEffect, useState } from "react";
import BadgeImage from "./../../assets/img/Badge.png"
import BadgeDelayImage from "./../../assets/img/Badge-delay.png"
import Paginate from "components/Paginate";
import { CheckPermission, PERMISSIONS, RESOURCE } from "../../utils";
import queryString from 'query-string';
import NotFound from "components/NotFound/NotFound";
import { api } from "helpers";

const ChannelPatnerPropertyList = () => {
    const dispatch = useDispatch();
    const daoNavOptions = ["Awaiting", "Hold", "Approved", "Rejected"]
    const [activeTab, setActiveTab] = useState("Awaiting");
    const [propertiesData, setPropertiesData] = useState([]);
    const { search } = useLocation();
    const page = queryString.parse(search)?.page
    const getDaoNavOptions = () => {
        return (
            <Card className='p-2'>
                <div className="d-flex justify-content-between">
                    <div className="btn-group" role="group" aria-label="Options">
                        {daoNavOptions.map((v, i) =>
                            <button
                                type="button"
                                className={
                                    `btn rz-shadow-none button cursor-pointer ${activeTab === v ? 'rz-button-primary' : 'white'
                                    }`
                                }
                                style={{
                                    borderRadius: 5
                                }}
                                onClick={() => setActiveTab(v)}
                            >
                                {v}
                            </button>
                        )}
                    </div>
                </div>
            </Card>
        );
    };
    const { properties_partner, pager, gettingPropertyPartner } = useSelector(
        (state) => state.getPropertiesPartnerReducer
    );
    useEffect(() => "do something when id changes", [page]);
    useEffect(() => {
        localStorage.removeItem("property_id")
        localStorage.removeItem("amenities")
        localStorage.removeItem("locationfeature")
        localStorage.removeItem("channel")
    });
    const onInit = () => {
        dispatch(propertyActions.getPartnerProperties({ page: page ? page : 1 }));
    }

    useEffect(() => {
        onInit()
    }, [page]);


    useEffect(() => {
        let data = []
        if (activeTab == "Awaiting") {
            data = properties_partner?.filter((d) =>
                (d.status == "Pending" || d.status == "Inactive"));
        }
        if (activeTab == "Hold") {
            data = properties_partner?.filter((d) =>
                (d.status == "Hold"));
        }
        if (activeTab == "Rejected") {
            data = properties_partner?.filter((d) =>
                (d.status == "Rejected"));
        }
        if (activeTab == "Approved") {
            data = properties_partner?.filter((d) =>
                (d.status == "Active"));
        }
        console.log("properties_partner", properties_partner)
        setPropertiesData(data || [])
    }, [activeTab, properties_partner])


    const onRemoveProperty = (id) => {
        let confirm = window.confirm("Are sure you want to trash this property?");
        if (confirm)
            dispatch(propertyActions.genericPropertyAction("softDeleteProperty", id, onInit));
    };

    const onForceRemoveProperty = (id) => {
        let confirm = window.confirm(
            "Are sure you want to remove this property permanently?"
        );
        if (confirm)
            dispatch(
                propertyActions.genericPropertyAction("forceDeleteProperty", id, onInit)
            );
    };
    const onPropertyBookingChange = (property_id, e) => {
        let data = {
            property_id,
            is_booking_amount_required: e.target.checked ? true : false,
        };
        dispatch(
            propertyActions.genericPropertyAction("updatePropertyStatus", data, onInit)
        );
    };


    function getThumbnailFromVideo(value) {

        if (value) {
            let value_arr = value.split("/");
            if (value_arr.length > 0) {
                if (value_arr[3]) {
                    let youtube_id = value_arr[3];
                    let video_url = `https://i1.ytimg.com/vi/${youtube_id}/hqdefault.jpg`;
                    return video_url
                }
            }
        }
    }
    const setLocalStorage = async (item) => {
        localStorage.setItem("property_id", item.id)
        localStorage.setItem("channel", true);
    }
    return (
        <>
            <Container fluid>
                <div className='content-header mb-3'>
                    <h3 className='mb-0 rz-heading rz-text-22'>Channel Partner</h3>
                </div>
                {getDaoNavOptions()}
                <Row className="mt-4">
                    <div className="col">
                        <Card className="shadow">
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="rz-thead-style">
                                    <tr>
                                        <th scope="col">#ID</th>
                                        <th scope="col">Created By</th>
                                        <th scope="col">Property Name</th>
                                        <th scope="col">Created At</th>
                                        <th scope="col">Value</th>
                                        <th scope="col">Plots</th>
                                        <th scope="col">Tokens</th>
                                        <th scope="col">DAO</th>
                                        <th scope="col">Type</th>
                                        <th scope="col">Docs Uploaded</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Booking Amount Check</th>
                                        {CheckPermission(PERMISSIONS?.CREATE, RESOURCE?.Property) && <th scope="col">Actions</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {gettingPropertyPartner && (
                                        <tr className="text-center">
                                            <td colSpan="10">
                                                <Spinner animation="border" role="status" >{null}</Spinner>
                                            </td>
                                        </tr>
                                    )}

                                    {propertiesData && propertiesData.length > 0 ?
                                        propertiesData.map((item, i) => (
                                            <tr key={i}>
                                                <th>
                                                    <Link
                                                        onClick={() => setLocalStorage(item)}
                                                        to={`/admin/property/${item.id}/edit`}>
                                                        <span className="mb-0 text-sm">
                                                            #{item.id}
                                                        </span>
                                                    </Link>
                                                </th>
                                                <td>
                                                    <span className="mb-0 text-sm">
                                                        <div>{item?.createdBy?.name}</div>
                                                        <small className="text-muted">
                                                            {item?.createdBy?.address || "NA"}
                                                        </small>
                                                    </span>
                                                </td>
                                                <th
                                                    scope="row">
                                                    <Media
                                                        style={
                                                            !CheckPermission(PERMISSIONS?.CREATE, RESOURCE?.Property) ?
                                                                {
                                                                    "pointer-events": "none"
                                                                } : {}}
                                                        className="align-items-center">
                                                        <Link
                                                            onClick={() => setLocalStorage(item)}
                                                            to={`/admin/property/${item.id}/edit`}
                                                            className="avatar rounded-circle mr-3"
                                                        >
                                                            <img alt="..." src={item?.featured_image ?
                                                                item?.featured_image : item?.medias ? getThumbnailFromVideo(item?.medias[0]?.url) : null} />
                                                        </Link>
                                                        <Link
                                                            onClick={() => setLocalStorage(item)}
                                                            to={`/admin/property/${item.id}/edit`}>
                                                            <span className="mb-0 text-sm">
                                                                <div>{item.name}</div>
                                                                <small className="text-muted">
                                                                    {item?.location?.address}
                                                                </small>
                                                            </span>
                                                        </Link>
                                                    </Media>
                                                </th>
                                                <td>{moment(item.created_at).format("D-MMM-Y")}</td>
                                                <td>
                                                    <span id={`tooltip-${item.id}`}>
                                                        {moneyFormat(item.total_property_value)}
                                                    </span>
                                                    <UncontrolledTooltip target={`tooltip-${item.id}`}>
                                                        Current Month
                                                    </UncontrolledTooltip>
                                                </td>
                                                <td>{item?.svg_elements_count ? item?.svg_elements_count : 1}</td>
                                                <td>{item?.total_property_sqyds ? item?.total_property_sqyds : 0}</td>
                                                <td className="text-capitalize">
                                                    {item?.sto_sale_address != null
                                                        ? <img src={BadgeImage} alt="" />
                                                        : <img src={BadgeDelayImage} alt="" />}
                                                </td>
                                                <td>
                                                    <Badge
                                                        color=""
                                                        className={`badge badge-pill badge-${item?.propertyType?.id == 1
                                                            ? "success"
                                                            : "primary"
                                                            } mr-4`}
                                                    >
                                                        {item?.propertyType?.name}
                                                    </Badge>
                                                </td>
                                                <td>{item?.propertyDocument.length}</td>
                                                <td>
                                                    <Badge
                                                        color=""
                                                        className={`badge badge-pill badge-${item.status == "Active"
                                                            ? "success"
                                                            : "primary"
                                                            } mr-4`}
                                                    >
                                                        {item.status}
                                                    </Badge>
                                                </td>
                                                <td>
                                                    <div className="custom-control custom-switch">
                                                        <input
                                                            type="checkbox"
                                                            disabled={!CheckPermission(PERMISSIONS?.CREATE, RESOURCE?.Property)}
                                                            checked={item?.is_booking_amount_required == true ? true : false}
                                                            name={`property_booking`}
                                                            onChange={(e) =>
                                                                onPropertyBookingChange(item.id, e)
                                                            }
                                                            className="custom-control-input"
                                                            id={`property-booking-${item.id}`}
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor={`property-booking-${item.id}`}
                                                        >
                                                            <Badge
                                                                color=""
                                                                className={`badge badge-pill badge-${item?.is_booking_amount_required == true
                                                                    ? "success"
                                                                    : "primary"
                                                                    } mr-4`}
                                                            >
                                                                {item?.is_booking_amount_required ? "Active" : "InActive"}
                                                            </Badge>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td>
                                                    {CheckPermission(PERMISSIONS?.CREATE, RESOURCE?.Property) &&
                                                        <div className="d-flex justify-content-start align-items-center">
                                                            <Button
                                                                onClick={() => onRemoveProperty(item.id)}
                                                                className="btn btn-sm btn-link text-danger bg-transparent border-0"
                                                            >
                                                                Soft Delete <i className="fa fa-trash"></i>
                                                            </Button>
                                                            <Button
                                                                onClick={() => onForceRemoveProperty(item.id)}
                                                                className="btn btn-sm btn-link text-danger bg-transparent border-0"
                                                            >
                                                                Hard Delete <i className="fa fa-trash"></i>
                                                            </Button>
                                                        </div>
                                                    }
                                                </td>
                                            </tr>
                                        )) : (
                                            <></>
                                        )}
                                </tbody>
                            </Table>
                            {propertiesData?.length <= 0 &&
                                <NotFound
                                    message={`No ${activeTab} Property Found.`}
                                />
                            }
                            {/* {((pager && pager.pages.length > 0) && (propertiesData && propertiesData.length > 0)) && (
                                <Paginate path="/admin/property" pager={pager} />
                            )} */}
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default ChannelPatnerPropertyList;
