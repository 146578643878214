export const userConstants = {
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",
  LOGIN_EMPTY: "USERS_LOGIN_EMPTY",

  LOGOUT: "USERS_LOGOUT",
  LOGOUT_REQUEST: "USERS_LOGOUT_REQUEST",

  VERIFY_OTP_REQUEST: "USERS_VERIFY_OTP_REQUEST",
  VERIFY_OTP_SUCCESS: "USERS_VERIFY_OTP_SUCCESS",
  VERIFY_OTP_FAILURE: "USERS_VERIFY_OTP_FAILURE",

  RESEND_OTP_REQUEST: "USERS_RESEND_OTP_REQUEST",
  RESEND_OTP_SUCCESS: "USERS_RESEND_OTP_SUCCESS",
  RESEND_OTP_FAILURE: "USERS_RESEND_OTP_FAILURE",

  AUTH_SUCCESS: "USER_AUTH_SUCCESS",
  AUTH_FAILURE: "USER_AUTH_FAILURE",

  GET_USERS_REQUEST: "GET_USERS_REQUEST",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAILURE: "GET_USERS_FAILURE",
};
