import { useMutation, useQuery } from "react-query";
import { api } from "../helpers";
const useGetAssetSellerMyCommission = (options) => {
    return useQuery([`useGetAssetSellerMyCommission`, options],
        async () => {
            const data = await api.get(`/asset-seller/admin/my-commission`);
            return data;
        }, {
        keepPreviousData: true,
        refetchOnWindowFocus: false
    });
};
const useGetAssetSellerMyCommissionById = (options = {}) => {
    return useQuery([`useGetAssetSellerMyCommissionById${options?.property_id}`, options],
        async ({ queryKey }) => {
            if (queryKey[1]?.property_id) {
                const data = await api.get(`/asset-seller/admin/my-commission/${queryKey[1]?.property_id}`);
                return data;
            }
            else return {};
        }
        , {
            keepPreviousData: true,
        });
}

export {
    useGetAssetSellerMyCommission,
    useGetAssetSellerMyCommissionById
};
