import { useGetCompanyTokenByPropertyId } from '../../../hooks/useCompany';
import React from 'react'
import { Link, NavLink } from "react-router-dom";
import { Button, Card, Col, Container, Row, Spinner, Table } from "reactstrap";
import NotFound from 'components/NotFound/NotFound';
import { FiEdit } from 'react-icons/fi';

export default function TokenCertificate() {
    const { data } = useGetCompanyTokenByPropertyId({
        property_id: localStorage.getItem("property_id")
    });

    return (
        <Container fluid className="mt-2">
            <Row className="my-2">
                <Col>
                    <h3 className='rz-heading rz-text-18'>Token Certificate for Property: {localStorage.getItem("property_name")}</h3>
                </Col>
            </Row>
            <Card>
                <Table className='mt-3' responsive>
                    <thead className="rz-thead-style">
                        <tr>
                            <th>CERTIFICATE ID</th>
                            <th>SHARES CLASS</th>
                            <th>SHARES</th>
                            <th className="text-center">STATUS</th>
                            <th>shareholder name</th>
                            <th>ISSUE DATE</th>
                            <th className="text-right">ACTIONS</th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            data?.data?.map((certificate) => (
                                <tr >
                                    <td>
                                        <NavLink
                                            to={{
                                                pathname: `/company/tokenCertificateDetails`,
                                                state: {
                                                    certificate: certificate,
                                                },
                                            }}
                                            color="primary">
                                            #{certificate?.id}
                                        </NavLink>
                                    </td>
                                    <td>
                                        {certificate?.token_class}
                                    </td>
                                    <td>{certificate?.no_token}</td>

                                    <td className="text-center"> {certificate?.status} </td>
                                    <td>
                                        {" "}
                                        <img width="30" style={{
                                            borderRadius: 15,
                                            width: 30,
                                            height: 30,
                                            objectFit: "contain",
                                            marginRight: 5
                                        }}
                                            src={certificate?.user?.avatar} alt="" />
                                        {certificate?.user?.name}{" "}
                                    </td>
                                    <td>{certificate?.created_at}</td>
                                    <td className="text-right">
                                        <Link
                                            to={{
                                                pathname: `/company/tokenCertificateDetails`,
                                                state: {
                                                    certificate: certificate,
                                                },
                                            }}
                                        >
                                            <FiEdit style={{ fontSize: "20px" }} className="ryzer-text-blue cursor-pointer" />
                                        </Link>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
                {data?.data?.length <= 0 &&
                    <NotFound
                        message="No cretificate generated for this property."
                    />
                }
            </Card>
        </Container>
    )
}
