import { api } from "../../helpers";

export const userService = {
  login,
  register,
  logout,
  updateMe,
  resendOtp,
  verifyOtp,
  getMe,
  getUsers,
};

function login(data) {
  return api.post("auth/login", data);
}
function register(data) {
  return api.post("auth/register", {
    ...data,
    org_id: true
  });
}
function logout() {
  localStorage.clear();
}

function updateMe(data, step) {
  return api.put(`me/step/${step}`, data, {
    headers: {
      "Content-Type":
        step == "one" ? "application/json" : "multipart/form-data",
    },
  });
}

function getMe(query) {
  return api.get(`me?select=${query}`);
}

function resendOtp(data) {
  return api.post(`auth/resend`, data);
}

function verifyOtp(data) {
  return api.post("auth/verify", data).then((user) => {
    return user;
  });
}

function getUsers() {
  return api.get(`/users`);
}
