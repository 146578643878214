import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import classnames from 'classnames'
import {
    Button, Card, Col, Container, Form, Table,
    Row, Label, FormGroup, ModalFooter, Modal, ModalHeader, ModalBody
} from "reactstrap";
import { toast } from 'react-toastify';
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useCompanyInvestorUpdateTemplate } from 'hooks/useInvestorUpdateTemplate';
import { useCreateInvestorUpdateTemplateMutation } from 'hooks/useInvestorUpdateTemplate';
import { useUpdateInvestorUpdateTemplateMutation } from 'hooks/useInvestorUpdateTemplate';
import { useCompanyInvestorUpdateTemplateById } from 'hooks/useInvestorUpdateTemplate';

export default function CreateorUpdateInvestorUpdate() {
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({})
    const [status, setStatus] = useState({
        status: 'DRAFT' // default status value
    });
    const history = useHistory();
    const {
        register: register1,
        handleSubmit: handleSubmit1,
        setValue: setValue1,
        trigger: trigger1,
        formState: { errors: errors1, isValid: isValid1 } } = useForm({})
    const { mutate: createInvestorUpdate } = useCreateInvestorUpdateTemplateMutation()
    const { mutate: updateInvestorUpdate } = useUpdateInvestorUpdateTemplateMutation()
    const { data, refetch } = useCompanyInvestorUpdateTemplateById(localStorage.getItem("investor_update_template_id"))
    useEffect(() => {
        if (data) {
            setValue1("heading", data?.heading)
            setValue1("update_type", data?.update_type)
            setValue1("update_type_subtitle", data?.update_type_subtitle)
            setValue1("listofupdate", data?.listofupdate)
        }
    }, [data])
    useEffect(() => {
        if (localStorage.getItem("investor_update_template_id"))
            refetch()
    }, [])
    const onSubmit1 = async (inputData) => {
        if (!inputData)
            return;

        if (!data?.heading && data?.heading == "") {
            toast.error("Heading is required");
            return;
        }
        if (!data?.update_type && data?.update_type == "") {
            toast.error("Update type is required");
            return;
        }
        if (!data?.update_type_subtitle && data?.update_type_subtitle == "") {
            toast.error("Update type subtitle is required");
            return;
        }
        if (!data?.listofupdate && data?.listofupdate == "") {
            toast.error("Other Deatils is required");
            return;
        }
        if (localStorage.getItem("investor_update_template_id")) {
            await updateInvestorUpdate(
                {
                    variables: {
                        params: {
                            id: localStorage.getItem("investor_update_template_id"),
                        },
                        input: {
                            ...inputData,
                            status: status
                        },
                    },
                }, {
                onSuccess: (res) => {
                    refetch()
                        toast.success('Deatils Updated Successfully')
                        localStorage.setItem("investor_update_template_id", res?.data?.id)
                }
            })
        }
        else {
            await createInvestorUpdate(
                {
                    variables: {
                        params: {
                            property_id: localStorage.getItem("property_id")
                        },
                        input: {
                            ...inputData,
                            status: status
                        },
                    },
                }, {
                onSuccess: (res) => {
                    refetch()
                    toast.success('Investor Update Created Successfully')
                        localStorage.setItem("investor_update_template_id", res?.data?.id)
                }
            })
        }
        history.push("/company/update")
    }
    return (
        <>
            <Container>
                <Card className="p-4">
                    <Row className="my-2 bg-grey p-2 align-item-center">
                        <Col>
                            <h3>Create Investor Updates Template</h3>
                        </Col>
                    </Row>
                    <Form onSubmit={handleSubmit1(onSubmit1)}>
                        <Row>
                            <FormGroup tag={Col} md='12'>
                                <Label className='form-label' htmlFor={`heading`}>
                                    Heading
                                </Label>
                                <input
                                    id={`heading`}
                                    placeholder='eg. Need to change bulb for this property by this month'
                                    {...register1(`heading`)}
                                    className={classnames({ 'is-invalid': errors1.heading, 'form-control': true })}
                                />
                            </FormGroup>
                            <FormGroup tag={Col} md='6'>
                                <Label className='form-label'
                                    htmlFor={`update_type`}>
                                    Update Type
                                </Label>
                                <select className="form-control"
                                    {...register1(`update_type`)}
                                >
                                    <option>Management</option>
                                    <option>Board</option>
                                    <option>Anual Report</option>
                                </select>
                            </FormGroup>
                            <FormGroup tag={Col} md='6'>
                                <Label className='form-label' htmlFor={`update_type_subtitle`}>
                                    Update type Sub Heading
                                </Label>
                                <input
                                    id={`update_type_subtitle`}
                                    placeholder='Management Update from Ryzer'
                                    {...register1(`update_type_subtitle`)}
                                    className={classnames({ 'is-invalid': errors1.update_type_subtitle, 'form-control': true })}
                                />
                            </FormGroup>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <label >Other instructions</label>
                                    <textarea
                                        {...register1("listofupdate")}
                                        rows={10} cols={3} className='form-control'>
                                    </textarea>
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className='d-flex justify-content-end mb-4'>
                            <Link className="btn btn-primary" to="/company/update">
                                <i className="fa fa-back"></i> Cancle
                            </Link>
                            {!localStorage.getItem("investor_update_template_id") && (
                                <Button
                                    onClick={() => {
                                        setStatus("DRAFT")
                                        onSubmit1()
                                    }}
                                    type='submit'
                                    color='primary'
                                    className='btn-next'>
                                    <span className='align-middle d-sm-inline-block d-none'>
                                        {"Save as Draft"}
                                    </span>
                                </Button>)
                            }
                            <Button
                                onClick={() => {
                                    setStatus("DRAFT")
                                    onSubmit1()
                                }}
                                type='submit'
                                color='primary'
                                className='btn-next'>
                                <span className='align-middle d-sm-inline-block d-none'>
                                    {localStorage.getItem("investor_update_template_id") ? "Update Details" : "Create Update"}
                                </span>
                            </Button>
                        </div>
                    </Form>
                </Card>
                {/* {localStorage.getItem("investor_update_template_id") && (
                    <Card className="p-4">
                        <div className='content-header py-4 d-flex justify-content-between align-items-center'>
                            <h3 className='mb-0 text-muted'>List Of Update Sub Items</h3>
                            <button onClick={() => addEditModal()} className="btn btn-primary" type="button">
                                <i className='fa fa-plus'></i> Add List Of Update
                            </button>
                        </div>
                        <Row>
                            <Col>
                                {data?.listofupdate && (
                                    <div className="accordion">
                                        {
                                            data?.listofupdate.map((item, i) => {
                                                return (
                                                    <div className="card" key={i}>
                                                        <div className="d-flex justify-content-between
                                                            allign-item-center
                                                            pl-2
                                                            mt-4">
                                                            <div>
                                                                <h3>{item?.title}</h3>
                                                                <h4>{item?.subtitle}</h4>
                                                            </div>
                                                            <div>
                                                                <div className='d-flex align-items-center'>
                                                                    <button onClick={() => removePropertyFaq(item.title)} key={i} className="text-muted btn-sm btn btn-link" type="button">
                                                                        <i className='fa fa-trash'></i>
                                                                    </button>
                                                                    <button onClick={() => addEditModal(item.title)} className="text-muted btn-sm btn btn-link" type="button">
                                                                        <i className='fa fa-edit'></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Card>
                )} */}
                {/* <Modal isOpen={isModalOpen} centered={true} className='modal-lg'>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <ModalHeader>
                            Add Sub Item Updates
                        </ModalHeader>
                        <ModalBody>
                            <FormGroup>
                                <Label className='form-label' htmlFor={`question`}>
                                    Title
                                </Label>
                                <input
                                    {...register(`title`)}
                                    className={classnames({ 'is-invalid': errors.title, 'form-control': true })}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label className='form-label' htmlFor={`question`}>
                                    Subtitle
                                </Label>
                                <input
                                    {...register(`subtitle`)}
                                    className={classnames({ 'is-invalid': errors.subtitle, 'form-control': true })}
                                />
                            </FormGroup>

                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={() => setIsModalOpen(!isModalOpen)}>
                                Cancel
                            </Button>
                            {' '}
                            <Button
                                color="primary"
                            >
                                {'Save'}
                            </Button>
                        </ModalFooter>
                    </Form>
                </Modal> */}
            </Container>
        </>
    )
}
