import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Card, FormGroup, Label, Spinner, Table } from "reactstrap";
import { moneyFormat } from "utils";
import IconEdit from "./../../assets/img/Icon-edit.png"
import VerticalMenuIcon from "../../assets/img/vertical-menu-icon.png"
import NotFound from "components/NotFound/NotFound";
import { useGetOrderFilter } from "hooks/useProperty";
import { redirectToPropertyDao } from "utils";
import { DatePicker } from "reactstrap-date-picker";
import moment from "moment";
import exportFromJSON from "export-from-json";
import { useGetAllPropertyFilter } from "hooks/useProperty";
import { useGetPropertyWaitlistReportFilter } from "hooks/useReport";
const WaitingList = () => {

  const { data: filterDb, isLoading } = useGetAllPropertyFilter();
  const [filtterData, setFilterData] = useState({
    property_id: "",
    from_date: new Date().toISOString(),
    to_date: new Date().toISOString(),
  })
  const { data: orders, isLoading: loading } = useGetPropertyWaitlistReportFilter({
    filter: filtterData,
    property_id: filtterData.property_id
  }, (
    filtterData.property_id &&
    filtterData.property_id != ""));

  function handleChange(value) {
    setFilterData({
      ...filtterData,
      from_date: value
    })
  }

  function handleChangeTo(value) {
    setFilterData({
      ...filtterData,
      to_date: value
    })
  }

  const exportPDF = () => {
    const title = `order-report-${filtterData?.from_date}-${filtterData?.to_date}`;

    const report_data = orders?.data?.map((order) => {
      return ({
        "ORDER ID": order?.id,
        "STATUS": order?.status,
        "CUSTOMER NAME": order?.user_name,
        "PROPERTY Name": order?.property_name,
        "PLOT ID": order?.plot_id,
        "NO OF SQYD": order?.no_sqyds,
        "Asset Type": order?.investment_type,
        "TOTAL VALUE": order?.total_amountp,
        "LAST ACTIVITY": moment(order?.created_at).format("DD-MM-YYYY")
      })
    })

    exportFromJSON({ data: report_data, fileName: title, exportType: 'csv' });
  };

  return (
    <>
      <div className="row mt-3">
        <div className="col-6">
          <FormGroup>
            <Label>Transaction - From Date</Label>
            <DatePicker
              size="md"
              id="from-date"
              value={filtterData.from_date}
              onChange={(v, f) => handleChange(v, f)} />
          </FormGroup>
        </div>
        <div className="col-6">
          <FormGroup>
            <Label>Transaction - To Date</Label>
            <DatePicker
              id="to-date"
              value={filtterData.to_date}
              onChange={(v, f) => handleChangeTo(v, f)} />
          </FormGroup>
        </div>
      </div>
      <div className="row">
        <div className='col-6'>
          <label>Select Asset</label>
          <select
            value={filtterData.property_id}
            onChange={(e) => setFilterData({
              ...filtterData,
              property_id: e.target.value
            })}
            className='form-control'>
            <option value=''>Select Asset</option>
            {filterDb && filterDb?.map((user) => (
              <option key={user?.id} value={user?.id}>{user?.name}</option>
            ))
            }
          </select>
        </div>
      </div>
      <div className="row pb-2 pt-4 mx-1">
        <button onClick={() => {
          exportPDF()
        }
        }
          className="btn rz-button-primary ml-3" type="button">
          Generate Report
        </button>
        <button onClick={() => setFilterData({
          property_id: "",
          from_date: new Date().toISOString(),
          to_date: new Date().toISOString(),
        })}
          className="btn rz-button-primary" type="button">
          Clear Filter
        </button>
      </div>
      <Card className="border-none">
        <Table responsive className="custom-table-div mt-3">
          <thead className="rz-thead-style">
            <tr>
              <th className="d-flex align-items-center">
                USER ID
                <div className="ml-3">
                  <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </div>
              </th>

              <th>
                <div className="d-flex align-items-center">
                  CUSTOMER NAME
                  <div className="ml-3">
                    <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
              </th>

              <th>
                <div className="d-flex align-items-center">
                  CUSTOMER Phone
                  <div className="ml-3">
                    <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
              </th>

              <th>
                <div className="d-flex align-items-center">
                  CUSTOMER email
                  <div className="ml-3">
                    <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Asset Name
                  <div className="ml-3">
                    <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
              </th>

              <th>
                <div className="d-flex align-items-center">
                  LAST ACTIVITY
                  <div className="ml-3">
                    <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr className="text-center">
                <td colSpan="10">
                  <Spinner animation="border" role="status" >{null}</Spinner>
                </td>
              </tr>
            ) : (
              orders?.data?.map((order, i) => {
                return (
                  <tr key={i}>
                    <td style={{ cursor: 'pointer' }}>
                      <NavLink to={`/admin/customer/${order?.user_id}`}>
                        {order?.user_id}
                      </NavLink>
                    </td>
                    <td>{order?.user?.name}</td>
                    <td>{order?.user?.phone}</td>
                    <td>{order?.user?.email}</td>
                    <td> {order?.property?.name} </td>
                    <td>{moment(order?.updated_at).format("DD-MM-YYYY")}</td>
                  </tr>
                );
              })
            )}
          </tbody>
        </Table>
        {orders?.data?.length <= 0 &&
          <NotFound
            message="Please Refine Your Filter"
          />
        }
      </Card>
    </>
  );
};
export default WaitingList;
