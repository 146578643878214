import { userConstants } from "../../constants";

export function getUsersReducer(state = {}, action) {
  switch (action.type) {
    case userConstants.GET_USERS_REQUEST:
      return { loading: true };
    case userConstants.GET_USERS_SUCCESS:
      return { users: action.payload.data, pager: action.payload.pager };
    case userConstants.GET_USERS_FAILURE:
      return {};
    default:
      return state;
  }
}
