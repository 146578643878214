import { orderConstants } from "../../constants/order.contstants";

export function updateOrderStatusReducer(state = {}, action) {
  switch (action.type) {
    case orderConstants.UPDATE_ORDERS_STATUS_REQUEST:
      return { loading: true };
    case orderConstants.UPDATE_ORDERS_STATUS_SUCCESS:
      return { singleOrder: action.payload.data };
    case orderConstants.UPDATE_ORDERS_STATUS_FAILURE:
      return { loading: true };
    default:
      return state;
  }
}
