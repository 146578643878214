import React from "react";
import Amenities from "./Amenities";
import LocationFeature from "./LocationFeature";

const Config = () => {
    return (
        <>
            <Amenities />
            <LocationFeature />
        </>
    );
};

export default Config;
