import { useEffect, useRef, useState } from "react";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Wizard from "components/Wizard";
import { useLocation } from "react-router-dom";

const Index = ({ parentStepper }) => {
  const ref = useRef(null);
  const location = useLocation();
  const [stepper, setStepper] = useState(null);
  const steps = [
    {
      id: "Step1-step-1",
      title: "Vote details",
      subtitle: location?.state?.proposal ? "Completed" : "In Progress",
      content: (
        <Step1
          parentStepper={parentStepper}
          stepper={stepper}
          type="wizard-vertical"
        />
      ),
    },
    {
      id: "Review-step-2",
      title: "Review",
      subtitle:
        location?.state?.proposal ? "In Progress" : "Not Completed",
      content: (
        <Step2
          parentStepper={parentStepper}
          stepper={stepper}
          type="wizard-vertical"
        />
      ),
    },
  ];

  return (
    <div>
      <Wizard
        type="modern-vertical"
        ref={ref}
        steps={steps}
        className="shadow-none bg-white"
        options={{
          linear: false,
        }}
        contentclassName="shadow-none border-left rounded-0"
        instance={(el) => setStepper(el)}
      />
    </div>
  );
};

export default Index;
