import React, { useEffect, useState } from "react";

import {
    Badge,
    Button, Card, Col,
    Container, Form, FormGroup, Label, Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row, Spinner, Table
} from "reactstrap";
import modal_close from './../../assets/img/modal-close.png';
import { useForm } from "react-hook-form";
import NotFound from "components/NotFound/NotFound";
import { toast } from "react-toastify";
import { useDeleteDirectorById } from "hooks/useCompany";
import { api } from "helpers";
import * as yup from 'yup'
import { yupResolver } from "@hookform/resolvers/yup";
import { AiOutlineDelete } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { accountAction } from "redux/actions";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { Link } from "react-router-dom";
export default function AccountManagement({ stepper }) {

    const { company_id } = useParams();
    const dispatch = useDispatch();
    const { accounts = [], loading } = useSelector(
        (state) => state.getAccountReducer
    );
    const [viewMode, setViewMode] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [id, setId] = useState('');
    const [isModalDirector, setIsModalDirector] = useState(false);
    useEffect(() => {
        dispatch(accountAction.getAccount(company_id));
    }, [company_id]);


    const accountSchema = yup.object().shape({
        holder_name: yup.string().required("Name is Required."),
        account_number: yup.string().required("Number is Required."),
        //bank_name: yup.string().required('Bank Name Required'),
        ifsc_number: yup.string().required('IFSC code required'),
        category: yup.string().required('category required'),
        additional_information: yup.string()
    });

    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm({
        resolver: yupResolver(accountSchema),
    });
    const onSubmit = async (data) => {
        data.company_id = company_id;
        dispatch(accountAction.postAccount({ data, company_id }));
        setIsModalDirector(false)

    };

    const viewAccount = (account) => {
        reset({
            holder_name: account?.holder_name,
            account_number: account?.account_number,
            ifsc_number: account?.ifsc_number,
            category: account?.category,
            additional_information: account?.additional_information
        })
    }
    const handleAccountDelete = (id) => {
        setDeleteModal(false);
        dispatch(accountAction.deleteAccount({ id, company_id }));
    };
    const AddAccount = () => {
        reset({
            holder_name: "",
            account_number: "",
            ifsc_number: "",
            additional_information: ""
        })
    }
    return (
        <>
            <Container>
                <Card className="mb-5">
                    <Row className="my-2 align-items-center mb-4">
                        <Col className="text-left">
                            <h3 className="all-page-heading mb-0" style={{ fontSize: "20px" }}>Bank Account Management</h3>
                        </Col>
                        <Col className="text-right">
                            <button
                                type="button"
                                className="btn rz-button-primary"
                                onClick={() => { AddAccount(); setIsModalDirector(!isModalDirector) }}>

                                {" "}
                                Add new account
                            </button>
                        </Col>
                    </Row>
                    <Table className="align-items-center table-flush" >
                        <thead className="rz-thead-style">
                            <tr>
                                <th>Account holder</th>
                                <th>Category</th>
                                <th>bank account</th>
                                <th className="text-right">ACTIONS</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                loading ?
                                    <tr className="text-center">
                                        <td colSpan="10">
                                            <Spinner animation="border" role="status" >{null}</Spinner>
                                        </td>
                                    </tr>
                                    :
                                    <>
                                        {
                                            accounts.length != 0 ?
                                                accounts?.map((account) => (
                                                    <tr>
                                                        <td>{account?.holder_name}</td>
                                                        <td>{account?.category}</td>
                                                        <td>{account?.metadata?.bank?.BANK}</td>
                                                        <td className="text-right">
                                                            <div className="d-flex justify-content-end">
                                                                <div
                                                                    onClick={() => { viewAccount(account); setViewMode(true); setIsModalDirector(!isModalDirector) }}
                                                                    color="primary"
                                                                    className=" ryzer-text-blue cursor-pointer bg-transparent border-0"
                                                                >
                                                                    <AiOutlineEye style={{ fontSize: "22px" }} />
                                                                </div>
                                                                <div
                                                                    onClick={() => { }}
                                                                    className="  ryzer-text-blue ml-3 cursor-pointer bg-transparent border-0"
                                                                >
                                                                    <AiOutlineDelete onClick={() => { setId(account.id); setDeleteModal(true) }} style={{ fontSize: "22px" }} />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )) : <tr className="text-center">
                                                    <td className="" colSpan='10'>
                                                        <NotFound
                                                            message="No Bank Account"
                                                        />
                                                    </td>
                                                </tr>
                                        }
                                    </>
                            }
                        </tbody>
                    </Table>

                </Card>
                <div className='mt-5 align-item-center d-flex justify-content-between'>
                    <div>
                        <Button type='button' color='success' onClick={() => stepper.next()} className='btn-next' >
                            <span className='align-middle d-sm-inline-block d-none'>Skip</span>
                            <i className='fa fa-fast-forward align-middle mt-1 mr-sm-25 mr-0'></i>
                        </Button>
                    </div>
                    <div className='d-flex align-item-center justify-content-end'>
                        <Link to='/admin/company'>
                            <Button type='button' className='rz-button-outline-primary'>
                                Cancel
                            </Button>
                        </Link>
                        <Button color='primary' className=' ml-3 mr-2  rz-button-outline-primary' onClick={() => stepper.previous()}>
                            <BsArrowLeftShort style={{ fontSize: "22px" }} />
                            <span className='align-middle d-sm-inline-block d-none'>Back</span>
                        </Button>
                        <Button type='button' onClick={() => stepper.next()} className='btn-next rz-button-primary'>
                            <BsArrowRightShort style={{ fontSize: "22px" }} />
                            <span className='align-middle d-sm-inline-block d-none'>Next</span>
                        </Button>
                    </div>
                </div>
            </Container>
            <Modal isOpen={isModalDirector} centered={true} className="container modal-lg">
                <div>
                    <div className="modal-content">
                        <div className="modal-header px-4">
                            <div className="all-page-heading" style={{ fontSize: "18px" }}>{viewMode ? 'View Account' : 'Add New Account'}</div>
                            <button type="button" className="close" data-dismiss="modal" onClick={() => { setViewMode(false); setIsModalDirector(!isModalDirector) }}>
                                &times;
                            </button>
                        </div>
                        <div className="modal-body px-3 pt-0">
                            <Container fluid className="">

                                <Card className="rz-shadow-none border-0">
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <Row>
                                            <Col className="my-2" md="6" xs="12">
                                                <Label> Account holder name  *</Label>
                                                <input
                                                    className="form-control styled-input "
                                                    type="text"
                                                    readOnly={viewMode}
                                                    placeholder="Enter Name"
                                                    {...register("holder_name", {})}
                                                />
                                                {errors?.holder_name && <div className="mt-1 rz-text-14" style={{ color: "red" }}>{errors.holder_name.message}</div>}

                                            </Col>
                                            <Col className="my-2" md="6" xs="12">
                                                <Label> Account number *</Label>
                                                <input
                                                    className="form-control styled-input "
                                                    type="number"
                                                    noValidate
                                                    readOnly={viewMode}
                                                    placeholder="Enter number"
                                                    {...register("account_number")}
                                                />
                                                {errors?.account_number && <div className="mt-1 rz-text-14" style={{ color: "red" }}>{errors.account_number.message}</div>}
                                            </Col>
                                            <Col className="my-2" md="6" xs="12">
                                                <Label> IFSC Code  *</Label>
                                                <input
                                                    className="form-control styled-input "
                                                    type="text"
                                                    readOnly={viewMode}
                                                    placeholder="Enter IFSC code"
                                                    {...register("ifsc_number")}
                                                />
                                                {errors?.ifsc_number && <div className="mt-1 rz-text-14" style={{ color: "red" }}>{errors.ifsc_number.message}</div>}


                                            </Col>
                                            <Col className="my-2" md="6" xs="12">
                                                <Label> Category *</Label>
                                                <select disabled={viewMode} className="form-control styled-menu" {...register("category")}>

                                                    <option value='Brokerage'>Brokerage</option>
                                                    <option value='Legal'>Legal</option>
                                                </select>
                                            </Col>
                                            <Col className="my-2" md="12" xs="12">
                                                <FormGroup>
                                                    <Label className='form-label' htmlFor={`additional_information`}> Additional information </Label>
                                                    <textarea
                                                        rows='4'
                                                        readOnly={viewMode}
                                                        id={`additional_information`}
                                                        className="form-control styled-area"
                                                        {...register('additional_information')}
                                                        placeholder="Enter details"
                                                    />

                                                </FormGroup>
                                            </Col>
                                            <Col className="mt-5 d-flex justify-content-end text-center" md="12" xs="12">
                                                <div>
                                                    <Button
                                                        outline
                                                        className="mt-3 rz-button-outline-primary"
                                                        onClick={() => { setViewMode(false); setIsModalDirector(!isModalDirector) }}>
                                                        Cancel
                                                    </Button>

                                                    {!viewMode &&
                                                        <>
                                                            <input className="d-none" id='submit' type="submit" />
                                                            <Button className="mt-3 ml-3 rz-button-primary" onClick={() => setIsModalOpen(true)} type="button">
                                                                Submit
                                                            </Button>
                                                        </>
                                                    }


                                                </div>
                                            </Col>
                                        </Row>
                                        {!viewMode &&
                                            <Modal isOpen={isModalOpen} centered={true} className="modal-lg">
                                                <div className="px-4 pt-4 d-flex position-relative justify-content-between">
                                                    <div>
                                                        <h3 className="confirm-modal-title montserrat-medium " id="exampleModalLabel">
                                                            Confirmation
                                                        </h3>
                                                    </div>
                                                    <div>
                                                        <Button
                                                            type="button"
                                                            className="btn modal-close"
                                                            onClick={() => setIsModalOpen(false)}
                                                        >
                                                            <img src={modal_close} />
                                                        </Button>
                                                    </div>
                                                </div>
                                                <ModalBody className="px-4">
                                                    <p>Once you have added a bank account, you cannot edit the details later. Please confirm if all the details are correct.</p>
                                                </ModalBody>
                                                <ModalFooter className="pt-0">
                                                    <Button type="button" className="rz-button-outline-primary" onClick={() => setIsModalOpen(!isModalOpen)}>
                                                        Cancel
                                                    </Button>
                                                    <label for='submit' onClick={() => setIsModalOpen(!isModalOpen)} className="btn ml-4 rz-button-primary">Confirm</label>
                                                </ModalFooter>
                                            </Modal>
                                        }
                                    </Form>
                                </Card >
                            </Container >
                        </div>
                    </div>
                </div>

            </Modal>
            <Modal isOpen={deleteModal} centered={true} className="modal-lg">
                <div className="px-4 pt-4 d-flex position-relative justify-content-between">
                    <div>
                        <h3 className="confirm-modal-title montserrat-medium " id="exampleModalLabel">
                            Confirmation
                        </h3>
                    </div>
                    <div>
                        <Button
                            type="button"
                            className="btn modal-close"
                            onClick={() => setDeleteModal(false)}
                        >
                            <img src={modal_close} />
                        </Button>
                    </div>
                </div>
                <ModalBody className="px-4">
                    <p>Are you sure want to delete the bank account added earlier?</p>
                </ModalBody>
                <ModalFooter className="pt-0">
                    <Button type="button" className="rz-button-outline-primary" onClick={() => setDeleteModal(false)}>
                        No
                    </Button>
                    <Button type="button" onClick={() => handleAccountDelete(id)} className="ml-4 rz-button-primary">Yes</Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

