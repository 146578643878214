import { ethers } from 'ethers';
import { useUpdateProposal } from 'hooks/useDAO';
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, Col, Container, Form, Label, Row, Spinner } from "reactstrap";
import { useAccount } from 'wagmi';
import DAO from '../../../../../contract/abi/DAO.json';
export default function Step2({ stepper }) {
  const [loading, setLoading] = useState(false);
  const proposal = JSON.parse(localStorage.getItem("proposal") || "{}");
  const { isConnected, address } = useAccount()
  const { mutate: updateProposal } = useUpdateProposal()
  const history = useHistory();
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()
  let contract = new ethers.Contract(
    localStorage.getItem("sto_sale_address"),
    DAO,
    signer);

  useEffect(() => {
    contract.on("ProposalCreated", async (proposalId, proposer, targets, values, signatures, calldatas, startBlock, endBlock, description) => {
      await updateProposal({
        id: proposal?.id,
        smart_contract: localStorage.getItem("sto_sale_address"),
        property_id: localStorage.getItem("property_id"),
        proposal_id: proposalId?.toString(),
      }, {
        onSuccess: (res) => {
          toast.success('Proposal Submited.');
          localStorage.setItem("dao_redirect", 4);
          history.push("/company/dao");
        }
      })
    });
    return () => {
      contract.removeAllListeners("ProposalCreated");
    };
  }, [contract?.address])
  const onSubmit = async () => {
    if (!isConnected) {
      toast.error("Please Connect Wallet");
      return;
    }
    try {
      setLoading(true);
      let transaction = await contract.propose(
        ["0xAb8483F64d9C6d1EcF9b849Ae677dD3315835cb2"],//smart contract address
        [0],//smart contract function params
        ["0x"],//smart contract function name or link
        proposal?.title + "\nCategory " + proposal?.category + " \nDescription " + proposal?.description + "\nDate " + new Date().toString())//descriptios
      await transaction.wait();
      setLoading(false);
      await updateProposal({
        id: proposal?.id,
        property_id: localStorage.getItem("property_id"),
        proposal_id: transaction?.data,
        proposal_metadata: {
          hash: transaction?.hash
        }
      }, {
        onSuccess: (res) => {
          // toast.success('Proposal Submited.');
        }
      })
    }
    catch (error) {
      setLoading(false);
      if ((error.message != undefined) &&
        (error.message).indexOf("execution reverted: Governor: proposal already exists") != -1) {
        toast.error("Proposal already exists");
        localStorage.setItem("dao_redirect", 4);
        history.push("/company/dao");
      }
      else if (error?.message) {
        toast.error(error?.message);
      }
    }
  }
  return (
    <Container>
      <div className='content-header'>
        <h3 className='mb-0 text-muted'>Review</h3>
      </div>
      {loading && (
        <tr className="text-center">
          <td colSpan="10">
            <Spinner animation="border" role="status" >{null}</Spinner>
          </td>
        </tr>
      )}
      <Card className="p-4">
        <Row className="justify-content-between">
          <Col>
            <table class="table table-borderless">
              <tbody>
                <tr>
                  <th scope="col">Support %</th>
                  <th scope="col">{80}</th>
                </tr>
                <tr>
                  <th scope="row">Minimum Approval %</th>
                  <td>{80}</td>
                </tr>
                <tr>
                  <th scope="row">Vote Duration</th>
                  <td>{1} week</td>
                </tr>
                <tr>
                  <th scope="row">Vote Title</th>
                  <td>{proposal?.title}</td>
                </tr>
                <tr>
                  <th scope="row">Vote Category</th>
                  <td>{proposal?.category}</td>
                </tr>
                <tr>
                  <th scope="row">Description</th>
                  <td style={{
                    overflow: "hidden"
                  }}>{proposal?.description}</td>
                </tr>
              </tbody>
            </table>
            <hr className='mt-5' />

          </Col>
        </Row>
        <div className='d-flex justify-content-between mt-4'>
          <Button type='button' color='success' onClick={() => stepper.previous()} className='btn-next' >
            <i className='fa fa-fast-backward align-middle mt-1 mr-sm-25 mr-0'></i>
            <span className='align-middle d-sm-inline-block d-none ml-2'>Previous</span>
          </Button>
          <Button type='submit'
            color='primary'
            onClick={() => onSubmit()}
            // disabled={proposal?.proposal_id ? true : false}
            className='btn-next'>
            <span className='align-middle d-sm-inline-block d-none'>
              {proposal?.proposal_id ? "Already Published" : "Publish OnChain"}
            </span>
            <i className='fa fa-chevron-right align-middle mr-sm-25 mr-0'></i>
          </Button>
        </div>
      </Card>
    </Container>
  )
}
