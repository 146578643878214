import { useQuery } from "react-query";
import { API_ENDPOINTS } from "../helpers";
import { api } from "../helpers";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

const fetchPropertyActivityQuery = async (id) => {
    if (id > 0 && id != undefined) {
        const data = await api.get(`${API_ENDPOINTS.PROPERTYACTIVITY}/${id}`);
        return data?.data;
    }
    else
        return []
};
const usePropertyActivityQuery = (id) => {
    return useQuery([API_ENDPOINTS.PROPERTYACTIVITY, id],
        () => fetchPropertyActivityQuery(id), {
            keepPreviousData: true,
            refetchOnWindowFocus: false
    });
};
const useDeletePropertyActivityMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (id) => api.delete(`${API_ENDPOINTS.PROPERTYACTIVITY}/${id}`),
        {
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.PROPERTYACTIVITY);
            },
        }
    );
};
const useUpdatePropertyActivityClassMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { id, input } }) =>
            api.put(`${API_ENDPOINTS.PROPERTYACTIVITY}/${id}`, input)
        ,
        {
            onSuccess: () => {
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.PROPERTYACTIVITY);
            },
            onError: (error) => {
                toast.error(error?.response?.data?.message);
            },
        }
    );
};
const useCreatePropertyActivityClassMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { input } }) =>
            api.post(`${API_ENDPOINTS.PROPERTYACTIVITY}`, input),
        {
            onSuccess: (result) => {
                if (result.type == "success") {
                    toast.success(result?.message);
                } else {
                    toast.error(result?.message);
                }
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.PROPERTYACTIVITY);
            },
            onError: (error) => {
                toast.error(error?.response?.data?.message);
            },
        }
    );
};
export {
    usePropertyActivityQuery,
    useCreatePropertyActivityClassMutation,
    useUpdatePropertyActivityClassMutation,
    useDeletePropertyActivityMutation
};
