import { configConstants } from '../../constants';

export function getCountryReducer(state = {}, action) {
    switch (action.type) {
        case configConstants.GET_COUNTRY_REQUEST:
            return { loading: true };
        case configConstants.GET_COUNTRY_SUCCESS:
            return { countryStates: action.payload.data };
        case configConstants.GET_COUNTRY_FAILURE:
            return { loading: true };
        default:
            return state
    }
}