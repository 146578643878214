import { useQuery, useQueryClient, useMutation } from "react-query";
import { api } from "../helpers";
const useGetReviews = (options = {}) => {
    return useQuery([`reviewsList`, options],
        async ({ queryKey }) => {
            const data = await api.get('/reviews');
            return data;
        }
        , {
            keepPreviousData: true,
            refetchOnWindowFocus: false
        });
};
const useUpdateReviewStatus = () => {
    return useMutation(
        async (data) => {
            const review_id = data?.review_id;
            delete data?.review_id;
            await api.put(`/reviews/${review_id}`, data);
        },
        {
            onSuccess: (res) => {
                console.log('Update', res)
            },
            onSettled: () => {
                // queryClient.invalidateQueries(`/property/${id}}`);
            },
        }
    );
};
const useGetFeedback = (options = {}) => {
    return useQuery([`feedback`, options],
        async ({ queryKey }) => {
            const { data } = await api.get('/order/admin/feedback');
            return data;
        }
        , {
            keepPreviousData: true,
        });
};
const useGetFeedbackAdmin = (id) => {
    return useQuery([`feedbackadmin`, id],
        async ({ queryKey }) => {
            if (id != undefined) {
                const { data } = await api.get(`/order/admin/feedback/${id}`);
                return data;
            }
            else
                return [];
        }
        , {
            keepPreviousData: true,
        });
};
export {
    useUpdateReviewStatus,
    useGetReviews,
    useGetFeedback,
    useGetFeedbackAdmin
};
