import { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Badge, Button, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { propertyActions } from 'redux/actions';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { configActions } from 'redux/actions';
import { PERMISSIONS } from 'utils';
import { RESOURCE } from 'utils';
import { CheckPermission } from 'utils';
import { useMutatePropertyCompanyLocationFeatureStatus } from 'hooks/useProperty';
import { useMutatePropertyCompanyLocationFeatureAdd } from 'hooks/useProperty';
import { toast } from 'react-toastify';

const LocationFeature = ({ stepper, type }) => {
    const dispatch = useDispatch()
    const [savedAmenity, setSavedAmenity] = useState([]);
    const [getAmenity, setGetAmenity] = useState([]);
    const { property } = useSelector(state => state.propertyReducer);
    const [locationfeature, setLocationFeature] = useState([])
    // const { locationfeature } = useSelector(state => state.getLocationFeatureReducer);
    const AmenitySchema = yup.object().shape({
        amenities: yup.array().min(1, 'Please select at least 1 location')
    })
    const { register, handleSubmit, reset, setValue, getValues, control, trigger, formState: { errors } } = useForm({
        resolver: yupResolver(AmenitySchema)
    })
    const { mutate } = useMutatePropertyCompanyLocationFeatureStatus()
    const { mutate: addLocationFeature } = useMutatePropertyCompanyLocationFeatureAdd()
    useEffect(() => {
        if (property) {
            let amm = []
            if (property?.propertyLocationFeature) {
                property?.propertyLocationFeature.map(o => amm.push(o.location_id));
                setSavedAmenity(amm)
                setGetAmenity(property?.propertyLocationFeature)
            }
        }
    }, [property]);

    useEffect(() => {
        if (getAmenity) {
            let amm = []
            if (getAmenity) {
                getAmenity.map(o => amm.push(o.location_id));
                setSavedAmenity(amm)
            }
        }
    }, [getAmenity]);

    useEffect(() => {
        if (localStorage.getItem('locationfeature')) {
            setLocationFeature(JSON.parse(localStorage.getItem('locationfeature') || {}))
        }
    }, [localStorage.getItem('locationfeature')]);

    const onSubmit = async () => {
        const data = {
            property_id: property.id,
            amenities: savedAmenity,
        }
        await addLocationFeature(
            data,
            {
                onSuccess: async (res) => {
                    toast.success(res.message)
                    setGetAmenity(res?.data)
                },
                onError: (error) => {
                    console.log(error)
                }
            }
        );
    }

    const onPropertyStatusChange = async (id, e) => {
        const amData = getAmenity?.filter(o => o.location_id == id?.id);
        if (amData?.length <= 0) {
            toast.error("Please Save Data Before Change Status");
            return
        }
        let data = {
            property_id: property.id,
            id: amData?.length > 0 && amData[0].id,
            status: e.target.checked ? "Done" : "inprogress",
        };
        await mutate(
            data,
            {
                onSuccess: async (res) => {
                    toast.success(res.message)
                    setGetAmenity(res?.data)
                },
                onError: (error) => {
                    console.log(error)
                }
            }
        );
    };
    const checkStatus = (id) => {
        const data = getAmenity?.filter(o => o.location_id == id?.id);
        return data?.length > 0 && data[0].status
    }
    function addOrRemoveFromArray(element) {
        let array = [...savedAmenity];
        const index = array.indexOf(parseFloat(element));
        if (index === -1) {
            array.push(parseFloat(element));
        } else {
            array.splice(index, 1);
        }
        setSavedAmenity(array)
    }
    return (
        <Fragment>
            <div className='content-header'>
                <h3 className='mb-0 rz-heading rz-text-16'>Update Feature</h3>
            </div>
            <Form onSubmit={handleSubmit(onSubmit)}>
                {locationfeature && (
                    <Row>
                        {
                            locationfeature?.map((item, i) => {
                                return (
                                    <FormGroup tag={Col} md='12' className="p-2" check key={i}>
                                        <div className='d-flex justify-content-start align-items-center'>
                                            <input type="checkbox"
                                                onChange={(e) => addOrRemoveFromArray(e.target.value)}
                                                checked={savedAmenity.includes(item.id)}
                                                value={item.id} className="form-control mr-4"
                                                id={item.id} style={{ width: '20px' }} />
                                            <div>
                                                <img src={item.icon} width='20' className='mr-2' />
                                                {item.name}
                                            </div>
                                            <div className="custom-control custom-switch" style={{ marginLeft: 20 }}>
                                                {savedAmenity.includes(item.id) && (
                                                    <>
                                                        <input
                                                            type="checkbox"
                                                            disabled={!CheckPermission(PERMISSIONS?.CREATE, RESOURCE?.Property)}
                                                            checked={checkStatus(item) == "Done" ? true : false}
                                                            onChange={(e) =>
                                                                onPropertyStatusChange(item, e)
                                                            }
                                                            value={"Done"}
                                                            className="custom-control-input"
                                                            id={`property-location-feature-${item.id}`}
                                                        />
                                                        <label
                                                            className="custom-control-label"
                                                            htmlFor={`property-location-feature-${item.id}`}
                                                        >
                                                            <Badge
                                                                color=""
                                                                className={`badge badge-pill badge-${checkStatus(item) == "Done"
                                                                    ? "success"
                                                                    : "primary"
                                                                    } mr-4 mt-0`}
                                                            >
                                                                {checkStatus(item)}
                                                            </Badge>
                                                        </label>
                                                    </>
                                                )
                                                }
                                            </div>

                                        </div>
                                    </FormGroup>
                                )
                            })
                        }
                    </Row>
                )}
                {errors.amenities && (
                    <small className="form-text text-danger">{errors.amenities?.message}</small>
                )}
                <div className='d-flex justify-content-center mt-4'>
                    <Button type='submit' className='rz-button-primary'>
                        <span className='align-middle d-sm-inline-block d-none'>Save Feature</span>
                    </Button>
                </div>
            </Form>
        </Fragment>
    )
}

export default LocationFeature;