import React, { useState } from "react";
import { Card, FormGroup, Label, Spinner, Table } from "reactstrap";
import { moneyFormat } from "utils";
import NotFound from "components/NotFound/NotFound";
import { useGetOrderFilter } from "hooks/useProperty";
import { DatePicker } from "reactstrap-date-picker";
import moment from "moment";
import exportFromJSON from "export-from-json";
import { useGetPaymentReportFilter } from "hooks/useReport";

const PaymentList = () => {

  const { data: filterDb, isLoading } = useGetOrderFilter()
  const intialState = {
    user_id: "",
    phone: "",
    type: "",
    status: "",
    from_date: new Date().toISOString(),
    to_date: new Date().toISOString(),
  }
  const [filtterData, setFilterData] = useState(intialState)
  const [applyFilter, setApplyFilter] = useState({})
  const { data: orders, isLoading: loading } = useGetPaymentReportFilter({
    filter: applyFilter
  }, Object.keys(applyFilter)?.length > 0);

  function handleChange(value) {
    setFilterData({
      ...filtterData,
      from_date: value
    })
  }

  function handleChangeTo(value) {
    setFilterData({
      ...filtterData,
      to_date: value
    })
  }

  const exportPDF = () => {
    const title = `payment-report-${filtterData?.from_date}-${filtterData?.to_date}`;

    const report_data = orders?.data?.map((order) => {
      return ({
        "Order ID": order?.id,
        "STATUS": order?.status,
        "CUSTOMER NAME": order?.name,
        "Type": order?.type,
        "No Token": order?.tokens,
        "Mpesa Payment Refer Number": order?.meta?.paymentData?.reference_id,
        "Checkout Request ID": order?.meta?.paymentData?.checkout_request_id,
        "Amount": order?.amount,
        "Transaction Date": moment(order?.meta?.paymentData?.date).format("DD-MM-YYYY")
      })
    })
    exportFromJSON({ data: report_data, fileName: title, exportType: 'csv' });
  };
  return (
    <>
      <div className="row">
        <div className='col-6'>
          <label>Select Using Mobile</label>
          <input
            onChange={(e) => setFilterData({
              ...filtterData,
              phone: e.target.value
            })}
            value={filtterData.phone}
            className='form-control'
            placeholder="Enter mobile"
            id="phone" />
        </div>
        <div className='col-6'>
          <label>Select User</label>
          <select
            value={filtterData.user_id}
            onChange={(e) => setFilterData({
              ...filtterData,
              user_id: e.target.value
            })}
            className='form-control'>
            <option value='all'>All</option>
            {filterDb && filterDb?.users?.map((user) => (
              <option key={user?.user_id} value={user?.user_id}>{user?.name}</option>
            ))
            }
          </select>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-6">
          <FormGroup>
            <Label>Transaction - From Date</Label>
            <DatePicker
              size="md"
              id="from-date"
              value={filtterData.from_date}
              onChange={(v, f) => handleChange(v, f)} />
          </FormGroup>
        </div>
        <div className="col-6">
          <FormGroup>
            <Label>Transaction - To Date</Label>
            <DatePicker
              id="to-date"
              value={filtterData.to_date}
              onChange={(v, f) => handleChangeTo(v, f)} />
          </FormGroup>
        </div>
      </div>
      <div className="row">
        <div className='col-6'>
          <label>Select Type</label>
          <select
            value={filtterData.type}
            onChange={(e) => setFilterData({
              ...filtterData,
              type: e.target.value
            })}
            className='form-control'>
            <option selected value=''>All</option>
            <option value="Order Booking">Order Booking</option>
            <option value="Order Full Payment">Order Full Payment</option>
            <option value="deposit">Deposit</option>
          </select>
        </div>
        <div className='col-6'>
          <label>Select Status</label>
          <select
            onChange={(e) => setFilterData({
              ...filtterData,
              status: e.target.value
            })}
            value={filtterData.status}
            className='form-control'
            id="status">
            <option selected value=''>All</option>
            <option value="Awaiting payment confirmation">Awaiting payment confirmation</option>
            <option value="Completed">Completed</option>
            <option value="Processing">Processing</option>
            <option value="Failed">Failed</option>
          </select>
        </div>
      </div>
      <div className="row pb-2 pt-4 mx-1">
        <button
          onClick={() => setApplyFilter(filtterData)}
          className="btn rz-button-primary mr-3" type="button">
          Apply Filter
        </button>
        <button
          disabled={!orders?.data || orders?.data?.length <= 0}
          onClick={() => exportPDF()}
          className="btn rz-button-primary mr-3" type="button">
          Downalod Report
        </button>
        <button onClick={() => {
          setFilterData(intialState)
          setApplyFilter({})
        }}
          className="btn rz-button-primary" type="button">
          Clear Filter
        </button>
      </div>
      <Card className="border-none">
        <Table responsive className="custom-table-div mt-4">
          <thead className="rz-thead-style">
            <tr>
              <th className="d-flex align-items-center">
                Transection ID
                <div className="ml-3">
                  <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </div>
              </th>

              <th>STATUS</th>

              <th>
                <div className="d-flex align-items-center">
                  CUSTOMER NAME
                  <div className="ml-3">
                    <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  CUSTOMER PHONE
                  <div className="ml-3">
                    <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  CUSTOMER EMAIL
                  <div className="ml-3">
                    <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
              </th>
              {/* <th>
                <div className="d-flex align-items-center">
                  NO OF tokens
                  <div className="ml-3">
                    <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
              </th> */}
              <th>
                <div className="d-flex align-items-center">
                  Type
                  <div className="ml-3">
                    <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Amount
                  <div className="ml-3">
                    <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Transaction Date
                  <div className="ml-3">
                    <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr className="text-center">
                <td colSpan="10">
                  <Spinner animation="border" role="status" >{null}</Spinner>
                </td>
              </tr>
            ) : (
              orders?.data?.map((order, i) => {
                return (
                  <tr key={i}>
                    <td
                      className="text-primary">
                      #{order?.id}
                    </td>
                    <td> {order?.status} </td>
                    <td>{order?.name || "NA"}</td>
                    <td>{order?.phone || "NA"}</td>
                    <td>{order?.email || "NA"}</td>
                    <td>{order?.tokens || "NA"}</td>
                    <td>{order?.meta?.paymentData?.reference_id || "NA"}</td>
                    <td>{order?.meta?.paymentData?.checkout_request_id || "NA"}</td>
                    <td>{order?.type}</td>
                    <td> {moneyFormat(order?.amount)} </td>
                    <td>{moment(order?.meta?.paymentData?.date).format("DD-MM-YYYY")}</td>
                  </tr>
                );
              })
            )}
          </tbody>
        </Table>
        {orders?.data?.length <= 0 &&
          <NotFound
            message="Please Refine Your Filter"
          />
        }
      </Card>
    </>
  );
};
export default PaymentList;
