import { Badge, Button, Card, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import Risk from './metadata/Risk';
import Exits from './metadata/Exits';
import Tax from './metadata/Tax';
import Disclosure from './metadata/Disclosure';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const PropertyMetadata = ({ stepper, type, parentStepper }) => {
    return (
        <>
            <div className='content-header'>
                <h3 className='mb-0 rz-heading rz-text-18'>Asset Metabase</h3>
            </div>
            <Card className="bg-white shadow border-0 p-4">
                <Risk></Risk>
            </Card>
            <Card className="bg-white shadow border-0 p-4 mt-4">
                <Exits></Exits>
            </Card>
            <Card className="bg-white shadow border-0 p-4 mt-4">
                <Disclosure></Disclosure>
            </Card>
            <Card className="bg-white shadow border-0 p-4 mt-4">
                <Tax></Tax>
            </Card>
            <div className='d-flex align-items-center justify-content-between mt-5'>
                <Button type='button' color='success' onClick={() => parentStepper.next()} className='btn-next' >
                    <span className='align-middle d-sm-inline-block d-none'>Skip</span>
                    <i className='fa fa-fast-forward align-middle mt-1 mr-sm-25 mr-0'></i>
                </Button>
                <div className='d-flex align-items-center justify-content-end'>
                    <Link to='/admin/property'>
                        <Button type='button' className='rz-button-outline-primary'>
                            Cancel
                        </Button>
                    </Link>
                    <Button className='rz-button-outline-primary ml-3 mr-2' onClick={() => stepper.previous()}>
                        <BsArrowLeftShort style={{ fontSize: "22px" }} />
                        <span className='align-middle d-sm-inline-block d-none'>Back</span>
                    </Button>

                    <Button type='button' onClick={() => parentStepper.next()} className='rz-button-primary'>
                        <BsArrowRightShort style={{ fontSize: "22px" }} />
                        <span className='align-middle d-sm-inline-block d-none'>Next</span>

                    </Button>
                </div>
            </div>
        </>
    )
}

export default PropertyMetadata;