import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Badge, Button, Col, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import classnames from 'classnames'
import { usePropertyMetadataQuery } from 'hooks/usePropertyMetadata';
import { useCreatePropertyMetadataClassMutation } from 'hooks/usePropertyMetadata';
import { useDeletePropertyMetadataMutation } from 'hooks/usePropertyMetadata';
import { useUpdatePropertyMetadataClassMutation } from 'hooks/usePropertyMetadata';
import NotFound from 'components/NotFound/NotFound';
import { HiOutlinePlusCircle } from 'react-icons/hi';

const Risk = ({ stepper, type, parentStepper }) => {
    const { property } = useSelector(state => state.propertyReducer);
    const { isPropertyLoading } = useSelector(state => state.propertyReducer);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { data: propertyMetadataList } = usePropertyMetadataQuery(
        localStorage.getItem("property_id") ? `risk/${localStorage.getItem("property_id")}` : undefined,
        true// (stepper?._currentIndex == 6 && parentStepper?._currentIndex == 2)
    );
    const { mutate: createMetadata, isLoading: creating } =
        useCreatePropertyMetadataClassMutation();
    const { mutate: updateMetadata, isLoading: updating } =
        useUpdatePropertyMetadataClassMutation();
    const { mutate: deleteMetadata } = useDeletePropertyMetadataMutation();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm({})

    const onSubmit = async (data) => {
        if (data?.id) {
            updateMetadata({
                variables: {
                    id: data?.id,
                    input: {
                        property_id: property?.id,
                        type: 'risk',
                        name: data?.name,
                        description: data?.description,
                    },
                },
            });
        } else {
            createMetadata({
                variables: {
                    input: {
                        property_id: property?.id,
                        type: 'risk',
                        name: data?.name,
                        description: data?.description,
                    },
                }
            });
        }

        setIsModalOpen(!isModalOpen)
    }
    const addEditModal = (faqId) => {
        var faq = propertyMetadataList?.find((id) => id?.id == faqId);
        setValue('description', faq?.description)
        setValue('name', faq?.name)
        setValue('id', faq?.id)
        setIsModalOpen(!isModalOpen)
    }

    const removePropertyFaq = (id) => {
        if (window.confirm('Are you sure?')) {
            deleteMetadata(id);
        }
    }

    return (
        <Fragment>
            <div className='content-header py-4 d-flex justify-content-between align-items-center'>
                <h3 className='mb-0 rz-heading rz-text-16'>Property Risk factors List</h3>
                <button onClick={() => addEditModal()} className="btn rz-button-primary" type="button">
                    <HiOutlinePlusCircle className="mr-2" style={{ fontSize: "20px" }} /> Add Property Risk factors
                </button>
            </div>
            <Row>
                <Col>
                    {propertyMetadataList && (
                        <div className="accordion">
                            {
                                propertyMetadataList?.map((item, i) => {
                                    return (
                                        <div className="card" key={i}>
                                            <div className="d-flex justify-content-between
                                            allign-item-center
                                            px-5 py-2">
                                                <div>
                                                    <h3>{item?.name}</h3>
                                                    <h5>{item?.description}</h5>
                                                </div>
                                                <div>
                                                    <div className='d-flex align-items-center'>
                                                        <button onClick={() => removePropertyFaq(item.id)} key={i} className="text-muted btn-sm btn btn-link" type="button">
                                                            <i className='fa fa-trash'></i>
                                                        </button>
                                                        <button onClick={() => addEditModal(item.id)} className="text-muted btn-sm btn btn-link" type="button">
                                                            <i className='fa fa-edit'></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )}
                </Col>
            </Row>
            {propertyMetadataList?.length <= 0 &&
                <NotFound
                    message="No Property Risks Found."
                />
            }
            <Modal isOpen={isModalOpen} centered={true} className='modal-lg'>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <ModalHeader>
                        <h1 className='rz-heading rz-text-18'>Add Risk factors For Property</h1>
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label className='form-label' htmlFor={`question`}>
                                Name
                            </Label>
                            <input
                                {...register(`name`)}
                                className={classnames({ 'is-invalid': errors.name, 'form-control': true })}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className='form-label' htmlFor={`question`}>
                                Description
                            </Label>
                            <textarea
                                rows='4'
                                {...register(`description`)}
                                className={classnames({ 'is-invalid': errors.description, 'form-control': true })}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button className='rz-button-outline-primary' onClick={() => setIsModalOpen(!isModalOpen)}>
                            Cancel
                        </Button>
                        {' '}
                        <Button
                            className='rz-button-primary'
                        >
                            {'Save'}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>

        </Fragment>
    )
}

export default Risk;