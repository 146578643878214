import { propertyConstants } from '../../constants';

export function addEditPropertyReducer(state = {}, action) {
    switch (action.type) {
        case propertyConstants.ADD_EDIT_PROPERTY_REQUEST:
            return { savingProperty: true };
        case propertyConstants.ADD_EDIT_PROPERTY_SUCCESS:
            return {};
        case propertyConstants.ADD_EDIT_PROPERTY_FAILURE:
            return {};
        default:
            return state
    }
}