import React, { useEffect } from 'react'
import { propertyActions } from '../../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import ABI from './../../../contract/abi/DAO.json';
import bytecode from './../../../contract/bytecode/DAO.json';
import { ethers } from 'ethers';
import { getExplorer } from '../../../helpers/networks';
import { useUpdatePropertySto } from '../../../hooks/useProperty';
import { Button, Card, Col, Container, Form, Label, Row } from "reactstrap";
import { useAccount } from 'wagmi';
import { toast } from 'react-toastify';
import { useWeb3ModalNetwork } from '@web3modal/react';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import { Link } from 'react-router-dom';

export default function CreateDAO({ stepper, type }) {
  const dispatch = useDispatch();
  const property = useSelector(state => state.propertyReducer.property);
  const [token_address, setTokenAddress] = React.useState("")
  const [name, setName] = React.useState("")
  const { mutate } = useUpdatePropertySto()
  const { isConnected, address } = useAccount()
  const { selectedChain } = useWeb3ModalNetwork()
  useEffect(() => {
    if (property) {
      setTokenAddress(property?.sto_erc20_address)
    }
  }, [property])
  const handleSTO = async () => {
    if (!isConnected) {
      toast.error("Please connet your wallet first.")
      return;
    }
    if (selectedChain?.id != property?.blockchain_metadata?.id) {
      toast.error(`Please switch network to ${property?.blockchain_metadata?.name}`)
      return;
    }
    if (!ethers.utils.isAddress(token_address)) {
      toast.error("Please enter valid token address.")
      return;
    }
    if (!name) {
      toast.error("Please enter name")
      return;
    }
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner()
    const factory = new ethers.ContractFactory(ABI, bytecode, signer)
    var contract;
    try {
      contract = await factory.deploy(token_address, name);
    }
    catch (err) {
      console.log(err);
      return;
    }
    var data = {}
    data.property_id = property?.id
    data.sto_sale_address = contract?.address;
    data.is_sto_sale_created = true;
    await mutate(data, {
      onSuccess: (res) => {
        console.log(res);
        stepper.next();
      }
    })
    dispatch(propertyActions.getProperty(property?.id))
  }
  return (
    <>
      <div className='content-header'>
        <h3 className='mb-0 rz-heading rz-text-18'>Enter Details for Decentralized Autonomous Organization</h3>
      </div>
      {!property?.is_sto_sale_created ? (
        <Card className="p-4">
          <Row className="justify-content-between">
            <Col>
              <Form >
                <Row>
                  <Col className="my-2" md="12" xs="12">
                    <Label>DAO Name</Label>
                    <input
                      className="form-control"
                      type="text"
                      value={name} onChange={e => setName(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="my-2" md="12" xs="12">
                    <Label>Enter Security Token Address</Label>
                    <input
                      className="form-control"
                      type="text"
                      disabled={true}
                      value={token_address}
                      onChange={e => setTokenAddress(e.target.value)}
                    />
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Card>
      ) : <>
        <a
          className="btn btn-primary mt-1"
          href={`${property?.blockchain_metadata?.blockExplorers?.default?.url}/token/${property?.sto_sale_address}`}
          target="_blank"
          rel="noreferrer"
        >
          View DAO on Explorer
        </a>
        <Col className="my-4" md="12" xs="12">
          <Label> Your DAO Token Address</Label>
          <h1>{property?.sto_erc20_address}</h1>
        </Col>
        <Col className="my-4" md="12" xs="12">
          <Label> Your Security Token Name</Label>
          <h1>{property?.sto_name}</h1>
        </Col>
        <Col className="my-4" md="12" xs="12">
          <Label> Your DAO Address</Label>
          <h1>{property?.sto_sale_address}</h1>
        </Col>
      </>}
      <div className='mt-5 align-items-center d-flex justify-content-between'>
        <Button type='button' color='success' onClick={() => stepper.next()} className='btn-next' >
          <span className='align-middle d-sm-inline-block d-none'>Skip</span>
          <i className='fa fa-fast-forward align-middle mt-1 mr-sm-25 mr-0'></i>
        </Button>
        <div className='d-flex align-items-center justify-content-end'>
          <Link to='/admin/property'>
            <Button type='button' className='rz-button-outline-primary'>
              Cancel
            </Button>
          </Link>
          <Button className='rz-button-outline-primary mr-2 ml-3' onClick={() => stepper.previous()}>
            <BsArrowLeftShort style={{ fontSize: "22px" }} />
            <span className='align-middle d-sm-inline-block d-none'>Back</span>
          </Button>

          <Button type='button'
            className='rz-button-primary'
            disabled={property?.is_sto_sale_created ? true : false}
            onClick={() => handleSTO()} >
            <BsArrowRightShort style={{ fontSize: "22px" }} />
            Next
          </Button>
        </div>
      </div>
    </>
  )
}
// Step 1
//function name-: delegate("0xAb8483F64d9C6d1EcF9b849Ae677dD3315835cb2");

// Step 2
//function name-: propose(["0xAb8483F64d9C6d1EcF9b849Ae677dD3315835cb2"],[0],["0x"],"klcsalkcalkscklasclkaslck");
// Output -: sample propesal id -: 10394053170830399800502616194608390106705601948153280827398783444663342601754

// Step 3
//function name-: castVote(10394053170830399800502616194608390106705601948153280827398783444663342601754,0/1/2);
// Output -: sample propesal id -: 10394053170830399800502616194608390106705601948153280827398783444663342601754



