
import NotFound from 'components/NotFound/NotFound';
import { useGetSuperAdminWithdraw } from 'hooks/useFeeManagement '
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom';
import { Card, Container, Row, Button, Badge, Table, Spinner, Col } from 'reactstrap'

const SuperAdminFee = () => {
    const { data = [], isLoading } = useGetSuperAdminWithdraw();
    const [tab, setTab] = useState('Payment Pending');
    const [rowBackup, setRowBackup] = useState([])
    const [row, setRow] = useState([])
    useEffect(() => {
        if (data.length > 0) {
            setRow(data.filter((d) => d.status == tab));
            setRowBackup(data);
        }
    }, [data]);
    useEffect(() => {
        setRow(rowBackup.filter((d) => d.status == tab))
    }, [tab])
    return (
        <>
            <Container fluid>
                <Row className="mt-3">
                    <Col>
                        <div>
                            <Button
                                type="button cursor-pointer"
                                className={`rz-shadow-none button cursor-pointer ${tab === 'Payment Pending' ? 'rz-button-primary' : 'white'
                                    }`}
                                onClick={() => setTab('Payment Pending')}
                            >
                                <span className=''>Pending</span>
                            </Button>
                            <Button
                                type="button"
                                className={`rz-shadow-none button cursor-pointer ${tab === 'Payment Done' ? 'rz-button-primary' : 'white'
                                    }`}
                                onClick={() => setTab('Payment Done')}
                            >
                                Approved
                            </Button>
                            <Button
                                type="button"
                                className={`rz-shadow-none button cursor-pointer ${tab === 'Payment Rejected' ? 'rz-button-primary' : 'white'
                                    }`}
                                onClick={() => setTab('Payment Rejected')}
                            >
                                Rejected
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <div className="col">
                        <Card className="rz-shadow-none">
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="rz-thead-style">
                                    <tr>
                                        <th scope="col">company Name</th>
                                        <th scope="col">requested amount</th>
                                        <th scope="col">requested by</th>
                                        <th scope="col">requested Date</th>
                                        <th scope="col">transfer to</th>
                                        <th scope="col">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoading && (
                                        <tr className="text-center">
                                            <td colSpan="10">
                                                <Spinner animation="border" role="status" >{null}</Spinner>
                                            </td>
                                        </tr>
                                    )}

                                    {row && row.length > 0 ?
                                        row.map((item, i) => (
                                            <tr key={item?.id}>
                                                <td style={{ color: "#5E5873", fontWeight: "bold" }}>
                                                    <NavLink
                                                        to={{
                                                            pathname: `/admin/super-admin-withdraw/details`,
                                                            state: {
                                                                withdraw: item,
                                                            },
                                                        }}
                                                    >{item?.company?.name}</NavLink>
                                                </td>
                                                <td scope="col">
                                                    {item?.amount_net}
                                                </td>
                                                <td style={{ color: "#5E5873", fontWeight: "bold" }}>{item?.admin?.name}</td>
                                                <td>{moment(item?.created_at).format("D-MMM-Y")}</td>
                                                <td></td>
                                                <td>

                                                    {
                                                        item?.status == 'Payment Pending' ?
                                                            <Badge className="rental-payout-pending">
                                                                Payment Pending
                                                            </Badge> : item?.status == 'Payment Done' ?
                                                                <Badge className="rental-payout-paid">
                                                                    Payment Paid
                                                                </Badge>

                                                                : <Badge className="rental-payout-hold">
                                                                    Payment Failed
                                                                </Badge>
                                                    }

                                                </td>


                                            </tr>
                                        )) : (
                                            <></>
                                        )}
                                </tbody>
                            </Table>
                            {row?.length <= 0 &&
                                <NotFound
                                    message={`No Result Found`}
                                />
                            }
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    )
}

export default SuperAdminFee
