import { employeeConstants } from '../../constants';

export function getEmployeeReducer(state = {}, action) {
    switch (action.type) {
        case employeeConstants.GET_EMPLOYEE_REQUEST:
            return { loading: true };
        case employeeConstants.GET_EMPLOYEE_SUCCESS:
            return { employee: action.payload.data };
        case employeeConstants.GET_EMPLOYEE_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}