import { useMutation, useQuery } from "react-query";
import { api, API_ENDPOINTS } from "../helpers";

// const useFeeManagement = (options = {}) => {
//     return useQuery([`platform/feeManagement`, options],
//         async () => {
//             const { data } = await api.get(`/platform/feeManagement`);
//             return data;
//         }
//         , {
//             keepPreviousData: true,
//         });
// }
const useGetAllFees = (options = {}) => {
    return useQuery([`useGetAllFees`, options],
        async () => {
            let url = `/superadmin/allFees`
            if (options?.property_id) {
                url = `/superadmin/allFees?property_id=${options?.property_id}`
            }
            const { data } = await api.get(url);
            return data;
        }
        , {
            keepPreviousData: true,
        }
    );
}
const useAssetListById = (options = {}) => {
    return useQuery([`useAssetListById`, options],
        async ({ queryKey }) => {
            console.log("quert=>", queryKey[1]);
            if (queryKey[1]?.fee_type && queryKey[1]?.property_id) {
                const data = await api.get(`/superadmin/assetWiseOrders/${queryKey[1]?.fee_type}?property_id=${queryKey[1]?.property_id}`);
                return data;
            }
            else return {};
        }
        , {
            keepPreviousData: true,
        });
}
const useGetPlatformById = (options = {}) => {
    return useQuery([`useGetPlatformById${options?.fee_type}`, options],
        async ({ queryKey }) => {
            if (queryKey[1]?.fee_type) {
                const url = `/superadmin/assetWiseFees/${queryKey[1]?.fee_type}`
                if (queryKey[1]?.property_id) {
                    url += `?property_id=${queryKey[1]?.property_id}`;
                }
                const data = await api.get(url);
                return data;
            }
            else return {};
        }
        , {
            keepPreviousData: true,
        });
}
const useGetAccount = (options = {}) => {
    return useQuery([`useGetAccount`, options],
        async () => {
            const { data } = await api.get(`/config/accounts-managment/company/${options.id}`);

            return data;
        }
        , {
            keepPreviousData: true,
        }
    );
}
const useMutateAssetWiseWithdraw = () => {
    return useMutation(
        async (data) => {
            await api.post(`/withdraw/createcompanyAssetWiseWithdraw`, data);
        },
        {
            onSuccess: (res) => {

            },
            onSettled: () => {
            },
            onError: (err) => {
                console.log(err)
            }
        }
    );
};
const useSellertWiseWithdraw = () => {
    return useMutation(
        async (data) => {
            await api.post(`/withdraw/createAssetSellerWithdraw`, data);
        },
        {
            onSuccess: (res) => {

            },
            onSettled: () => {
            },
            onError: (err) => {
                console.log(err)
            }
        }
    );
};
const useGetWithdrawTimeline = (options = {}) => {
    return useQuery([`useGetWithdrawTimeline`, options],
        async () => {

            const { data } = await api.post(`/withdraw/getCompanyAssetWiseWithdraw`, options);
            return data;
        }
        , {
            keepPreviousData: true,
        }
    );
}
const useGetSuperAdminWithdraw = (options = {}) => {
    return useQuery([`useGetSuperAdminWithdraw`, options],
        async () => {

            const { data } = await api.get(`/withdraw/getSuperAdminWithdraw`);
            return data;
        }
        , {
            keepPreviousData: true,
        }
    );
}
const useGetSellerWithdraw = (options = {}) => {
    return useQuery([`useGetSellerWithdraw`, options],
        async () => {

            const { data } = await api.get(`/withdraw/getAssetSellerWithdraw?type=${options.type}`);
            return data;
        }
        , {
            keepPreviousData: true,
        }
    );
}

export {
    // useFeeManagement,
    useGetAllFees,
    useGetSuperAdminWithdraw,
    useMutateAssetWiseWithdraw,
    useGetPlatformById,
    useAssetListById,
    useGetAccount,
    useSellertWiseWithdraw,
    useGetWithdrawTimeline,
    useGetSellerWithdraw
};
