import { configConstants } from '../../constants';

export function getAmenityReducer(state = {}, action) {
    switch (action.type) {
        case configConstants.GET_AMENITY_REQUEST:
            return { loading: true };
        case configConstants.GET_AMENITY_SUCCESS:
            return { amenity: action.payload };
        case configConstants.GET_AMENITY_FAILURE:
            return { loading: true };
        default:
            return state
    }
}

export function getLocationFeatureReducer(state = {}, action) {
    switch (action.type) {
        case configConstants.GET_LOCATIONFEATURE_REQUEST:
            return { loading: true };
        case configConstants.GET_LOCATIONFEATURE_SUCCESS:
            return { locationfeature: action.payload };
        case configConstants.GET_LOCATIONFEATURE_FAILURE:
            return { loading: true };
        default:
            return state
    }
}