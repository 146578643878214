export const percentageConstants = {

    GET_PERCENTAGE_REQUEST: "GET_PERCENTAGE_REQUEST",
    GET_PERCENTAGE_SUCCESS: "GET_PERCENTAGE_SUCCESS",
    GET_PERCENTAGE_FAILURE: "GET_PERCENTAGE_FAILURE",

    GET_PROPERTY_PERCENTAGE_REQUEST: "GET_PROPERTY_PERCENTAGE_REQUEST",
    GET_PROPERTY_PERCENTAGE_SUCCESS: "GET_PROPERTY_PERCENTAGE_SUCCESS",
    GET_PROPERTY_PERCENTAGE_FAILURE: "GET_PROPERTY_PERCENTAGE_FAILURE",

    ADD_PERCENTAGE_REQUEST: "ADD_PERCENTAGE_REQUEST",
    ADD_PERCENTAGE_SUCCESS: "ADD_PERCENTAGE_SUCCESS",
    ADD_PERCENTAGE_FAILURE: "ADD_PERCENTAGE_FAILURE",

    UPDATE_PERCENTAGE_REQUEST: "UPDATE_PERCENTAGE_REQUEST",
    UPDATE_PERCENTAGE_SUCCESS: "UPDATE_PERCENTAGE_SUCCESS",
    UPDATE_PERCENTAGE_FAILURE: "UPDATE_PERCENTAGE_FAILURE",

    DELETE_PERCENTAGE_REQUEST: "DELETE_PERCENTAGE_REQUEST",
    DELETE_PERCENTAGE_SUCCESS: "DELETE_PERCENTAGE_SUCCESS",
    DELETE_PERCENTAGE_FAILURE: "DELETE_PERCENTAGE_FAILURE",

};
