import { api } from "../../helpers";

export const accountService = {
    getAccount,
    deleteAccount,
    postAccount,
    viewAccount
};
function getAccount(company_id) {
    return api.get(`/config/accounts-managment/company/${company_id}`);
}
function deleteAccount(account_id) {
    return api.delete(`/config/accounts-managment/${account_id}`);
}
function postAccount(data) {
    return api.post(`/config/accounts-managment`, data);
}
function viewAccount(id) {
    return api.get(`/config/accounts-managment/${id}`);
}  