import React, { useState } from 'react'
import { Button, Col, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { toast } from 'react-toastify'
import { useMutateUpdateCompany } from 'hooks/useCompany';
import { QueryClient } from 'react-query';
import { BsArrowRightShort, BsArrowLeftShort } from "react-icons/bs";
import { Link } from 'react-router-dom';
export default function RiskDisclosures({ stepper, type, company }) {
    const [text, setText] = React.useState("")
    const [disclosures, setDisclosures] = React.useState([])
    const [isFinalModalOpen, setIsFinalModalOpen] = useState(false);
    const { mutate: updateCompnay } = useMutateUpdateCompany();
    const queryClient = new QueryClient();
    async function handleSubmit() {
        if (text) {
            let data = {
                "risk_disclosure": text,
                id: company?.id,
                "disclosures": JSON.stringify(disclosures)
            }
            await updateCompnay(data, {
                onSuccess: (res) => {
                    toast.success('Company Updated Successfully');
                    queryClient.invalidateQueries(`user_company_${company?.id}`)
                    stepper.next();
                    setIsFinalModalOpen(false);
                }
            })
        } else {
            toast.error("Please fill all data")
        }
    }
    React.useEffect(() => {
        if (company) {
            setText(company?.risk_disclosure)
            setDisclosures(company?.disclosures)
        }
    }, [company])

    function changeDisclosure(index, status) {
        let updated = [];
        for (let i = 0; i < disclosures.length; i++) {
            if (i == index) {
                updated.push({ ...disclosures[i], status: status })
            } else {
                updated.push(disclosures[i])
            }
        }
        setDisclosures(updated)
    }
    return (
        <>
            <div className='content-header'>
                <h3 className='mb-0 rz-heading rz-text-18'>Risk & Disclosure</h3>
            </div>

            <FormGroup className='px-0' tag={Col} md={12}>
                <label className='form-label'>Risk</label>
                <textarea rows={10} cols={10} className='form-control px-3 ' value={text} onChange={e => setText(e.target.value)} />
            </FormGroup>
            <div className='content-header mt-5'>
                <h3 className='mb-0 rz-heading rz-text-18'>Disclosures</h3>
            </div>
            <p>Select multiple options (if any)</p>
            {disclosures?.map((item, index) => (
                <div key={index} className='d-flex flex-direction-row align-items-center mb-2'>
                    <input className="cursor-pointer"
                        style={{ width: "18px", height: "18px", accentColor: "#7367F0" }} checked={item?.status} onChange={e => changeDisclosure(index, !item?.status)} type="checkbox" />
                    <label className='ml-3 mb-0 form-label'>{item?.name}</label>
                </div>
            ))}
            <div className='mt-5 align-item-center d-flex justify-content-between'>
                <div>
                    <Button type='button' color='success' onClick={() => stepper.next()} className='btn-next' >
                        <span className='align-middle d-sm-inline-block d-none'>Skip</span>
                        <i className='fa fa-fast-forward align-middle mt-1 mr-sm-25 mr-0'></i>
                    </Button>
                </div>
                <div className='d-flex align-item-center justify-content-end'>
                    <Link to='/admin/company'>
                        <Button type='button' className='rz-button-outline-primary'>
                            Cancel
                        </Button>
                    </Link>
                    <Button color='primary' className='btn-prev ml-3 mr-2 d-flex rz-button-outline-primary' onClick={() => stepper.previous()}>
                        <BsArrowLeftShort style={{ fontSize: "22px" }} />
                        <span className='align-middle d-sm-inline-block d-none'>Back</span>
                    </Button>
                    <Button type='button' onClick={() => setIsFinalModalOpen(true)} className='btn-next rz-button-primary'>
                        <BsArrowRightShort style={{ fontSize: "22px" }} />
                        <span className='align-middle d-sm-inline-block d-none'>Next</span>

                    </Button>
                </div>
            </div>

            <Modal isOpen={isFinalModalOpen} centered={true} className='modal-lg'>

                <ModalHeader>
                    Submit
                </ModalHeader>
                <ModalBody>
                    <p>Are you sure you want to submit?</p>
                    <p>
                        Please make sure that complete information is correct, and you agree to send it for the reviewer, you may receive a notification from the “Reviewer” if any specific information is missed.
                        Thank you
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => setIsFinalModalOpen(!isFinalModalOpen)}>
                        Cancel
                    </Button>
                    {' '}
                    <Button type='button' onClick={() => handleSubmit()} color="primary" >
                        Submit
                    </Button>
                </ModalFooter>

            </Modal>
        </>
    )
}
