import { api } from "./api.helper";

export const uploadImage = async (featureImage) => {
    const formDataNew = new FormData();
    formDataNew.append("file", featureImage)
    try {
        const res = await api.post("/file",
            formDataNew
        );
        return res?.url
    } catch (ex) {
        console.log(ex);
        return "";
    }
}
