export const orderConstants = {
  GET_ORDERS_REQUEST: "GET_ORDERS_REQUEST",
  GET_ORDERS_SUCCESS: "GET_ORDERS_SUCCESS",
  GET_ORDERS_FAILURE: "GET_ORDERS_FAILURE",

  GET_SINGLE_ORDERS_REQUEST: "GET_SINGLE_ORDERS_REQUEST",
  GET_SINGLE_ORDERS_SUCCESS: "GET_SINGLE_ORDERS_SUCCESS",
  GET_SINGLE_ORDERS_FAILURE: "GET_SINGLE_ORDERS_FAILURE",

  UPDATE_ORDERS_STATUS_REQUEST: "UPDATE_ORDERS_STATUS_REQUEST",
  UPDATE_ORDERS_STATUS_SUCCESS: "UPDATE_ORDERS_STATUS_SUCCESS",
  UPDATE_ORDERS_STATUS_FAILURE: "UPDATE_ORDERS_STATUS_FAILURE",

  REFUND_ORDERS_STATUS_REQUEST: "REFUND_ORDERS_STATUS_REQUEST",
  REFUND_ORDERS_STATUS_SUCCESS: "REFUND_ORDERS_STATUS_SUCCESS",
  REFUND_ORDERS_STATUS_FAILURE: "REFUND_ORDERS_STATUS_FAILURE",
};
