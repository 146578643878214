export const configConstants = {
  GET_COUNTRY_REQUEST: "GET_COUNTRY_REQUEST",
  GET_COUNTRY_SUCCESS: "GET_COUNTRY_SUCCESS",
  GET_COUNTRY_FAILURE: "GET_COUNTRY_FAILURE",

  GET_AMENITY_REQUEST: "GET_AMENITY_REQUEST",
  GET_AMENITY_SUCCESS: "GET_AMENITY_SUCCESS",
  GET_AMENITY_FAILURE: "GET_AMENITY_FAILURE",

  GET_LOCATIONFEATURE_REQUEST: "GET_LOCATIONFEATURE_REQUEST",
  GET_LOCATIONFEATURE_SUCCESS: "GET_LOCATIONFEATURE_SUCCESS",
  GET_LOCATIONFEATURE_FAILURE: "GET_LOCATIONFEATURE_FAILURE",


  GET_VIDEOLINK_REQUEST: "GET_VIDEOLINK_REQUEST",
  GET_VIDEOLINK_SUCCESS: "GET_VIDEOLINK_SUCCESS",
  GET_VIDEOLINK_FAILURE: "GET_VIDEOLINK_FAILURE",

  GET_POPUPTEXT_REQUEST: "GET_POPUPTEXT_REQUEST",
  GET_POPUPTEXT_SUCCESS: "GET_POPUPTEXT_SUCCESS",
  GET_POPUPTEXT_FAILURE: "GET_POPUPTEXT_FAILURE",

  GET_CITYLOCATION_REQUEST: "GET_CITYLOCATION_REQUEST",
  GET_CITYLOCATION_SUCCESS: "GET_CITYLOCATION_SUCCESS",
  GET_CITYLOCATION_FAILURE: "GET_CITYLOCATION_FAILURE",


  POST_AMENITY_REQUEST: "POST_AMENITY_REQUEST",
  POST_AMENITY_SUCCESS: "POST_AMENITY_SUCCESS",
  POST_AMENITY_FAILURE: "POST_AMENITY_FAILURE",

  DELETE_AMENITY_REQUEST: "DELETE_AMENITY_REQUEST",
  DELETE_AMENITY_SUCCESS: "DELETE_AMENITY_SUCCESS",
  DELETE_AMENITY_FAILURE: "DELETE_AMENITY_FAILURE",

  UPDATE_AMENITY_REQUEST: "UPDATE_AMENITY_REQUEST",
  UPDATE_AMENITY_SUCCESS: "UPDATE_AMENITY_SUCCESS",
  UPDATE_AMENITY_FAILURE: "UPDATE_AMENITY_FAILURE",


  ADD_EDIT_DOCUMENT_CHECKLIST_REQUEST: "ADD_EDIT_DOCUMENT_CHECKLIST_REQUEST",
  ADD_EDIT_DOCUMENT_CHECKLIST_SUCCESS: "ADD_EDIT_DOCUMENT_CHECKLIST_SUCCESS",
  ADD_EDIT_DOCUMENT_CHECKLIST_FAILURE: "ADD_EDIT_DOCUMENT_CHECKLIST_FAILURE",

  GET_LEGAL_CHECKLIST_REQUEST: "GET_LEGAL_CHECKLIST_REQUEST",
  GET_LEGAL_CHECKLIST_SUCCESS: "GET_LEGAL_CHECKLIST_SUCCESS",
  GET_LEGAL_CHECKLIST_FAILURE: "GET_LEGAL_CHECKLIST_FAILURE",

  ADD_LEGAL_CHECKLIST_REQUEST: "ADD_LEGAL_CHECKLIST_REQUEST",
  ADD_LEGAL_CHECKLIST_SUCCESS: "ADD_LEGAL_CHECKLIST_SUCCESS",
  ADD_LEGAL_CHECKLIST_FAILURE: "ADD_LEGAL_CHECKLIST_FAILURE",

  UPDATE_LEGAL_CHECKLIST_REQUEST: "UPDATE_LEGAL_CHECKLIST_REQUEST",
  UPDATE_LEGAL_CHECKLIST_SUCCESS: "UPDATE_LEGAL_CHECKLIST_SUCCESS",
  UPDATE_LEGAL_CHECKLIST_FAILURE: "UPDATE_LEGAL_CHECKLIST_FAILURE",

  DELETE_LEGAL_CHECKLIST_REQUEST: "DELETE_LEGAL_CHECKLIST_REQUEST",
  DELETE_LEGAL_CHECKLIST_SUCCESS: "DELETE_LEGAL_CHECKLIST_SUCCESS",
  DELETE_LEGAL_CHECKLIST_FAILURE: "DELETE_LEGAL_CHECKLIST_FAILURE",
};
