import NotFound from 'components/NotFound/NotFound';
import { useDeletePropertyMetadataMutation } from 'hooks/useInvestorUpdate';
import { useCompanyInvestorUpdate } from 'hooks/useInvestorUpdate';
import React from 'react'
import { HiOutlinePlusCircle } from 'react-icons/hi';
import { Link, NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Card, Col, Container, Form, Table, Row, Badge } from "reactstrap";
export default function InvestorUpdateList() {
    React.useEffect(() => {
        localStorage.setItem("investor_update_id", "")
    }, [])
    const { mutate: deleteInvestorUpdate } = useDeletePropertyMetadataMutation()
    const onRemoveInvestorUpdate = async (id) => {
        let confirm = window.confirm("Are sure you want to trash this property?");
        if (confirm) {
            await deleteInvestorUpdate(id, {
                onSuccess: (res) => {
                    toast.success('Investor Update Delete Successfully')
                }
            })
        }
    };
    const { data } = useCompanyInvestorUpdate({
        company_id: localStorage.getItem("company_id"),
        property_id: localStorage.getItem("property_id")
    });
    return (
        <>
            <Row className="my-2">
                <Col className="text-right">
                    <NavLink
                        onClick={() => {
                            localStorage.setItem("update_redirect", 1);
                        }}
                        to={{
                            pathname: `/company/sentInvestorUpdate`,
                        }}
                        className="btn rz-button-primary">
                        <HiOutlinePlusCircle className='mr-2' style={{ fontSize: "22px" }} /> Create New
                    </NavLink>
                </Col>
            </Row>
            <Card>
                <Table className='mt-3' responsive>
                    <thead className="rz-thead-style">
                        <tr>
                            <th>TITLE</th>
                            <th>STATUS</th>
                            <th>SENT BY</th>
                            <th>SENT AT</th>
                            <th className="text-right">ACTIONS</th>
                        </tr>
                    </thead>

                    <tbody>
                        {data && data?.map((update) => (
                            <tr>
                                <td>
                                    {update?.heading}
                                </td>
                                <td>
                                    <Badge
                                        color=""
                                        className={`badge badge-pill badge-${update?.status == "ACTIVE"
                                            ? "success"
                                            : "primary"
                                            } mr-4`}
                                    >
                                        {update?.status}
                                    </Badge>
                                </td>
                                <td>
                                    <img width="30" style={{ borderRadius: 15 }} src={update?.createdBy?.avatar} alt="" />
                                    {" "}{update?.createdBy?.name}
                                </td>
                                <td>{new Date(update?.created_at).toDateString()}</td>
                                <td className="text-right">
                                    <Link
                                        onClick={() => {
                                            localStorage.setItem("update_redirect", 1);
                                            localStorage.setItem("investor_update_id", update?.id)
                                        }}
                                        to={`/company/sentInvestorUpdate`}
                                        className="btn btn-sm btn-link bg-transparent"
                                    >
                                        <i className="fa fa-edit"></i>
                                    </Link>

                                    <Button
                                        onClick={() => onRemoveInvestorUpdate(update?.id)}
                                        className="btn btn-sm btn-link text-danger bg-transparent border-0"
                                    >
                                        <i className="fas fa-trash    "></i>
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                {data?.length <= 0 &&
                    <NotFound
                        message="Create first update for this property."
                    />
                }
            </Card>
        </>
    )
}
