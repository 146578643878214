import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    FormFeedback,
    Label,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../../redux/actions';
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

const otpSchema = yup.object().shape({
    otp: yup.string().length(4, 'Otp must be 4 digit'),
});

const Otp = () => {
    const { register, handleSubmit, control, formState: { errors, isValid, isDirty } } = useForm({ resolver: yupResolver(otpSchema) });
    const dispatch = useDispatch();
    const verifyingOtp = useSelector(state => state.verifyOtpReducer.verifyingOtp);
    const auth = useSelector(state => state.authReducer.user);
    const user = useSelector(state => state.loginReducer.user);
    
    const history = useHistory();

    useEffect(() => {
        if (user === undefined && auth != null) {
            history.replace('/auth/login')
        }
    }, [user]);

    function onOtpSubmit(data) {
        data.user_id = user.user_id
        data.role = user?.role
        dispatch(userActions.verifyOtp(data, history));
    }

    function resendOtp(e){
        e.preventDefault();
        dispatch(userActions.resendOtp({
            phone: user?.phone,
            role: user?.role,
        }));
    }

    return (
        <>
            {/* <Col lg="5" md="7"> */}
            {/* <Card className="bg-white shadow border-0"> */}
            <div className="px-lg-5 px-md-4 py-lg-5">
                <div className="text-center text-muted mb-4">
                    <img
                        style={{
                            width: 50,
                            height: 50,
                            borderRadius: 50,
                            objectFit: "contain",
                            marginBottom: 20
                        }}
                        alt="..."
                        src={require("./../../assets/img/brand/playstore-icon.png").default}
                    />
                    <h3>We sent a text message to your mobile device</h3>
                    <div className="mb-4">
                        <small>A text message with a verification code was sent to your mobile number</small>
                    </div>
                    <Form role="form" onSubmit={handleSubmit(onOtpSubmit)}>
                        <FormGroup>
                            <input {...register('otp')} className={`form-control ${errors.otp ? 'is-invalid' : ''}`} placeholder='Enter 4 digit code here' />
                            <FormFeedback> {errors.otp?.message} </FormFeedback>
                        </FormGroup>
                        <Row>
                            <Col>
                                <a href="#" color="link" onClick={resendOtp}>Resend Otp</a>
                            </Col>
                        </Row>
                        <div className="text-center">
                            <Button type="submit" className="my-4 w-100" color="primary" disabled={verifyingOtp}>
                                {verifyingOtp ? 'Please wait...' : 'Sign in'}
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
            {/* </Card> */}
            {/* </Col> */}
        </>
    );
};

export default Otp;
