import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import DonutChart from "react-donut-chart";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useGetCompanyPropertyById,
  useMutateShareCompany,
} from "../../../hooks/useCompany";
import { QueryClient } from "react-query";
export default function CreateShareClass() {

  const { data } = useGetCompanyPropertyById({
    company_id: localStorage.getItem("company_id"),
    property_id: localStorage.getItem("property_id")
  });
  const [total, setTotal] = useState({
    total_token: 0,
    total_allocated: 0,
    total_sell: 0
  });

  console.log(total)

  useEffect(async () => {
    var allocated, sell, total;
    if (data?.company?.LLPCompanyShare?.filter((share) => share.property_id == localStorage.getItem("property_id"))?.length > 0) {
      allocated = data?.company?.LLPCompanyShare?.filter((share) => share.property_id == localStorage.getItem("property_id"))[0]?.class_allocate_shares
    }
    if (data?.total?.length > 0) {
      sell = data?.total[0]?.total_sqyds_sold
    }
    if (data?.total?.length > 0) {
      total = data?.total[0]?.no_sqyds
    }
    const final = {
      total_token: total ? total : 0,
      total_allocated: allocated ? allocated : 0,
      total_sell: sell ? sell : 0
    };

    setTotal(final);
  }, [data])
  const queryClient = new QueryClient();
  const { mutate } = useMutateShareCompany();
  const SignupSchema = yup.object().shape({
    class_allocate_shares: yup.number().required(),
    assign_managers: yup.number().required(),
  });
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      class_allocate_shares:
        total.total_allocated,
    },
    resolver: yupResolver(SignupSchema),
  });
  const onSubmit = async (data) => {
    if (
      data?.company?.total_token <
      parseFloat(getValues("class_allocate_shares"))
    ) {
      alert("Allocated Share is Not More than Total");
      return;
    }
    const formData = {
      class_allocate_shares: getValues("class_allocate_shares"),
      assign_managers: getValues("assign_managers"),
      description: getValues("description"),
      class_name: "Allocated",
      company_id: localStorage.getItem("company_id"),
      property_id: localStorage.getItem("property_id")
    };
    await mutate(formData, {
      onSuccess: (res) => {
        queryClient.invalidateQueries(`user_company_16`);
      },
    });
  };
  return (
    <>
      {data?.company?.LLPCompanyShare?.length > 0
        &&
        data?.company?.LLPCompanyShare
          ?.filter((share) => share.property_id == localStorage.getItem("property_id"))?.length > 0
        ? (
          <Container fluid className="mt-2">
            <Row className="my-2">
              <Col>
                <h3>Share Class for Property: {localStorage.getItem("property_name")}</h3>
              </Col>
              <Col className="text-right">
                <button
                  type="button"
                  className="h4 mb-0 text-uppercase d-none d-lg-inline-block btn btn-primary"
                  data-toggle="modal"
                  data-target="#myModal"
                >
                  <i className="fas fa-plus "></i> Create New
                </button>
              </Col>
            </Row>
            <div className="row">
              <Card className="shareclass-chart shadow">
                <div class="row">
                  <div class="col-sm-5 my-3 pl-5">
                    <div class="col-xs-4">
                      <div className="form-group">
                        <label>Allocated shares</label>

                      </div>
                    </div>

                    <div class="col-xs-4">
                      <div className="form-group">
                        <i
                          className="fas fa-circle pl-1"
                          style={{ color: "#7367F0" }}
                        ></i>
                        <label className="timeline-p pl-1">Tokens allocated</label>
                        <p>{total.total_allocated}</p>
                      </div>
                    </div>
                    <div class="col-xs-4">
                      <div className="form-group">
                        <label>
                          <i
                            className="fas fa-circle pl-1"
                            style={{ color: "#02D3AF" }}
                          ></i> Tokens available
                        </label>
                        <p className="timeline-p">
                          {total.total_token -
                            total.total_allocated}
                        </p>
                      </div>
                    </div>
                    <div class="col-xs-4">
                      <div className="form-group">
                        <label>
                          <i className="fas fa-circle pl-1" style={{ color: "#F96E6F" }} /> Tokens Sold
                        </label>
                        <p className="timeline-p">{total.total_sell}</p>
                      </div>
                    </div>
                    <div class="col-xs-4">
                      <div className="form-group">
                        <label>authorization by</label>
                        <p className="timeline-p">{data?.company?.LLPCompanyShare[0]?.authorization}</p>
                      </div>
                      <div className="form-group">
                        <label>authorization date</label>
                        <p className="timeline-p">
                          {
                            data?.company?.LLPCompanyShare[0]?.created_at?.split(
                              "T"
                            )[0]
                          }
                        </p>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="text-right my-3">
                      <label><i className="fas fa-edit  mr-2" /></label>
                      <label><i className="fas fa-trash" /></label>

                    </div>
                    <DonutChart
                      data={[
                        {
                          label: "Total allocated",
                          value:
                            total.total_allocated /
                            total.total_token,
                        },
                        {
                          label: "Total available",
                          value:
                            (total.total_token -
                              total.total_allocated) /
                            total.total_token,
                        },
                      ]}
                      height={250}
                      width={250}
                      legend={false}
                      colors={['#7367F0', '#F96E6F', '#02D3AF']}
                    />
                  </div>
                </div>
              </Card>

              <Card className="shareclass-chart-2 shadow">
                <div class="row">
                  <div class="col-sm-5 my-3 pl-5">
                    <div class="col-xs-4">
                      <div className="form-group">
                        <label>Un-allocated shares</label>
                      </div>
                    </div>

                    <div class="col-xs-4">
                      <div className="form-group">
                        <label><i className="fas fa-circle" style={{ color: "#F96E6F" }} /> Total Un-allocated</label>
                        <p className="timeline-p">

                          {total.total_token -
                            total.total_allocated}
                        </p>
                      </div>
                    </div>
                    <div class="col-xs-4">
                      <div className="form-group">
                        <label><i className="fas fa-circle" style={{ color: "#7367F0" }} /> Tokens allocated</label>
                        <p className="timeline-p">
                          {total.total_allocated}
                        </p>
                      </div>
                    </div>
                    <div class="col-xs-4">
                      <div className="form-group">
                        <label>authorization by</label>
                        <p>{data?.company?.LLPCompanyShare[0]?.authorization}</p>
                      </div>
                    </div>
                    <div class="col-xs-4">
                      <div className="form-group">
                        <label>authorization date</label>
                        <p className="timeline-p">
                          {
                            data?.company?.LLPCompanyShare[0]?.created_at?.split(
                              "T"
                            )[0]
                          }
                        </p>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className="text-right my-3">
                      <label><i className="fas fa-edit  mr-2" /></label>
                      <label><i className="fas fa-trash" /></label>

                    </div>
                    <DonutChart
                      data={[
                        {
                          label: "Total Un-allocated",
                          value:
                            (total.total_token -
                              total.total_allocated) /
                            total.total_token,
                        },
                        {
                          label: "Total allocated",
                          value:
                            total.total_allocated /
                            total.total_token,
                        },
                        {
                          label: "Total Sell",
                          value:
                            total.total_sell /
                            total.total_token,
                        },
                      ]}
                      height={250}
                      width={250}
                      legend={false}
                    />
                  </div>
                </div>
              </Card>
            </div>
          </Container>
        ) : (
          <Container fluid className="mt-5">
            <Row className="my-2 text-center">
              <Col>
                <h3>Share Class for Property: {localStorage.getItem("property_name")}</h3>
                <h1>{total.total_token}</h1>
                <p>Total Token Available</p>
              </Col>
            </Row>
            <Row className="my-2 text-center">
              <Col>
                <button
                  type="button"
                  className="h4 mb-0 text-uppercase d-none d-lg-inline-block btn btn-primary"
                  data-toggle="modal"
                  data-target="#myModal"
                >
                  <i className="fas fa-plus "></i> Create New Share Class
                </button>
              </Col>
            </Row>
          </Container>
        )}
      <div className="container">
        <div className="modal right fade" id="myModal" role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-title">Create New Share className</div>
                <button type="button" className="close" data-dismiss="modal">
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <from onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-sm-12 form-group">
                      Share Class Name
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Allocated"
                        name="text"
                        {...register(`class_name`)}
                        disabled={true}
                      />
                    </div>
                    <div className="col-sm-12 form-group">
                      Total Token
                      <input
                        type="text"
                        className="form-control"
                        placeholder={total.total_token}
                        name="text"
                        disabled={true}
                      />
                    </div>
                    <div className="col-sm-12 form-group">
                      Allocated Share
                      <input
                        type="text"
                        className="form-control"
                        placeholder="200"
                        name="text"
                        {...register(`class_allocate_shares`)}
                      />
                    </div>
                    <div className="col-sm-12 form-group">
                      Assign Manager <br />
                      <select
                        className="form-control"
                        {...register("assign_managers")}
                      >
                        {data && data?.company?.LLPAdvisorsMembers?.length > 0
                          ? data?.company?.LLPAdvisorsMembers?.map((role) => (
                            <option key={role?.id} value={role?.id}>
                              {role?.name}
                            </option>
                          ))
                          : null}
                      </select>
                    </div>
                    <div className="col-sm-12 form-group">
                      Description
                      <textarea
                        className="form-control"
                        placeholder="Text"
                        {...register(`description`)}
                      ></textarea>
                    </div>
                    <div className="col-sm-12 form-group">
                      Legal Copy
                      <input
                        type="file"
                        className="form-control"
                        id="inputGroupFile02"
                      />
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        onClick={() => onSubmit()}
                        className="btn btn-success"
                        data-dismiss="modal"
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="btn btn-default close-btn"
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                    </div>
                  </from>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
