import { accountConstants } from "redux/constants/account.constants";

export function viewAccountReducer(state = {}, action) {
  switch (action.type) {
    case  accountConstants.VIEW_ACCOUNT_REQUEST:
      return { loading: true };
    case  accountConstants.VIEW_ACCOUNT_SUCCESS:
      return { account: action.payload.data };
    case  accountConstants.VIEW_ACCOUNT_FAILURE:
      return { loading: true };
    default:
      return state;
  }
}
