import { useGetGeneral } from 'hooks/useDAO';
import React from 'react'
import { Card } from 'reactstrap'
import Icon from "./../../../assets/img/Icon.png"
export default function General() {
  const { data, isLoading } = useGetGeneral({
    property_id: localStorage.getItem("property_id")
  });

  return (
    <Card className="my-2 py-3 d-flex flex-cloumn align-items-center bg-white">
      <Card className="p-3 m-3 shadow-lg bg-white  rounded align-items-center  justify-content-between"
        style={{
          borderRadius: 6,
          width: "93%"
        }}
      >
        <div>
          <h1>Company : {data?.property?.name}</h1>
          <p>Description : ipsum dolor sit. Amet dessert donut candy chocolate bar cotton dessert candy chocolate. Candy muffin danish. Macaroon brownie jelly beans marzipan cheesecake oat cake. Car</p>
          <div className='d-flex flex-row justify-content-between px-4'>
            <p>Role : Super Admin</p>
            <p>DAO Type : ERC20</p>
            <p>Total Token supply : {data?.property?.total_property_sqyds}</p>
          </div>
        </div>
      </Card>
      <div
        style={{ width: "95%" }} className="d-flex flex-row justify-content-between">
        <Card className="py-5 px-8 m-3 shadow-lg bg-white  rounded align-items-center  justify-content-between">
          <img alt="..."
            style={{
              height: 60,
              borderRadius: 60,
              width: 60
            }}
            src={Icon}
          />
          <h1 className='mt-2'>{data?.proposal}</h1>
          <h3>Voting</h3>
        </Card>
        <Card className="py-5 px-8 m-3 shadow-lg bg-white  rounded align-items-center  justify-content-between">
          <img alt="..."
            style={{
              height: 60,
              borderRadius: 60,
              width: 60
            }}
            src={Icon}
          />
          <h1 className='mt-2'>{data?.voters}</h1>
          <h3>Holders</h3>
        </Card>
        <Card className="py-5 px-8 m-3 shadow-lg bg-white  rounded align-items-center  justify-content-between">
          <img alt="..."
            style={{
              height: 60,
              borderRadius: 60,
              width: 60
            }}
            src={Icon}
          />
          <h1 className='mt-2'>{data?.voters}</h1>
          <h3 >Voters</h3>
        </Card>
      </div>
    </Card>
  )
}
