import { useQuery } from "react-query";
import { API_ENDPOINTS } from "../helpers";
import { api } from "../helpers";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

const fetchPropertyHostedByQuery = async (id) => {
    if (id > 0 && id != undefined) {
        const data = await api.get(`${API_ENDPOINTS.PROPERTYHOSTEDBY}/${id}`);
        return data?.data;
    }
    else
        return undefined
};
const usePropertyHostedByQuery = (id) => {
    return useQuery([API_ENDPOINTS.PROPERTYHOSTEDBY, id],
        () => fetchPropertyHostedByQuery(id), {
            keepPreviousData: false,
            refetchOnWindowFocus: false
    });
};
const useUpdatePropertyHostedByClassMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { id, input } }) => 
        { return api.put(`${API_ENDPOINTS.PROPERTYHOSTEDBY}/${id}`, input) },
        {
            onSuccess: () => {
                // toast.success("Hosted By Updated Successfully");
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.PROPERTYHOSTEDBY);
            },
            onError: (error) => {
                toast.error(error?.response?.data?.message);
            },
        }
    );
};
const useAddHighlightMutataion=()=>{
    return useMutation(async(options)=>{
        await api.post('property/hosteByHighlight',options);
    },
    {
        onSuccess: () => {
            toast.success("HightLight Add sucessfully");
        },
        onSettled: () => {
           
        },
        onError: (error) => {
            
        },
    }
    )
}
const useUpdateHighlightMutataion=()=>{
    return useMutation(async({data,id})=>{
        await api.put(`/property/hosteByHighlight/${id}`,data);
    },
    {
        onSuccess: () => {
            toast.success("HightLight Update sucessfully");
        },
        onSettled: () => {
           
        },
        onError: (error) => {
            
        },
    }
    )
}
const useDeleteHighlightMutataion=()=>{
    return useMutation(async(option)=>{
        await api.delete(`property/hosteByHighlight/${option.highlight_id}?hosted_by_id=${option.hosted_by_id}`);
    },
    {
        onSuccess: () => {
            toast.success("HightLight Delete sucessfully");
        },
        onSettled: () => {
           
        },
        onError: (error) => {
            
        },
    }
    )
}
export {
    usePropertyHostedByQuery,
    useUpdatePropertyHostedByClassMutation,
    useAddHighlightMutataion,
    useDeleteHighlightMutataion,
    useUpdateHighlightMutataion
};
