import Wizard from "../../components/Wizard";
import { useRef, useState } from "react";
import CompanyInfo from "./CompanyInfo";
import SPVMemo from './SPVMemo'
import BankDetails from "./BankDetails";
import RiskDisclosures from "./RiskDisclosures";
import { useGetCompanyById } from "./../../hooks/useCompany";
import BoardMember from "./BoardMember";
import LegalAdvisors from "./LegalAdvisors";
import AccountManagement from "./AccountManagement";

const Index = ({ parentStepper, company_id }) => {
  const ref = useRef(null);
  const { data } = useGetCompanyById({
    company_id: company_id
  });
  const [stepper, setStepper] = useState(null);
  var sc = data?.company?.step_completed;
  const steps = [
    {
      id: "company-info-1",
      title: "Company Info",
      subtitle: sc >= 4.1 ? "Completed" : "In Progress",
      content: (
        <CompanyInfo
          parentStepper={parentStepper}
          stepper={stepper}
          company={data?.company}
          type="wizard-vertical"
        />
      ),
    },
    {
      id: "spv-memo-2",
      title: "SPV Memo",
      subtitle:
        sc == 4.1 ? "In Progress" : sc >= 4.2 ? "Completed" : "Not Completed",
      content: (
        <SPVMemo
          parentStepper={parentStepper}
          stepper={stepper}
          company={data?.company}
          type="wizard-vertical"
        />
      ),
    },
    {
      id: "bank-details-3",
      title: "Bank Details",
      subtitle:
        sc == 4.2 ? "In Progress" : sc >= 4.3 ? "Completed" : "Not Completed",
      content: (
        <BankDetails
          parentStepper={parentStepper}
          stepper={stepper}
          company={data?.company}
          type="wizard-vertical"
        />
      ),
    },
    {
      id: "risk-disclosures-4",
      title: "Risk & Disclosure",
      subtitle:
        sc == 4.3 ? "In Progress" : sc >= 4.4 ? "Completed" : "Not Completed",
      content: (
        <RiskDisclosures
          parentStepper={parentStepper}
          stepper={stepper}
          company={data?.company}
          type="wizard-vertical"
        />
      ),
    },
    {
      id: "account-management-4",
      title: "Account Management",
      subtitle:
        sc == 4.3 ? "In Progress" : sc >= 4.4 ? "Completed" : "Not Completed",
      content: (
        <AccountManagement
          parentStepper={parentStepper}
          stepper={stepper}
          company={data?.company}
          type="wizard-vertical"
        />
      ),
    },
    {
      id: "setting-4",
      title: "Board Member",
      subtitle:
        sc == 4.3 ? "In Progress" : sc >= 4.4 ? "Completed" : "Not Completed",
      content: (
        <BoardMember
          parentStepper={parentStepper}
          stepper={stepper}
          company={data?.company}
          type="wizard-vertical"
        />
      ),
    },
    {
      id: "setting-5",
      title: "Legal Advisors",
      subtitle:
        sc == 4.3 ? "In Progress" : sc >= 4.4 ? "Completed" : "Not Completed",
      content: (
        <LegalAdvisors
          parentStepper={parentStepper}
          stepper={stepper}
          company={data?.company}
          type="wizard-vertical"
        />
      ),
    },
    // {
    //   id: "create-sto-5",
    //   title: "Create ERC-20",
    //   subtitle:
    //     sc == 4.4 ? "In Progress" : sc >= 4.5 ? "Completed" : "Not Completed",
    //   content: (
    //     <CreateSto
    //       parentStepper={parentStepper}
    //       stepper={stepper}
    //       company={data?.company}
    //       type="wizard-vertical"
    //     />
    //   ),
    // }
  ];
  return (
    <div>
      <Wizard
        type="modern-vertical"
        ref={ref}
        steps={steps}
        className="shadow-none"
        options={{
          linear: false,
        }}
        contentclassName="shadow-none border-left rounded-0"
        instance={(el) => setStepper(el)}
      />
    </div>
  );
};

export default Index;