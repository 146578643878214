import { toast } from "react-toastify";
import { settingConstants } from "redux/constants/setting.constants";
import { settingService } from "redux/services";

export const settingActions = {
    getSettings,
    genericSettings,
};

function getSettings() {
    return (dispatch) => {
        dispatch(request());
        settingService.getSettings().then(
            (res) => {
                dispatch(success(res));
            },
            (error) => {
                toast.error(error);
                dispatch(failure(error));
            }
        );
    };

    function request() { return { type: settingConstants.GET_SETTINGS_REQUEST } }
    function success(res) { return { type: settingConstants.GET_SETTINGS_SUCCESS, payload: res } }
    function failure(error) { return { type: settingConstants.GET_SETTINGS_FAILURE, error } }
}

function genericSettings(data, service) {
    return (dispatch) => {
        dispatch(request(data));
        settingService[service](data).then(
            (res) => {
                dispatch(success(res));
                toast.success(res.message);
            },
            (error) => {
                toast.error(error);
                dispatch(failure(error));
            }
        );
    };

    function request() { return { type: settingConstants.GET_SETTINGS_REQUEST } }
    function success(res) { return { type: settingConstants.GET_SETTINGS_SUCCESS, payload: res } }
    function failure(error) { return { type: settingConstants.GET_SETTINGS_FAILURE, error } }
}