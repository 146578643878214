import { useEffect, useState, Fragment, forwardRef } from 'react'
import Stepper from 'bs-stepper'
import classnames from 'classnames'
import { PropTypes } from 'prop-types'
import 'bs-stepper/dist/css/bs-stepper.min.css'
import './../../assets/css/form-wizard.css'
import LoadingIcon from 'components/Icons/LoadingIcon'

const Wizard = forwardRef((props, ref) => {
    // ** Props
    const { type, className, steps, separator, options, instance } = props

    // ** State
    const [activeIndex, setActiveIndex] = useState(0)

    // ** Vars
    let stepper = null

    // ** Step change listener on mount
    useEffect(() => {
        stepper = new Stepper(ref.current, options)

        ref.current.addEventListener('shown.bs-stepper', function (event) {
            setActiveIndex(event.detail.indexStep)
        })

        if (instance) {
            instance(stepper)
        }
    }, [])

    // ** Renders Wizard Header
    const renderHeader = () => {
        return steps.map((step, index) => {
            return (
                <Fragment key={step.id}>
                    {index !== 0 && index !== steps.length ? <div className='line'>{separator}</div> : null}
                    {index !== 0 && index !== steps.length && type === 'modern-vertical' ? <div className="bs-stepper-line"></div> : null}
                    <div
                        className={classnames('step', {
                            crossed: activeIndex > index,
                            active: index === activeIndex
                        })}
                        data-target={`#${step.id}`}
                    >
                        <button type='button' className='step-trigger' disabled={step.subtitle=='Completed' || step.subtitle=='In Progress' ? false : true }>
                            <span className={`bs-stepper-box ${step.subtitle=='Completed' ? 'bg-success' : ''}`} id='bs-stepper-box'>
                                {step.icon ? step.icon : (step.subtitle=='Completed'? <i className='fa fa-check'></i> : <LoadingIcon/>)}
                            </span>
                            <span className='bs-stepper-label'>
                                <span className='bs-stepper-title'>{step.title}</span>
                                {step.subtitle ? <span className='bs-stepper-subtitle'>{step.subtitle}</span> : null}
                            </span>
                        </button>
                    </div>
                </Fragment>
            )
        })
    }

    // ** Renders Wizard Content
    const renderContent = () => {
        return steps.map((step, index) => {
            return (
                <div
                    className={classnames('content', {
                        'active dstepper-block': activeIndex === index
                    })}
                    id={step.id}
                    key={step.id}
                >
                    {step.content}
                </div>
            )
        })
    }

    return (
        <div
            ref={ref}
            className={classnames('bs-stepper', {
                [className]: className,
                vertical: type === 'vertical',
                'vertical wizard-modern': type === 'modern-vertical',
                'wizard-modern': type === 'modern-horizontal'
            })}
        >
            <div className='bs-stepper-header'>{renderHeader()}</div>
            <div className={`bs-stepper-content ${props.contentClass}`}>{renderContent()}</div>
        </div>
    )
})

export default Wizard

// ** Default Props
Wizard.defaultProps = {
    type: 'horizontal',
    separator: <i className='fa fa-chevron-right text-sm'></i>,
    options: {}
}

// ** PropTypes
Wizard.propTypes = {
    type: PropTypes.string,
    instance: PropTypes.func,
    options: PropTypes.object,
    className: PropTypes.string,
    separator: PropTypes.element,
    contentClass: PropTypes.string,
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            subtitle: PropTypes.any,
            icon: PropTypes.any,
            content: PropTypes.any.isRequired
        })
    ).isRequired
}
