import { propertyConstants } from '../constants';
import { propertyService } from '../services';
import { toast } from "react-toastify";
import { history } from 'helpers';
import { deletePropertyObj } from 'utils';
import { updatePropertyDocumentObj } from 'utils';

export const propertyActions = {
    genericPropertyAction,
    getProperties,
    aboutOne,
    getProperty,
    savePropertySvg,
    saveAboutTwoBlock,
    saveAboutBlukAddBlock,
    saveAboutTwo,
    saveAboutThreeGrowth,
    saveAboutThree,
    storeMediaOne,
    storeMediaOneGallary,
    deleteMediaOneGallary,
    storeMediaTwoDocs,
    deleteMediaTwoDocs,
    storeMediaTwo,
    saveTimeLimit,
    saveAboutFive,
    updateKeyHighLight,
    storeAdditionalOne,
    storeAdditionalTwo,
    storeAdditionalThree,
    storeAdditionalFourFaq,
    storeAdditionalFour,
    deleteAdditionalFourFaq,
    companyCreate,
    updateCompany,
    addCompanyBankAccount,
    updateMediaTwoDocs,
    getPartnerProperties,
    getTokenSellerProperties,
    getTokenSellerSuperAdmin,
    getTokenSellerMyAsset
};

// this is a common function, when having repeated/same kinda of code call this action
function genericPropertyAction(service, data, onInit) {
    return dispatch => {
        dispatch(request(data));
        propertyService[service](data)
            .then(
                res => {
                    dispatch(success(res.data));
                    toast.success(res.message)
                    if (onInit) {
                        onInit()
                    }
                },
                error => {
                    dispatch(failure(error));
                    toast.error(error)
                }
            );
    };

    function request(res) { return { type: propertyConstants.PROPERTY_REQUEST, payload: res } }
    function success(res) { return { type: propertyConstants.PROPERTY_SUCCESS, payload: res } }
    function failure(error) { return { type: propertyConstants.PROPERTY_FAILURE, error } }
}

function deleteAdditionalFourFaq(id) {
    return dispatch => {
        dispatch(request(id));
        propertyService.deleteAdditionalFourFaq(id)
            .then(
                res => {
                    dispatch(success(deletePropertyObj('propertyFaq', id)));
                    toast.success(res.message)
                },
                error => {
                    dispatch(failure(error));
                    toast.error(error)
                }
            );
    };

    function request(res) { return { type: propertyConstants.PROPERTY_REQUEST, payload: res } }
    function success(res) { return { type: propertyConstants.PROPERTY_SUCCESS, payload: res } }
    function failure(error) { return { type: propertyConstants.PROPERTY_FAILURE, error } }
}


function storeAdditionalFour(data, stepper, parentStepper) {
    return dispatch => {
        dispatch(request(data));
        propertyService.storeAdditionalFour(data)
            .then(
                res => {
                    dispatch(success(res.data));
                    toast.success(res.message)
                    stepper.next();
                },
                error => {
                    dispatch(failure(error));
                    toast.error(error)
                }
            );
    };

    function request(res) { return { type: propertyConstants.PROPERTY_REQUEST, payload: res } }
    function success(res) { return { type: propertyConstants.PROPERTY_SUCCESS, payload: res } }
    function failure(error) { return { type: propertyConstants.PROPERTY_FAILURE, error } }
}

function storeAdditionalFourFaq(data) {
    return dispatch => {
        dispatch(request(data));
        propertyService.storeAdditionalFourFaq(data)
            .then(
                res => {
                    dispatch(success(res.data));
                    toast.success(res.message)
                },
                error => {
                    dispatch(failure(error));
                    toast.error(error)
                }
            );
    };

    function request(res) { return { type: propertyConstants.PROPERTY_REQUEST, payload: res } }
    function success(res) { return { type: propertyConstants.PROPERTY_SUCCESS, payload: res } }
    function failure(error) { return { type: propertyConstants.PROPERTY_FAILURE, error } }
}

function storeAdditionalThree(data, stepper) {
    return dispatch => {
        dispatch(request(data));
        propertyService.storeAdditionalThree(data)
            .then(
                res => {
                    dispatch(success(res.data));
                    toast.success(res.message)
                    stepper.next();
                },
                error => {
                    dispatch(failure(error));
                    toast.error(error)
                }
            );
    };

    function request(res) { return { type: propertyConstants.PROPERTY_REQUEST, payload: res } }
    function success(res) { return { type: propertyConstants.PROPERTY_SUCCESS, payload: res } }
    function failure(error) { return { type: propertyConstants.PROPERTY_FAILURE, error } }
}

function storeAdditionalTwo(data, stepper) {
    return dispatch => {
        dispatch(request(data));
        propertyService.storeAdditionalTwo(data)
            .then(
                res => {
                    dispatch(success(res.data));
                    toast.success(res.message)
                    // stepper.next();
                },
                error => {
                    dispatch(failure(error));
                    toast.error(error)
                }
            );
    };

    function request(res) { return { type: propertyConstants.PROPERTY_REQUEST, payload: res } }
    function success(res) { return { type: propertyConstants.PROPERTY_SUCCESS, payload: res } }
    function failure(error) { return { type: propertyConstants.PROPERTY_FAILURE, error } }
}

function storeAdditionalOne(data, stepper) {
    return dispatch => {
        dispatch(request(data));
        propertyService.storeAdditionalOne(data)
            .then(
                res => {
                    dispatch(success(res.data));
                    toast.success(res.message)
                    if (stepper)
                        stepper.next();
                },
                error => {
                    dispatch(failure(error));
                    toast.error(error)
                }
            );
    };

    function request(res) { return { type: propertyConstants.PROPERTY_REQUEST, payload: res } }
    function success(res) { return { type: propertyConstants.PROPERTY_SUCCESS, payload: res } }
    function failure(error) { return { type: propertyConstants.PROPERTY_FAILURE, error } }
}

function storeMediaTwo(data, stepper, parentStepper) {
    return dispatch => {
        dispatch(request(data));
        propertyService.storeMediaTwo(data)
            .then(
                res => {
                    dispatch(success(res.data));
                    toast.success(res.message)
                    parentStepper.next()
                },
                error => {
                    dispatch(failure(error));
                    toast.error(error)
                }
            );
    };

    function request(res) { return { type: propertyConstants.PROPERTY_REQUEST, payload: res } }
    function success(res) { return { type: propertyConstants.PROPERTY_SUCCESS, payload: res } }
    function failure(error) { return { type: propertyConstants.PROPERTY_FAILURE, error } }
}

function deleteMediaTwoDocs(doc_id) {
    return dispatch => {
        dispatch(request(doc_id));
        propertyService.deleteMediaTwoDocs(doc_id)
            .then(
                res => {
                    dispatch(success(deletePropertyObj('propertyDocument', doc_id)));
                    toast.success(res.message)
                },
                error => {
                    dispatch(failure(error));
                    toast.error(error)
                }
            );
    };

    function request(res) { return { type: propertyConstants.PROPERTY_REQUEST, payload: res } }
    function success(res) { return { type: propertyConstants.PROPERTY_SUCCESS, payload: res } }
    function failure(error) { return { type: propertyConstants.PROPERTY_FAILURE, error } }
}
function updateMediaTwoDocs(data) {
    return dispatch => {
        dispatch(request(data.doc_id));
        propertyService.updateMediaTwoDocs(data)
            .then(
                res => {
                    dispatch(success(updatePropertyDocumentObj(
                        'propertyDocument',
                        data.doc_id,
                        data.protected,
                        data.status
                    )));
                    toast.success(res.message)
                },
                error => {
                    dispatch(failure(error));
                    toast.error(error)
                }
            );
    };

    function request(res) { return { type: propertyConstants.PROPERTY_REQUEST, payload: res } }
    function success(res) { return { type: propertyConstants.PROPERTY_SUCCESS, payload: res } }
    function failure(error) { return { type: propertyConstants.PROPERTY_FAILURE, error } }
}


function storeMediaTwoDocs(data) {
    return dispatch => {
        dispatch(request(data));
        propertyService.storeMediaTwoDocs(data)
            .then(
                res => {
                    dispatch(success(res.data));
                    toast.success(res.message)
                },
                error => {
                    dispatch(failure(error));
                    toast.error(error)
                }
            );
    };

    function request(res) { return { type: propertyConstants.PROPERTY_REQUEST, payload: res } }
    function success(res) { return { type: propertyConstants.PROPERTY_SUCCESS, payload: res } }
    function failure(error) { return { type: propertyConstants.PROPERTY_FAILURE, error } }
}

function deleteMediaOneGallary(id) {
    return dispatch => {
        dispatch(request(id));
        propertyService.deleteMediaOneGallary(id)
            .then(
                res => {
                    dispatch(success(deletePropertyObj('medias', id)));
                    toast.success(res.message)
                },
                error => {
                    dispatch(failure(error));
                    toast.error(error)
                }
            );
    };

    function request(res) { return { type: propertyConstants.PROPERTY_REQUEST, payload: res } }
    function success(res) { return { type: propertyConstants.PROPERTY_SUCCESS, payload: res } }
    function failure(error) { return { type: propertyConstants.PROPERTY_FAILURE, error } }
}

function storeMediaOneGallary(data, stepper) {
    return dispatch => {
        dispatch(request(data));
        propertyService.storeMediaOneGallary(data)
            .then(
                res => {
                    dispatch(success(res.data));
                    toast.success(res.message)
                },
                error => {
                    dispatch(failure(error));
                    toast.error(error)
                }
            );
    };

    function request(res) { return { type: propertyConstants.PROPERTY_REQUEST, payload: res } }
    function success(res) { return { type: propertyConstants.PROPERTY_SUCCESS, payload: res } }
    function failure(error) { return { type: propertyConstants.PROPERTY_FAILURE, error } }
}

function storeMediaOne(data, stepper) {
    return dispatch => {
        dispatch(request(data));
        propertyService.storeMediaOne(data)
            .then(
                res => {
                    dispatch(success(res.data));
                    toast.success(res.message)
                    stepper.next()
                },
                error => {
                    dispatch(failure(error));
                    toast.error(error)
                }
            );
    };

    function request(res) { return { type: propertyConstants.PROPERTY_REQUEST, payload: res } }
    function success(res) { return { type: propertyConstants.PROPERTY_SUCCESS, payload: res } }
    function failure(error) { return { type: propertyConstants.PROPERTY_FAILURE, error } }
}

function saveAboutThree(data, stepper, parentStepper, property) {
    return dispatch => {
        dispatch(request(data));

        propertyService.saveAboutThree(data)
            .then(
                res => {
                    dispatch(success(res.data));
                    toast.success(res.message)
                    if (property?.stage == "Pre-leased") {
                        stepper.next()
                        return;
                    }
                    parentStepper.next()
                },
                error => {
                    dispatch(failure(error));
                    toast.error(error)
                }
            );
    };

    function request(res) { return { type: propertyConstants.PROPERTY_REQUEST, payload: res } }
    function success(res) { return { type: propertyConstants.PROPERTY_SUCCESS, payload: res } }
    function failure(error) { return { type: propertyConstants.PROPERTY_FAILURE, error } }
}

function saveAboutThreeGrowth(data) {
    return dispatch => {
        dispatch(request(data));

        propertyService.saveAboutThreeGrowth(data)
            .then(
                res => {
                    dispatch(success(res.data));
                    toast.success(res.message)
                },
                error => {
                    dispatch(failure(error));
                    toast.error(error)
                }
            );
    };

    function request(res) { return { type: propertyConstants.PROPERTY_REQUEST, payload: res } }
    function success(res) { return { type: propertyConstants.PROPERTY_SUCCESS, payload: res } }
    function failure(error) { return { type: propertyConstants.PROPERTY_FAILURE, error } }
}

function saveAboutTwo(data, stepper) {
    return dispatch => {
        dispatch(request(data));

        propertyService.saveAboutTwo(data)
            .then(
                res => {
                    dispatch(success(res.data));
                    toast.success(res.message)
                    stepper.next()
                },
                error => {
                    dispatch(failure(error));
                    toast.error(error)
                }
            );
    };

    function request(res) { return { type: propertyConstants.PROPERTY_REQUEST, payload: res } }
    function success(res) { return { type: propertyConstants.PROPERTY_SUCCESS, payload: res } }
    function failure(error) { return { type: propertyConstants.PROPERTY_FAILURE, error } }
}
function saveTimeLimit(data, stepper) {
    return dispatch => {
        dispatch(request(data));

        propertyService.saveTimeLimit(data)
            .then(
                res => {
                    dispatch(success(res.data));
                    toast.success(res.message)
                    stepper.next()
                },
                error => {
                    dispatch(failure(error));
                    toast.error(error)
                }
            );
    };

    function request(res) { return { type: propertyConstants.PROPERTY_REQUEST, payload: res } }
    function success(res) { return { type: propertyConstants.PROPERTY_SUCCESS, payload: res } }
    function failure(error) { return { type: propertyConstants.PROPERTY_FAILURE, error } }
}
function updateKeyHighLight(id, data) {
    return dispatch => {
        dispatch(request(data));

        propertyService.updateKeyHighLight(id, data)
            .then(
                res => {
                    dispatch(success(res.data));
                    toast.success(res.message)
                },
                error => {
                    dispatch(failure(error));
                    toast.error(error)
                }
            );
    };

    function request(res) { return { type: propertyConstants.PROPERTY_REQUEST, payload: res } }
    function success(res) { return { type: propertyConstants.PROPERTY_SUCCESS, payload: res } }
    function failure(error) { return { type: propertyConstants.PROPERTY_FAILURE, error } }
}
function saveAboutFive(data) {
    return dispatch => {
        dispatch(request(data));

        propertyService.saveAboutFive(data)
            .then(
                res => {
                    dispatch(success(res.data));
                    toast.success(res.message)

                },
                error => {
                    dispatch(failure(error));
                    toast.error(error)
                }
            );
    };

    function request(res) { return { type: propertyConstants.PROPERTY_REQUEST, payload: res } }
    function success(res) { return { type: propertyConstants.PROPERTY_SUCCESS, payload: res } }
    function failure(error) { return { type: propertyConstants.PROPERTY_FAILURE, error } }
}
function saveAboutTwoBlock(data) {
    return dispatch => {
        dispatch(request(data));

        propertyService.saveAboutTwoBlock(data)
            .then(
                res => {
                    // const { property } =  store.getState().propertyReducer
                    // delete property.propertyPlot
                    // property.propertyPlot = res.data
                    dispatch(success(res.data));
                    toast.success(res.message)
                },
                error => {
                    dispatch(failure(error));
                    toast.error(error)
                }
            );
    };

    function request(res) { return { type: propertyConstants.PROPERTY_REQUEST, payload: res } }
    function success(res) { return { type: propertyConstants.PROPERTY_SUCCESS, payload: res } }
    function failure(error) { return { type: propertyConstants.PROPERTY_FAILURE, error } }
}
function saveAboutBlukAddBlock(data) {
    return dispatch => {
        dispatch(request(data));

        propertyService.saveAboutBlukAddBlock(data)
            .then(
                res => {
                    dispatch(success(res.data));
                    toast.success(res.message)
                },
                error => {
                    dispatch(failure(error));
                    toast.error(error)
                }
            );
    };

    function request(res) { return { type: propertyConstants.PROPERTY_REQUEST, payload: res } }
    function success(res) { return { type: propertyConstants.PROPERTY_SUCCESS, payload: res } }
    function failure(error) { return { type: propertyConstants.PROPERTY_FAILURE, error } }
}
function savePropertySvg(data) {
    return dispatch => {
        dispatch(request(data));

        propertyService.savePropertySvg(data)
            .then(
                res => {
                    dispatch(success(res.data));
                },
                error => {
                    dispatch(failure(error));
                    toast.error(error)
                }
            );
    };

    function request(res) { return { type: propertyConstants.PROPERTY_REQUEST, payload: res } }
    function success(res) { return { type: propertyConstants.PROPERTY_SUCCESS, payload: res } }
    function failure(error) { return { type: propertyConstants.PROPERTY_FAILURE, error } }
}

function aboutOne(data, stepper) {
    return dispatch => {
        dispatch(request(data));

        propertyService.aboutOne(data)
            .then(
                res => {
                    dispatch(success(res.data));
                    history.push({
                        pathname: `/admin/property/${res.data.id}/edit`,
                        state: { property_id: res.data.id }
                    });
                    stepper.next()
                },
                error => {
                    dispatch(failure(error));
                    toast.error(error)
                }
            );
    };

    function request(res) { return { type: propertyConstants.PROPERTY_REQUEST, payload: res } }
    function success(res) { return { type: propertyConstants.PROPERTY_SUCCESS, payload: res } }
    function failure(error) { return { type: propertyConstants.PROPERTY_FAILURE, error } }
}


function companyCreate(data, stepper) {
    return dispatch => {
        dispatch(request(data));
        propertyService.companyCreate(data)
            .then(
                res => {
                    toast.success("Compnay Created successfully!")
                    stepper.next()
                },
                error => {
                    dispatch(failure(error));
                    toast.error(error)
                }
            );
    };

    function request(res) { return { type: propertyConstants.PROPERTY_REQUEST, payload: res } }
    function success(res) { return { type: propertyConstants.PROPERTY_SUCCESS, payload: res } }
    function failure(error) { return { type: propertyConstants.PROPERTY_FAILURE, error } }
}

function updateCompany(data, company_id, stepper, setIsFinalModalOpen) {
    return dispatch => {
        dispatch(request(data));
        propertyService.updateCompany(data, company_id)
            .then(
                res => {
                    if (setIsFinalModalOpen) {
                        setIsFinalModalOpen(false)
                    }
                    toast.success("Company updated successfully!")
                    stepper.next()
                },
                error => {
                    dispatch(failure(error));
                    toast.error(error)
                }
            );
    };

    function request(res) { return { type: propertyConstants.PROPERTY_REQUEST, payload: res } }
    function success(res) { return { type: propertyConstants.PROPERTY_SUCCESS, payload: res } }
    function failure(error) { return { type: propertyConstants.PROPERTY_FAILURE, error } }
}

function addCompanyBankAccount(data, company_id, property_id, stepper) {
    return dispatch => {
        dispatch(request(data));

        propertyService.addCompanyBankAccount(data, company_id)
            .then(
                res => {
                    dispatch(success(res.data));
                    history.push({
                        pathname: `/admin/property/${property_id}/edit`,
                        state: { property_id: property_id }
                    });
                    stepper.next()

                },
                error => {
                    dispatch(failure(error));
                    toast.error(error)
                }
            );
    };

    function request(res) { return { type: propertyConstants.PROPERTY_REQUEST, payload: res } }
    function success(res) { return { type: propertyConstants.PROPERTY_SUCCESS, payload: res } }
    function failure(error) { return { type: propertyConstants.PROPERTY_FAILURE, error } }
}

function getProperty(data) {
    return dispatch => {
        dispatch(request(data));

        propertyService.getProperty(data)
            .then(
                res => {
                    dispatch(success(res.data));
                },
                error => {
                    toast.error(error)
                    dispatch(failure(error));
                }
            );
    };

    function request(res) { return { type: propertyConstants.PROPERTY_REQUEST, payload: res } }
    function success(res) { return { type: propertyConstants.PROPERTY_SUCCESS, payload: res } }
    function failure(error) { return { type: propertyConstants.PROPERTY_FAILURE, error } }
}

function getProperties(data) {
    return dispatch => {
        dispatch(request(data));

        propertyService.getProperties(data)
            .then(
                res => {
                    dispatch(success(res));
                },
                error => {
                    toast.error(error)
                    dispatch(failure(error));
                }
            );
    };

    function request(res) { return { type: propertyConstants.GET_PROPERTIES_REQUEST, payload: res } }
    function success(res) { return { type: propertyConstants.GET_PROPERTIES_SUCCESS, payload: res } }
    function failure(error) { return { type: propertyConstants.GET_PROPERTIES_FAILURE, error } }
}
function getPartnerProperties(data) {
    return dispatch => {
        dispatch(request(data));

        propertyService.getPartnerProperties(data)
            .then(
                res => {
                    dispatch(success(res));
                },
                error => {
                    toast.error(error)
                    dispatch(failure(error));
                }
            );
    };

    function request(res) { return { type: propertyConstants.GET_PROPERTIES_PARTNER_REQUEST, payload: res } }
    function success(res) { return { type: propertyConstants.GET_PROPERTIES_PARTNER_SUCCESS, payload: res } }
    function failure(error) { return { type: propertyConstants.GET_PROPERTIES_PARTNER_FAILURE, error } }
}
function getTokenSellerProperties(data) {
    return dispatch => {
        dispatch(request(data));

        propertyService.getTokenSellerProperties(data)
            .then(
                res => {
                    dispatch(success(res));
                },
                error => {
                    toast.error(error)
                    dispatch(failure(error));
                }
            );
    };

    function request(res) { return { type: propertyConstants.GET_PROPERTIES_TOKEN_SELLER_REQUEST, payload: res } }
    function success(res) { return { type: propertyConstants.GET_PROPERTIES_TOKEN_SELLER_SUCCESS, payload: res } }
    function failure(error) { return { type: propertyConstants.GET_PROPERTIES_TOKEN_SELLER_FAILURE, error } }
}
function getTokenSellerMyAsset(data) {
    return dispatch => {
        dispatch(request(data));

        propertyService.getTokenSellerMyAsset(data)
            .then(
                res => {
                    dispatch(success(res));
                },
                error => {
                    toast.error(error)
                    dispatch(failure(error));
                }
            );
    };

    function request(res) { return { type: propertyConstants.GET_PROPERTIES_TOKEN_SELLER_MYASSET_REQUEST, payload: res } }
    function success(res) { return { type: propertyConstants.GET_PROPERTIES_TOKEN_SELLER_MYASSET_SUCCESS, payload: res } }
    function failure(error) { return { type: propertyConstants.GET_PROPERTIES_TOKEN_SELLER_MYASSET_FAILURE, error } }
}
function getTokenSellerSuperAdmin(data) {
    return dispatch => {
        dispatch(request(data));

        propertyService.getTokenSellerSuperAdmin(data)
            .then(
                res => {
                    dispatch(success(res));
                },
                error => {
                    toast.error(error)
                    dispatch(failure(error));
                }
            );
    };

    function request(res) { return { type: propertyConstants.GET_PROPERTIES_TOKEN_SELLER_SUPERADMIN_REQUEST, payload: res } }
    function success(res) { return { type: propertyConstants.GET_PROPERTIES_TOKEN_SELLER_SUPERADMIN_SUCCESS, payload: res } }
    function failure(error) { return { type: propertyConstants.GET_PROPERTIES_TOKEN_SELLER_SUPERADMIN_FAILURE, error } }
}