import { configConstants } from "../../constants";

export function updateAmenityReducer(state = {}, action) {
  switch (action.type) {
    case configConstants.UPDATE_AMENITY_REQUEST:
      return { loading: true };
    case configConstants.UPDATE_AMENITY_SUCCESS:
      return { amenity: action.payload };
    case configConstants.UPDATE_AMENITY_FAILURE:
      return { loading: true };
    default:
      return state;
  }
}
