import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Badge, Button, Card, Col, Container, Row, Spinner, Table } from "reactstrap";
import { employeeActions } from "redux/actions";
import BadgeImage from "../../assets/img/Badge.png"
import BadgeDelayImage from "../../assets/img/Badge-delay.png"
import BadgeCancleImage from "../../assets/img/Badge-cancel.png"


const EmployeeListRole = ({ match }) => {
  const { employee, loading } = useSelector(
    (state) => state.getEmployeeReducer
  );
  const location = useLocation();
  const { role } = location.state;
  const dispatch = useDispatch();

  const hanldeEmployeeDelete = (id) => {
    const confirm = window.confirm("Are You sure? ");
    if (confirm) {
      dispatch(employeeActions.deleteEmployees(id));
    }
  };

  useEffect(() => {
    dispatch(employeeActions.getEmployees(`?role=${match?.params?.role_name}`));
  }, []);

  return (
    <>
      <Container fluid className="mt-2">
        <Row className="my-2">
          <Col>
            <h3 className="text-uppercase">{role?.name} LIST</h3>
          </Col>
          <Col className="text-right">
            {/* <NavLink
              to={{
                pathname: `/admin/employee/add`,
                state: {
                  employee: {},
                },
              }}
              className="btn btn-primary"
            >
              <i className="fas fa-plus    "></i> Add New Employee
            </NavLink> */}
          </Col>
        </Row>
        <Card>
          <Table responsive>
            <thead className="thead-light">
              <tr>
                <th>EMPLOYEE ID</th>
                <th>EMPLOYEE NAME</th>
                <th>ROLE</th>
                <th>CONTACT NUMBER</th>
                {role?.id == 4 &&
                  <th>KYB</th>}
                <th className="text-center">STATUS</th>
                <th className="text-right">ACTIONS</th>
              </tr>
            </thead>

            {loading ? (
              <tr className="text-center">
                <td colSpan="10">
                  <Spinner animation="border" role="status" >{null}</Spinner>
                </td>
              </tr>
            ) : (
              <tbody>
                {employee?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td> {role?.name == "ChannelPartners" ?
                        <Link
                          to={{
                            pathname: `/admin/channel/${item.id}`,
                            state: {
                              user: item
                            }
                          }}>
                          <span className="mb-0 text-sm">
                            #{item.id}
                          </span>
                        </Link>
                        :
                        item?.id}</td>
                      <td>
                        <img width="30"
                          style={{ borderRadius: 15 }}
                          src={item?.avatar} alt="" />{" "}
                        {item?.name}
                      </td>
                      <td> {item?.role_data?.name} </td>
                      <td> {item?.phone} </td>
                      {role?.id == 4 &&
                        <td>
                          {item?.kyc?.status == "auto_approved"
                            ? <img src={BadgeImage} alt="" /> :
                            item?.kyc?.status == "rejected"
                              ? <img src={BadgeCancleImage} alt="" />
                            : <img src={BadgeDelayImage} alt="" />}
                        </td>}
                      <td className="text-center">
                        <Badge
                          color=""
                          className={`badge badge-pill badge-${item?.status == "Active"
                            ? "success"
                            : "danger"
                            }`}
                        >
                          {item?.status}
                        </Badge>
                      </td>
                      <td className="text-right">
                        {
                          (role?.id != 4 && role?.id != 2) && (
                            <NavLink
                              to={{
                                pathname: `/admin/employee/${item?.id}/edit`,
                                state: {
                                  employee: item,
                                },
                              }}
                              // state={{ employee: item }}
                              className=" btn btn-primary btn-sm"
                            >
                              <i className="fas fa-pen"></i>
                            </NavLink>
                          )
                        }
                        <Button
                          onClick={() => hanldeEmployeeDelete(item?.id)}
                          color="primary"
                          className="btn-sm"
                        >
                          <i className="fas fa-trash    "></i>
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </Table>
        </Card>
      </Container>
    </>
  );
};

export default EmployeeListRole;
