import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Label, ModalFooter, ModalBody, FormGroup, Modal, ModalHeader, Row, Table } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { configActions } from "redux/actions";
import { useParams } from "react-router";
import NotFound from "components/NotFound/NotFound";
import { useForm } from "react-hook-form";

const AddEditDocumentChecklist = () => {

    const [modalOpen, setModalOpen] = useState(false);

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const { countryStates: country } = useSelector(
        (state) => state.getCountryReducer
    );
    const dispatch = useDispatch();
    const [documentList, setDocumentList] = useState([]);
    const [stateId, setStateId] = useState(1);
    const [states, setStates] = useState([]);

    const { id } = useParams();

    const handleStateChange = (e) => {
        const stateInfo = country?.states.find((state) => {
            return state.name == e.target.value;
        });
        const temp = [];
        stateInfo?.documentList.map((doc) => {
            temp.push({
                id: doc.id,
                name: doc.name,
                type: doc.type,
                document_type: doc.document_type,
            });
        });
        setDocumentList(temp);
        setStateId(stateInfo?.id);
    };

    useEffect(() => {
        dispatch(configActions.getCountryStates(process.env.REACT_APP_COUNTRY_IN));
    }, []);

    useEffect(() => {
        if (country?.states) {
            const options = [];
            country?.states.map((item) => {
                options.push({ value: item.id, label: item.name });
            });
            setStates(options);
            setStateId(id);
        }

        setDocumentList(country?.states[id - 1]?.documentList || []);
    }, [country]);

    const {
        register: register,
        handleSubmit: handleSubmit,
        control,
        setValue: setValue,
        getValues: getValues,
        reset: reset,
        formState: { errors } } = useForm({
        })

    const onSubmit2 = () => {
        let data = {
            id: getValues("id"),
            name: getValues("name"),
            state_id: stateId,
            type: getValues("type"),
            description: getValues("description"),
            document_type: getValues("document_type"),
        }
        dispatch(configActions.addEditDocumentChecklist(data));
        setModalOpen(false);

    }
    const addEditModal = (id) => {
        toggleModal()
        if (id == null) return reset();
        var document = documentList?.filter((document) => document?.id == id);
        document = document?.[0]
        console.log("document", document)
        setValue('name', document?.name)
        setValue('type', document?.type)
        setValue('description', document?.description)
        setValue('document_type', document?.document_type)
        setValue('id', document?.id)
    }
    const onDeleteDocument = (id) => {
        const confirm = window.confirm("Are you sure to Delete?");
        if (confirm) {
            dispatch(configActions.deleteDocumentChecklist(id));
        }
    };

    return (
        <Container fluid className="mt-2">
            <Card className="card bg-white p-4 mt-5">
                <h5>State*</h5>
                <Row>
                    <Col>
                        <select
                            disabled={true}
                            onChange={(e) => handleStateChange(e)}
                            className="form-control mb-2 "
                        >
                            {states.map((state) => {
                                return (
                                    <option
                                        selected={state.value == id}
                                        key={state.value}
                                        value={state.name}
                                    >
                                        {state.label}
                                    </option>
                                );
                            })}
                        </select>
                    </Col>
                    <Col>
                        <Button color="primary" onClick={() => {
                            toggleModal();
                            reset();
                        }}>Add New Document Type</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="mb-2 mt-5">
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Document Name</th>
                                        <th>File Type</th>
                                        <th>Document Type</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {documentList?.map((document, index) => (
                                        <tr key={index}>
                                            <td>{document.name}</td>
                                            <td>{document.type}</td>
                                            <td>{document?.document_type}</td>
                                            <td>
                                                <div className="d-flex justify-content-start align-items-center">
                                                    <Button
                                                        onClick={() => onDeleteDocument(document?.id)}
                                                        className="btn btn-sm btn-link text-danger bg-transparent border-0"
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </Button>
                                                    <Button
                                                        onClick={() => addEditModal(document?.id)}
                                                        color="primary"
                                                        className="btn-sm"
                                                    >
                                                        <i className="fas fa-pen"></i>
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            {documentList?.length <= 0 &&
                                <NotFound
                                    message="Documents Not Found."
                                />
                            }
                        </div>
                    </Col>
                </Row>
            </Card>
            <Modal isOpen={modalOpen} centered={true} className='modal-lg'>
                <Form>
                    <ModalHeader>
                        Add New Document Checklist
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label className='form-label' htmlFor={`name`}>
                                Name
                            </Label>
                            <input
                                type="text"
                                id="documentName"
                                {...register(`name`)}
                                className='form-control'
                            />
                            {errors?.name && <span className="text-danger">This field is required</span>}
                        </FormGroup>
                        <FormGroup>
                            <Label for="type">Document Type*</Label>
                            <select className='form-control' name="type" id="type" {...register("type", { required: true })}>
                                <option value=''>Select File Type</option>
                                <option value='image/png'>.png Image Format</option>
                                <option value='image/jpeg'>.jpeg Image Format</option>
                                <option value='image/svg+xml'>.svg Image Format</option>
                                <option value='application/pdf'>.pdf Format</option>
                                <option value='application/msword'>.doc Microsoft Word Format</option>
                                <option value='application/vnd.openxmlformats-officedocument.wordprocessingml.document'>.docx Microsoft Word Format</option>
                            </select>
                            {errors?.type && <span className="text-danger">This field is required</span>}
                        </FormGroup>
                        <FormGroup>
                            <Label for="document_type">Document Format*</Label>
                            <select className='form-control' name="document_type" id="document_type" {...register("document_type", { required: true })}>
                                <option value=''>Select Document Type</option>
                                <option value='Asset Pitch deck'>Asset Pitch deck</option>
                                <option value='Asset documents'>Asset documents</option>
                                <option value='SPV Legal Reports'>SPV Legal Reports</option>
                                <option value='Valuation'>Valuation</option>
                                <option value='Market research'>Market research</option>
                            </select>
                            {errors?.document_type && <span className="text-danger">This field is required</span>}
                        </FormGroup>
                        <FormGroup>
                            <Label className='form-label' htmlFor={`description`}>
                                Description
                            </Label>
                            <textarea
                                id="description"
                                rows={5} cols={10}
                                {...register(`description`)}
                                className='form-control'
                            />
                            {errors?.description && <span className="text-danger">This field is required</span>}
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => setModalOpen(!modalOpen)}>
                            Cancel
                        </Button>
                        <Button
                            onClick={() => onSubmit2()}
                            color="primary"
                        >
                            {"Save"}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </Container>
    );
};

export default AddEditDocumentChecklist;


{/* <hr></hr>
                            <h3> Add New Document Type </h3>
                            <Card style={{ padding: 20 }}>
                                <Row className="">
                                    <Col xs="12" md="6">
                                        <Label> Name of Document* </Label>
                                        <input
                                            value={document}
                                            onChange={handleDocumentChange}
                                            className="form-control mb-2 "
                                            type="text"
                                            placeholder="Name of the Document"
                                        />
                                    </Col>

                                    <Col xs="12" md="5">
                                        <Label> Select File Type* </Label>
                                        <select onChange={(e) => setForamt(e.target.value)} className="form-control mb-2 ms-1">
                                            <option value=''>Select File Type</option>
                                            <option value='image/png'>.png Image Format</option>
                                            <option value='image/jpeg'>.jpeg Image Format</option>
                                            <option value='image/svg+xml'>.svg Image Format</option>
                                            <option value='application/pdf'>.pdf Format</option>
                                            <option value='application/msword'>.doc Microsoft Word Format</option>
                                            <option value='application/vnd.openxmlformats-officedocument.wordprocessingml.document'>.docx Microsoft Word Format</option>
                                        </select>
                                    </Col>
                                    <Col xs="12" md="5">
                                        <Label> Select Document Type* </Label>
                                        <select onChange={(e) => setForamt(e.target.value)} className="form-control mb-2 ms-1">
                                            <option value=''>Select Document Type</option>
                                            <option value='Asset Pitch deck'>Asset Pitch deck</option>
                                            <option value='Asset documents'>Asset documents</option>
                                            <option value='SPV Legal Reports'>SPV Legal Reports</option>
                                            <option value='Market research'>Market research</option>
                                            <option value='Valuation'>Valuation</option>
                                        </select>
                                    </Col>

                                    <Col xs="12" md="12">
                                        <Label> Description </Label>
                                        <textarea
                                            value={description}
                                            onChange={e => setDescription(e.target.value)}
                                            className="form-control"
                                            rows={5} cols={10}
                                            placeholder="Enter Address">
                                        </textarea>
                                    </Col>
                                    <Col className="mt-3" xs="12" md="5" >
                                        <Button
                                            color='primary'
                                            disabled={document == '' || format == '' || description == ''}
                                            onClick={appendNewDocument}
                                            className='rz-button-primary mt-2'>
                                            <HiOutlinePlusCircle className="mr-2" style={{ fontSize: "20px" }} />  Add Document Type
                                        </Button>
                                    </Col>
                                </Row>
                            </Card> */}