import { useUpdateUnSubcribes } from 'hooks/useMeQuery';
import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Row, Col, Button, Form } from 'reactstrap';
import unsubscribe from './../../assets/img/unsubscribed.png';
import rightIcon from './../../assets/img/right-arrow.png';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
const Unsubscribe = () => {
    const { token } = useParams();
    const { mutate: updateSubcribe } = useUpdateUnSubcribes()
    const handleUnsubscribe = async () => {
        await updateSubcribe({
            token: token
        }, {
            onSuccess: (res) => {
                toast.success(res?.message);
            },
            onError: () => {
                
                toast.error("Invalid Unsubscribe Link.")
            }
        })
    };
    return (
        <div className="unsubscribe-wrapper">

            <div className='unsubscribe'>
                <Row>
                    <Col className='px-0 pt-4' xs='12'>
                        <div className='bg-white pt-5 d-flex justify-content-center'>
                            <img className='img-fluid' src={unsubscribe} />
                        </div>
                    </Col>
                    <Col className='px-0 pt-4 pb-5 bg-white' xs="12">
                        <h1 className='text-center  unsubscribed-heading'>Are you sure about unsubscribing?</h1>
                        <p className="text-center px-3 px-md-5 unsubscribed-text">
                            Thank you for being a part of our newsletter community. We have successfully processed your unsubscribe request.
                        </p>
                        <div className='mt-5 pb-2'>
                            <Form className='d-flex justify-content-center'>
                                    <Button  className='btn unsubscribe-btn' onClick={() => handleUnsubscribe()}>
                                        Unsubscribe me
                                    </Button>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>

    );
};
export default Unsubscribe;
