import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Badge, Button, Card, Col, Container, NavLink, Row, Table } from "reactstrap";
import { orderActions } from "redux/actions";
import { moneyFormat } from "utils";
import { FinalAgreement, final_agreement_css } from "../../components/AdminDocs/FinalAgreement";
import { toast } from "react-toastify";
import { api } from '../../helpers'
import CalendarIcon from '../../assets/img/calendar-icon.png'
import DollarIcon from '../../assets/img/dollar-icon.png'
import PdfImg from '../../assets/img/PDF.png'
import checkIcon from './../../assets/img/icons/common/check.svg';
import checkGrenIcon from './../../assets/img/icons/common/check-green.svg';
import { checkNotChannelPartner } from "utils";
import { ChainBasedRouting } from "utils";

const SingleOrder = () => {
  const { id } = useParams();
  const { singleOrder, feedback } = useSelector((state) => state.getSingleOrderReducer);
  const dispatch = useDispatch();
  const [file, setFile] = React.useState("")

  const [paymentTime, setPaymentTime] = React.useState({ day: "", hour: "", minute: "", second: "" });
  const [documentTime, setDocumentTime] = React.useState({ day: "", hour: "", minute: "", second: "" });
  const [bookingTime, setBookingTime] = React.useState({ day: "", hour: "", minute: "", second: "" });
  const [paymentSummery, setpaymentSummery] = React.useState({});
  const [tab, setTab] = React.useState({});
  const [plot, setPlot] = React.useState({});

  useEffect(() => {
    dispatch(orderActions.getSingleOrder(id));
  }, [id]);

  useEffect(() => {
    if (singleOrder) {
      api.get(`/order/getPlotByPlotId/${singleOrder?.plot_id}`).then((res) => {
        setPlot(res.data);
      });

      const parseTime = (timeString) => {
        const defaultTime = "24:00:00:00";
        return timeString ? timeString.split(":") : defaultTime.split(":");
      };

      const setParsedTime = (stateSetter, timeArray) => {
        stateSetter({
          day: parseInt(timeArray[0] || 0),
          hour: parseInt(timeArray[1] || 0),
          minute: parseInt(timeArray[2] || 0),
          second: parseInt(timeArray[3] || 0)
        });
      };

      setParsedTime(setPaymentTime, parseTime(singleOrder?.full_payment_deadline_time));
      setParsedTime(setDocumentTime, parseTime(singleOrder?.document_deadline_time));
      setParsedTime(setBookingTime, parseTime(singleOrder?.investment_deadline_time));
    }
  }, [singleOrder]);


  useEffect(() => {
    const paymentSummeryTemp = singleOrder?.payment_transection?.filter((d) => d.type == "Order Booking");
    if (paymentSummeryTemp?.length > 0) {
      setpaymentSummery(paymentSummeryTemp?.[0])
    }
  }, [singleOrder]);



  useEffect(() => {
    if (tab == "Order Booking") {
      const paymentSummeryTemp = singleOrder?.payment_transection?.filter((d) => d.type == "Order Booking");
      if (paymentSummeryTemp?.length > 0) {
        setpaymentSummery(paymentSummeryTemp?.[0])
      }
    }
    if (tab == "Order Full Payment") {
      const paymentSummeryTemp = singleOrder?.payment_transection?.filter((d) => d.type == "Order Full Payment");
      if (paymentSummeryTemp?.length > 0) {
        setpaymentSummery(paymentSummeryTemp?.[0])
      } else {
        setpaymentSummery({})
      }
    }
  }, [tab]);
  const statusUpdate = (e) => {
    const updatedData = {
      order_id: singleOrder.id,
      status: e.target.value,
    };
    if (e.target.value !== "Status") {
      const confirm = window.confirm(
        `Are You sure to Change the status to ${e.target.value}? `
      );

      confirm
        ? dispatch(orderActions.updateOrderStatus(updatedData, singleOrder.id))
        : (e.target.value = "Status");
    }
  };
  const refundOrder = () => {
    const updatedData = {
      order_id: singleOrder.id,
    };
    const confirm = window.confirm(
      `Are You sure to Refund This Order? `
    );
    if (confirm) {
      dispatch(orderActions.updateOrderRefund(updatedData, singleOrder.id))
    }
  };
  function handlePaymentDeadline(e, time) {
    e.preventDefault();
    let data = {
      payment_time: `${time.day}:${time.hour}:${time.minute}:${time.second}`
    };
    api
      .post(`/order/update-order-time-status/${singleOrder?.id}`, data)
      .then(() => {
        document.getElementById("payment-deadline-modal").click();
      })
      .catch((err) => toast.error(err));
  }

  function handleDocumentTime(e, time) {
    e.preventDefault();
    let data = {
      document_sign_time: `${time.day}:${time.hour}:${time.minute}:${time.second}`
    };
    api
      .post(`/order/update-order-time-status/${singleOrder?.id}`, data)
      .then(() => {
        document.getElementById("document-deadline-modal").click();
      })
      .catch((err) => toast.error(err));
  }

  async function handleBookingTime(e, time) {
    e.preventDefault();
    let data = {
      investment_deadline_time: `${time.day}:${time.hour}:${time.minute}:${time.second}`
    };
    await api
      .post(`/order/update-order-time-status/${singleOrder?.id}`, data)
      .then(() => {
        document.getElementById("booking-deadline-modal").click();
      })
      .catch((err) => toast.error(err));
  }
  const handleDeadlineChange = (type, value, modalType) => {
    switch (modalType) {
      case "payment":
        setPaymentTime((prevTime) => ({
          ...prevTime,
          [type]: value,
        }));
        break;
      case "document":
        setDocumentTime((prevTime) => ({
          ...prevTime,
          [type]: value,
        }));
        break;
      case "booking":
        setBookingTime((prevTime) => ({
          ...prevTime,
          [type]: value,
        }));
        break;
      default:
        break;
    }
  };
  const DeadlineModal = ({ id, title, handleDeadlineChange, handleDeadlineSubmit, deadlineTime }) => (
    <div className="modal fade" id={id} tabIndex="-1" role="dialog" aria-labelledby={`${id}Title`} aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title" id="exampleModalLongTitle">{title} Deadline</h3>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form onSubmit={(e) => handleDeadlineSubmit(e, deadlineTime)}>
            <div className="modal-body border-bottom">
              <div className="d-flex">
                <div className="form-group pr-3">
                  <label className="text-muted FW-12 mb-1">Days *</label>
                  <input required value={deadlineTime?.day} onChange={(e) => handleDeadlineChange("day", e.target.value)} type="number" className="form-control" />
                </div>
                <div className="form-group pr-3">
                  <label className="text-muted FW-12 mb-1">Hours *</label>
                  <input required value={deadlineTime?.hour} onChange={(e) => handleDeadlineChange("hour", e.target.value)} type="number" className="form-control" />
                </div>
                <div className="form-group pr-3">
                  <label className="text-muted FW-12 mb-1">Minutes *</label>
                  <input required value={deadlineTime?.minute} onChange={(e) => handleDeadlineChange("minute", e.target.value)} type="number" className="form-control" />
                </div>
                <div className="form-group">
                  <label className="text-muted FW-12 mb-1">Seconds *</label>
                  <input required value={deadlineTime?.second} onChange={(e) => handleDeadlineChange("second", e.target.value)} type="number" className="form-control" />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-outline-secondary border-primary" data-dismiss="modal">
                Cancel
              </button>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );

  function DownloadDoc1(order) {
    document.getElementById('first-party-loop').innerHTML = "";
    document.getElementById('second-party-loop-div').innerHTML = "";
    getPropertyDetail()
  }
  // const [priceBreakupEdit, setPriceBreakupEdit] = React.useState(false)
  // const [fee, setFee] = React.useState([])

  // function changePercent(value, name) {
  //   let updated = [];
  //   for (let i = 0; i < fee.length; i++) {
  //     if (fee[i].name == name) {
  //       updated.push({ ...fee[i], percent: value })
  //     } else {
  //       updated.push(fee[i])
  //     }
  //   }
  //   setFee(updated)
  // }
  function fetchAmountFromPercent(percent, order) {
    let each_percent_amount = parseFloat(order?.total_amount) / 100;
    return (each_percent_amount * parseFloat(percent)).toFixed(2)
  }
  // function handleSubmitBreakup() {
  //   if (fee.length > 0) {
  //     let data = {
  //       fee: JSON.stringify(fee)
  //     }
  //     api.post(`/order/update-price-breakup-order/${singleOrder?.id}`, data).then(() => {
  //       let currentTotal = parseFloat(singleOrder?.total_amount).toFixed(2)
  //       fee?.map((item, index) => {
  //         currentTotal = parseFloat(currentTotal) + parseFloat(fetchAmountFromPercent(singleOrder?.total_amount, item?.value))
  //       })
  //       setPriceBreakupEdit(false)
  //     }).catch(err => console.log(err))
  //   }
  // }
  function getPropertyDetail() {
    async function getAllDocInformation() {
      api.get(`/order/fetchAllDocInformation/${singleOrder?.property_id}/${singleOrder?.plot_id}`).then(response => {
        let data = response.data;
        let first_party_loop_html = '';
        let second_party_loop_div = '';
        let third_party_loop = '';
        let fourth_party_loop = '';
        if (data && data?.orders?.length > 0) {
          data?.orders?.map((item, index) => {
            first_party_loop_html = first_party_loop_html + `<p>(${index + 1}) <span class="text-capitalize font-weight-bold">${item?.user?.name}</span> S/o <span class="font-weight-bold text-capitalize">${item?.user?.father_name ? item?.user?.father_name : 'Dummy Text'}</span> . R/o ${item?.user?.address ? item?.user?.address : 'Asset 7, AT Complex, Noida, Delhi 3100536'}. which expression shall,
            unless it be repugnant to the subject or context thereof, include their legal heirs, successors,
            nominees and permitted assignees and hereinafter called the as
            designated partner, </p>`

            second_party_loop_div = second_party_loop_div + `<div class="d-flex">
            <div class="col-md-6 mb-5">
                <p>(_______________, Designated Partner)</p>
                <p class='mt-0 pt-0'>${item?.user?.name}</p>
            </div>
            <div class="col-md-6 mb-5">
                <p>Witnesses:</p>
                <p>Name: ………………….</p>
                <p>Address: ……………………………………….</p>
                <p>Signature: …… ………………………………..</p>
            </div>
            </div>`

            third_party_loop = third_party_loop + `<p> ${index + 1} Party ${item?.user?.percentage ? item?.user?.percentage : 50}% i.e. Rs ${item?.total_amount} (Rupees ……………………. only)</p>`
            fourth_party_loop = fourth_party_loop + `<p>${index + 1} Party- ${item?.user?.percentage ? item?.user?.percentage : 50}%</p>`
          })
        }

        document.getElementById('first-party-loop').innerHTML = first_party_loop_html;
        document.getElementById('second-party-loop-div').innerHTML = second_party_loop_div
        document.getElementById('third-party-loop').innerHTML = third_party_loop
        document.getElementById('fourth-party-loop').innerHTML = fourth_party_loop
        document.getElementById('llp_contribution').innerHTML = data?.llp_contribution


        let llp_name_element = document.getElementsByClassName('llp_name');
        let llp_name_address = document.getElementsByClassName('llp_address');

        for (let i = 0; i < llp_name_element.length; i++) {
          llp_name_element[i].innerHTML = data?.llp_name
        }


        for (let i = 0; i < llp_name_address.length; i++) {
          llp_name_address[i].innerHTML = data?.llp_address
        }

        let html = final_agreement_css;
        html = html + " " + document.getElementById('final_agreement_pdf').innerHTML;
        var myWindow = window.open('', 'final_agreement_pdf');
        myWindow.document.write('<html><head><title>final_agreement_pdf</title>');
        myWindow.document.write('<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css" integrity="sha384-zCbKRCUGaJDkqS1kPbPd7TveP5iyJE0EjAuZQTgFLD2ylzuqKfdKlfG/eSrtxUkn" crossorigin="anonymous">')
        myWindow.document.write("</head><body>");
        myWindow.document.write(html);
        myWindow.document.write('</body></html>');
        myWindow.document.close();

        myWindow.onload = function () {
          myWindow.focus();
          myWindow.print();
        }



      }).catch(err => {
        console.log(err)
      });
    }

    getAllDocInformation()
  }

  function submitImage(income_file) {
    setFile(income_file)
    if (income_file && singleOrder) {
      const formData = new FormData();
      formData.append("cover_image_legal", income_file)
      dispatch(orderActions.updateCoverImage(formData, singleOrder))
    } else {
      toast.error("Please select an image to upload");
    }
  }

  function copyFunction(id) {
    /* Get the text field */
    var copyText = document.getElementById(id);

    /* Select the text field */
    copyText.focus()
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    document.execCommand('copy')

    /* Alert the copied text */
  }

  function calculateOwnershipPercentage() {
    if (singleOrder && plot) {
      if (plot.investment_type == "Fractional") {
        let result = parseFloat(singleOrder?.no_sqyds) / parseFloat(plot.no_sqyds)
        return parseFloat(parseFloat(result) * 100).toFixed(2)
      }

      if (plot.investment_type == "Co-Ownership") {
        let result = parseFloat(singleOrder?.no_units) / parseFloat(plot.no_units)
        return parseFloat(parseFloat(result) * 100).toFixed(2)
      }
    }
  }
  function checkBadge() {
    console.log(Object?.values(paymentSummery)?.length)
    return (singleOrder?.status == "Cancelled") ? (
      <Badge
        className="rz_paymemt_status"
      >Refund Requested</Badge>
    ) :
      singleOrder?.status == "Refunded" ? (
        <Badge
          className="rz_paymemt_status_success"
        >Refunded</Badge>
      ) :
        singleOrder?.status == "Refund failed" ?
          (
            <Badge
              className="rz_paymemt_status"
            >
              Refund failed
            </Badge>
          ) : singleOrder?.status == "Failed" ?
            (
              <Badge
                className="rz_paymemt_status_faild"
              >
                Failed
              </Badge>
            ) :
            Object?.values(paymentSummery)?.length > 0 ?
              <p className="mb-0 status">{paymentSummery?.status}</p> : <></>
  }
  return (
    <div>
      <FinalAgreement order={singleOrder} />
      <Container className="mt-5" fluid>
        <div className="row">
          <div className="col-lg-6 mt-4" style={{ minHeight: "265px" }}>
            <Card className="p-3 singleOrder-detail-card1">
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <img className="mr-3" src={CalendarIcon} alt="" />
                  <div>
                    <p className="mb-n1 font-weight-bold">  {moneyFormat(singleOrder?.total_amount)}</p>
                    <small>Order amount</small>
                  </div>
                </div>

                <div className="d-flex align-items-center">
                  <img className="mr-3" src={CalendarIcon} alt="" />
                  <div>
                    <p className="mb-n1 font-weight-bold">{new Date(singleOrder?.created_at).toDateString()}</p>
                    <small>Booked Date & Time</small>
                  </div>
                </div>

                <div className="d-flex align-items-center">
                  <img className="mr-3 book-amt-img" src={DollarIcon} alt="" />
                  <div>
                    <p className="mb-n1 font-weight-bold">  {moneyFormat(singleOrder?.booking_amount)}</p>
                    <small>Booking Amount</small>
                  </div>
                </div>
              </div>

              <div className="px-3">
                <div className="row mt-4 pb-2 border-bottom">
                  <div className="col-md-6 pl-0 d-flex align-items-center">
                    <i class="fa fa-globe mr-2" aria-hidden="true"></i>
                    <h5 className="mb-0">Total Order Value</h5>
                  </div>

                  <div className="col-md-6 pr-0">
                    {moneyFormat(singleOrder?.total_amountp)}
                  </div>
                </div>
              </div>

              <div className="px-3">
                <div className="row mt-2 pb-2 border-bottom">
                  <div className="col-md-6 pl-0 d-flex align-items-center">
                    <i class="fa fa-user mr-2" aria-hidden="true"></i>
                    <h5 className="mb-0">Ownership Percentage</h5>
                  </div>

                  <div className="col-md-6 pr-0">
                    {singleOrder && calculateOwnershipPercentage()}
                  </div>
                </div>
              </div>

              <div className="px-3">
                <div className="row mt-2 pb-2 border-bottom">
                  <div className="col-md-6 pl-0 d-flex align-items-center">
                    <i class="fa fa-star mr-2" aria-hidden="true"></i>
                    <h5 className="mb-0">No of Tokens</h5>
                  </div>

                  <div className="col-md-6 pr-0">
                    {singleOrder?.no_sqyds}
                  </div>
                </div>
              </div>

              <div className="px-3">
                <div className="row mt-2 pb-2">
                  <div className="col-md-6 pl-0 d-flex align-items-center">
                    <i class="fa fa-star mr-2" aria-hidden="true"></i>
                    <h5 className="mb-0">Order Status</h5>
                  </div>

                  <div className="col-md-6 pr-0">
                    <a>{
                      singleOrder?.total_amount < 0 ?
                        singleOrder?.status_level == 1 ? "Relist Requested" :
                          singleOrder?.status_level == 2 ? "Approve Relist" :
                                singleOrder?.status
                        : singleOrder?.status
                    }</a>
                  </div>
                </div>
              </div>
            </Card>
          </div>

          <div className="col-lg-6 mt-4" style={{ minHeight: "265px" }}>
            {checkNotChannelPartner() && (
              <>
                <Card className="p-3 d-flex align-items-center flex-row">
                  <h3 className="mb-0 text-nowrap mr-5">Change order status</h3>
                  {
                    singleOrder?.total_amount < 0 ?
                      //relist Flow
                      <select onChange={statusUpdate}
                        defaultValue={singleOrder?.status}
                        className="custom-select">
                        <option value="Status"
                          selected={12 == singleOrder?.status_level}
                        >Status</option>
                        <option value="Relist requested"
                          disabled={1 <= singleOrder?.status_level}
                          selected={1 == singleOrder?.status_level}
                        >Relist requested</option>
                        <option value="Awaiting 100% Investment"
                          disabled={2 <= singleOrder?.status_level}
                          selected={2 == singleOrder?.status_level}
                        >Approve Relist</option>
                        <option value="Document signature Pending"
                          disabled={3 <= singleOrder?.status_level}
                          selected={3 == singleOrder?.status_level}
                        >Document signature Pending</option>
                        <option value="Awaiting document verification"
                          disabled={4 <= singleOrder?.status_level}
                          selected={4 == singleOrder?.status_level}
                        >Awaiting document verification</option>
                        <option value="Token Burn & Refunded"
                          disabled={singleOrder?.status_level > 4}
                          selected={17 == singleOrder?.status_level}
                        >Token Burn & Refunded</option>
                        <option value="Cancelled"
                          disabled={13 <= singleOrder?.status_level}
                          selected={13 == singleOrder?.status_level}
                        >Cancelled</option>
                      </select>
                      : singleOrder?.booking_amount == 0 ?
                        //full amount flow
                        <select onChange={statusUpdate} defaultValue={singleOrder?.status}
                          className="custom-select ">
                          <option value="Status">Status</option>
                          <option value="Awaiting 100% Investment"
                            disabled={1 <= singleOrder?.status_level}
                            selected={1 == singleOrder?.status_level}
                          >Awaiting 100% Investment</option>

                          <option value="Document signature Pending"
                            disabled={3 <= singleOrder?.status_level}
                            selected={3 == singleOrder?.status_level}
                          >Document signature Pending</option>

                          <option value="Awaiting document verification"
                            disabled={(singleOrder?.status_level >= 4)}
                            selected={4 == singleOrder?.status_level}
                          >Awaiting document verification</option>

                          <option value="Awaiting Token transfer"
                            disabled={(singleOrder?.token_transferred == true && singleOrder?.status_level == 8)}
                            selected={singleOrder?.status_level > 4}
                          >Transfer Token</option>

                          <option value="Cancelled"
                            disabled={13 <= singleOrder?.status_level}
                            selected={13 == singleOrder?.status_level}
                          >Cancelled</option>

                          <option value="Refund initiated"
                            selected={16 == singleOrder?.status_level}
                          >Refund initiated</option>

                          <option value="Refunded"
                            selected={17 == singleOrder?.status_level}
                          >Refunded</option>

                        </select> :
                        <select onChange={statusUpdate} defaultValue={singleOrder?.status}
                          className="custom-select ">
                          <option value="Status">Status</option>
                          <option value="Pending Payment"
                            disabled={singleOrder?.status_level > 1}
                            selected={1 == singleOrder?.status_level}
                          >Pending Payment</option>

                          <option value="Booked"
                            disabled={1 <= singleOrder?.status_level}
                            selected={1 == singleOrder?.status_level}
                          >Booked</option>

                          {/* <option value="Awaiting 100% Investment"
                            selected={1 == singleOrder?.status_level}
                          >Awaiting 100% Investment</option> */}

                          <option value="Document signature Pending"
                            disabled={2 <= singleOrder?.status_level}
                            selected={2 == singleOrder?.status_level}
                          >Document signature Pending</option>

                          <option value="Awaiting document verification"
                            disabled={(singleOrder?.status_level >= 4)}
                            selected={4 == singleOrder?.status_level}
                          >Awaiting document verification</option>

                          <option value="Full payment pending"
                            disabled={(singleOrder?.status_level >= 5)}
                            selected={5 == singleOrder?.status_level}
                          >Full payment pending</option>

                          <option value="Awaiting payment confirmation"
                            disabled={6 <= singleOrder?.status_level}
                            selected={6 == singleOrder?.status_level}
                          >Awaiting payment confirmation</option>

                          <option value="Awaiting Token transfer"
                            disabled={(singleOrder?.token_transferred == true)}
                          >Token Transfer</option>

                          <option value="Cancelled"
                            disabled={13 <= singleOrder?.status_level}
                            selected={13 == singleOrder?.status_level}
                          >Cancelled</option>

                          <option value="Refund initiated"
                            selected={16 == singleOrder?.status_level}
                          >Refund initiated</option>

                          <option value="Refunded"
                            selected={17 == singleOrder?.status_level}
                          >Refunded</option>
                        </select>
                  }
                </Card>
                <Card className="p-3 legal-copies-status mt-2">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="mb-0">Upload First Legal Copy</h4>
                    <div className="upload-copy-icons">
                      {/* Booking deadline modal */}
                      <button type="button" class="modal-btn" data-toggle="modal" data-target="#booking-deadline">
                        <i class="fa fa-bell" aria-hidden="true"></i>
                      </button>
                      <div class="modal fade" id="booking-deadline" tabindex="-1" role="dialog" aria-labelledby="Doc-deadlineTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h3 class="modal-title" id="exampleModalLongTitle">Securing Payment Deadline</h3>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <form onSubmit={e => handleBookingTime(e, bookingTime)}>
                              <div class="modal-body border-bottom">
                                <div className="d-flex">
                                  <div class="form-group pr-3">
                                    <label className="text-muted FW-12 mb-1">Day *</label>
                                    <input value={parseInt(bookingTime?.day)}
                                      onChange={(e) =>
                                        setBookingTime({
                                          ...bookingTime, day: e.target.value
                                        })}
                                      required type="number" class="form-control" />
                                  </div>
                                  <div class="form-group pr-3">
                                    <label className="text-muted FW-12 mb-1">Hours *</label>
                                    <input value={parseInt(bookingTime?.hour)}
                                      onChange={(e) =>
                                        setBookingTime({
                                          ...bookingTime, hour: e.target.value
                                        })}
                                      required type="number" class="form-control" />
                                  </div>
                                  <div class="form-group pr-3">
                                    <label className="text-muted FW-12 mb-1">Minutes *</label>
                                    <input value={parseInt(bookingTime?.minute)}
                                      onChange={(e) =>
                                        setBookingTime({
                                          ...bookingTime, minute: e.target.value
                                        })}
                                      required type="number" class="form-control" />
                                  </div>
                                  <div class="form-group">
                                    <label className="text-muted FW-12 mb-1">Seconds *</label>
                                    <input value={parseInt(bookingTime?.second)} onChange={(e) =>
                                      setBookingTime({
                                        ...bookingTime, second: e.target.value
                                      })}
                                      required type="number" class="form-control" />
                                  </div>
                                </div>
                              </div>
                              <div class="modal-footer">
                                <button id="booking-deadline-modal" type="button" class="btn btn-outline-secondary border-primary" data-dismiss="modal">Cancel</button>
                                <button type="submit" class="btn btn-primary">Submit</button>
                              </div>
                            </form>

                          </div>
                        </div>
                      </div>
                      {/* Document deadline modal */}
                      <button type="button" class="modal-btn" data-toggle="modal" data-target="#Doc-deadline">
                        <i class="fa fa-clock" aria-hidden="true"></i>
                      </button>
                      <div class="modal fade" id="Doc-deadline" tabindex="-1" role="dialog" aria-labelledby="Doc-deadlineTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h3 class="modal-title" id="exampleModalLongTitle">Document Deadline</h3>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <form onSubmit={e => handleDocumentTime(e, documentTime)}>
                              <div class="modal-body border-bottom">
                                <div className="d-flex">
                                  <div class="form-group pr-3">
                                    <label className="text-muted FW-12 mb-1">Day *</label>
                                    <input value={parseInt(documentTime?.day)}
                                      onChange={(e) =>
                                        setDocumentTime({
                                          ...documentTime, day: e.target.value
                                        })}
                                      required type="number" class="form-control" />
                                  </div>
                                  <div class="form-group pr-3">
                                    <label className="text-muted FW-12 mb-1">Hours *</label>
                                    <input value={parseInt(documentTime?.hour)}
                                      onChange={(e) =>
                                        setDocumentTime({
                                          ...documentTime, hour: e.target.value
                                        })}
                                      required type="number" class="form-control" />
                                  </div>
                                  <div class="form-group pr-3">
                                    <label className="text-muted FW-12 mb-1">Minutes *</label>
                                    <input value={parseInt(documentTime?.minute)}
                                      onChange={(e) =>
                                        setDocumentTime({
                                          ...documentTime, minute: e.target.value
                                        })}
                                      required type="number" class="form-control" />
                                  </div>
                                  <div class="form-group">
                                    <label className="text-muted FW-12 mb-1">Seconds *</label>
                                    <input value={parseInt(documentTime?.second)} onChange={(e) =>
                                      setDocumentTime({
                                        ...documentTime, second: e.target.value
                                      })}
                                      required type="number" class="form-control" />
                                  </div>
                                </div>
                              </div>
                              <div class="modal-footer">
                                <button id="document-deadline-modal" type="button" class="btn btn-outline-secondary border-primary" data-dismiss="modal">Cancel</button>
                                <button type="submit" class="btn btn-primary">Submit</button>
                              </div>
                            </form>

                          </div>
                        </div>
                      </div>
                      {/* payment deadline modal */}
                      <button type="button" class="modal-btn" data-toggle="modal" data-target="#payment-deadline">
                        <i class="fa fa-inr" aria-hidden="true"></i>
                      </button>
                      <div class="modal fade" id="payment-deadline" tabindex="-1" role="dialog" aria-labelledby="payment-deadlineTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h3 class="modal-title" id="exampleModalLongTitle">Payment Deadline</h3>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <form onSubmit={(e) => handlePaymentDeadline(e, paymentTime)}>
                              <div class="modal-body border-bottom">
                                <div className="d-flex">
                                  <div class="form-group pr-3">
                                    <label className="text-muted FW-12 mb-1">Day *</label>
                                    <input value={parseInt(paymentTime?.day)}
                                      onChange={(e) =>
                                        setPaymentTime({
                                          ...paymentTime, day: e.target.value
                                        })}
                                      required type="number" class="form-control" />
                                  </div>
                                  <div class="form-group pr-3">
                                    <label className="text-muted FW-12 mb-1">Hours *</label>
                                    <input value={parseInt(paymentTime?.hour)}
                                      onChange={(e) =>
                                        setPaymentTime({
                                          ...paymentTime, hour: e.target.value
                                        })}
                                      required type="number" class="form-control" />
                                  </div>
                                  <div class="form-group pr-3">
                                    <label className="text-muted FW-12 mb-1">Minutes *</label>
                                    <input value={parseInt(paymentTime?.minute)}
                                      onChange={(e) =>
                                        setPaymentTime({
                                          ...paymentTime, minute: e.target.value
                                        })}
                                      required type="number" class="form-control" />
                                  </div>
                                  <div class="form-group">
                                    <label className="text-muted FW-12 mb-1">Seconds *</label>
                                    <input value={parseInt(paymentTime?.second)} onChange={(e) =>
                                      setPaymentTime({
                                        ...paymentTime, second: e.target.value
                                      })}
                                      required type="number" class="form-control" />
                                  </div>
                                </div>
                              </div>
                              <div class="modal-footer">
                                <button id="payment-deadline-modal" type="button" class="btn btn-outline-secondary border-primary" data-dismiss="modal">Cancel</button>
                                <button type="submit" class="btn btn-primary">Submit</button>
                              </div>
                            </form>

                          </div>
                        </div>
                      </div>
                      {/* Signature URL modal */}
                      {/* <button type="button" class="modal-btn mr-0" data-toggle="modal" data-target="#sign-URL">
                        <i class="fa fa-file mr-0" aria-hidden="true"></i>
                      </button>
                      <div class="modal fade" id="sign-URL" tabindex="-1" role="dialog" aria-labelledby="sign-URLTitle" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h3 class="modal-title" id="exampleModalLongTitle">Signature URL</h3>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body border-bottom">
                              <div class="form-group">
                                <label className="text-muted FW-14">URL *</label>
                                <input type="text" class="form-control" placeholder="Paste URL here" />
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-outline-secondary border-primary" data-dismiss="modal">Cancel</button>
                              <button type="button" class="btn btn-primary">Submit</button>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>

                  <div className="d-flex align-items-end justify-content-between mt-3">
                    <div>
                      <small className="text-muted mb-1">Upload Doc</small>
                      <input type="file" class="form-control d-none" placeholder="Choose file" onChange={(e) => submitImage(e.target.files[0])} id="order-detail-doc-upload" />
                      <div className="border d-flex align-items-center justify-content-between order-detail-doc-upload" onClick={() => document.getElementById('order-detail-doc-upload').click()}>
                        <small className="px-3">Choose file</small>
                        <p className="browseBTN mb-0">Browse</p>
                      </div>
                    </div>
                    <button onClick={() => DownloadDoc1(singleOrder)} className="btn btn-primary mt-2" >Download PDF</button>
                  </div>
                  {file && <small id="emailHelp" class="form-text d-flex mt-2"><img className="mr-2" src={PdfImg} alt="" /> {file?.name} <p style={{ cursor: 'pointer' }} onClick={() => setFile("")} className="mb-0 text-danger FW-14 ml-4">Delete</p></small>}

                </Card>
              </>
            )}
          </div>
        </div>

        <Card className="p-3 mt-4">
          <ul class="nav nav-tabs order-detail-nav" id="myTab" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" id="OrderOverview-tab" data-toggle="tab" href="#OrderOverview" role="tab" aria-controls="OrderOverview" aria-selected="true">Order Overview</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="document-tab" data-toggle="tab" href="#document" role="tab" aria-controls="document" aria-selected="false">Documents</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="transactions-tab" data-toggle="tab" href="#transactions" role="tab" aria-controls="transactions" aria-selected="false">Transactions</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="timeline-tab" data-toggle="tab" href="#timeline" role="tab" aria-controls="timeline" aria-selected="false">Timeline</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="property-tab" data-toggle="tab" href="#property" role="tab" aria-controls="property" aria-selected="false">Feedback</a>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active mt-3" id="OrderOverview" role="tabpanel" aria-labelledby="OrderOverview-tab">
              <div className="row">
                <div className="col-md-4">
                  <h5 className="mt-3">Invoice To:</h5>
                  <div className="d-flex align-items-center">
                    {singleOrder?.user?.avatar && <img className="profile-img mr-2" src={singleOrder?.user?.avatar} alt="" />}
                    <div>
                      <p className="FW-14 mb-0 font-weight-bold mb-n1">{singleOrder?.user?.name}</p>
                      <p className="mb-0 FW-10">DOB: 02 July 1998</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mt-3">
                    <i class="fa fa-envelope FW-14 ml-2 pl-1 mr-4" aria-hidden="true"></i>
                    <p className="mb-0 FW-14">{singleOrder?.user?.email}</p>
                  </div>

                  <div className="d-flex align-items-center mt-2">
                    <i class="fa fa-phone FW-14 ml-2 pl-1 mr-4" aria-hidden="true"></i>
                    <p className="mb-0 FW-14">{singleOrder?.user?.phone}</p>
                  </div>

                  <div className="d-flex align-items-baseline mt-2">
                    <i class="fa fa-map-marker FW-14 ml-2 pl-1 mr-4" aria-hidden="true"></i>
                    <p className="mb-0 FW-14">{singleOrder?.user?.address} </p>
                  </div>
                  <div className="d-flex align-items-baseline mt-2">
                    <i class="fa fa-envelope FW-14 ml-2 pl-1 mr-4" aria-hidden="true"></i>
                    <p className="mb-0 FW-14">{singleOrder?.user?.wallet_address} </p>
                  </div>

                </div>

                <div className="col-md-4">
                  <h5 className="mt-3">Invoice From:</h5>
                  <div className="d-flex align-items-center">
                    <i class="fa fa-envelope FW-14 mr-4" aria-hidden="true"></i>
                    <p className="mb-0 FW-14">{singleOrder?.property?.LLPCompany?.name}</p>
                  </div>
                  <div className="d-flex align-items-center mt-3">
                    <i class="fa fa-envelope FW-14 mr-4" aria-hidden="true"></i>
                    <p className="mb-0 FW-14">{singleOrder?.property?.LLPCompany?.email}</p>
                  </div>

                  <div className="d-flex align-items-center mt-2">
                    <i class="fa fa-phone FW-14 mr-4" aria-hidden="true"></i>
                    <p className="mb-0 FW-14">{singleOrder?.property?.LLPCompany?.phone}</p>
                  </div>

                  <div className="d-flex align-items-baseline mt-2">
                    <i class="fa fa-map-marker FW-14 mr-4" aria-hidden="true"></i>
                    <p className="mb-0 FW-14">{singleOrder?.property?.LLPCompany?.address} </p>
                  </div>
                </div>
                {
                  parseFloat(singleOrder?.total_amount) > 0 &&
                  (<div className="col-md-4">
                    <div className="payment-summary-div">
                      <ul class="nav nav-tabs justify-content-around py-1" id="myTab" role="tablist">
                        <li class="nav-item">
                          <a class="nav-link active FW-14 font-weight-bold"
                            onClick={() => {
                              setTab("Order Booking")
                            }}
                            id="Booking-tab" data-toggle="tab" href="#Booking" role="tab" aria-controls="Booking" aria-selected="true">
                            {singleOrder?.booking_amount > 0 ? "Booking" : "Full amount"}
                          </a>
                        </li>
                        {((singleOrder?.booking_amount > 0 && singleOrder?.status_level >= 6)) &&
                          <li class="nav-item">
                            <a class="nav-link FW-14 font-weight-bold"
                              onClick={() => setTab("Order Full Payment")}
                              id="FullAmt-tab" data-toggle="tab" href="#FullAmt" role="tab" aria-controls="FullAmt" aria-selected="false">Full amount</a>
                          </li>}
                      </ul>
                      <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active p-3"
                          id="Booking"
                          role="tabpanel"
                          aria-labelledby="Booking-tab">
                          <div className="d-flex align-items-center justify-content-between">
                            <h4 className="mb-0">Payment Summary</h4>
                            {
                              checkBadge()
                            }
                          </div>
                          <div className="d-flex justify-content-between mt-3">
                            <p className="mb-0 FW-14 font-weight-400">Total:</p>
                            <p className="mb-0 FW-14 font-weight-400">{moneyFormat(
                              singleOrder?.booking_amount > 0 ?
                                singleOrder?.booking_amount : singleOrder?.total_amountp)}</p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="mb-0 FW-14 font-weight-400">Payment ID:</p>
                            <p className="mb-0 FW-14 font-weight-400">{paymentSummery?.razorpay_order_id}</p>
                          </div>
                        </div>
                        <div class="tab-pane fade p-3" id="FullAmt"
                          role="tabpanel" aria-labelledby="FullAmt-tab">
                          <div className="d-flex align-items-center justify-content-between">
                            <h4 className="mb-0">Payment Summary</h4>
                            {
                              checkBadge()
                            }
                          </div>
                          <div className="d-flex justify-content-between mt-3">
                            <p className="mb-0 FW-14 font-weight-400">Total:</p>
                            <p className="mb-0 FW-14 font-weight-400">{moneyFormat(singleOrder?.total_amountp - singleOrder?.booking_amount)}</p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="mb-0 FW-14 font-weight-400">Payment ID:</p>
                            <p className="mb-0 FW-14 font-weight-400">{paymentSummery?.razorpay_order_id}</p>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">

                        {
                          (singleOrder?.status == "Cancelled" || singleOrder?.status == "Refund failed") ? (
                            <button
                              onClick={() => refundOrder()}
                              className="payment_suceess_btn">
                              <img className="mr-2" src={checkIcon} />
                              <span>Approve refund</span>
                            </button>
                          ) :
                            singleOrder?.status == "Refunded" ? (
                              <div className="payment_suceess_outline">
                                <img className="mr-2" src={checkGrenIcon} />
                                <span>Refund Approved</span>
                              </div>
                            ) : <></>
                        }
                      </div>
                    </div>
                  </div>)
                }
              </div>

              <div className="table-responsive mt-3">
                <table class="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">PROPERTY</th>
                      <th scope="col">TOKENS</th>
                      <th scope="col">TOKEN PRICE</th>
                      <th scope="col">TOTAL PRICE</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr>
                      <th>
                        <div className="d-flex flex-column justify-content-between" style={{ minHeight: '12rem' }}>
                          <div>
                            <p className="mb-0 text-primary FW-14">{singleOrder?.property?.name}</p>
                            <p className="mb-0 FW-14">
                              {singleOrder?.property?.location?.address} ,
                              {singleOrder?.property?.location?.city},
                              {singleOrder?.property?.location?.country}</p>
                          </div>
                          <div className="d-flex">
                            <div className="ID-div d-flex align-items-center p-3 mr-4">
                              <div className="mr-5">
                                <h6 className="mb-0">Asset ID:</h6>
                                <p className="mb-0 font-weight-600">{singleOrder?.property_id}</p>
                                <input className="d-none" id="property-id-copy-p" type="text" value={singleOrder?.property_id} />
                              </div>
                              <i
                                style={{ cursor: 'pointer' }}
                                onClick={() => copyFunction("property-id-copy-p")} class="fa fa-clone text-primary" aria-hidden="true"></i>
                            </div>
                            <div className="ID-div d-flex align-items-center p-3">
                              <div className="mr-5">
                                <h6 className="mb-0">Plot/Flat ID:</h6>
                                <p className="mb-0 font-weight-600">{singleOrder?.plot_id}</p>
                                <input className="d-none" id="plot-id-copy-p" type="text" value={singleOrder?.plot_id} />
                              </div>
                              <i onClick={() => copyFunction("plot-id-copy-p")} class="fa fa-clone text-primary" aria-hidden="true"></i>
                            </div>
                            <div className="ID-div d-flex align-items-center p-3 ml-4">
                              <div className="mr-5">
                                <h6 className="mb-0">Status Level:</h6>
                                <p className="mb-0 font-weight-600">{singleOrder?.status_level}</p>
                                <input className="d-none" id="plot-id-copy-p" type="text" value={singleOrder?.status_level} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </th>
                      <td>{singleOrder?.no_sqyds}</td>
                      <td>{moneyFormat(singleOrder?.price)}</td>
                      <td>{moneyFormat(singleOrder?.total_amount)}</td>
                      <td>
                        <div className="price-break-div p-3">
                          <div className="d-flex align-items-center justify-content-between">
                            <h4 className="mb-0">Price Breakup</h4>
                            {/* {priceBreakupEdit ? <button type="button" onClick={() => handleSubmitBreakup()} className="btn btn-primary btn-sm">Submit</button> : <i style={{ cursor: 'pointer' }} onClick={() => setPriceBreakupEdit(true)} class="fa fa-pencil-square-o text-primary" aria-hidden="true"></i>} */}
                          </div>

                          <div className="d-flex justify-content-between mt-3">
                            <p className="mb-0 FW-12">Total Price:</p>
                            <p className="mb-0 FW-14 font-weight-400">{moneyFormat(singleOrder?.total_amount)}</p>
                          </div>
                          {singleOrder?.fees?.length > 0 && singleOrder?.fees?.map((item, index) => (

                            <div key={index} className="d-flex justify-content-between">
                              {item?.name != "Booking Amount" &&
                                <>
                                  <p className="mb-0 FW-12">{item?.name} ({item.value}%)</p>
                                  {/* {priceBreakupEdit ? <input className="form-control-sm w-50 border" type="text" value={item.value} onChange={e => changePercent(e.target.value, item.name)} /> : */}
                                  <p className="mb-0 FW-14 font-weight-400 mr-2">{moneyFormat(fetchAmountFromPercent(item.value, singleOrder, item?.name))}</p>
                                  {/* } */}
                                </>
                              }
                            </div>
                          ))}
                          <div className="d-flex justify-content-between">
                            <p className="mb-0 FW-14 font-weight-600">Total Amount:</p>
                            <p className="mb-0 font-weight-600">{moneyFormat(singleOrder?.total_amountp)}</p>
                          </div>
                          <br />
                          {singleOrder?.booking_amount > 0 &&
                            <div className="d-flex justify-content-between">
                              <p className="mb-0 FW-14 font-weight-600">Booking Amount</p>
                              <p className="mb-0 font-weight-600">{moneyFormat(singleOrder?.booking_amount)}</p>
                            </div>
                          }
                          <div>

                          </div>

                        </div>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
              <Col className="d-flex justify-content-end flex-wrap">
                {(singleOrder?.token_transferred == true) && (singleOrder?.status_level >= 6) ?
                  (<a
                    className="btn btn-primary mt-2"
                    href={`${singleOrder?.property?.blockchain_metadata?.blockExplorers?.default?.url}/${ChainBasedRouting(singleOrder?.property?.blockchain_metadata?.id)?.transection}/${singleOrder?.transaction_hash?.filter((hash) => hash.transaction == "Token Transferred")[0]?.hash}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Token Transfer on block explorer
                  </a>) :
                  (null)
                }
                {(singleOrder?.token_payment_is_done == true &&
                  (singleOrder?.status_level >= 6)) ?
                  (<a
                    className="btn btn-primary mt-2"
                    href={`${singleOrder?.property?.blockchain_metadata?.blockExplorers?.default?.url}/tx/${singleOrder?.transaction_hash?.filter((hash) => hash.transaction == "Token Burn")[0]?.hash}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Token Burn on block explorer
                  </a>) :
                  (null)
                }
                {(singleOrder?.indr_transferred == true &&
                  (singleOrder?.status_level >= 6)) ?
                  (<a
                    className="btn btn-primary mt-2"
                    href={`${singleOrder?.property?.blockchain_metadata?.blockExplorers?.default?.url}/tx/${singleOrder?.indrTransection?.[0]?.meta?.hash}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    INDR Token Transection on block explorer
                  </a>) :
                  (null)
                }
              </Col>
              <Col className="my-4">
                {(singleOrder?.transaction_failed_reason) &&
                  (singleOrder?.status_level == 11 || singleOrder?.status_level == 15) ?
                  (<p>
                    Asset Token Transection Failed Reason-: {"  "}
                    <b>
                      {singleOrder?.transaction_failed_reason}
                    </b>
                  </p>) :
                  (null)
                }
              </Col>
              <Col className="my-4">
                {(singleOrder?.indr_transaction_failed_reason) &&
                  (singleOrder?.status_level == 11 || singleOrder?.status_level == 15) ?
                  (<p>
                    INDR Token Transection Failed Reason-: {"  "}
                    <b>
                      {singleOrder?.indr_transaction_failed_reason}
                    </b>
                  </p>) :
                  (null)
                }
              </Col>
            </div>
            <div class="tab-pane fade" id="document" role="tabpanel" aria-labelledby="document-tab">
              <Table className="my-4" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Document Type</th>
                    <th>Upload</th>
                    <th className="text-right">ACTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  {singleOrder?.documentlist?.map((item, index) => (
                    <tr key={index}>
                      <td>{item?.document_name}</td>
                      <td>{item?.activity}</td>
                      <td className="text-right">
                        <Link
                          to={{
                            pathname: `#`,
                          }}
                          className=" btn btn-sm btn-primary"
                        >
                          <i className="fas fa-eye"></i>
                        </Link>

                        <Button
                          onClick={() => console.log("hy")}
                          color="primary"
                          className="btn-sm "
                        >
                          <i className="fas fa-trash    "></i>
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div class="tab-pane fade" id="transactions" role="tabpanel" aria-labelledby="transactions-tab">
              <Table className="my-4" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Transaction Id</th>
                    <th>Total Amount</th>
                    <th>Status</th>
                    <th>Type</th>
                    <th>Payment Date</th>
                  </tr>
                </thead>
                <tbody>
                  {singleOrder?.orderTransaction?.map((item, index) => (
                    <tr key={index}>
                      <td>{item?.id}</td>
                      <td>{item?.total_amount}</td>
                      <td>{
                        <Badge
                          color=""
                          className={`badge badge-pill badge-${item?.status == "Completed"
                            ? "success"
                            : "primary"
                            } mr-4`}
                        >
                          {item?.status}
                        </Badge>
                      }</td>
                      <td>{
                        <Badge
                          color=""
                          className={`badge badge-pill badge-${item?.method == "Refund"
                            ? "danger"
                            : "success"
                            } mr-4`}
                        >
                          {item?.method}
                        </Badge>
                      }</td>
                      <td>{new Date(item?.created_at).toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>

            </div>
            <div class="tab-pane fade" id="timeline" role="tabpanel" aria-labelledby="timeline-tab">
              <Table className="my-4" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Tracking Id</th>
                    <th>Status</th>
                    <th>complete_status</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {singleOrder?.orderTracking?.map((item, index) => (
                    <tr key={index}>
                      <td>{item?.id}</td>
                      <td>{item?.status}</td>
                      <td>{
                        <Badge
                          color=""
                          className={`badge badge-pill badge-${item?.complete_status
                            ? "success"
                            : "primary"
                            } mr-4`}
                        >
                          {item?.complete_status ? "Done" : "Pending"}
                        </Badge>
                      }</td>
                      <td>{
                        item?.complete_status ?
                          new Date(item?.updated_at).toLocaleString() : ""}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
            <div class="tab-pane fade" id="property" role="tabpanel" aria-labelledby="property-tab">
              <Table className="my-4" responsive>
                <thead className="thead-light">
                  <tr>
                    <th>Id</th>
                    <th>Feedback</th>
                    <th>Rating</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {feedback?.map((item, index) => (
                    <tr key={index}>
                      <td>{item?.id}</td>
                      <td>{item?.reviews}</td>
                      <td>{item?.ratings}</td>
                      <td>{new Date(item?.created_at).toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </Card>
      </Container>
    </div>
  );
};

export default SingleOrder;

// var tokenInWei = 0;
// let options = {}
// options = {
//   contractAddress: singleOrder?.property?.sto_sale_address,
//   functionName: "buyTokens",
//   abi: RyzerCoin,
//   params: {
//     beneficiary: singleOrder?.user?.wallet_address,
//     tokens: tokenInWei,
//   },
// };
// await contractProcessor.fetch({
//   params: options,
//   onSuccess: async (res) => {
//     var transaction_hashObj;
//     if (singleOrder?.transaction_hash) {
//       transaction_hashObj = [
//         ...singleOrder?.transaction_hash,
//         {
//           transaction: "Token Transferred",
//           hash: res?.hash
//         }
//       ]
//     }
//     else {
//       transaction_hashObj = [
//         {
//           transaction: "Token Transferred",
//           hash: res?.hash
//         }
//       ]
//     }
//     const updatedData = {
//       order_id: singleOrder.id,
//       status: "Awaiting Token transfer",
//       transaction_hash: transaction_hashObj
//     };
//     dispatch(orderActions.updateOrderStatus(updatedData, orderId))
//   },
//   onError: (error) => {
//     if (error) {
//       if ((error?.data?.message != undefined) &&
//         (error?.data?.message).indexOf("execution reverted: AccessControl") != -1) {
//         toast.error("You Don't Have Permission to Mint Token");
//       }
//       else if (error?.code == (-32603)) {
//         toast.error(error?.data?.message);
//       }
//       else if (error?.code == (404)) {
//         toast.error(error?.data?.text);
//       }
//     }
//   },
// });

// async function approve_token_transfer() {
//   if (!isConnected) {
//     toast.error("Please Connect Wallet");
//     return;
//   }
//   if (!singleOrder?.property?.sto_erc20_address) {
//     toast.error("STO is not Created For Selected Property");
//     return;
//   }
//   if (!singleOrder?.user?.wallet_address) {
//     toast.error("User Wallet Address is Missing.");
//     return;
//   }
//   if (!singleOrder?.property?.LLPCompany?.wallet_address) {
//     toast.error("Company Wallet Address is Missing.");
//     return;
//   }
//   const provider = new ethers.providers.Web3Provider(window.ethereum)
//   const signer = provider.getSigner()
//   let contract = new ethers.Contract(
//     singleOrder?.property?.sto_erc20_address,
//     RyzerCoin,
//     signer)
//   try {
//     const token = parseFloat(singleOrder?.no_sqyds)
//     var tokenInWei = ethers.utils.parseUnits("".concat(token), 18).toString();
//     let transaction = await contract.transfer(singleOrder?.property?.LLPCompany?.wallet_address,
//       tokenInWei)
//     await transaction.wait()
//     var transaction_hashObj;
//     if (singleOrder?.transaction_hash) {
//       transaction_hashObj = [
//         ...singleOrder?.transaction_hash,
//         {
//           transaction: "Relisted",
//           hash: transaction?.hash
//         }
//       ]
//     }
//     else {
//       transaction_hashObj = [
//         {
//           transaction: "Relisted",
//           hash: transaction?.hash
//         }
//       ]
//     }
//     const updatedData = {
//       order_id: singleOrder.id,
//       status: "Relisted",
//       transaction_hash: transaction_hashObj
//     };
//     dispatch(orderActions.updateOrderStatus(updatedData, orderId))
//   }
//   catch (error) {
//     if ((error.message != undefined) &&
//       (error.message).indexOf("execution reverted: AccessControl") != -1) {
//       toast.error("You Don't Have Permission to Mint Token");
//     }
//     else if ((error.message != undefined) &&
//       error.message.indexOf("execution reverted: KYC is not completed, purchase is prohibited") != -1) {
//       toast.error("KYC is not completed, purchase is prohibited");
//     }
//     else if (error?.message) {
//       toast.error(error?.message);
//     }
//     return;
//   }
// }

// const { isConnected, address } = useAccount()

// async function transferToken() {
//   if (!isConnected) {
//     toast.error("Please Connect Wallet");
//     return;
//   }
//   if (!singleOrder?.property?.sto_sale_address) {
//     toast.error("DAO is not Created For Selected Property");
//     return;
//   }
//   if (!singleOrder?.user?.wallet_address) {
//     toast.error("User Wallet Address is Missing.");
//     return;
//   }
//   const provider = new ethers.providers.Web3Provider(window.ethereum)
//   const signer = provider.getSigner()
//   let contract = new ethers.Contract(
//     singleOrder?.property?.sto_erc20_address,
//     RyzerCoin,
//     signer)
//   try {
//     const token = parseFloat(singleOrder?.no_sqyds)
//     var tokenInWei = ethers.utils.parseUnits("".concat(token), 18).toString();
//     let transaction = await contract.mint(singleOrder?.user?.wallet_address,
//       tokenInWei)
//     await transaction.wait()
//     var transaction_hashObj;
//     if (singleOrder?.transaction_hash) {
//       transaction_hashObj = [
//         ...singleOrder?.transaction_hash,
//         {
//           transaction: "Token Transferred",
//           hash: transaction?.hash
//         }
//       ]
//     }
//     else {
//       transaction_hashObj = [
//         {
//           transaction: "Token Transferred",
//           hash: transaction?.hash
//         }
//       ]
//     }
//     const updatedData = {
//       order_id: singleOrder.id,
//       status: "Token transfer successful",
//       transaction_hash: transaction_hashObj
//     };
//     dispatch(orderActions.updateOrderStatus(updatedData, singleOrder?.id))
//   }
//   catch (error) {
//     console.log("error", error)
//     if ((error.message != undefined) &&
//       (error.message).indexOf("execution reverted: AccessControl") != -1) {
//       toast.error("You Don't Have Permission to Mint Token");
//     }
//     else if ((error.message != undefined) &&
//       error.message.indexOf("execution reverted: KYC is not completed, purchase is prohibited") != -1) {
//       toast.error("KYC is not completed, purchase is prohibited");
//     }
//     else if (error?.message) {
//       toast.error(error?.message);
//     }
//   }
// }app.get('/api/v1/removeData', async (req, res) => {
