import { yupResolver } from "@hookform/resolvers/yup"
import classNames from "classnames"
import { api } from "helpers"
import { useEffect, useState } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { BiPlusCircle } from "react-icons/bi"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify";
import { FaRegTimesCircle } from "react-icons/fa";
import { Button, Col, Container, Form, FormGroup, Row } from "reactstrap"
import { propertyActions } from "redux/actions"
import { checkAttechment } from "utils"
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs"
import { Link } from "react-router-dom"

const InvenstorCashFlow = ({ parentStepper, stepper, type }) => {
    const property = useSelector(state => state.propertyReducer.property);
    const [doc1, setDoc1] = useState("");
    const dispatch = useDispatch()
    const { register, handleSubmit, trigger, reset, watch, setValue, control, formState: { errors } } = useForm({
        //resolver: yupResolver(plotSchema),

    }
    )
    const [attachement1, setAttachement1] = useState("")
    const Investment = [
        { label: 'Period', name: 'period' },
        { label: 'Interest (in ₹)', name: 'interest' },
        { label: 'Principle (in ₹)', name: 'principle' }
    ]
    const { fields: cashflowFields, append: appendcashflow, remove: removecashflow } = useFieldArray({
        control,
        name: 'data'
    });
    // useEffect(()=>{
    //     if(cashflowFields.length==0){
    //         appendcashflow({})
    //     }
    // },[])
    useEffect(() => {
        if (property) {
            if (property?.investorCashFlow) {
                setDoc1(property.investorCashFlow?.cashflow_doc);
                if (property.investorCashFlow?.data?.length > 0) {
                    setValue("data", property?.investorCashFlow?.data);
                }
                else {
                    appendcashflow({});
                }
            } else {
                appendcashflow({});
            }
        }
    }, [property])
    const onSubmit = async (value) => {
        value.property_id = localStorage.getItem('property_id');
        if (typeof attachement1 == 'object' && attachement1 != undefined) {
            const formDataNew = new FormData();
            formDataNew.append("file", attachement1)
            try {
                const res = await api.post("/file",
                    formDataNew
                );
                value.cashflow_doc = res?.url
            } catch (ex) {
                toast.error('Doc upload failed')
                return false;
            }
        }

        dispatch(propertyActions.saveAboutFive(value))
    }

    return (
        <>
            <Container>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className='content-header mb-5'>
                        <h3 className='mb-0 rz-text-secondary Montserrat-500'>Investor cash flows (%)</h3>
                    </div>
                    {/* <div style={{ backgroundColor: "whitesmoke", padding: "20px" }}>
                        {
                            Investment.map((obj, i) => {
                                return (
                                    <Row className='d-flex align-items-center mb-3' key={i}>
                                        <Col>
                                            <h3 className='rz-text-primary Montserrat-400' style={{ fontSize: "14px" }}>{obj.label}</h3>
                                        </Col>
                                        {
                                            [...Array(10)].map((w, j) => {
                                                return (
                                                    <Col className="px-1 cash-flow-col" key={`${i}-${j}`} >
                                                        <input {...register(`${obj.name}[${j}].index`)} type="hidden" value={j} />
                                                        <input
                                                            {...register(`${obj.name}[${j}].value`)}
                                                            //value={w?.value || null}
                                                            className={classNames({ 'is-invalid': false, 'form-control': true })}
                                                        />

                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row>
                                )
                            })
                        }
                    </div> */}
                    <div className="row pb-4 pt-5" style={{ flexWrap: "nowrap", overflowX: "scroll" }}>

                        <div className="col-3  d-flex" style={{ flexDirection: "column", width: "200px" }}>
                            <div>
                                {
                                    Investment.map((obj, index) => {
                                        return (
                                            <>
                                                <div className=" d-flex align-item-center" style={{ padding: "14px 0" }}>
                                                    <h3 className='rz-text-primary Montserrat-400 ' style={{ fontSize: "17px" }}>{obj.label}</h3>
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        {
                            cashflowFields.map((field, index) => {
                                return (
                                    <>
                                        <div className="col-2 cashflow-col-wrapper px-1">
                                            {cashflowFields?.length > 1 && <div className="remove-cashflow-col"><FaRegTimesCircle onClick={() => removecashflow(index)} className="remove-cashflow-col-icon" style={{ color: "#EA5455", fontSize: "20px" }} /></div>}
                                            <div className={`d-flex ${cashflowFields?.length > 1 && 'cashflow-col'} px-2 rounded`} style={{ flexDirection: "column" }}>
                                                <input type="hidden" value={field.index} name={`data.${index}.index`} />
                                                <input
                                                    className="form-control my-2"
                                                    type="text"
                                                    name={`data.${index}.period`}
                                                    {...register(`data.${index}.period`)}
                                                //defaultValue={field.period || ''}

                                                />
                                                <input
                                                    className="form-control my-2"
                                                    type="text"
                                                    name={`data.${index}.interest`}
                                                    {...register(`data.${index}.interest`)}
                                                //defaultValue={field.period || ''}

                                                />
                                                <input
                                                    className="form-control my-2"
                                                    type="text"
                                                    name={`data.${index}.principal`}
                                                    {...register(`data.${index}.principal`)}
                                                //defaultValue={field.period || ''}

                                                />
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                        }
                        <div className="col-1 d-flex py-2 flex-column">
                            <div className="h-100 d-flex flex-column rounded justify-content-center align-items-center" style={{ backgroundColor: "#EAEAEA" }}>
                                <BiPlusCircle onClick={() => appendcashflow({
                                })} style={{ fontSize: "23px", cursor: "pointer", color: "#7367F0" }} />
                            </div>
                        </div>

                    </div>
                    <Row className="mt-5">
                        <Col className='col-12 justify-content-end d-flex '>
                            <div>
                                {/* <Button outline color='primary'
                                >
                                   
                                    <svg style={{ fill: "#5e72e4" }} xmlns="http://www.w3.org/2000/svg" color='white' height="1em" viewBox="0 0 512 512"><path d="M48.5 224H40c-13.3 0-24-10.7-24-24V72c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2L98.6 96.6c87.6-86.5 228.7-86.2 315.8 1c87.5 87.5 87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3c-62.2-62.2-162.7-62.5-225.3-1L185 183c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8H48.5z" /></svg>
                                    <span className='align-middle d-sm-inline-block d-none'>Clear data</span>
                                </Button> */}
                                <label className='btn rz-button-primary' for='save_changes'>
                                    <i className='fa fa-save align-middle mr-sm-2 mr-0'></i>
                                    <span className='align-middle d-sm-inline-block d-none'>Save changes</span>
                                </label>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col>
                            <FormGroup tag={Col} md='6'>
                                <p className="all-page-heading" style={{ fontSize: "18px" }}>Cashflow Doc</p>
                                <p>Required doc is PDF which should not exceed 10mb.</p>
                                <label className='form-label'>
                                    Attachments
                                </label>
                                <div className="custom-file cursor-pointer">
                                    <input
                                        type="file"
                                        className='custom-file-input'
                                        onChange={e => setAttachement1(e.target.files[0])}
                                    />
                                    <label className="custom-file-label" htmlFor="customFile">{attachement1?.name ? attachement1.name : 'Choose file'}</label>
                                </div>
                                {checkAttechment(doc1) && <a href={`${doc1}`} target="_blank">View Attachement</a>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <input type="submit" id="save_changes" className="d-none" />

                    <div className='mt-5 align-item-center d-flex justify-content-between'>
                        <div>
                            <Button type='button' color='success' onClick={() => parentStepper.next()} className='btn-next' >
                                <span className='align-middle d-sm-inline-block d-none'>Skip</span>
                                <i className='fa fa-fast-forward align-middle mt-1 mr-sm-25 mr-0'></i>
                            </Button>
                        </div>
                        <div className='d-flex align-item-center justify-content-end'>
                            <Link to='/admin/property'>
                                <Button type='button' className='rz-button-outline-primary'>
                                    Cancel
                                </Button>
                            </Link>
                            <Button type='button' className='btn-prev ml-3 mr-2 d-flex rz-button-outline-primary' onClick={() => stepper.previous()}>
                                <BsArrowLeftShort style={{ fontSize: "22px" }} />
                                <span className='align-middle d-sm-inline-block d-none'>Back</span>
                            </Button>
                            <Button onClick={() => parentStepper.next()} className='btn-next rz-button-primary'>
                                <BsArrowRightShort style={{ fontSize: "22px" }} />
                                <span className='align-middle d-sm-inline-block d-none'>Next</span>

                            </Button>
                        </div>
                    </div>
                </Form>
            </Container>
        </>
    )
}

export default InvenstorCashFlow
