import { userConstants } from '../../constants';

export function verifyOtpReducer(state = {}, action) {
    switch (action.type) {
        case userConstants.VERIFY_OTP_REQUEST:
            return { verifyingOtp: true }
        case userConstants.VERIFY_OTP_SUCCESS:
            return {};
        case userConstants.VERIFY_OTP_FAILURE:
            return {};
        default:
            return state
    }
}