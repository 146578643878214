import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { api } from '../../helpers/api.helper'
import { Table } from "reactstrap";

const Reviews = () => {
    const { id } = useParams()
    const [list, setList] = useState([])
    const [average,setAverage] = React.useState("")
    useEffect(() => {
        if (id) {
            api.get(`/reviews/getAllEmployeeReviews/${id}`).then(response => {
                setAverage(response?.data?.average)
                setList(response?.data?.reviews)
            }).catch(err => console.log(err))
        }
    }, [])
    return (
        <div>
            {average && <p className="float-right mr-3 font-weight-bold"> Overall Ratings : {average} </p>}
            <Table responsive>
                <thead className="thead-light">
                    <tr>
                        <th>Customer ID</th>
                        <th>Customer NAME</th>
                        <th>Rating</th>
                        <th>Review</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {list?.map((item,index)=>(
                        <tr key={index}>
                            <td>{item?.customer_id}</td>
                            <td>
                                {item?.avatar && <img width="30" className="img-fluid mx-2" src={item?.avatar} />}
                                {item?.customer_name}
                            </td>
                            <td>
                                {item?.ratings}
                            </td>
                            <td>
                                {item?.reviews}
                            </td>
                            <td>
                                {new Date(item?.created_at).toDateString()}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

export default Reviews;