import { RESOURCE } from "./utils";

import TokenCertificate from "views/company_dashboard/tokenCertificate/TokenCertificate";
import TokenCertificateDetails from "views/company_dashboard/tokenCertificate/TokenCertificateDetails";

import InvestorList from "views/company_dashboard/investors/InvestorList";
import CreateorUpdateInvestorUpdate from "views/company_dashboard/investors/CreateorUpdateInvestorUpdate";

import CapTable from "views/company_dashboard/capTable/CapTable";

import CompanyDashboard from "views/company_dashboard/company/CompanyDashboard";
import CreateShareClass from "views/company_dashboard/shareclass/CreateShareClass";

import CompanyOrdersList from "views/company_dashboard/orders/CompanyOrdersList";
import SingleOrder from "views/orders/SingleOrder";

import CompanyFeedBack from "views/company_dashboard/feedback/CompanyFeedBack";
import DAO from "views/company_dashboard/dao/DAO";
import CreateVote from 'views/company_dashboard/dao/voating/CreateVote'
import VoatingDetails from 'views/company_dashboard/dao/voating/VoatingDetails'
import InvestorUpdate from "views/company_dashboard/investors/InvestorUpdate";
import SentInvestorUpdate from "views/company_dashboard/investors/SentInvestorUpdate";

import RentalPayOutDashboard from "views/company_dashboard/RentalPayout/Dashboard";
import RentalPayOutMonthandYear from "views/company_dashboard/RentalPayout/MonthAndYear";

import FeeManagement from "views/company_dashboard/FeeManagement/FeeManagement";
import AssetWiseOrder from "views/company_dashboard/FeeManagement/AssetWiseOrder";

var routes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: "fas fa-chart-line",
        component: CompanyDashboard,
        layout: "/company",
        permissions_name: RESOURCE?.DAO,
        sidebar: true,
    },
    {
        path: "/capTable",
        name: "Cap Table overview",
        icon: "fas fa-file-invoice-dollar",
        component: CapTable,
        layout: "/company",
        permissions_name: RESOURCE?.DAO,
        sidebar: true,
    },
    {
        path: "/investors",
        name: "Investor’s overview",
        icon: "fas fa-users",
        component: InvestorList,
        layout: "/company",
        permissions_name: RESOURCE?.DAO,
        sidebar: true,
    },
    {
        path: "/update",
        name: "Investors Update",
        icon: "fas fa-user-clock",
        component: InvestorUpdate,
        layout: "/company",
        permissions_name: RESOURCE?.DAO,
        sidebar: true,
    },
    {
        path: "/shareclass",
        name: "Share class",
        icon: "fas fa-balance-scale",
        component: CreateShareClass,
        layout: "/company",
        permissions_name: RESOURCE?.DAO,
        sidebar: true,
    },
    {
        path: "/tokenCertificate",
        name: "Token Certificate",
        icon: "fas fa-medal",
        component: TokenCertificate,
        layout: "/company",
        permissions_name: RESOURCE?.DAO,
        sidebar: true,
    },
    // {
    //     path: "/companySetting",
    //     name: "Setting",
    //     icon: "fas fa-gear",
    //     component: CompanySetting,
    //     layout: "/company",
    //     permissions_name: RESOURCE?.Configuration,
    //     sidebar: true,
    // },
    {
        path: "/order",
        name: "Orders",
        icon: "fas fa-shopping-cart",
        component: CompanyOrdersList,
        layout: "/company",
        permissions_name: RESOURCE?.DAO,
        sidebar: true,
    },
    {
        path: "/feedback",
        name: "FeedBack",
        icon: "fas fa-comment",
        component: CompanyFeedBack,
        layout: "/company",
        permissions_name: RESOURCE?.DAO,
        sidebar: true,
    },
    {
        path: "/dao",
        name: "DAO",
        icon: "fas fa-network-wired",
        component: DAO,
        layout: "/company",
        permissions_name: RESOURCE?.DAO,
        sidebar: true,
    },
    {
        path: "/createInvestOrUpdate",
        name: "Create/Update Investor Update Template",
        icon: "fas fa-list",
        component: CreateorUpdateInvestorUpdate,
        layout: "/company",
    },
    {
        path: "/sentInvestorUpdate",
        name: "Create Investor Update",
        icon: "fas fa-list",
        component: SentInvestorUpdate,
        layout: "/company",
    },
    {
        path: "/tokenCertificateDetails",
        name: "Token Certificate",
        icon: "fas fa-list",
        component: TokenCertificateDetails,
        layout: "/company",
        sidebar: false,
    },
    {
        path: "/singleorder/:id",
        name: "Order",
        icon: "fas fa-shopping-cart",
        component: SingleOrder,
        layout: "/company",
        sidebar: false,
    },
    {
        path: "/createvote",
        name: "Create Vote",
        icon: "fas fa-list",
        component: CreateVote,
        layout: "/company",
        sidebar: false,
    },
    {
        path: "/voatingDetails",
        name: "Voating Details",
        icon: "fas fa-list",
        component: VoatingDetails,
        layout: "/company",
        sidebar: false,
    },
    {
        path: "/FeeManagement",
        name: "Fee Management",
        icon: "fas fa-money-bill-wave",
        component: FeeManagement,
        layout: "/company",
        permissions_name: RESOURCE?.DAO,
        sidebar: true,
    },
    {
        path: "/assetWiseOrder/:fee_type",
        name: "Fee Management",
        icon: "fas fa-money-bill-wave",
        component: AssetWiseOrder,
        layout: "/company",
        permissions_name: RESOURCE?.DAO,
        sidebar: false,
    },
    {
        path: "/RentalPayOut",
        name: "Rental PayOut",
        icon: "fas fa-money-bill-wave",
        component: RentalPayOutDashboard,
        layout: "/company",
        permissions_name: RESOURCE?.DAO,
        sidebar: true,
    },
    {
        path: "/RentalPayOutMonthandYear/:month/:year",
        name: "Rental PayOut",
        icon: "fas fa-money-bill-wave",
        component: RentalPayOutMonthandYear,
        layout: "/company",
        permissions_name: RESOURCE?.DAO,
        sidebar: false,
    },
];

export default routes;
