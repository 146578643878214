import NotFound from "components/NotFound/NotFound";
import React from "react";
import { NavLink } from "react-router-dom";
import { Badge, Card, Container, Table } from "reactstrap";
import { useGetFeedbackAdmin } from "../../../hooks/useReviews";

const CompanyFeedBack = () => {
    const {
        data: feedback,
        isLoading: loading } =
        useGetFeedbackAdmin(localStorage.getItem("property_id"));
    return (
        <>
            <Container className="mt-2" fluid>
                <h3 className="ml-1 rz-heading rz-text-18">All Order Feedback</h3>
                <Card>
                    <Table className="mb-4 mt-3" responsive>
                        <thead className="rz-thead-style">
                            <tr>
                                <th>Feedback Id</th>
                                <th>Order ID</th>
                                <th>Property Name</th>
                                <th>Feedback</th>
                                <th>User</th>
                                <th>Rating</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {feedback?.map((item, index) => (
                                <tr key={index}>
                                    <td>{item?.id}</td>
                                    <td
                                        style={{ cursor: 'pointer' }}
                                        className="text-primary">
                                        <NavLink
                                            to={{
                                                pathname: `/company/singleorder/${item?.order_id}`,
                                                state: {
                                                    editable: false,
                                                },
                                            }}
                                        >
                                            #{item?.order_id}
                                        </NavLink>
                                    </td>
                                    <td>{item?.name}</td>
                                    <td>{item?.reviews}</td>
                                    <td>
                                        <img
                                            style={{
                                                borderRadius: 15,
                                                height: 30,
                                                width: 30,
                                            }}
                                            src={item?.avatar} alt="" />{" "}
                                        {item?.user_name}
                                    </td>
                                    <td>{item?.ratings}</td>
                                    <td>{new Date(item?.created_at).toLocaleString()}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    {feedback?.length <= 0 &&
                        <NotFound
                            message="No one feedback found into this property."
                        />
                    }
                </Card>
            </Container>
        </>
    );
};

export default CompanyFeedBack;
