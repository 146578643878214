import {
    Card,
    Table,
    Row,
    Col,
    Spinner,
    Container,
} from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useGetProposalSingle } from "hooks/useDAO";
import NotFound from "components/NotFound/NotFound";
import DonutChart from "react-donut-chart";
import MenuIcon from "./../../../../assets/img/menu-icon.png"
import IconEyeImage from "./../../../../assets/img/Icon-eye.png"

const VoatingDetails = () => {
    const location = useLocation();
    const proposal = location?.state?.proposal;
    const { loading, data } = useGetProposalSingle({
        id: proposal?.id
    });
    return (
        <>
            <Container>
                <div class="row">
                    <div class="col-md-8">
                        <h3>Proposal Details</h3>
                        <Card className="cap-table-pbar" style={{ overflow: "hidden" }}>
                            <div className="p-4">
                                <h4>Smart contract Link</h4>
                                <a
                                    href={`${JSON.parse(localStorage.getItem("blockchain_metadata"))?.blockExplorers?.default?.url}/token/${data?.smart_contract}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {data?.smart_contract}
                                </a>
                                <h4 className="mt-2">Title</h4>
                                <p>{data?.title}</p>
                                <h4>Description</h4>
                                <p>{data?.description}</p>
                                <h4>Images</h4>
                                <div className='d-flex flex-row justify-content-start'>
                                    {data?.medias?.map((d) => (
                                        <img
                                            src={d?.url} className="rounded"
                                            style={{
                                                width: '50px',
                                                marginLeft: 10
                                            }} />
                                    ))}
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div class="col-md-4">
                        <div class="row">
                            <h3>Tokens For Allocation: </h3>
                            <Card className="cap-table">
                                <div class="row">
                                    <div class="col-sm-12 my-3 pl-5">
                                        <DonutChart
                                            data={[
                                                {
                                                    label: "TOTAL",
                                                    value: data?.total_voaters,
                                                },
                                                {
                                                    label: "NO",
                                                    value: data?.total_voaters_no,
                                                },
                                                {
                                                    label: "YES",
                                                    value: data?.total_voaters_yes,
                                                },
                                            ]}
                                            height={250}
                                            width={250}
                                            legend={false}
                                            colors={['#7367F0', '#F96E6F', '#02D3AF']}
                                        />
                                    </div>
                                </div>
                                <div className="raw d-flex flex-row justify-content-center">
                                    <div>
                                        <h4>YES: {data?.total_voaters_yes}</h4>
                                    </div>
                                    <div>
                                        <h4 className="ml-2">NO: {data?.total_voaters_no}</h4>
                                    </div>
                                </div>
                                <div className="raw d-flex flex-row justify-content-center">
                                    <h4>TOTAL: {data?.total_voaters}</h4>
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
                <div class="row my-5">
                    <div class="col-md-8">
                        <Card className="cap-table-pbar p-4">
                            <h3>Voting</h3>
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Voting ID</th>
                                        <th scope="col">User</th>
                                        <th scope="col">Voting status</th>
                                        <th scope="col">Transaction</th>
                                        <th scope="col">Voted on</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data?.proposalVote?.map((investor) => (
                                            <tr>
                                                <td>{investor?.id}</td>
                                                <td>{" "}<img
                                                    width="30"
                                                    style={{
                                                        objectFit: 'contain',
                                                        borderRadius: 30,
                                                        marginRight: 5
                                                    }}
                                                    src={investor?.user?.avatar} alt="" />
                                                    {investor?.user?.name}{" "}
                                                </td>
                                                <td>{investor?.vote_type}</td>
                                                <td>
                                                    <a
                                                        href={`${JSON.parse(localStorage.getItem("blockchain_metadata"))?.blockExplorers?.default?.url}/tx/${investor?.hash}`}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <img src={IconEyeImage} alt="" />
                                                    </a>
                                                </td>
                                                <td>{investor?.updated_at}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </Table>
                            {data?.investors?.length <= 0 &&
                                <NotFound
                                    message="No one invested into this property."
                                />
                            }
                        </Card>
                    </div>
                    <div class="col-md-4">
                        <Card className="user-timeline-div">
                            <div className="d-flex align-items-center p-3">
                                <img src={MenuIcon} alt="" />
                                <h2 className="mb-0 ml-3">Proposal Timeline</h2>
                            </div>
                        </Card>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default VoatingDetails;
