import React, { Fragment, useEffect, useState, useCallback } from 'react'
import classnames from 'classnames'
import { useForm } from 'react-hook-form'
import { FormGroup, Row, Col, Button, Form, Label } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { propertyActions } from 'redux/actions'
import { useDropzone } from 'react-dropzone'
import { toast } from 'react-toastify'
import { useCreateProposal, useDeleteImageProposal, useUpdateProposal, useUploadImageProposal } from './../../../../../hooks/useDAO'
import { useHistory, useLocation, useParams } from 'react-router-dom'
const Step1 = ({ stepper }) => {
    const location = useLocation();
    const [proposalData, setProposalData] = useState(undefined);
    const [addUpdate, setaddUpdate] = useState(false);
    const { mutate: createProposal, isLoading: loading } = useCreateProposal()
    const { mutate: updateProposal } = useUpdateProposal()
    const { mutate: uploadImageProposal } = useUploadImageProposal()
    const { mutate: deleteImageProposal } = useDeleteImageProposal()

    const [category, setCategory] = useState("")
    const history = useHistory();

    const { register, handleSubmit, reset,
        setValue,
        control, clearErrors, getValues, trigger,
        formState: { errors } } = useForm({
    })


    const onSubmit = async (data) => {
        trigger()
        if (category == '' || category == undefined) {
            toast.error("Please Select Category");
            return;
        }
        if (proposalData?.id) {
            await updateProposal({
                ...data,
                id: proposalData?.id,
                property_id: localStorage.getItem("property_id"),
                category: category
            }, {
                onSuccess: (res) => {
                    localStorage.setItem("proposal", JSON.stringify(proposalData));
                    stepper.next();
                }
            })
        }
        else {
            await createProposal({
                ...data,
                property_id: localStorage.getItem("property_id"),
                category: category
            }, {
                onSuccess: (res) => {
                    console.log("res", res)
                    if (res?.type == "success") {
                        toast.success(res?.message);
                        localStorage.setItem("dao_redirect", 4);
                        history.push("/company/dao");
                    }
                }
            })
        }
    }
    React.useEffect(() => {
        if (!addUpdate)
        setProposalData(location?.state?.proposal)
    }, [location?.state?.proposal])

    React.useEffect(() => {
        if (proposalData) {
            setCategory(proposalData?.category);
            setValue("title", proposalData?.title);
            setValue("description", proposalData?.description);
        }
    }, [proposalData])
    const onDrop = useCallback(async (acceptedFiles) => {
        // verify if only total 5 files can be uploaded...
        let reqFile = acceptedFiles?.length;
        if ((reqFile) > 20) {
            return toast.error('You can only upload max 20 images');
        }
        const formData = new FormData();
        acceptedFiles.forEach((file, i) => {
            formData.append('gallary_images', file);
        })
        await uploadImageProposal({
            id: localStorage.getItem("vote_id"),
            data: formData
        }, {
            onSuccess: (res) => {
                if (res?.type == "success") {
                    toast.success(res?.message)
                    setaddUpdate(true)
                    if (res?.data) {
                        setProposalData(res?.data)
                    }
                }
            }
        })
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: (droppedFiles) => onDrop(droppedFiles),
        accept: 'image/jpeg, image/png'
    })
    const onRemoveGallary = async (e, id) => {
        await deleteImageProposal({
            id: id
        }, {
            onSuccess: (res) => {
                if (res?.type == "success") {
                    setaddUpdate(true)
                    toast.success(res?.message)
                    if (res?.data) {
                        setProposalData(res?.data)
                    }
                }
            }
        })
    }
    return (
        <Fragment>
            <div className='content-header'>
                <h3 className='mb-0 text-muted'>Vote details</h3>
            </div>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="card">
                    <div className="card-body">
                        <Row>
                            <Col>
                                <FormGroup>
                                    <label >Title *</label>
                                    <input
                                        disabled={proposalData?.proposal_id ? true : false}
                                        {...register("title")}
                                        className="form-control w-100"
                                        placeholder="Paste Title" />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label className='form-label' htmlFor={`property_type`}>
                                        Category
                                    </Label>
                                    <select
                                        disabled={proposalData?.proposal_id ? true : false}
                                        onChange={(e) => setCategory(e.target.value)}
                                        id="property_type"
                                        className={classnames({ 'is-invalid': errors.category, 'form-control': true })}
                                    >
                                        <option
                                            value=''>Select Category Type*</option>
                                        <option
                                            selected={"Managemeent" == category}
                                            value="Managemeent">Managemeent</option>
                                        <option
                                            selected={"Legal" == category}
                                            value="Legal">Legal</option>
                                    </select>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <label >Other instructions</label>
                                    <textarea
                                        disabled={proposalData?.proposal_id ? true : false}
                                        {...register("description")}
                                        rows={3} cols={3} className='form-control'>
                                    </textarea>
                                </FormGroup>
                            </Col>
                        </Row>
                        {proposalData &&
                        <Row className='mt-4'>
                            <Col>
                                <h3 className='mb-0 text-muted'>Gallery (Upto 10 Images)</h3>
                                <small className='text-muted'>Required image resolution 800x400, image size 10mb.</small>
                                <div className='mt-2'>
                                    <div {...getRootProps()}>
                                        <input  {...getInputProps()} />
                                        <input type='hidden' {...register(`gallary_images`)} />
                                        <div className='dropzone'>
                                            {
                                                isDragActive ?
                                                    <p>Drop the files here ...</p> :
                                                    <p>Drag 'n' drop some files here, or click to select files <br /><i>(Only *.jpeg and *.png images will be accepted)</i></p>
                                            }
                                        </div>
                                    </div>
                                    {errors.gallary_images && (
                                        <small className="form-text text-danger">{errors.gallary_images.message}</small>
                                    )}
                                </div>
                            </Col>
                            <Col>
                                <h3 className='text-muted text-center'>Image previews</h3>
                                <div className='d-flex justify-content-center align-items-center h-100 flex-column'>
                                        {proposalData && proposalData?.medias && (
                                        <div className='d-flex flex-row flex-wrap'>
                                            {
                                                    proposalData?.medias.map((item, i) => {
                                                    return (
                                                        <span className="img-container mb-3 p-1" key={i} >
                                                            <img src={item.url} className="rounded" style={{ width: '175px' }} />
                                                            <a href='#' className='bg-white' onClick={(e) => onRemoveGallary(e, item.id)}>
                                                                <i className='fa fa-times text-sm text-black rm-gallary-img m-2'></i>
                                                            </a>
                                                        </span>
                                                    )
                                                })
                                            }
                                        </div>
                                    )}
                                </div>
                            </Col>
                            </Row>}
                    </div>
                </div>
                <div className='d-flex justify-content-between mt-4'>
                    <Button type='button' color='success' onClick={() => {
                        localStorage.setItem("dao_redirect", 4);
                        history.push("/company/dao");
                    }} className='btn-next' >
                        <i className='fa fa-fast-backward align-middle mt-1 mr-sm-25 mr-0'></i>
                        <span className='align-middle d-sm-inline-block d-none ml-2'>Go Back</span>
                    </Button>
                    <Button type='button' color='success' onClick={() => {
                        localStorage.setItem("proposal", JSON.stringify(proposalData));
                        stepper.next()
                    }} className='btn-next' disabled={!proposalData}>
                        <span className='align-middle d-sm-inline-block d-none'>Skip</span>
                        <i className='fa fa-fast-forward align-middle mt-1 mr-sm-25 mr-0'></i>
                    </Button>
                    <Button type='submit' color='primary' className='btn-next'>
                        <span className='align-middle d-sm-inline-block d-none'>
                            {!proposalData?.id ? "Create Proposal" : "Save & Next"}
                        </span>
                        <i className='fa fa-chevron-right align-middle mr-sm-25 mr-0'></i>
                    </Button>
                </div>
            </Form>
        </Fragment>
    )
}

export default Step1
