import { rolesConstants } from "redux/constants/roles.constants";

export function getRolesReducer(state = {}, action) {
  switch (action.type) {
    case rolesConstants.GET_ROLES_REQUEST:
      return { loading: true };
    case rolesConstants.GET_ROLES_SUCCESS:
      return { roles: action.payload.data, pager: action.payload.pager };
    case rolesConstants.GET_ROLES_FAILURE:
      return { loading: true };
    default:
      return state;
  }
}
