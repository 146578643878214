import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { HiOutlinePlusCircle } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Button, Card, Col, Container, Row, Spinner, Table } from "reactstrap";
import { configActions } from "redux/actions";

const DocumentChecklist = () => {
  const { countryStates: country, loading } = useSelector(
    (state) => state.getCountryReducer
  );
  const dispatch = useDispatch();
  const [states, setStates] = useState([]);
  const [pages, setPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(0);
  const [slicedStates, setSlicedStates] = useState([]);

  useEffect(() => {
    dispatch(configActions.getCountryStates(process.env.REACT_APP_COUNTRY_IN));
  }, []);

  useEffect(() => {
    if (country?.states) {
      setStates(country.states);
      setSlicedStates(country.states.slice(0, 6));
      setPages(country.states.length / 6);
    }
  }, [country]);

  const handlePagination = (index) => {
    setPageNumber(index);
    const sliced = states.slice(6 * index, (index + 1) * 6);
    setSlicedStates(sliced);
  };

  return (
    <>
      <Container fluid className="mt-2 px-5 ">
        <Row className="d-flex mb-3  py-1 align-items-center rounded">
          <Col xs="12" md="6" className="text-md-left text-center">
            <span className="rz-heading rz-text-18"> Document Checklist </span>
          </Col>

          <Col xs="12" md="6" className="text-md-right text-center">
            <NavLink
              className="btn rz-button-primary"
              to={`/admin/config/document-checklist/${1}`}
            >
              <HiOutlinePlusCircle className="ml-2" style={{ fontSize: "20px" }} /> Add New Document
            </NavLink>
          </Col>
        </Row>
        <Row>
          <Col className="document-checklist-table">
            <Table responsive>
              <thead className="rz-thead-style">
                <tr>
                  <th scope="col"> STATE </th>
                  <th scope="col"> NO OF DOCS </th>
                  {/* <th scope="col"> LAST UPDATED </th> */}
                  <th className="text-right" scope="col">
                    {" "}
                    ACTIONS{" "}
                  </th>
                </tr>
              </thead>

              {loading ? (
                <tr className="text-center">
                  <td colSpan="10">
                    <Spinner animation="border" role="status" >{null}</Spinner>
                  </td>
                </tr>
              ) : (
                <tbody>
                  {states?.map((state) => {
                    return (
                      <tr className="bg-white" key={state.id}>
                        <td> {state.name} </td>
                        <td> {state.documentList.length} </td>
                        {/* <td> Time (IST) </td> */}
                        <td className="text-right">
                          <NavLink
                            to={`/admin/config/document-checklist/${state.id}`}
                          >
                            <FiEdit style={{ fontSize: "20px" }} className="ryzer-text-blue" />
                          </NavLink>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </Table>
          </Col>
        </Row>
        {/* <Row className="text-center d-flex justify-content-end mb-3">
          {[...Array(pages).keys()].map((page, index) => {
            return (
              <Button
                onClick={(e) => handlePagination(index)}
                className={
                  index == pageNumber
                    ? "btn-success btn-sm  mt-3"
                    : "mt-3 btn-sm "
                }
              >
                {index + 1}
              </Button>
            );
          })}
        </Row> */}
      </Container>
    </>
  );
};

export default DocumentChecklist;
