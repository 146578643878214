import React, { useEffect, useState } from "react";
import {
    useMutateAdvisorCompany,
} from "hooks/useCompany";
import {
    Button, Card, Col,
    Container, Form, Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row, Table
} from "reactstrap";
import { useForm } from "react-hook-form";
import NotFound from "components/NotFound/NotFound";
import { QueryClient } from "react-query";
import { toast } from "react-toastify";
import { useDeleteLegalAdvisorById } from "hooks/useCompany";
import { Link } from "react-router-dom";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";

export default function LegalAdvisors({
    company, stepper
}) {
    const { mutate: addAdvisor } = useMutateAdvisorCompany();
    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
        },
    });
    const [isModalAdvisor, setIsModalAdvisor] = useState(false);
    const [firm, setFirm] = useState('');
    const [LLPAdvisorsMembers, setLLPAdvisorsMembers] = useState([]);
    useEffect(() => {
        if (company?.LLPAdvisorsMembers)
            setLLPAdvisorsMembers(company?.LLPAdvisorsMembers)
    }, [company])
    const onSubmit = async () => {
        const formData = {
            name: getValues("name"),
            email: getValues("email"),
            firm: firm,
            phone_number: getValues("phone"),
            type: getValues("type"),
            area_of_expertise: getValues("area_of_expertise"),
            note: getValues("note"),
            company_id: company?.id,
        };
        await addAdvisor(formData, {
            onSuccess: (res) => {
                toast.success(res?.message);
                reset();
                setLLPAdvisorsMembers(res?.data || [])
                setIsModalAdvisor(false);
            },

        });
    };
    const { mutate: deleteAdvisor } = useDeleteLegalAdvisorById()
    const handleDelete = async (id) => {
        const sure = window.confirm("Are you Sure to Delete?");
        if (sure) {
            await deleteAdvisor(
                {
                    id: id,
                    company_id: company?.id
                },
                {
                    onSuccess: async (res) => {
                        toast.success("Advisor Deleted Successfully.");
                        setLLPAdvisorsMembers(res?.data || [])
                    },
                    onError: (error) => {
                        console.log(error)
                    }
                }
            );
        }
    };

    const addEditModal = (settingId) => {
        var member = LLPAdvisorsMembers?.find(({ id }) => id == settingId);
        setValue('name', member?.name)
        setValue('email', member?.email)
        setFirm(member?.firm)
        setValue('phone', member?.phone_number)
        setValue('name', member?.name)
        setValue('area_of_expertise', member?.area_of_expertise)
        setValue('note', member?.note)
        setIsModalAdvisor(true);
    }
    return (
        <Container>
            <Row className="mt-3">
                <div className="col">
                    <Card className="p-3 mb-5">
                        <Row className="mb-4 align-items-center">
                            <Col className="text-left">
                                <h3 className="rz-heading rz-text-18">Legal Advisors</h3>
                            </Col>
                            <Col className="text-right">
                                <button
                                    type="button"
                                    className="mb-0 btn rz-button-primary"
                                    onClick={() => setIsModalAdvisor(!isModalAdvisor)}>

                                    {" "}
                                    Add New Member
                                </button>
                            </Col>
                        </Row>
                        <Table className="align-items-center table-flush" responsive>
                            <thead className="rz-thead-style">
                                <tr>
                                    <th>Name</th>
                                    <th>Phone</th>
                                    <th>Email</th>
                                    <th>Type</th>
                                    {/* <th>Added Date</th> */}
                                    <th className="text-right">ACTIONS</th>
                                </tr>
                            </thead>

                            <tbody>
                                {LLPAdvisorsMembers?.map((board) => (
                                    <tr>
                                        <td>{board?.name}</td>
                                        <td>{board?.phone_number}</td>
                                        <td>{board?.email}</td>
                                        <td>{board?.type}</td>
                                        {/* <td>{board?.updated_at}</td> */}
                                        <td >
                                            <div className="d-flex align-items-center justify-content-end">
                                                <div
                                                    className="mr-3"
                                                    onClick={() => addEditModal(board?.id)}
                                                >
                                                    <FiEdit style={{ fontSize: "20px" }} className="ryzer-text-blue cursor-pointer" />
                                                </div>
                                                <div
                                                    onClick={() => handleDelete(board?.id)}
                                                >
                                                    <AiOutlineDelete style={{ fontSize: "22px" }} className="ryzer-text-blue cursor-pointer" />
                                                </div>
                                            </div>

                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        {LLPAdvisorsMembers?.length <= 0 &&
                            <NotFound
                                message="Create first board member for this property."
                            />
                        }
                    </Card>
                    <div className='mt-5 align-item-center d-flex justify-content-end'>

                        <div className='d-flex align-item-center justify-content-end'>
                            <Link to='/admin/company'>
                                <Button type='button' className='rz-button-outline-primary'>
                                    Cancel
                                </Button>
                            </Link>
                            <Button color='primary' className=' ml-3   rz-button-primary' onClick={() => stepper.previous()}>
                                <BsArrowLeftShort style={{ fontSize: "22px" }} />
                                <span className='align-middle d-sm-inline-block d-none'>Back</span>
                            </Button>
                        </div>
                    </div>
                </div>

            </Row>
            <Modal isOpen={isModalAdvisor} centered={true} className="container modal-lg">
                <div>
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title">Add Legal Advisor</div>
                            <button type="button" className="close" data-dismiss="modal" onClick={() => setIsModalAdvisor(!isModalAdvisor)}>
                                &times;
                            </button>
                        </div>
                        <div className="modal-body">
                            <from onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-sm-6 form-group">
                                        <label>Name *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            {...register(`name`)}
                                            placeholder="Full Name"
                                        />
                                    </div>
                                    <div className="col-sm-6 form-group">
                                        <label>Firm</label>
                                        <input
                                            value={firm}
                                            onChange={(e) => setFirm(e.target.value)}
                                            type="text"
                                            className="form-control"
                                            placeholder="Firm Name"
                                            name="Firm Name"
                                        />
                                    </div>
                                    <div className="col-sm-6 form-group">
                                        <label>Email *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            {...register(`email`)}
                                            placeholder="Email"
                                        />
                                    </div>
                                    <div className="col-sm-6 form-group">
                                        <label>Phone *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            {...register(`phone`)}
                                            placeholder="Phone"
                                        />
                                    </div>
                                    <div className="col-sm-6 form-group">
                                        <label>Area of Expertise *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Area of Expertise"
                                            name="Area of Expertise"
                                            {...register(`area_of_expertise`)}
                                        />
                                    </div>
                                    <div className="col-sm-6 form-group">
                                        <label>Type *</label>
                                        <select className="form-control"
                                            {...register(`type`)}
                                        >
                                            <option>Adviser</option>
                                            <option>Manager</option>
                                            <option>Board Member</option>
                                        </select>
                                    </div>
                                    {/* <div className="col-sm-6 form-group">
                                        <label>Tags *</label>
                                        <input
                                            type="text"
                                            {...register(`tags`)}
                                            className="form-control"
                                            placeholder="Tags"
                                            name="Tags"
                                        />
                                    </div> */}
                                    <div className="col-sm-12 form-group">
                                        Admin notes
                                        <textarea
                                            {...register("note")}
                                            className="form-control"
                                            rows="5"
                                            placeholder="Note"
                                        ></textarea>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn rz-button-outline-primary"
                                        data-dismiss="modal"
                                        onClick={() => setIsModalAdvisor(!isModalAdvisor)}>
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => onSubmit()}
                                        className="btn ml-3 rz-button-primary"
                                        data-dismiss="modal"
                                    >
                                        Add
                                    </button>

                                </div>
                            </from>
                        </div>
                    </div>
                </div>
            </Modal>
        </Container>
    );
}
