import {
    Badge,
    Card,
    Media,
    Table,
    Container,
    Row,
    Spinner,
} from "reactstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useContext, useEffect } from "react";
import Paginate from "components/Paginate";
import queryString from 'query-string';
import NotFound from "components/NotFound/NotFound";
import { notificationActions } from "redux/actions";

const NotificationList = () => {
    const dispatch = useDispatch();
    const { search } = useLocation();
    const page = queryString.parse(search)?.page
    const { admin_notification, pager, loading } = useSelector(
        (state) => state.getAdminNotifications
    );
    console.log("admin_notification", admin_notification)
    const onInit = () => {
        dispatch(notificationActions.getAdminNotifications({ page: page ? page : 1 }));
    }
    const BadgeComponent = ({ data }) => {
        return (
            <div>
                {Object.keys(data || {}).map(key => {
                    if (data[key]) {
                        return (
                            <Badge
                                key={key}
                                color=""
                                className="badge badge-pill badge-primary mr-4"
                            >
                                {key}
                            </Badge>
                        );
                    }
                    return null;
                })}
            </div>
        );
    };
    useEffect(() => {
        onInit()
    }, [page]);
    return (
        <>
            <Container fluid>
                <div className='content-header mb-3'>
                    <h3 className='mb-0 rz-heading rz-text-22'>Notification List</h3>
                </div>
                <Row className="mt-3">
                    <div className="col">
                        <Card className="shadow">
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="rz-thead-style">
                                    <tr>
                                        <th scope="col">Notification Type</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Customer ID</th>
                                        <th scope="col">Notification Sent AT</th>
                                        <th scope="col">Channel</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading && (
                                        <tr className="text-center">
                                            <td colSpan="10">
                                                <Spinner animation="border" role="status" >{null}</Spinner>
                                            </td>
                                        </tr>
                                    )}

                                    {admin_notification && admin_notification.length > 0 ?
                                        admin_notification.map((item, i) => (
                                            <tr key={i}>
                                                <td>{item?.notification_type}</td>
                                                <td>{item?.context?.email}</td>
                                                <td>{item?.user_id}</td>
                                                <td>{moment(item?.created_at).format("D-MMM-Y")}</td>
                                                <td>
                                                    <BadgeComponent data={item?.channels} />
                                                </td>
                                            </tr>
                                        )) : (
                                            <></>
                                        )}
                                </tbody>
                            </Table>
                            {admin_notification?.length <= 0 &&
                                <NotFound
                                    message="Notifications Not Found."
                                />
                            }
                            {((pager && pager.pages.length > 0) && (admin_notification && admin_notification.length > 0)) && (
                                <Paginate path="/admin/notification" pager={pager} />
                            )}
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};

export default NotificationList;
