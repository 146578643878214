
import React, { useContext, useState } from "react";
import { useLocation, Route, Switch, Redirect, useHistory } from "react-router-dom";
import { Container } from "reactstrap";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";

const Admin = (props) => {

    const mainContent = React.useRef(null);
    const [selected, setSelected] = useState(true);
    const location = useLocation();

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainContent.current.scrollTop = 0;
    }, [location]);

    const kyc = JSON.parse(localStorage.getItem("kyc"))
    const org_id = localStorage.getItem("org_id");
    const _routes = !org_id ?
        routes?.filter((d) => d?.role_type == undefined) :
        routes?.filter((d) => d?.role_type?.includes((selected ? 1 : 2)));

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.layout === "/admin") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            }
            else {
                return null;
            }
        });
    };

    const getBrandText = (path) => {
        for (let i = 0; i < routes.length; i++) {
            if (
                props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
                -1
            ) {
                return routes[i].name;
            }
        }
        return "Brand";
    };
    const history = useHistory();
    if (selected) {
        if (history?.location?.pathname == "/admin/seller") {
            history.push('/admin/property');
        }
    }
    return (
        <>
            <Sidebar
                {...props}
                routes={_routes}
                logo={{
                    innerLink: "/admin/index",
                    imgSrc: require("../assets/img/brand/logo.png").default,
                    imgAlt: "...",
                }}
            />
            <div className="main-content" ref={mainContent}>
                <AdminNavbar
                    setSelected={setSelected}
                    selected={selected}
                    {...props}
                    brandText={getBrandText(props.location.pathname)}
                />
                <Switch>
                    {getRoutes(routes)}
                    {(org_id > 0 && kyc.status != "auto_approved") ?
                        <Redirect from="*" to="/admin/kyc" /> :
                        <Redirect from="*" to="/admin/index" />
                    }

                </Switch>
                <Container fluid>
                    <AdminFooter />
                </Container>
            </div>
        </>
    );
};

export default Admin;
