import React from 'react'
import { toast } from 'react-toastify';
import { Button } from 'reactstrap'
import { api } from '../../helpers';
import { useMutateUpdateCompany } from 'hooks/useCompany';
import { useMutateCreateCompany } from 'hooks/useCompany';
import { QueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { checkAttechment } from '../../utils';
import { BsArrowRightShort } from "react-icons/bs";
import { Link } from 'react-router-dom';

export default function CompanyInfo({ stepper, type, company }) {
    const queryClient = new QueryClient()
    const history = useHistory();
    const [name, setName] = React.useState("");
    const [wallet_address, setWalletaddress] = React.useState("");
    const [address_object, setAddress] = React.useState({
        state: "",
        phone: "",
        city: "",
        address: "",
        pincode: "",
        email: "",
        account_id: ""
    });
    const [instrument, setInstrument] = React.useState("");
    const [incorportaion_type, setIncorporation] = React.useState("");
    const [country, setCountry] = React.useState("");
    const [countries, setCountries] = React.useState([])
    const [date, setDate] = React.useState("");
    const [doc1, setDoc1] = React.useState("");
    const [doc2, setDoc2] = React.useState("")
    const [attachement1, setAttachement1] = React.useState("")
    const [attachement2, setAttachement2] = React.useState("")
    const { mutate: updateCompnay } = useMutateUpdateCompany();
    const { mutate: createCompany } = useMutateCreateCompany();

    React.useEffect(() => {
        if (company) {
            setName(company?.name)
            setInstrument(company?.instrument)
            setIncorporation(company?.incorportaion_type)
            setCountry(company?.Country_of_Incorportaion)
            setDate(new Date(company?.closing_date).toISOString().slice(0, 10))
            setAttachement2(company?.RERA_Certificate)
            setAttachement1(company?.llp_agreement_copy)
            setWalletaddress(company?.wallet_address)
            setAddress({
                state: company?.state,
                phone: company?.phone,
                city: company?.city,
                address: company?.address,
                pincode: company?.pincode,
                email: company?.email,
                account_id: company?.account_id,
            })
        }
        api.get("/config/country").then(country_response => {
            setCountries(country_response.data)
        }).catch(err => console.log(err))
    }, [company])

    async function handleSubmit() {
        if (name && instrument && incorportaion_type && country && date) {
            const formData = new FormData();
            formData.append("name", name)
            formData.append("instrument", instrument)
            formData.append("incorportaion_type", incorportaion_type)
            formData.append("Country_of_Incorportaion", country)
            formData.append("closing_date", date);
            formData.append("wallet_address", wallet_address);
            if (address_object) {
                formData.append("state", address_object.state);
                formData.append("phone", address_object.phone);
                formData.append("city", address_object.city);
                formData.append("address", address_object.address);
                formData.append("pincode", address_object.pincode);
                formData.append("email", address_object.email);
                formData.append("account_id", address_object.account_id);
            }
            if (doc1) {
                formData.append("llp_agreement_copy", doc1)
            }
            if (doc2) {
                formData.append("RERA_Certificate", doc2)
            }
            if (!company) {
                await createCompany(formData, {
                    
                    onSuccess: (res) => {
                        history.push("/admin/company")
                    }
                })
            }
            else {
                let data = {
                    id: company?.id,
                    name: name,
                    instrument: instrument,
                    incorportaion_type: incorportaion_type,
                    Country_of_Incorportaion: country,
                    date: date,
                    RERA_Certificate: attachement1,
                    llp_agreement_copy: attachement2,
                    wallet_address: wallet_address,
                    ...address_object
                }
                if (doc1) {
                    const formDataNew = new FormData();
                    formDataNew.append("file", doc1)
                    try {
                        const res = await api.post("/file",
                            formDataNew
                        );
                        data.llp_agreement_copy = res?.url
                    } catch (ex) {
                        console.log(ex);
                        return;
                    }
                }
                if (doc2) {
                    const formDataNew = new FormData();
                    formDataNew.append("file", doc2)
                    try {
                        const res = await api.post("/file",
                            formDataNew
                        );

                        data.RERA_Certificate = res?.url
                    } catch (ex) {
                        console.log(ex);
                        return;
                    }
                }
                await updateCompnay(data, {
                    onSuccess: (res) => {
                        toast.success('Company Updated Successfully')
                        queryClient.invalidateQueries(`user_company_${company?.id}`);
                    },
                    onError: (err) => {
                        toast.error(err)
                        console.log(err)
                    }
                })
            }
        } else {
            toast.error("Please fill all data")
        }
    }
    return (
        <>
            <div className='content-header'>
                <h3 className='mb-2 rz-sub-heading'>Company Info</h3>
            </div>
            <div className='row'>
                <div className='col-md-6 form-group'>
                    <label className='form-label'>Company Name * </label>
                    <input
                        placeholder='eg. The Lake view Park, Brigade '
                        className='form-control'
                        value={name}
                        onChange={e => setName(e.target.value)}
                    />
                </div>
                <div className='col-md-6 form-group'>
                    <label className='form-label'>Instrument Type* </label>
                    <select className='form-control' value={instrument} onChange={e => setInstrument(e.target.value)}>
                        <option value="">Select Option</option>
                        <option value="Equity">Equity</option>
                        <option value="CCD">CCD</option>
                        <option value="NCD">NCD</option>
                        <option value="Equity+CCD">Equity+CCD</option>
                    </select>
                </div>
                <div className='col-md-6 form-group'>
                    <label className='form-label'>Incorporation Type * </label>
                    <select className='form-control' value={incorportaion_type} onChange={e => setIncorporation(e.target.value)}>
                        <option value="">Select Option</option>
                        <option value="Pvt. Limited">Pvt. Limited</option>
                        <option value="Partnership">Partnership</option>
                    </select>
                </div>
                <div className='col-md-6 form-group'>
                    <label className='form-label'>Country Of Incorporation * </label>
                    <select className='form-control' value={country} onChange={e => setCountry(e.target.value)}>
                        <option value="">Choose Country</option>
                        {countries?.map((item, index) => <option value={item?.name} key={index} >{item?.name}</option>)}
                    </select>
                </div>
                <div className='col-md-6 form-group'>
                    <label className='form-label'>Incorporation Date * </label>
                    <input className='form-control' type="date" value={date} onChange={e => setDate(e.target.value)} />
                </div>
                <div className='col-md-6 form-group'>
                    <label className='form-label'>Upload LLP Document * </label>
                    <div className="custom-file cursor-pointer">
                        <input className='custom-file-input' type="file" onChange={e => setDoc1(e.target.files[0])} />
                        <label className="custom-file-label" htmlFor="customFile">{doc1?.name ? doc1.name : 'Choose file'}</label>
                    </div>
                    {checkAttechment(attachement1) && <a href={`${attachement1}`} target="_blank">View Attachement</a>}
                </div>
                {/* <div className='col-md-6 form-group'>
                    <label className='form-label'>Upload RERA Document * </label>
                    <input className='form-control' type="file" onChange={e => setDoc2(e.target.files[0])} />
                    {checkAttechment(attachement2) && <a href={`${attachement2}`} target="_blank">View Attachement</a>}
                </div> */}
                <div className='col-md-12 form-group'>
                    <label className='form-label'>Wallet Address </label>
                    <input
                        placeholder='0x1D5E50754b504A6893E692C92aFeB2d530E79FB1'
                        className='form-control'
                        value={wallet_address}
                        onChange={e => setWalletaddress(e.target.value)}
                    />
                </div>
                <div className='col-md-12 form-group pb-2'>
                    <label className='form-label'>Address Line </label>
                    <input
                        placeholder='New Street'
                        className='form-control'
                        value={address_object.address}
                        onChange={e => setAddress({
                            ...address_object,
                            address: e.target.value
                        })}
                    />
                </div>
                <div className='col-md-12 form-group d-flex pb-2'>
                    <div>
                        <label className='form-label'>State </label>
                        <input
                            placeholder='Gujarat'
                            className='form-control'
                            value={address_object.state}
                            onChange={e => setAddress({
                                ...address_object,
                                state: e.target.value
                            })}
                        />
                    </div>
                    <div className="px-2">
                        <label className='form-label'>City </label>
                        <input
                            placeholder='Ahmedabad'
                            className='form-control'
                            value={address_object.city}
                            onChange={e => setAddress({
                                ...address_object,
                                city: e.target.value
                            })}
                        />
                    </div>
                    <div className="px-2">
                        <label className='form-label'>PostalCode </label>
                        <input
                            placeholder='456780'
                            className='form-control'
                            value={address_object.pincode}
                            onChange={e => setAddress({
                                ...address_object,
                                pincode: e.target.value
                            })}
                        />
                    </div>
                    <div className="px-2">
                        <label className='form-label'>Phone </label>
                        <input
                            placeholder='+91967890987'
                            className='form-control'
                            value={address_object.phone}
                            onChange={e => setAddress({
                                ...address_object,
                                phone: e.target.value
                            })}
                        />
                    </div>
                </div>
                <div className='col-md-6 form-group'>
                    <label className='form-label'>Email </label>
                    <input
                        placeholder='jone@gmail.com'
                        className='form-control'
                        value={address_object.email}
                        onChange={e => setAddress({
                            ...address_object,
                            email: e.target.value
                        })}
                    />
                </div>
                {/* <div className='col-md-6 form-group'>
                    <label className='form-label'>Payment Account ID </label>
                    <input
                        placeholder='Rozer Pay Account ID'
                        className='form-control'
                        value={address_object.account_id}
                        onChange={e => setAddress({
                            ...address_object,
                            account_id: e.target.value
                        })}
                    />
                </div> */}
            </div>

            <div className='d-flex py-2 justify-content-between'>
                <div>
                    {company && <Button type='button' color='success' onClick={() => stepper.next()} className='btn-next' >
                        <span className='align-middle d-sm-inline-block d-none'>Skip</span>
                        <i className='fa fa-fast-forward align-middle mt-1 mr-sm-25 mr-0'></i>
                    </Button>}
                </div>
                <div className='d-flex justify-content-end'>
                    <div className='d-flex align-items-center'>
                        <Link to='/admin/company'>
                            <Button type='button' className='rz-button-outline-primary'>
                                Cancel
                            </Button>
                        </Link>
                        <Button type='button' onClick={() => handleSubmit()} className='btn-next ml-3 d-flex justify-content-center align-items-center rz-button-primary'>
                            <BsArrowRightShort style={{ fontSize: "22px" }} />
                            <span className='align-middle d-sm-inline-block d-none'>
                                Next
                            </span>
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}
