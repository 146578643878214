import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import BadgeImage from "./../../assets/img/manonmoonsmall.png"

const NotFound = ({ message = "Data not found." }) => {
  return (
    <Row className="my-3 px-3 text-center">
      <Col>
        <img
          style={{
            width: "100px",
            height: "100px",
            objectFit: 'contain',
            marginBottom: "10px"
          }}
          src={BadgeImage} alt="" />
        <h3>{message}</h3>
      </Col>
    </Row>
  );
};

export default NotFound;
