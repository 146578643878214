import { userConstants } from '../../constants';
import jwt from 'jsonwebtoken';

let auth = JSON.parse(localStorage.getItem('user'));
const token = jwt.decode(auth?.token);

const initialState = auth ? { loggedIn: true, auth, rules: token?.rules } : {};
export function authReducer(state = initialState, action) {
    switch (action.type) {
        case userConstants.AUTH_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                auth: action.payload.data ? action.payload.data : action.payload
            };
        case userConstants.AUTH_FAILURE:
            return {};
        default:
            return state
    }
}