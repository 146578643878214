import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Label, Row } from "reactstrap";
import { useGetcompanyWithPropertyById } from "../../hooks/useCompany";
import { Link } from "react-router-dom";
import NotFound from "components/NotFound/NotFound";
import { redirectToPropertyDao } from "utils";

export default function CompanyPropertyList() {

  const { data: propertyList } = useGetcompanyWithPropertyById({
    company_id: localStorage.getItem("company_id"),
  });
  return (
    <>

      <Container>
        <Col className="my-2 mb-5" md="12" xs="12">
          {propertyList && propertyList?.properties?.length > 0 ? propertyList?.properties?.map((prop) => (
            <Card
              style={{
                padding: 20,
                margin: 10,
                justifyContent: 'space-between',
                flexDirection: 'row'
              }}
            >
              <h1 className="rz-heading rz-text-22">{prop?.name}</h1>
              <Col className="d-flex justify-content-end">
                <Link className="btn rz-button-primary"
                  onClick={() => {
                    redirectToPropertyDao(prop)
                  }}
                  to="/company/dashboard">
                  View Property Details
                </Link>
              </Col>

            </Card>
          )) :
            null
          }
        </Col>
        {propertyList && propertyList?.properties?.length <= 0 &&
          <Card>
            <NotFound
              message="Registed Property for selected company not found."
            />
          </Card>
        }
      </Container>
    </>
  );
}
const styles = {
  cardStyle: {
    borderRadius: "50%",
    backgroundColor: '#00CFE8',
    opacity: "80%",
    width: 50,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center'
  },
  cardStyleIcon: {
    fontSize: "1.5em",
    color: "white",
    paddingLeft: 10,
    paddingTop: 10
  }
}
