import { useQuery } from "react-query";
import { API_ENDPOINTS } from "../helpers";
import { api } from "../helpers";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

const fetchPropertyExpenseQuery = async (id) => {
    if (id > 0 && id != undefined) {
        const data = await api.get(`${API_ENDPOINTS.PROPERTYEXPENSE}/${id}`);
        return data?.data;
    }
    else
        return []
};
const usePropertyExpenseQuery = (id) => {
    return useQuery([API_ENDPOINTS.PROPERTYEXPENSE, id],
        () => fetchPropertyExpenseQuery(id),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false
        }
    );
};
const useDeletePropertyExpenseMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        (id) => api.delete(`${API_ENDPOINTS.PROPERTYEXPENSE}/${id}`),
        {
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.PROPERTYEXPENSE);
            },
        }
    );
};
const useUpdatePropertyExpenseClassMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { id, input } }) =>
            api.put(`${API_ENDPOINTS.PROPERTYEXPENSE}/${id}`, input)
        ,
        {
            onSuccess: () => {
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.PROPERTYEXPENSE);
            },
            onError: (error) => {
                toast.error(error?.response?.data?.message);
            },
        }
    );
};
const useCreatePropertyExpenseClassMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        ({ variables: { input } }) =>
            api.post(API_ENDPOINTS.PROPERTYEXPENSE, input),
        {
            onSuccess: (result) => {
                if (result.status === 200 || result?.data?.status === 200) {
                    toast.success(result.data.message);
                } else if (result.status === 400) {
                    toast.error(result?.message);
                }
            },
            onSettled: () => {
                queryClient.invalidateQueries(API_ENDPOINTS.PROPERTYEXPENSE);
            },
            onError: (error) => {
                toast.error(error?.response?.data?.message);
            },
        }
    );
};
export {
    usePropertyExpenseQuery,
    useCreatePropertyExpenseClassMutation,
    useUpdatePropertyExpenseClassMutation,
    useDeletePropertyExpenseMutation
};
