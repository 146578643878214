import React, { useEffect, useState } from "react";
import { NavItem, NavLink, Container, Nav, Row, Col, Card } from "reactstrap";
import InvestorUpdateList from "./InvestorUpdateList";
import InvestorUpdateTemplateList from "./InvestorUpdateTemplateList";


const InvestorUpdate = () => {
    const daoNavOptions = ["Updates", "Templates"]
    const [activeTab, setActiveTab] = useState("Updates");
    useEffect(() => {
        if (localStorage.getItem("update_redirect") == 1) {
            setActiveTab("Updates");
            localStorage.removeItem("dao_redirect")
        }
        if (localStorage.getItem("update_redirect") == 2) {
            setActiveTab("Templates");
            localStorage.removeItem("dao_redirect")
        }
    }, [localStorage.getItem("dao_redirect")])
    const getDaoNavOptions = () => {
        return (
            <Card className='p-2'>
                <div className="d-flex justify-content-between">
                    <div className="btn-group" role="group" aria-label="Options">
                        {daoNavOptions.map((v, i) =>
                            <button
                                type="button"
                                className={
                                    `btn rz-shadow-none button cursor-pointer ${activeTab === v ? 'rz-button-primary' : 'white'
                                    }`
                                }
                                style={{
                                    borderRadius: 5
                                }}
                                onClick={() => setActiveTab(v)}
                            >
                                {v}
                            </button>
                        )}
                    </div>
                </div>
            </Card>
        );
    };
    return (
        <Container className="mt-2" fluid>
            <h3 className="ml-1 rz-heading rz-text-18 mb-3">Investor Update Managemeent</h3>
            <Row>
                <Col>

                    {getDaoNavOptions()}
                    <div className="py-2 mt-2">
                        {activeTab == "Updates" ?
                            <InvestorUpdateList /> :
                            activeTab == "Templates" ?
                                <InvestorUpdateTemplateList /> :
                                // activeTab == "Investors List" ?
                                //     <InvestorList dao={true} /> :
                                //     activeTab == "Proposal" ?
                                //         <VoatingList /> :
                                //         activeTab == "Settings" ?
                                //             <Settings /> :
                                <></>
                        }
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default InvestorUpdate;
