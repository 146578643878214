import { propertyConstants } from '../../constants';

export function getPropertiesTokenSellerReducer(state = {}, action) {
    switch (action.type) {
        case propertyConstants.GET_PROPERTIES_TOKEN_SELLER_REQUEST:
            return { gettingPropertyTokenSeller: true };
        case propertyConstants.GET_PROPERTIES_TOKEN_SELLER_SUCCESS:
            return { properties_token_seller: action.payload.data, pager: action.payload.pager };
        case propertyConstants.GET_PROPERTIES_TOKEN_SELLER_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getTokenSellerMyAsset(state = {}, action) {
    switch (action.type) {
        case propertyConstants.GET_PROPERTIES_TOKEN_SELLER_MYASSET_REQUEST:
            return { getTokenSellerMyAsset: true };
        case propertyConstants.GET_PROPERTIES_TOKEN_SELLER_MYASSET_SUCCESS:
            return { properties_token_seller_myasset: action.payload.data, pager: action.payload.pager };
        case propertyConstants.GET_PROPERTIES_TOKEN_SELLER_MYASSET_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}
export function getTokenSellerSuperAdmin(state = {}, action) {
    switch (action.type) {
        case propertyConstants.GET_PROPERTIES_TOKEN_SELLER_SUPERADMIN_REQUEST:
            return { getTokenSellerSuperAdmin: true };
        case propertyConstants.GET_PROPERTIES_TOKEN_SELLER_SUPERADMIN_SUCCESS:
            return { properties_token_seller_superadmin: action.payload.data, pager: action.payload.pager };
        case propertyConstants.GET_PROPERTIES_TOKEN_SELLER_SUPERADMIN_FAILURE:
            return {
                error: action.error
            };
        default:
            return state
    }
}