import React from 'react'

function FinalAgreement({order}) {
    return (
        <div style={{ display: 'none' }} id='final_agreement_pdf'>
            <div class="print-pdf-doc-div">
                <img class="first-page-img" src={order?.cover_image_pdf} alt="" />

                <div class="inner-content">
                    <p class="FW-600 text-center text-26"><u>Limited Liability Partnership Agreement</u></p>

                    <p class="text-justify"><span class="FW-600">THIS AGREEMENT</span> of Limited Liability Partnership (LLP) is
                        made at New Delhi on this
                        17th day of February, 2022. </p>

                    <p class="FW-600">BETWEEN</p>
                    <div id='first-party-loop'></div>


                    <p>(All the PARTIES hereto, shall be collectively called or referred
                        to as the PARTNERS).</p>

                    {/* <p>WH EREAS the First Partner is ……………………… </p>

                    <p>WHEREAS the Second Partner is …………………………. </p> */}

                    <p>That, the Partners are all together agreed to make a Special Purpose Vehicle for the purpose of
                        purchasing Immovable properties and are also consented to make a Special Power of Attorney in Favour of
                        Ryzer & its management for the purpose of handling and managing the properties for which the same SPV is
                        created and in return will pay a sum as has been agreed between the Parties.</p>

                    <p><span class="FW-600">NOW</span> All partners are interested in forming a Limited Liability Partnership
                        (LLP) under the Limited
                        Liability Partnership Act, 2008 and intend to write down the terms and conditions of the said LLP as
                        below.</p>

                    <p class="FW-600 pt-3">IT IS HEREBY AGREED BY AND BETWEEN THE PARTNERS HERETO AS FOLLOWS:</p>

                    <p class="FW-600"><span className='text-capitalize llp_name'></span> LLP</p>

                    <p>A Limited Liability Partnership (LLP) shall be carried on in the name and style of M/s. <span className='text-capitalize llp_name'></span> LLP
                        and hereinafter called as the LLP.</p>

                    <p>Registered Office</p>

                    <p>2. The LLP shall have its Registered Office at <span className='text-capitalize llp_address'></span>. and/or at such other place or places,
                        as shall be agreed to by the majority of the
                        partners from time to time.</p>

                    <p class="FW-600">Capital Contribution</p>

                    <p>3. The Capital Contribution of the LLP shall be Rs. <span id='llp_contribution'></span> which shall be
                        contributed by the partners in the following proportions: <br />
                        <div id='third-party-loop'></div>
                        
                    </p>

                    <p>Profit sharing ratio (PSR)</p>






                    <p>4. All the Partners of the LLP are entitled to share Profits & Losses in the ratio as set forth
                        hereunder:</p>
                    <div id='fourth-party-loop'></div>

                    <p>The net profits & losses of the LLP shall be arrived at after providing for payment of Remuneration to
                        the Designated and working partners and Interest on Partners’ Contribution in the LLP or Loan given by
                        them to the LLP.
                    </p>
                    <p>The Partners may revise the PSR upon concurrence of all the two Partners, duly recorded in writing, by
                        way of an amendment to this Agreement.</p>

                    <p class="FW-600">Business and Objects of the LLP</p>
                    <p class="text-justify">5. The objects, business and activities of the LLP shall be under :</p>

                    <ul>
                        <li class="mt-4">To carry on in India the business of maintenance, development of any type of
                            residential,
                            commercial, multi-storied buildings, flats, houses, apartments, agricultural lands, cinemas, hotels,
                            bridges, road or works of any description on any land, buying and selling the same in full or in
                            parts and giving it away or parting away the possession of the same in full or in parts on lease,
                            hire or licence basis or in any other manner to individual, partnership concerns, companies or to
                            general public on such terms and conditions as may be considered necessary.</li>

                        <li>To deal with all kinds of immovable properties free-hold or leasehold or having any other rights
                            interest or share attached to them whether belonging to the company or not.</li>

                        <li>To act as agent for sellers and buyers for lands, buildings, flats both commercial and
                            residential, agricultural farms and other immovable properties in India or abroad and to collect
                            rent and income thereof.</li>

                        <li>To carry on business as dealers, re-sellers, house and estate agents, auctioneers, lessors,
                            builders, developers, experts, advisers, surveyors, planners, furnishers, designers in real estate,
                            immovable and movable properties and for that purpose, acquire, hold mortgage, take on lease,
                            exchange or otherwise acquire, improve, manage, survey, develop, sell, deal, dispose off, turn to
                            account or otherwise deal, prepare, layouts, prepare building sites, and to construct, reconstruct
                            repair, remodel, pull down, alter, improve, decorate, furnish and maintain, immovable and movable
                            properties other properties, lands, flats, mainsonetts, dwelling houses, shops, offices, markets,
                            commercial complex, theatre, clubs, factories, workshops and other fixtures.</li>
                    </ul>

                    <p class="FW-600 pt-4">Admission of New Partner</p>

                    <p>6. No Person may be introduced as a new partner without the consent of all the existing partners. Such
                        incoming partner shall give his prior consent to act as Partner of the LLP.
                    </p>

                    <p>7. Capital Contribution of the new partner may be tangible, intangible, movable or immovable property and
                        the incoming partner shall bring minimum Contribution as decided by the management of Ryzer platform.
                    </p>

                    <p>8. Profit Sharing Ratio (PSR) of the incoming partner will be as mutually agreed between all the
                        Partners.</p>

                    <p>Persons admitted as Partners shall duly comply with the provisions of Sec. 25(1) of LLP Act. and Rule 22
                        (1) and Form 6 of the LLP Rules & Forms, 2008 within the prescribed period.</p>

                    <p>Unless agreed to otherwise by all Partners, a person who has any business / professional interest in
                        conflict with the objects of the LLP shall not be admitted as its Partner.</p>

                    <p class="FW-600 pt-4">Remuneration & Interest to Partners</p>

                    <p class="text-justify">9. The LLP shall pay such Remuneration to the Designated Partners and working
                        partners as may be decided by the majority of the Partners, for rendering his/her/its services.</p>







                    <p>10. The LLP shall pay such Interest to the Partners as Capital Contribution in the LLP as may be decided
                        by the management of Ryzer Platform. Unless otherwise decided in writing by all the Partners, the
                        Capital Contribution of each Partner shall not accrue any interest thereon for the duration of the LLP
                    </p>

                    <p>11. If any partner advances any sum of money to the LLP over and above his Capital Contribution, the same
                        shall be a debt due from the LLP to the said partner and shall carry simple interest at the rate of 12
                        percent per annum, or such other rate as decided by the partners unanimously.</p>

                    <p class="FW-600">Rights of the Partners</p>

                    <p>12. All the partners here to shall have the rights, title and interest in all the assets and properties
                        in the said LLP in the proportion of their Capital Contribution. The Partners shall have the right,
                        either jointly or severally to enter into any business transaction with the LLP provided the same is on
                        arm’s length basis and has been approved by all the Partners unanimously.</p>

                    <p>13. All the partners of the LLP shall be the working partners and shall give time and attention as may be
                        required for the fulfilment of the objects of the LLP business or in can of any difficulty they can
                        transfer their work to Designated Partners as has been appointed by the Partners.</p>

                    <p>14. The LLP shall have perpetual succession and the death, retirement or insolvency of any partner shall
                        not dissolve the LLP.</p>

                    <p>15. On retirement /Leaving/seclusion of a partner, the retiring partner shall be entitled to full payment
                        in respect of all his rights, title and interest in the partnership as herein provided.
                    </p>

                    <p>However, upon insolvency of a partner his or her rights, title and interest in the LLP shall come to an
                        end.
                    </p>

                    <p>16. Upon the death of any of the partners herein any or all of his heirs or her heirs may be admitted as
                        a partner of the LLP in place of such deceased partner. The heirs, executors and administrators of such
                        deceased partners shall be entitled to and shall be paid the full payment in respect of the right, title
                        and interest of such deceased partner.</p>

                    <p>17. On the death of any partner, if his or her or its heirs opt not to become the partner, the surviving
                        partners shall have the option to purchase the contribution of the deceased partner in the LLP.</p>

                    <p class="ml-5">Each of the parties hereto shall be entitled to carry on their own, separate and independent
                        business as
                        hither to they might be doing or they may hereafter do as they deem fit and proper; and other Partners
                        and the LLP shall have no objection thereto provided that the said Partner has intimated the said fact
                        to the LLP before the start of the independent business, provided however that such Partner shall not
                        use the name or assets of the LLP to carry on the said business.</p>

                    <p class="FW-600 pt-5">Duties of the Partners</p>

                    <p>18. Each Partner shall be just and faithful to the other partners in the conduct of business and all the
                        transactions relating to the LLP.</p>

                    <p>19. Every partner shall indemnify the limited liability partnership (LLP) and the other existing partners
                        for any loss caused to it by his/her/its fraud in the conduct of the business of the limited liability
                        partnership (LLP).</p>

                    <p>20. Each partner shall render true accounts and full information of all things affecting the LLP to any
                        partner or his legal representatives.</p>



                    <p>21. In case any of the Partners of the LLP desires to transfer or assign his, her or its interest or
                        share in the LLP he/she has to offer the same to the remaining partners by giving 7 days’ notice . In
                        the absence of any communication by the remaining partners post the expiry of the said 7 days period,
                        the concerned partner can transfer or assign his share in the market.</p>

                    <p>I.&nbsp;&nbsp;&nbsp;&nbsp;No Partner shall without the written consent of all Partners of the LLP:-</p>

                    <ul>
                        <li class="LS-lower-roman">Employ any money, goods or effects of the LLP or pledge the credit thereof
                            except in the ordinary
                            course of business and upon the account or for the benefit of the LLP.</li>
                        <li class="LS-lower-roman">ii. Lend money or give credit on behalf of the LLP or to have any dealings
                            with any persons, Company
                            or firm whom the other Partners previously in writing have forbidden it to trust or deal with. Any
                            loss incurred through any breach of provisions shall be made good with the LLP by the Partner
                            incurring the same.</li>
                    </ul>

                    <p>Enter into any bond or become surety or furnish security with or for any person or do knowingly cause or
                        suffer to be done anything whereby the LLP property or any part thereof may be utilized, attached or
                        seized.</p>

                    <p>Assign, mortgage or charge his or her share in the LLP or any asset or property thereof or make any other
                        person a Partner therein.</p>

                    <p>Compromise or compound or (except upon payment in full) release or discharge any debt due to the LLP
                        except upon the written consent given by the other Partners.</p>

                    <p>Every Partner shall strictly engage to the objects of the LLP and shall not undertake any similar or
                        incidental activity outside the LLP except with the approval of all the Partners.</p>

                    <p>In case any of the Partners of the LLP desires to transfer or assign his interest or shares in the LLP,
                        he can only do so by offering the same to the remaining Partners.</p>

                    <p class="FW-600 pt-5">Meetings of Partners of the LLP</p>

                    <p class="pt-3">22. All matters related to the LLP as mentioned in Schedule B to this LLP Agreement shall be
                        decided by a
                        Resolution passed by majority in number of the partners & for this purpose each partner shall have one
                        vote. The quorum for the Meeting shall be at least two Partners.</p>

                    <p class="pt-3">23. The meeting of the Partners may be called by sending 15 days prior notice to all the
                        partners at
                        their residential address or by mail at the e-mail ID provided by the individual Partners in written to
                        the LLP. In case any partner is a foreign resident the meeting may be conducted by serving 15 days prior
                        notice through e-mail. Provided that the meeting may be called at shorter notice, if the majority of the
                        partners agree in writing to the same either before or after the meeting. In case, any urgent meeting is
                        called, the notice requirement may be ratified by all the Partners.</p>

                    <p class="pt-3">24. Meetings of the Partners shall ordinarily be held at the Registered Office of the LLP or
                        at any other
                        place as per the convenience of partners.</p>

                    <p class="pt-3">25. With the written Consent of all the partners, a Meeting of the Partners may be conducted
                        through
                        Tele-Conferencing or Video-Conferencing.</p>

                    <p class="pt-3">26. The limited liability partnership (LLP) shall ensure that decisions taken by it are
                        recorded in the
                        minutes within thirty days of taking such decisions and are kept and maintained at the registered office
                        of the LLP.</p>



                    <p class="FW-600 pt-4">Duties of Designated Partners</p>

                    <p>27. Each of the two partners out of all partners who were elected through voting system shall be
                        Designated Partners of the LLP. The Designated Partners shall be responsible for the doing of all acts,
                        matters and things as are required to be done by the limited liability partnership (LLP) in respect of
                        compliance of the provisions of the LLP Act including filing of any Document, Return, Statement and the
                        like Report pursuant to the provisions of Limited Liability Partnership Act, 2008.</p>

                    <p>28. The Designated Partners shall be responsible for the doing of all acts and deeds arising out of this
                        LLP Agreement.</p>

                    <p class="FW-600 pt-5">Books of Account</p>

                    <p>29. Books of Accounts of the limited liability partnership (LLP) shall be kept at the registered office
                        of the LLP for reference, access, inspection and having copies of by all the partners.</p>

                    <p>30. The accounting year of the LLP shall be the Financial Year, i.e., from 1st April of the year to 31st
                        March of the subsequent year. The first accounting year shall be from the date of commencement of the
                        LLP till 31st March of the subsequent year.</p>

                    <p>Withdrawal of Capital:</p>

                    <p>No Partner may withdraw capital standing to his credit except on exit or dissolution of the LLP.
                        Notwithstanding the foregoing, the Partners may, upon unanimous consent, withdraw such amounts in their
                        PSR from the Profits of the LLP (as duly certified by a chartered accountant), on a quarterly basis,
                        after defraying all expenses and costs of the LLP.</p>

                    <p class="FW-600 pt-5">Escrow Accounts</p>

                    <p>31. Bank Accounts of the LLP including Loans, Advances & Credit Limits, if any, from the Banks and
                        Financial Institutions taken by the LLP, may be opened and operated by the above mentioned First and
                        Second Party jointly. All Banking operations authority is jointly shared by the above mentioned elected
                        Designated Partners. Any financial transaction of the LLP necessarily requires the consent of both the
                        First and the Second Party. </p>

                    <p>No Partner is entitled to unilaterally enter into any agreement, create any indebtedness or take on any
                        liability (other than in the ordinary course of business) on behalf of the LLP unless expressly
                        authorised in writing by the remaining two Partners. </p>

                    <p class="FW-600 pt-5">Extent of Liability of the LLP</p>

                    <p>32. The LLP is not bound by anything done by a partner in dealing with a person if –</p>

                    <p>(1) the partner in fact has no authority to act for the LLP in doing a particular act; and</p>

                    <p>(2) the person knows that he has no authority or does not know or believe him to be a partner of the LLP.
                    </p>



                    <p class="FW-600">Indemnity</p>

                    <p>33. The LLP shall indemnify and defend its partners and other officers from and against any and all
                        liability in connection with claims, actions and proceedings (regardless of the outcome), judgment, loss
                        or settlement thereof, whether civil or criminal, arising out of or resulting from their respective
                        performances as partners and officers of the LLP, except for the gross negligence or willful misconduct
                        of the partner or officer seeking indemnification.</p>

                    <p>34. The LLP shall not be held liable for the personal liabilities incurred by the partners –</p>

                    <p>(1) For any claims, actions and proceedings (regardless of the outcome), judgment, loss or settlement
                        thereof, whether civil or criminal, arising out of or resulting from their respective performances as
                        partners in its individual capacity.</p>

                    <p class="FW-600 pt-5">Arbitration </p>

                    <p>35. All disputes between the partners or between the Partners and the LLP arising out of the LLP
                        Agreement which cannot be resolved in terms of this LLP Agreement shall be referred for arbitration as
                        per the provisions of the Arbitration and Conciliation Act, 1996 (26 of 1996). The arbitration
                        proceedings shall be conducted by a sole arbitrator appointed mutually by the parties, failing which as
                        appointed by a court of competent jurisdiction in accordance with the Arbitration Act. The seat and
                        venue of arbitration shall be anywhere in India where one of the Parties reside. Courts in India shall
                        have exclusive jurisdiction.</p>

                    <p class="FW-600 pt-5">Cessation of existing Partners</p>

                    <p>36. Any partner may cease to be a partner of the LLP by giving a notice in writing of not less than 7
                        days to the other partners of his intention to resign as partner.</p>

                    <p>37. No majority of Partners can expel any partner except in the situation where any partner has been
                        found guilty of carrying of activity/business of the LLP with fraudulent intent or against the interest
                        of the LLP.</p>

                    <p>The accounts of the LLP shall be taken as closed on the date of resignation/retirement of the Partner and
                        the amount found due from or payable to the resigning/ retiring Partner, shall be paid or recovered
                        within two months of the date of resignation/ retirement, including adjustment towards goodwill, if any.
                        On the exit of any Partner, the remaining Partners shall continue the business of the LLP along with all
                        its assets and liabilities</p>

                    <p class="FW-600 pt-5">Winding up of the LLP</p>

                    <p>38. The LLP can be wound up with the consent of all the partners subject to the provisions of the Limited
                        Liability Partnership Act 2008.</p>

                    <p class="pt-5"><span class="FW-600">IN WITNESS WHEREOF</span> the parties have put their respective hands
                        the day and year first hereinabove written.</p>

                    <p class="mb-5">Signed and delivered by</p>

                    <p>For and on behalf of <span className='text-capitalize font-weight-bold llp_name'></span> LLP </p>
                    <div id='second-party-loop-div'></div>
                </div>
            </div>
        </div>
    )
}

let final_agreement_css = `
    <style>
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: auto;
    }
    
    .FW-600{
        font-weight: 600;
    }
    
    .text-26{
        font-size: 26px !important;
    }
    
    .print-pdf-doc-div .first-page-img{
        margin: 1rem;
        max-width: 100%;
        height : 1500px;
        width: -webkit-fill-available;;
    }
    
    .doc-header{
        background-color: #725aec;
        margin-top: 1rem;
    }
    
    .doc-header img{
        background-color: white;
        width: 320px;
        margin-left: 10rem;
    }
    
    .print-pdf-doc-div .inner-content{
        margin: 1rem 2rem;
        padding: 1rem 5rem;
    }
    
    .print-pdf-doc-div p{
        font-size: 19px;
        text-align: justify;
    }
    
    .custom-border{
        border: 1px solid black;
        padding: .25rem .5rem;
    }
    
    .print-pdf-doc-div .footer{
        border-top: 1px solid black;
        margin-top: 1.2rem;
    }
    
    .print-pdf-doc-div .inner-content ul{
        margin-left: 3rem;
    }
    
    .print-pdf-doc-div .inner-content ul li{
        list-style: upper-roman;
        padding-left: 2rem;
        text-align: justify;
    }
    
    .LS-lower-roman{
        list-style: lower-roman !important;
    }
    </style>
`

export { FinalAgreement, final_agreement_css }