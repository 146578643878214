import {
    Card,
    Table,
    Row,
    Col,
    Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import NotFound from "components/NotFound/NotFound";
import { useGetProposal } from "hooks/useDAO";
import { useProposalDelete } from "hooks/useDAO";
import { toast } from "react-toastify";
import { useEffect } from "react";
import IconEyeImage from "./../../../../assets/img/Icon-eye.png"
import { useSelector } from "react-redux";

const VoatingList = () => {
    const { loading, data: proposals } = useGetProposal({
        property_id: localStorage.getItem("property_id")
    });
    useEffect(() => {
        localStorage.removeItem("vote_id")
    })
    const { mutate: deleteProposal } = useProposalDelete()
    const handleDelete = async (id) => {
        const sure = window.confirm("Are you Sure to Delete?");
        if (sure) {
            await deleteProposal(
                { id: id },
                {
                    onSuccess: async (res) => {
                        toast.success("Proposal Deleted Successfully.")
                    },
                    onError: (error) => {
                        console.log(error)
                    }
                }
            );
        }
    };
    return (
        <Card>
            <Row className="px-4 pt-4 pb-2 d-flex align-items-center">
                <Col className="d-flex justify-content-start">
                    <h3>Proposal List</h3>
                </Col>
                <Col className="d-flex justify-content-end">
                    <Link className="btn btn-primary" to="/company/createvote">
                        <i className="fa fa-plus"></i> Add Vote
                    </Link>
                </Col>
            </Row>
            <Row className="mt-3">
                <div className="col">
                    <Card className="shadow">
                        <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">ID</th>
                                    <th scope="col">VOTING title</th>
                                    <th scope="col">Category</th>
                                    <th scope="col">vote (YES)</th>
                                    <th scope="col">vote(NO)</th>
                                    <th scope="col">STATUS</th>
                                    {/* <th scope="col">Expiry on</th> */}
                                    <th scope="col">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading && (
                                    <tr className="text-center">
                                        <td colSpan="10">
                                            <Spinner animation="border" role="status" >{null}</Spinner>
                                        </td>
                                    </tr>
                                )}

                                {proposals && proposals.length > 0 &&
                                    proposals.map((item, i) => (
                                        <tr key={i}>
                                            <td>{item?.id}</td>
                                            <td>{item?.title}</td>
                                            <td>{item?.category}</td>
                                            <td>{item?.total_voaters_yes != undefined ?
                                                item?.total_voaters_yes
                                                : "Not Started"}</td>
                                            <td>{item?.total_voaters_no != undefined ?
                                                item?.total_voaters_no
                                                : "Not Started"}</td>
                                            <td>{item?.status}</td>
                                            {/* <td>{1}</td> */}
                                            <td>
                                                <div className="d-flex justify-content-start align-items-center">
                                                    <Link
                                                        onClick={() => {
                                                            localStorage.setItem("vote_id", item?.id);
                                                            localStorage.setItem("proposal", JSON.stringify(item));
                                                        }}
                                                        to={{
                                                            pathname: `/company/createvote`,
                                                            state: {
                                                                proposal: item,
                                                            },
                                                        }}
                                                        className="btn btn-sm btn-link bg-transparent"
                                                    >
                                                        <i className="fa fa-edit"></i>
                                                    </Link>

                                                    {!item?.proposal_id &&
                                                        <Link
                                                            onClick={(e) => handleDelete(item?.id)}
                                                            className="btn btn-sm btn-link bg-transparent"
                                                        >
                                                            <i className="fas fa-trash "></i>
                                                        </Link>}
                                                    {item?.proposal_id &&
                                                        <>
                                                            <Link
                                                                to={{
                                                                    pathname: `/company/voatingDetails`,
                                                                    state: {
                                                                        proposal: item,
                                                                    },
                                                                }}
                                                                className="btn btn-sm btn-link bg-transparent"
                                                            >
                                                                <img src={IconEyeImage} alt="" />
                                                            </Link>

                                                        </>
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </Table>
                        {proposals?.length <= 0 &&
                            <NotFound
                                message="Proposal Not Found."
                            />
                        }
                    </Card>
                </div>
            </Row>
        </Card>
    );
};

export default VoatingList;
