import { notificationConstants } from "../constants";
import { notificationService } from "../services";
import { toast } from "react-toastify";

export const notificationActions = {
  getAdminNotifications
};

function getAdminNotifications(data) {
  return (dispatch) => {
    dispatch(request());
    notificationService.getAdminNotifications(data).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };

  function request(res) {
    return { type: notificationConstants.GET_ADMIN_NOTIFICATION_REQUEST, payload: res };
  }
  function success(res) {
    return { type: notificationConstants.GET_ADMIN_NOTIFICATION_SUCCESS, payload: res };
  }
  function failure(error) {
    return { type: notificationConstants.GET_ADMIN_NOTIFICATION_FAILURE, error };
  }
}
