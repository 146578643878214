
import { toast } from "react-toastify";
import { blogConstants } from "redux/constants/blog.constants";
import { blogService } from "redux/services/blog.service";

export const blogsAction = { postBlogs, getBlogs, updateBlogs, deleteBlog, getSingleBlog };

// action for get   blogs
function getBlogs() {
  return (dispatch) => {
    dispatch(request());
    blogService.getBlogs().then(
      (res) => {
        dispatch(success(res));
        // toast.success(res.message);
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };

  function request(res) {
    return {
      type: blogConstants.GET_BLOGS_REQUEST,
    };
  }
  function success(res) {
    return {
      type: blogConstants.GET_BLOGS_SUCCESS,
      payload: res,
    };
  }
  function failure(res) {
    return {
      type: blogConstants.GET_BLOGS_FAILURE,
      payload: res,
    };
  }
}
function getSingleBlog(blog_id) {
  return (dispatch) => {
    dispatch(request());
    blogService.getsingleBlog(blog_id).then(
      (res) => {
        dispatch(success(res));
        // toast.success(res.message);
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };

  function request(res) {
    return {
      type: blogConstants.GET_SINGLE_BLOG_REQUEST,
    };
  }
  function success(res) {
    return {
      type: blogConstants.GET_SINGLE_BLOG_SUCCESS,
      payload: res,
    };
  }
  function failure(res) {
    return {
      type: blogConstants.GET_SINGLE_BLOG_FAILURE,
      payload: res,
    };
  }
}

// action for post   blogs
function postBlogs(data, history) {
  return (dispatch) => {
    dispatch(request());
    blogService.postBlogs(data).then(
      (res) => {
        dispatch(success(res));
        dispatch(blogsAction.getBlogs());
        toast.success("Blog Added Successfully");
        history.push('/admin/blog')
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };

  function request(res) {
    return {
      type: blogConstants.POST_BLOGS_REQUEST,
    };
  }
  function success(res) {
    return {
      type: blogConstants.POST_BLOGS_SUCCESS,
      payload: res,
    };
  }
  function failure(res) {
    return {
      type: blogConstants.POST_BLOGS_FAILURE,
      payload: res,
    };
  }
}

// action for update   blogs
function updateBlogs(data, blog_id, history) {
  return (dispatch) => {
    dispatch(request());
    blogService.updateBlogs(data, blog_id).then(
      (res) => {
        dispatch(success(res));
        toast.success(res.message);
        dispatch(blogsAction.getBlogs());
        history.push('/admin/blog')
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };

  function request(res) {
    return {
      type: blogConstants.UPDATE_BLOGS_REQUEST,
    };
  }
  function success(res) {
    return {
      type: blogConstants.UPDATE_BLOGS_SUCCESS,
      payload: res,
    };
  }
  function failure(res) {
    return {
      type: blogConstants.UPDATE_BLOGS_FAILURE,
      payload: res,
    };
  }
}

// action for delete   blogs
function deleteBlog(blog_id) {
  return (dispatch) => {
    dispatch(request());
    blogService.deleteBlog(blog_id).then(
      (res) => {
        dispatch(success(res));
        toast.success(res.message);
        dispatch(blogsAction.getBlogs());
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };

  function request(res) {
    return {
      type: blogConstants.DELETE_BLOGS_REQUEST,
    };
  }
  function success(res) {
    return {
      type: blogConstants.DELETE_BLOGS_SUCCESS,
      payload: res,
    };
  }
  function failure(res) {
    return {
      type: blogConstants.DELETE_BLOGS_FAILURE,
      payload: res,
    };
  }
}
