import { percentageConstants } from "../../../redux/constants/percentage.constants";

export function getPercentageReducer(state = {}, action) {
    switch (action.type) {
        case percentageConstants.GET_PERCENTAGE_REQUEST:
            return { loading: true };
        case percentageConstants.GET_PERCENTAGE_SUCCESS:
            return { percentages: action.payload.data };
        case percentageConstants.GET_PERCENTAGE_FAILURE:
            return { error: action.error };
        default:
            return state;
    }
}
