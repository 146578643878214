import React, { useState } from 'react'
import { Card, CardBody, CardSubtitle, CardTitle, Nav, NavItem } from 'reactstrap';
import { moneyFormat } from 'utils';
import cartIcon from './../../../assets/img/cart-icon.png';
import timeIcon from './../../../assets/img/timeline.png';
import briefcaseIcon from './../../../assets/img/briefcase.png';
import { useGetPlatformById } from 'hooks/useFeeManagement ';
import { useLocation } from 'react-router-dom';
import { FaArrowLeft } from "react-icons/fa6";
import PlatformOrder from './PlatformOrder';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
const styles = {
    cardStyle: {
        display: 'flex',
        marginTop: "3%",
        justifyContent: "space-between"
    },
    textStyle: {
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 500,
        color: '#82868B',
    },
    numberStyle: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
        fontSize: '24px',
        color: '#7367F0',
    }
}
const headingstyle = {
    fontFamily: 'Montserrat',
    color: "#5E5873",
    fontSize: '24px',
    fontWeight: 500,
}
export default function ChanelPartnerMyCommission() {
    const location = useLocation();
    const { fee = null } = location?.state;
    const history = useHistory();
    const { data } = useGetPlatformById({
        fee_type: fee?.name
    });
    const total = data?.data?.counts;
    const ColorCard = ({ color, title, subTitle, icon }) => {
        const iconStyle = {
            fontSize: '36px',
            marginRight: '10px',
            color: `white`,
        };
        const titleStyle = {
            color: 'white',
            fontFamily: 'Montserrat',
            fontWeight: 600,
        };
        const subTitleStyle = {
            color: 'white',
            fontFamily: 'Montserrat',
            fontWeight: 400,
        };
        return (
            <Card style={{ backgroundColor: color, display: 'flex' }}>
                <CardBody style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: "space-between",
                    paddingTop: "40px",
                    paddingBottom: "40px"
                }}>
                    <div>
                        <CardTitle tag="h2" style={titleStyle}>{moneyFormat(title)}</CardTitle>
                        <CardSubtitle tag="p" style={subTitleStyle}>{subTitle}</CardSubtitle>
                    </div>
                    {icon && (<div style={{
                        marginLeft: 2
                    }}>
                        <img src={icon} />
                    </div>)}
                </CardBody>
            </Card>
        );
    };
    const [selectedOption, setSelectedOption] = useState(1);
    const NewCard = () => {
        const handleOptionChange = (option) => {
            setSelectedOption(option);
        };
        return (
            <Card className='p-2'>
                <div className="d-flex justify-content-between">
                    <div className="btn-group" role="group" aria-label="Options">
                        <button
                            type="button"
                            className={`btn mr-3 d-flex align-items-center ${selectedOption === 1 ? 'btn-primary' : 'white'
                                }`}
                            style={{ borderRadius: "7px" }}
                            onClick={() => handleOptionChange(1)}
                        >
                            <img src={cartIcon} />
                            <span style={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 500 }} className='ml-2'> Orders & transactions</span>
                        </button>
                        <button
                            type="button"
                            className={`btn d-flex align-items-center ${selectedOption === 2 ? 'btn-primary' : 'white'
                                }`}
                            style={{ borderRadius: "7px" }}
                            onClick={() => handleOptionChange(2)}
                        >
                            <img src={timeIcon} />
                            <span style={{ fontFamily: 'Montserrat', fontSize: '14px', fontWeight: 500 }} className='ml-2'>Withdraw timeline</span>
                        </button>
                    </div>
                </div>
            </Card>
        );
    };
    return (
        <>
            <div className="px-4">
                <div className="row mx-0">
                    <div className='col-6 d-flex align-items-center pl-2'>
                        <div onClick={() => history.goBack()}>
                            <FaArrowLeft className='rz-text-18 cursor-pointer mr-2' />
                        </div><h3 className='mb-0' style={headingstyle}>{fee?.name}</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 mt-4">
                        <div className="row">
                            <div className="col-12">
                                <ColorCard
                                    color="#E9573E"
                                    title={total?.totalBalanceInEscrow}
                                    subTitle="Balance in Escrow"
                                    icon={briefcaseIcon}
                                />
                            </div>
                            <div className="col-6 mt-4">
                                <ColorCard
                                    color="#289B96"
                                    title={total?.totalCollectedFees}
                                    subTitle="Total collected"
                                // icon="fas fa-lock"
                                />
                            </div>
                            <div className="col-6 mt-4">
                                <ColorCard
                                    color="#7C278A"
                                    title={total?.totalWithdrawn}
                                    subTitle="Total Withdrawn (net)"
                                // icon="fas fa-arrow-up"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 mt-4">
                        <Card className='py-3 px-4' style={{ boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)" }}>
                            <div className="d-flex justify-content-between">
                                <h2 style={{
                                    fontFamily: 'Montserrat',
                                    fontWeight: 500,
                                    fontSize: '18px',
                                    color: '#5E5873',
                                }} tag="h2">Fee Breakup</h2>
                                {/* <p tag="p"
                                    style={{
                                        fontFamily: 'Montserrat',
                                        fontWeight: 400,
                                        fontSize: '12px',
                                        color: '#6E6B7B',
                                    }}
                                >Updated 1 hour ago <img className='ml-2' src={refreshIcon}/></p> */}
                            </div>
                            <div style={styles.cardStyle}>
                                <h2 tag="h2" style={styles.textStyle}>Total Assets</h2>
                                <CardSubtitle tag="h3" style={styles.numberStyle}>{total?.uniqueAssetCount}</CardSubtitle>
                            </div>
                            <div style={styles.cardStyle}>
                                <h2 tag="h2" style={styles.textStyle}>Total Orders</h2>
                                <CardSubtitle tag="h3" style={styles.numberStyle}>{total?.totalOrders}</CardSubtitle>
                            </div>
                            <div style={styles.cardStyle}>
                                <h2 tag="h2" style={styles.textStyle}>Total Transactions</h2>
                                <CardSubtitle tag="h3" style={styles.numberStyle}>{total?.totalOrderTransactions}</CardSubtitle>
                            </div>
                            <div style={styles.cardStyle}>
                                <h2 tag="h2" style={styles.textStyle}>Total Customers</h2>
                                <CardSubtitle tag="h3" style={styles.numberStyle}>{total?.uniqueUserCount}</CardSubtitle>
                            </div>
                            {/* <div className='mt-5' style={styles.cardStyle}>
                                <h2 tag="h2" style={styles.textStyle}>Last Transaction</h2>
                                <p tag="h3"
                                    style={
                                        {
                                            fontFamily: 'Montserrat',
                                            fontWeight: 600,
                                            fontSize: '16px',
                                            color: '#797979',
                                        }
                                    }>{new Date(data?.data?.[0]?.created_at)?.toDateString()}</p>
                            </div> */}
                        </Card>
                    </div>
                </div>
                {/* <div className='mt-5'>
                    <NewCard />
                </div> */}
                <div className='mt-3'>
                    <PlatformOrder name={fee?.name} data={data?.data} />
                </div>
            </div>
        </>
    )
}
