import { propertyConstants } from '../../constants';

export function propertyReducer(state = {}, action) {
    switch (action.type) {
        case propertyConstants.PROPERTY_REQUEST:
            return {
                ...state,
                isPropertyLoading: true,
            };
        case propertyConstants.PROPERTY_SUCCESS:
            return { property: action.payload };
        case propertyConstants.PROPERTY_FAILURE:
            return {
                ...state,
                isPropertyLoading: false,
            };
        case propertyConstants.PROPERTY_EMPTY:
            return { property: action.payload };
        default:
            return state
    }
}