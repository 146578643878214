import { api } from "../../helpers";

export const rolesService = {
  postRoles,
  getRoles,
  updateRoles,
  deleteRoles,
};

function postRoles(data) {
  return api.post(`/role`, data);
}

function updateRoles(data, role_id) {
  return api.post(`/role/${role_id}`, data);
}

function getRoles() {
  return api.get(`/role`);
}

function deleteRoles(role_id) {
  return api.delete(`/role/${role_id}`);
}
