import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Button, Card, Container, Spinner, Table } from "reactstrap";
import IconEyeImage from "./../../../assets/img/Icon-eye.png"
import { formatNumberWithRupeeSymbol } from "utils";
import { AiOutlineEye } from "react-icons/ai";

const PlatformOrder = ({ name, data }) => {
  return (
    <>
      <Card className="border-none mt-5">
        <Table responsive className="custom-table-div">
          <thead className="page-thead-style">
            <tr>
              <th className="d-flex align-items-center">
                asset Id
                <div className="ml-3">
                  <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </div>
              </th>

              <th>Company name</th>

              <th>
                <div className="d-flex align-items-center">
                  asset name
                  <div className="ml-3">
                    <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
              </th>


              <th>
                <div className="d-flex align-items-center">
                  Fee %
                  <div className="ml-3">
                    <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
              </th>
              <th>
                <div className="d-flex align-items-center">
                  Fee Amount
                  <div className="ml-3">
                    <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
              </th>

              <th>ACTIONS</th>
            </tr>
          </thead>
          <tbody className="page-tbody-cell">
            {(
              data?.fees?.map((order, i) => {
                return (
                  <tr key={i}>
                    <td
                      style={{ cursor: 'pointer' }}
                      className="text-primary">
                      <NavLink
                        to={{
                          pathname: `/admin/FeeManagement/assetWiseOrder/${name}?property_id=${order?.asset_id}`,
                          state: {
                            fee: {
                              name: name,
                              property_id: order?.asset_id
                            }
                          }
                        }}
                      >
                        {order?.asset_id}
                      </NavLink>
                    </td>
                    <td> {order?.company_id} </td>
                    <td> {order?.asset_name} </td>
                    <td>{order?.fees_value} </td>
                    <td> {formatNumberWithRupeeSymbol(order?.fees)}</td>
                    <td className="text-center">
                      <NavLink
                        to={{
                          pathname: `/admin/FeeManagement/assetWiseOrder/${name}?property_id=${order?.asset_id}`,
                          state: {
                            fee: {
                              name: name,
                              property_id: order?.asset_id
                            }
                          }
                        }}
                        color="primary"
                        className="ml-3"
                      >
                        <AiOutlineEye className="ryzer-text-blue cursor-pointer" style={{ fontSize: "22px" }} />
                      </NavLink>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </Table>
      </Card>
    </>
  );
};

export default PlatformOrder;
