import { useQuery, useQueryClient, useMutation } from "react-query";
import { api } from "../helpers";
const useGetCompanies = (options = {}) => {
    return useQuery([`companies`, options],
        async ({ queryKey }) => {
            const { data } = await api.get(`/company`);
            return data;
        }
        , {
            keepPreviousData: true,
            refetchOnWindowFocus: false
        });
}
const useMutateaddCompanyBankAccount = () => {
    return useMutation(
        async (data) => {
            const id = data?.id;
            delete data?.id;
            return await api.put(`/company/addBankAccount/${id}`, data);
        }
    );
};
const useMutateUpdateCompany = () => {
    return useMutation(
        async (data) => {
            const id = data?.id;
            delete data?.id;
            return await api.put(`/company/${id}`, data);
        }
    );
};
const useMutateCreateCompany = () => {
    return useMutation(
        async (data) => {
            await api.post(`/company/create`, data);
        },
        {
            onSuccess: (res) => {
                console.log(res)
            },
            onSettled: () => {
            },
            onError: (err) => {
                console.log(err)
            }
        }
    );
};
const usePropertyRecall = () => {
    return useMutation(
        async (data) => {
            return await api.post(`/property/recall/asset`, data);
        },
        {
            onSuccess: (res) => {
                console.log(res)
            },
            onSettled: () => {
            },
            onError: (err) => {
                console.log(err)
            }
        }
    );
};
const useMutateShareCompany = () => {
    return useMutation(
        async (data) => {
            const id = data?.company_id;
            delete data?.company_id;
            await api.put(`/company/share/create/${id}`, data);
        },
        {
            onSuccess: (res) => {
                console.log(res)
            },
            onSettled: () => {
            },
            onError: (err) => {
                console.log(err)
            }
        }
    );
};
const useMutateAdvisorCompany = () => {
    return useMutation(
        async (data) => {
            const id = data?.company_id;
            delete data?.company_id;
            return await api.put(`/company/advisor/${id}`, data);
        },
        {
            onSuccess: (res) => {
                console.log(res)
            },
            onSettled: () => {
            },
            onError: (err) => {
                console.log(err)
            }
        }
    );
};
const useDeleteLegalAdvisorById = () => {
    return useMutation(
        async (data) => {
            if (data?.id)
                return await api.delete(`/company/advisor/${data?.id}?company_id=${data?.company_id}`);
        }
    );
};
const useMutateAdvisorDirector = () => {
    return useMutation(
        async (data) => {
            const id = data?.company_id;
            delete data?.company_id;
            return await api.put(`/company/director/${id}`, data);
        }
    );
};
const useDeleteDirectorById = () => {
    return useMutation(
        async (data) => {
            if (data?.id)
                await api.delete(`/company/director/${data?.id}?company_id=${data?.company_id}`);
        }
    );
};
const useGetCompanyById = (options = {}, enable) => {
    return useQuery([`user_company_${options?.company_id}`, options],
        async ({ queryKey }) => {
            if (queryKey[1]?.company_id) {
                const data = await api.get(`/company/${queryKey[1]?.company_id}`);
                return data;
            }
            else return {};
        }
        , {
            keepPreviousData: true,
            enable: enable,
            refetchOnWindowFocus: false,
        });
}
const useDeleteCompanyById = () => {
    return useMutation(
        async (data) => {
            if (data?.id)
                await api.delete(`/company/${data?.id}`);
        },
        {
            onSuccess: (res) => {
            },
            onSettled: () => {
            },
        }
    );
};
const useGetCompanyCapById = (options = {}) => {
    return useQuery([`useGetCompanyCapById${options?.company_id}`, options],
        async ({ queryKey }) => {
            if (queryKey[1]?.company_id) {
                const data = await api.get(`/company/cap/${queryKey[1]?.company_id}`);
                return data;
            }
            else return {};
        }
        , {
            keepPreviousData: true,
            refetchOnWindowFocus: false
        });
}
const useGetCompanyPropertyById = (options = {}) => {
    return useQuery([`useGetCompanyPropertyById_${options?.company_id}`, options],
        async ({ queryKey }) => {
            if (queryKey[1]?.company_id && queryKey[1]?.property_id) {
                const data = await api.get(`/company/getCompanyById?company_id=${queryKey[1]?.company_id}&property_id=${queryKey[1]?.property_id}`);
                return data;
            }
            else return {};
        }
        , {
            keepPreviousData: true,
            refetchOnWindowFocus: false
        });
}
const useGetCompanyOrderById = (options = {}) => {
    return useQuery([`useGetCompanyOrderById${options?.company_id}`, options],
        async ({ queryKey }) => {
            if (queryKey[1]?.property_id) {
                const data = await api.get(`/order/company/${queryKey[1]?.property_id}`);
                return data;
            }
            else return {};
        }
        , {
            keepPreviousData: true,
            refetchOnWindowFocus: false
        });
}
const useGetCompanyTokenByPropertyId = (options = {}) => {
    return useQuery([`useGetCompanyTokenByPropertyId${options?.property_id}`, options],
        async ({ queryKey }) => {
            if (queryKey[1]?.property_id) {
                const data = await api.get(`/company/certificate/${queryKey[1]?.property_id}`);
                return data;
            }
            else return {};
        }
        , {
            keepPreviousData: true,
        });
}
const useGetcompanyWithPropertyById = (options = {}) => {
    return useQuery([`user_company_property_${options?.company_id}`, options],
        async ({ queryKey }) => {
            if (queryKey[1]?.company_id) {
                const data = await api.get(`/company/company_property/${queryKey[1]?.company_id}`);
                return data;
            }
            else return {};
        }
        , {
            keepPreviousData: true,
        });
}
export {
    useGetCompanies,
    useMutateUpdateCompany,
    useGetCompanyById,
    useMutateShareCompany,
    useMutateAdvisorCompany,
    useMutateAdvisorDirector,
    useDeleteDirectorById,
    useDeleteLegalAdvisorById,
    useGetCompanyPropertyById,
    useGetcompanyWithPropertyById,
    useGetCompanyTokenByPropertyId,
    useGetCompanyCapById,
    useMutateCreateCompany,
    useMutateaddCompanyBankAccount,
    useGetCompanyOrderById,
    useDeleteCompanyById,
    usePropertyRecall
};
