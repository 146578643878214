import { configConstants } from "redux/constants";

export function getVideoLinkReducer(state = {}, action) {
    switch (action.type) {
        case configConstants.GET_VIDEOLINK_REQUEST:
            return { loading: true };
        case configConstants.GET_VIDEOLINK_SUCCESS:
            return { videolink: action.payload };
        case configConstants.GET_VIDEOLINK_FAILURE:
            return { loading: true };
        default:
            return state
    }
}

export function getPopuptextReducer(state = {}, action) {
    switch (action.type) {
        case configConstants.GET_POPUPTEXT_REQUEST:
            return { loading: true };
        case configConstants.GET_POPUPTEXT_SUCCESS:
            return { popuptext: action.payload };
        case configConstants.GET_POPUPTEXT_FAILURE:
            return { loading: true };
        default:
            return state
    }
}

export function getCityLocationReducer(state = {}, action) {
    switch (action.type) {
        case configConstants.GET_CITYLOCATION_REQUEST:
            return { loading: true };
        case configConstants.GET_CITYLOCATION_SUCCESS:
            return { citylocation: action.payload };
        case configConstants.GET_CITYLOCATION_FAILURE:
            return { loading: true };
        default:
            return state
    }
}