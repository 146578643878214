import React from 'react'
import { Button, Col, FormGroup, Row } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
import { useMutateUpdateCompany } from 'hooks/useCompany';
import { QueryClient } from 'react-query';
import { uploadImage } from 'helpers/image.helper';
import { BsArrowRightShort, BsArrowLeftShort } from "react-icons/bs";
import { Link } from 'react-router-dom';
export default function SPVMemo({ stepper, type, company }) {
  const dispatch = useDispatch();
  const [spv, setSPV] = React.useState("")
  const { mutate: updateCompnay } = useMutateUpdateCompany();

  const [MOA, setMOA] = React.useState("")//MOA
  const [AOA, setAOA] = React.useState("")//AOA

  React.useEffect(() => {
    if (company) {
      setSPV(company?.spv_memo)
      setMOA(company?.moa)
      setAOA(company?.aoa)
    }
  }, [company])
  const queryClient = new QueryClient()

  async function handleSubmit() {
    if (!spv) {
      toast.error("Please fill svp data")
      return;
    }
    let data = {
      "spv_memo": spv || "",
      id: company?.id
    }
    if (MOA) {
      data.moa = await uploadImage(MOA)
      setMOA(null)
    }
    if (AOA) {
      data.aoa = await uploadImage(AOA)
      setAOA(null)
    }
    console.log("data", data)
    await updateCompnay(data, {
      onSuccess: (res) => {
        toast.success('Company Updated Successfully')
        queryClient.invalidateQueries(`user_company_${company?.id}`);
        stepper.next();
      }
    })
  }

  return (
    <>
      <div className='content-header'>
        <h3 className='mb-0 rz-heading rz-text-18'>SPV Memo</h3>
      </div>

      <Row className='mt-3'>
        <FormGroup className='mt-3 mb-5' tag={Col} md='12'>
          <label className='form-label'>
            SPV Memo
          </label>
          <textarea rows={10} cols={10} className='form-control' value={spv} onChange={e => setSPV(e.target.value)}>
          </textarea>
        </FormGroup>
        <FormGroup tag={Col} md='6'>
          <label className='form-label'>
            Upload MOA
          </label>
          <div className='custom-file cursor-pointer'>
            <input
              className='custom-file-input'
              type="file"
              onChange={e => setMOA(e.target.files[0])}
            />
            <label className='custom-file-label'>{MOA?.name ? MOA.name : 'Choose File'}</label>
          </div>

          {typeof MOA == "string" && <a href={`${MOA}`} target="_blank">View Attachement</a>}
        </FormGroup>
        <FormGroup tag={Col} md='6'>
          <label className='form-label'>
            Upload AOA
          </label>
          <div className='custom-file cursor-pointer'>
            <input
              className='custom-file-input'
              type="file"
              onChange={e => setAOA(e.target.files[0])}
            />
            <label className='custom-file-label'>{AOA?.name ? AOA.name : 'Choose File'}</label>
          </div>

          {typeof AOA == "string" && <a href={`${AOA}`} target="_blank">View Attachement</a>}
        </FormGroup>
      </Row>
      <div className='mt-5 align-item-center d-flex justify-content-between'>
        <div>
          <Button type='button' color='success' onClick={() => stepper.next()} className='btn-next' >
            <span className='align-middle d-sm-inline-block d-none'>Skip</span>
            <i className='fa fa-fast-forward align-middle mt-1 mr-sm-25 mr-0'></i>
          </Button>
        </div>
        <div className='d-flex align-item-center justify-content-end'>
          <Link to='/admin/company'>
            <Button type='button' className='rz-button-outline-primary'>
              Cancel
            </Button>
          </Link>
          <Button color='primary' className='btn-prev ml-3 mr-2 d-flex rz-button-outline-primary' onClick={() => stepper.previous()}>
            <BsArrowLeftShort style={{ fontSize: "22px" }} />
            <span className='align-middle d-sm-inline-block d-none'>Back</span>
          </Button>
          <Button type='button' onClick={() => handleSubmit()} className='btn-next rz-button-primary'>
            <BsArrowRightShort style={{ fontSize: "22px" }} />
            <span className='align-middle d-sm-inline-block d-none'>Next</span>

          </Button>
        </div>
      </div>
    </>
  )
}
