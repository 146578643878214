import moment from "moment";

const devideBy = 365;
export const getMonthInterestValue = (initialValue, interestRatePercentage) => {
    const timePeriod = 1 / 12;
    initialValue = initialValue * (1 + (interestRatePercentage / 100) / devideBy) ** (devideBy * timePeriod);
    return initialValue;
}
export const getInterestPercentage = (sqyds_end_price, sqyds_start_price) => {
    var avgGrowthPercent = (Math.pow(((sqyds_end_price / sqyds_start_price)), 1 / (devideBy * 11 / 12)) - 1) * devideBy * 100
    return avgGrowthPercent;
}
export const getInterestPercentageSimpleIntrest = (sqyds_end_price, sqyds_start_price) => {
    let ROI = ((sqyds_end_price - sqyds_start_price) / sqyds_start_price) * 100;
    return ROI;
}

export function serializeParams(obj) {
    var str = [];
    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
        }
    return str.join('&');
}
export function sortByMonth(arr) {
    var months = moment.monthsShort();
    arr = arr?.sort(function (a, b) {
        return months.indexOf(a.month)
            - months.indexOf(b.month);
    });
    return arr;
}