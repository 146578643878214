export const settingConstants = {
    
    GET_SETTINGS_REQUEST: "GET_SETTINGS_REQUEST",
    GET_SETTINGS_SUCCESS: "GET_SETTINGS_SUCCESS",
    GET_SETTINGS_FAILURE: "GET_SETTINGS_FAILURE",

    ADD_SETTINGS_REQUEST: "ADD_SETTINGS_REQUEST",
    ADD_SETTINGS_SUCCESS: "ADD_SETTINGS_SUCCESS",
    ADD_SETTINGS_FAILURE: "ADD_SETTINGS_FAILURE",

    UPDATE_SETTINGS_REQUEST: "UPDATE_SETTINGS_REQUEST",
    UPDATE_SETTINGS_SUCCESS: "UPDATE_SETTINGS_SUCCESS",
    UPDATE_SETTINGS_FAILURE: "UPDATE_SETTINGS_FAILURE",

    DELETE_SETTINGS_REQUEST: "DELETE_SETTINGS_REQUEST",
    DELETE_SETTINGS_SUCCESS: "DELETE_SETTINGS_SUCCESS",
    DELETE_SETTINGS_FAILURE: "DELETE_SETTINGS_FAILURE",

};
