import moment from "moment";
import { useLocation } from "react-router-dom";
import { Card, Container ,Button} from "reactstrap"


const SuperAdminWithdrawDetails = () => {
    const location=useLocation()
    const withdraw=location?.state?.withdraw;
    return (
        <>
            <Container fluid>
                <Card className="py-3 px-3">
                    <table class="table table-borderless">
                        <tbody>
                            <tr>
                                <th scope="col"><h3>Details</h3></th>
                            </tr>
                            <tr>
                                <th scope="col">Company Name</th>
                                <th scope="col">{withdraw?.company?.name}</th>
                            </tr>
                            <tr>
                                <th scope="row">Requested amount</th>
                                <td>{withdraw?.amount_net}</td>
                            </tr>
                            <tr>
                                <th scope="row">Requested by</th>
                                <td>{withdraw?.admin?.name}</td>
                            </tr>
                            <tr>
                                <th scope="row">Requested Date</th>
                                <td>{moment(withdraw?.created_at).format("D-MMM-Y")}</td>
                            </tr>
                            <tr>
                                <th scope="row">Transfer to</th>
                                <td></td>
                            </tr>
                            <tr>
                                <th scope="row">Additional Information</th>
                                <td>{withdraw?.additional_information}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="d-flex py-3 justify-content-end">
                        <Button className="rz-button-outline-danger">
                            Reject
                        </Button>
                        <Button className='ml-3 rz-button-success'>
                            Approve
                        </Button>
                    </div>
                </Card>
                
            </Container>
        </>
    )
}

export default SuperAdminWithdrawDetails
