import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Button, Card, Container, Spinner, Table, Modal, Badge, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { AiOutlineEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import moment from "moment";

const WithdrawTimeline = ({ data = [] }) => {

    return (
        <>
            <Card className="bitem-none mt-5">
                <Table responsive className="custom-table-div">
                    <thead className="page-thead-style">
                        <tr>
                            <th className="d-flex align-items-center">
                                Payment date

                            </th>

                            <th>payment type</th>

                            <th>
                                <div className="d-flex align-items-center">
                                    Asset name

                                </div>
                            </th>


                            <th>
                                <div className="d-flex align-items-center">
                                    AMOUNT
                                    (Gross)

                                </div>
                            </th>
                            <th>
                                <div className="d-flex align-items-center">
                                    AMOUNT
                                    (Net)

                                </div>
                            </th>
                            <th>
                                <div className="d-flex align-items-center">
                                    status

                                </div>
                            </th>
                            <th>
                                <div className="d-flex align-items-center">
                                    ACTIONS
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="page-tbody-cell">
                        {(
                            data?.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td
                                            style={{ cursor: 'pointer' }}
                                            className="text-primary">
                                            <NavLink
                                                to={{
                                                    pathname: ``,

                                                }}
                                            >
                                                {moment(item?.created_at).format("D-MMM-Y")}
                                            </NavLink>
                                        </td>
                                        <td> {item?.payment_type}</td>
                                        <td>{item?.property_id}</td>
                                        <td> ₹ {item?.amount}</td>
                                        <td> ₹ {item?.amount_net}</td>
                                        <td>
                                            {
                                                item?.status == 'Payment Pending' ?
                                                    <Badge className="rental-payout-pending">
                                                        Payment Pending
                                                    </Badge> : item?.status == 'Payment Done' ?
                                                        <Badge className="rental-payout-paid">
                                                            Payment Paid
                                                        </Badge>

                                                        : <Badge className="rental-payout-hold">
                                                            Payment Failed
                                                        </Badge>
                                            }
                                        </td>
                                        <td className="d-flex justify-content-end">
                                            {
                                                item?.status=='Payment Done' ?
                                                    <Link to=''><AiOutlineEye style={{ fontSize: "22px" }} className="ryzer-text-blue" /></Link> : null
                                            }
                                        </td>
                                    </tr>
                                );
                            })
                        )}
                    </tbody>
                </Table>
            </Card>
        </>
    );
};

export default WithdrawTimeline;

