import Paginate from "components/Paginate";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useParams, useLocation } from "react-router-dom";
import { Button, Card, Container, Spinner, Table } from "reactstrap";
import BadgeImage from "../../assets/img/Badge.png"
import BadgeDelayImage from "../../assets/img/Badge-delay.png"
import IconEditImage from "../../assets/img/Icon-edit.png"
import IconEyeImage from "../../assets/img/Icon-eye.png"
import { useDeleteCustomer, useGetCustomer, useSoftDeleteCustomer } from "../../hooks/useMeQuery";
import queryString from 'query-string';
import NotFound from "components/NotFound/NotFound";
import { toast } from "react-toastify";
const CustomerList = () => {
  const { search } = useLocation();
  const page = queryString.parse(search)?.page
  const [queryParams, setQueryParams] = useState("");
  const [queryParamsOld, setQueryParamsOld] = useState(false);
  const { mutate: removeCustomerDb } = useDeleteCustomer()
  const { mutate: trashCustomerDb } = useSoftDeleteCustomer()
  const [filtterData, setFilterData] = useState({
    name: "",
    phone: "",
    kyc: null
  })
  const { data, isLoading: loading, pager, refetch } = useGetCustomer({
    page: page ? page : 1,
    filter: filtterData
  });
  console.log(data);

  // useEffect(() => {
  //   if (filtterData.phone && filtterData.phone != "") {
  //     setQueryParamsOld(true)
  //   }
  //   if (filtterData.name && filtterData.name != "") {
  //     setQueryParamsOld(true)
  //   };
  //   if (filtterData.kyc && filtterData.kyc != null) {
  //     setQueryParamsOld(true)
  //   };
  // }, [filtterData]);

  const removeCustomer = async (user_id) => {
    const confirmed = window.confirm(`Are you sure? you want to delete this customer.?`);
    if (confirmed) {
      await removeCustomerDb({
        user_id: user_id
      }, {
        onSuccess: (res) => {
          toast.success('Customer Deleted Successfully');
          refetch();
          return;
        },
        onError: (err) => {
          toast.error('Something Went Wrong.');
          return;
        }
      })
    }
  }
  const trashCustomer = async (user_id) => {
    const confirmed = window.confirm(`Are you sure? you want to trash this customer.?`);
    if (confirmed) {
      await trashCustomerDb({
        user_id: user_id
      }, {
        onSuccess: (res) => {
          toast.success('Customer Trashed Successfully');
          refetch();
          return;
        },
        onError: (err) => {
          toast.error('Something Went Wrong.');
          return;
        }
      })
    }
  }
  return (
    <>
      <Container className="mt-2" fluid>
        <div className='d-flex justify-content-start align-items-center mb-2'>
          <div className='col-6'>
            <input
              onChange={(e) => setFilterData({
                ...filtterData,
                phone: e.target.value
              })}
              value={filtterData.phone}
              className='form-control'
              placeholder="Enter mobile"
              id="phone" />
          </div>
          <div className='col-6'>
            <input
              onChange={(e) => setFilterData({
                ...filtterData,
                name: e.target.value
              })}
              value={filtterData.name}
              className='form-control'
              placeholder="Enter name or email"
              id="name" />
          </div>
          {/* <div className='col-3'>
            <select
              value={filtterData.kyc}
              onChange={(e) => setFilterData({
                ...filtterData,
                kyc: e.target.value
              })}
              className='form-control'>
              <option selected value=''>Select KYC Status</option>
              <option selected={filtterData?.kyc == "Yes"} value='Yes'>Yes</option>
              <option selected={filtterData?.kyc == "No"} value='No'>No</option>
            </select>
          </div> */}
          {/* <button
            onClick={() => {
              if (queryParamsOld) {
                setQueryParams(filtterData);
                refetch();
              }
            }
            }
            disabled={!queryParamsOld}
            className="btn btn-primary" type="button">
            Apply Filter
          </button> */}
          {/* <button onClick={() => {
            if (queryParamsOld) {
              setFilterData({
                phone: "",
                name: "",
                kyc: null
              });
              setQueryParams(filtterData)
            }
          }}
            className="btn btn-primary" type="button">
            Clear Filter
          </button> */}
        </div>
        <Card className="mt-5">
          <Table responsive className="custom-table-div">
            <thead className="rz-thead-style">
              <tr>
                <th className="d-flex align-items-center">
                  CUSTOMER ID

                </th>

                <th>
                  <div className="d-flex align-items-center">
                    CUSTOMER NAME

                  </div>
                </th>

                <th>
                  <div className="d-flex align-items-center">
                    Mobile No

                  </div>
                </th>

                <th>
                  <div className="d-flex align-items-center">
                    Wallet Address

                  </div>
                </th>

                <th>
                  <div className="d-flex align-items-center">
                    LAST ACTIVITY

                  </div>
                </th>

                <th>
                  <div className="d-flex align-items-center">
                    INVESTED

                  </div>
                </th>

                <th>
                  <div className="d-flex align-items-center">
                    TOTAL VALUE

                  </div>
                </th>

                <th>CURRENT LOCATION</th>
                <th>E-KYC</th>
                <th className="text-right">ACTIONS</th>
              </tr>
            </thead>
            {loading ? (
              <tr className="text-center">
                <td colSpan="10">
                  <Spinner animation="border" role="status" >{null}</Spinner>
                </td>
              </tr>
            ) : (
              <tbody>
                {data?.data?.map((customer) => {
                  return (
                    <tr className="" key={customer?.id}>
                      <td>  <NavLink to={{
                        pathname: `/admin/customer/${customer?.id}`,
                        state: {
                          customer: customer,
                          editable: false,
                        },
                      }}
                        color="primary">#{customer?.id} </NavLink></td>
                      <td>
                        <NavLink to={{
                          pathname: `/admin/customer/${customer?.id}`,
                          state: {
                            customer: customer,
                            editable: false,
                          },
                        }}
                          color="primary">
                          <div className="d-flex align-items-center">
                            <img
                              className="mx-2"
                              width="30"
                              src={customer?.avatar}
                              alt=""
                            />
                            <div className="d-flex flex-column">
                              <span className="customer-name">{customer?.name}</span>
                              <span className="customer-mail">{customer?.email}</span>
                            </div>
                          </div>
                        </NavLink>
                      </td>
                      <td>
                        {customer?.phone}
                      </td>
                      <td>
                        {customer?.wallet_address ? customer?.wallet_address : "Not Provided"}
                      </td>
                      <td>
                        {new Date(customer?.updated_at).toDateString()} <br />
                        {new Date(customer?.updated_at).toLocaleTimeString()}
                      </td>
                      <td> {customer?.total_investment_sqyds ? customer?.total_investment_sqyds : 0} </td>
                      <td> {customer?.total_investment_amount ? customer?.total_investment_amount : 0} </td>{/* + <span className="extra-amt">1001</span> */}

                      <td>{customer?.country ? customer?.country : "Not Provided"}</td>

                      <td className="text-capitalize">
                        {customer?.kyc?.status == "auto_approved"
                          ? <img src={BadgeImage} alt="" />
                          : <img src={BadgeDelayImage} alt="" />}
                      </td>
                      <td className="text-right">
                        {/* <NavLink
                          to={{
                            pathname: `/admin/customer/${customer?.id}`,
                            state: {
                              customer: customer,
                              editable: false,
                            },
                          }}
                          color="primary"
                        >
                          <img src={IconEyeImage} alt="" />

                        </NavLink>
                        <NavLink
                          to={{
                            pathname: `/admin/customer/${customer?.id}`,
                            state: {
                              customer: customer,
                              editable: false,
                            },
                          }}
                          color="primary"
                          className="ml-3"
                        >
                          <img src={IconEditImage} alt="" />

                        </NavLink> */}
                        <Button
                          className="btn btn-sm btn-link text-danger bg-transparent border-0"
                          onClick={() => trashCustomer(customer?.id)}
                        >
                          Soft Delete <i className="fa fa-trash"></i>
                        </Button>
                        <Button
                          onClick={() => removeCustomer(customer?.id)}
                          className="btn btn-sm btn-link text-danger bg-transparent border-0"
                        >
                          Hard Delete <i className="fa fa-trash"></i>
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </Table>
          {data?.data?.length <= 0 &&
            <NotFound
              message="Invite customer into your mobile app."
            />
          }
          {data?.data?.length > 0 && data?.pager && data?.pager.pages.length && (
            <Paginate path="/admin/customer" pager={data?.pager}
            />
          )}
          <div className="ml-3 mb-2">
            <NavLink
              to={{
                pathname: `/admin/archive-customer`
              }}
              color="primary"
            >
              Archive Customer

            </NavLink>
          </div>
        </Card>

      </Container>
    </>
  );
};

export default CustomerList;
