import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import MyCommissionById from '../comman/MyCommissionById/MyCommissionById';
import { useGetAssetSellerMyCommissionById } from 'hooks/useAssetSeller';
export default function AssetSellerMyCommissionById() {
    const location = useLocation();
    const { asset = null,type } = location?.state;
    const { data } = useGetAssetSellerMyCommissionById({
        property_id: asset?.property_id
    })
    
    const total = data?.total
    return (
        <>
            <div className="px-4">
                <MyCommissionById
                    total={total}
                    data={data}
                    type={type}
                    asset={asset}
                />
            </div>
        </>
    )
}
