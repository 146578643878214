import { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Button, Col, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Card, Container, Spinner, Table, Badge } from 'reactstrap';
import { percentageActions } from "../../redux/actions/percentage.actions";
import { Controller, useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import classnames from 'classnames'
import { CheckPermission } from "utils";
import { PERMISSIONS } from "utils";
import { RESOURCE } from "utils";
import Select from 'react-select';
import NotFound from "components/NotFound/NotFound";
import { percentageService } from "redux/services";
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';


const PropertySetting = ({
    property,
    parentStepper,
    stepper
}) => {
    const { propertyPercentages: percentages, loading: ploading } = useSelector((state) => state.getPropertyPercentageReducer);
    const dispatch = useDispatch();
    const [isModalOpenP, setIsModalOpenP] = useState(false);
    const [columnList, setColiumnList] = useState([]);
    const [selectedPercentage, setselectedPercentage] = useState();
    const SettingSchema = yup.object().shape({
        name: yup.string().required(),
    })
    const { register: register2,
        handleSubmit: handleSubmit2,
        control,
        setValue: setValue2,
        getValues: getValues2,
        reset: reset2,
        formState: { errors: errors2 } } = useForm({
            resolver: yupResolver(SettingSchema)
        })

    useEffect(() => {
        dispatch(percentageActions.getPropertyPercentages(property?.id));
    }, [property]);

    useEffect(() => {
        setIsModalOpenP(false);
    }, [percentages]);

    const syncProperty = async () => {
        await percentageService.syncPropertyPercentages(property?.id)
        dispatch(percentageActions.getPropertyPercentages(property?.id));
    }

    useEffect(() => {
        const uniquePropertyTypes = percentages?.reduce((accumulator, current) => {
            current?.property_types?.forEach(type => {
                if (!accumulator.find(accType => accType.value === type.value)) {
                    accumulator.push(type);
                }
            });
            return accumulator;
        }, []);
        setColiumnList(uniquePropertyTypes)
    }, [percentages]);

    const property_types = useWatch({
        control,
        name: "property_types",
    });

    const onPropertyPercentageStatusChange = (id, e) => {
        let data = {
            id,
            status: e.target.checked ? true : false,
        };
        dispatch(
            percentageActions.genericPropertyPercentages(data, "updatePropertyPercentages", property?.id)
        );
    };

    const onSubmit2 = () => {
        let value = selectedPercentage?.value;
        let data = {
            id: selectedPercentage?.id,
            property_types: selectedPercentage?.property_types,
            types: getValues2("types"),
            index: getValues2("index"),
        }
        const type = selectedPercentage?.property_types?.[0]?.value;
        if (type == "LAND PARCEL") {
            data["LAND PARCEL"] = getValues2("LAND PARCEL");
            value = getValues2("LAND PARCEL");
        }
        if (type == "RESIDENTIAL") {
            data["RESIDENTIAL"] = getValues2("RESIDENTIAL")
            value = getValues2("RESIDENTIAL");
        }
        if (type == "COMMERCIAL") {
            data["COMMERCIAL"] = getValues2("COMMERCIAL")
            value = getValues2("COMMERCIAL");
        }
        if (type == "HOLIDAY_HOME") {
            data["HOLIDAY_HOME"] = getValues2("HOLIDAY_HOME")
            value = getValues2("HOLIDAY_HOME");
        }
        data.value = value;
        setIsModalOpenP(false);
        if (data.id)
            dispatch(percentageActions.genericPropertyPercentages(data, 'updatePropertyPercentages', property?.id));
        else
            dispatch(percentageActions.genericPropertyPercentages(data, 'storePropertyPercentages', property?.id))
    }

    const addEditModal = (settingId) => {
        setIsModalOpenP(!isModalOpenP);
        if (settingId == null) return reset2();
        var percentage = percentages?.find(({ id }) => id == settingId);
        setValue2('name', percentage?.name)
        setValue2('property_types', percentage?.property_types)
        setValue2('types', percentage?.types)
        setValue2('index', percentage?.index)
        percentage?.property_types?.forEach(item => {
            setValue2(`${item.value}`, `${item.percentage || 0}`);
        });
        setValue2('id', percentage?.id)
        setselectedPercentage(percentage)
    }
    const onDeleteSetting = (settingId, check) => {
        const confirm = window.confirm("Are you sure to Delete?");
        if (confirm) {
            dispatch(percentageActions.genericPropertyPercentages(settingId, 'deletePropertyPercentages', property?.id));
        }
    };

    return (
        <div style={{ overflowX: "scroll" }}>
            <Row className="mt-4">
                <Col className="d-flex justify-content-between  align-items-center">
                    <h3 className='mb-0 rz-heading rz-text-16'>Manage Fee</h3>
                    <Button
                        onClick={() => syncProperty()}
                        className="rz-button-primary"
                    >
                        {"Sync Property Percentage "}
                    </Button>
                </Col>
            </Row>
            <Card className="mt-5">
                <Table >
                    <thead className="rz-thead-style">
                        <tr>
                            <th>Name</th>
                            <th>Asset Type</th>
                            {columnList?.map((d) => (
                                <th>{d?.label}%</th>
                            ))}
                            <th>Type</th>
                            <th>Order</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ploading ? (
                            <tr className="text-center">
                                <td colSpan="10">
                                    <Spinner animation="border" role="status" >{null}</Spinner>
                                </td>
                            </tr>
                        ) : (
                            percentages?.map((percentage, i) => {
                                return (
                                    <tr key={i}>
                                        <td> {percentage.name} </td>
                                        <td>
                                            {(percentage?.property_types)?.map((d) => (
                                                <Badge
                                                    color=""
                                                    className={`badge badge-pill badge-primary`}
                                                >
                                                    {d?.label}
                                                </Badge>
                                            ))}
                                        </td>
                                        {columnList?.map(column => {
                                            const match = percentage?.property_types.find(
                                                type => type.value == column.value
                                            );

                                            return match
                                                ? { ...column, percentage: match.percentage }
                                                : { ...column, percentage: '0' };
                                        })?.map((d) => (
                                            <td>{d.percentage || 0}</td>
                                        ))}
                                        <td>
                                            <div className="d-flex">
                                                {(percentage?.types || [])?.map((d) => (
                                                    <Badge
                                                        color=""
                                                        className={`badge badge-pill badge-primary mr-1`}
                                                    >
                                                        {d?.label}
                                                    </Badge>
                                                ))}
                                            </div>
                                        </td>
                                        <td> {percentage.index} </td>
                                        <td >
                                            <div className="custom-control custom-switch">
                                                <input
                                                    type="checkbox"
                                                    disabled={!CheckPermission(PERMISSIONS?.CREATE, RESOURCE?.Setting)}
                                                    checked={percentage.status == true ? true : false}
                                                    name={`property_status`}
                                                    onChange={(e) =>
                                                        onPropertyPercentageStatusChange(percentage.id, e)
                                                    }
                                                    className="custom-control-input"
                                                    id={`property-status-${percentage.id}`}
                                                />
                                                <label
                                                    className="custom-control-label"
                                                    htmlFor={`property-status-${percentage.id}`}
                                                >
                                                    <Badge
                                                        color=""
                                                        className={`badge badge-pill badge-${percentage.status == "Active"
                                                            ? "success"
                                                            : "primary"
                                                            } mr-4`}
                                                    >
                                                        {percentage.status}
                                                    </Badge>
                                                </label>
                                            </div>
                                        </td>
                                        <td>
                                            <Button
                                                onClick={() => addEditModal(percentage?.id)}
                                                color="primary"
                                                className="btn-sm"
                                            >
                                                <i className="fas fa-pen"></i>
                                            </Button>

                                            <Button
                                                onClick={() => onDeleteSetting(percentage?.id)}
                                                color="primary"
                                                className="btn-sm"
                                            >
                                                <i className="fas fa-trash"></i>
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            })
                        )}
                    </tbody>
                </Table>
                {percentages?.length <= 0 &&
                    <div className='d-flex p-3 flex-column justify-content-center align-items-center'>
                        <NotFound
                            message="No Fees Added Please Sync Fees"
                        />
                        <Button
                            onClick={() => syncProperty()}
                            className="rz-button-primary"
                        >
                            {"Sync Property Percentage "}
                        </Button>
                    </div>
                }
            </Card>
            <Modal isOpen={isModalOpenP} centered={true} className='modal-lg'>
                <Form>
                    <ModalHeader>
                        PropertyPercentages
                    </ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label className='form-label' htmlFor={`name`}>
                                Name
                            </Label>
                            <input
                                disabled={true}
                                {...register2(`name`)}
                                className={classnames({ 'is-invalid': errors2.name, 'form-control': true })}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label className='form-label' htmlFor={`name`}>
                                Order
                            </Label>
                            <input
                                {...register2(`index`)}
                                className={classnames({ 'is-invalid': errors2.index, 'form-control': true })}
                            />
                        </FormGroup>
                        {/* <FormGroup>
                            <Label className='form-label' htmlFor={`PropertyPercentagestype`}>
                                Asset Type *
                            </Label>
                            <Controller
                                name={`property_types`}
                                control={control}
                                render={({ field }) =>
                                    <Select
                                        {...field}
                                        isMulti
                                        options={[
                                            { value: `${PROPERTY_TYPE?.LANDPARCEL}`, label: 'Land Parcel' },
                                            { value: `${PROPERTY_TYPE?.RESIDENTIAL}`, label: 'Residential' },
                                            { value: `${PROPERTY_TYPE?.COMMERCIAL}`, label: 'Commercial' },
                                            { value: `${PROPERTY_TYPE?.HOLIDAY_HOME}`, label: 'Holiday Home' },
                                            { value: `${PROPERTY_TYPE?.AGRICULTURE_LAND}`, label: 'Agriculture Land' }
                                        ]}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />}
                            />
                        </FormGroup> */}
                        {
                            property_types?.map((pt) => (
                                <FormGroup>
                                    <Label className='form-label' htmlFor={`${pt?.value}`}>
                                        {pt?.label} PropertyPercentage
                                    </Label>
                                    <input
                                        number
                                        rows='4'
                                        {...register2(`${pt?.value}`)}
                                        className={classnames({ 'is-invalid': errors2.pt?.value, 'form-control': true })}
                                    />
                                </FormGroup>
                            ))
                        }
                        <FormGroup>
                            <Label className='form-label' htmlFor={`PropertyPercentagestype`}>
                                Fees Type (Buy,Sell)
                            </Label>
                            <Controller
                                name={`types`}
                                control={control}
                                render={({ field }) =>
                                    <Select
                                        {...field}
                                        isMulti
                                        options={[
                                            { value: `BUY`, label: 'BUY' },
                                            { value: `SELL`, label: 'SELL' },
                                        ]}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => setIsModalOpenP(!isModalOpenP)}>
                            Cancel
                        </Button>
                        {' '}
                        <Button
                            onClick={() => onSubmit2()}
                            color="primary"
                        >
                            {ploading ? 'Saving...' : 'Save'}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
            <div className='d-flex align-items-center justify-content-between mt-5'>
                <Button type='button' color='success' onClick={() => stepper.next()} className='btn-next' >
                    <span className='align-middle d-sm-inline-block d-none'>Skip</span>
                    <i className='fa fa-fast-forward align-middle mt-1 mr-sm-25 mr-0'></i>
                </Button>

                <div className='flex align-items-center justify-content-end '>
                    <Button className='rz-button-outline-primary mr-2 ml-3' onClick={() => stepper.previous()}>
                        <BsArrowLeftShort style={{ fontSize: "22px" }} />
                        <span className='align-middle d-sm-inline-block d-none'>Back</span>
                    </Button>
                    <Button className='rz-button-primary' onClick={() => stepper.next()}>
                        <BsArrowRightShort style={{ fontSize: "22px" }} />
                        <span className='align-middle d-sm-inline-block d-none'>Next</span>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default PropertySetting;
