import { useMutation, useQuery } from "react-query";
import { api } from "../helpers";
import { Serializer } from "utils";
const fetchUserdata = async ({ queryKey }) => {
    const { data } = await api.get('/me');
    return data;
};

const useMeQuery = (options = {}) => {
    return useQuery([`admin_user`, options], fetchUserdata, {
        keepPreviousData: true,
    });
};
const useGetRoleData = (options = {}) => {
    return useQuery([`roleslist`, options],
        async ({ queryKey }) => {
            const { data } = await api.get('/role');
            return data;
        }
        , {
            keepPreviousData: true,
        });
};
const useGetCustomer = (options = {}) => {
    return useQuery([`customer_list${options.page}`, options],
        async ({ queryKey }) => {
            const data = await api.get(`/users?role=2&page=${queryKey[1].page}&${Serializer(queryKey[1]?.filter)}`);
            return data;
        }
        , {
            keepPreviousData: true,
            refetchOnWindowFocus: false
        });
};
const useGetArchiveCustomer = (options = {}) => {
    return useQuery([`customer_archive_list${options.page}`, options],
        async ({ queryKey }) => {
            const data = await api.get(`/users/archive/list?role=2&page=${queryKey[1].page}&${Serializer(queryKey[1]?.filter)}`);
            return data;
        }
        , {
            keepPreviousData: true,
            refetchOnWindowFocus: false
        });
};
const useGetPortfolioById = (options = {}) => {
    return useQuery([`userPortfolio_${options?.user_id}`, options],
        async ({ queryKey }) => {
            if (queryKey[1]?.user_id) {
                const { data } = await api.get(`/me/portfolioById/${queryKey[1]?.user_id}`);
                return data;
            }
            else return {};

        }
        , {
            keepPreviousData: true,
        });
}
const useGetAllRole = (options = {}) => {
    return useQuery([`role_list`, options],
        async ({ queryKey }) => {
            const { data, pager } = await api.get('/role');
            return { data, pager };
        }
        , {
            keepPreviousData: true,
        });
};
const useDeleteCustomer = () => {
    return useMutation(
        async (data) => {
            await api.post('/user/delete', data);
        },
        {
            onSuccess: (res) => {
            },
            onSettled: () => {
            },
        }
    );
};
const useSoftDeleteCustomer = () => {
    return useMutation(
        async (data) => {
            await api.post('/user/softdelete', data);
        },
        {
            onSuccess: (res) => {
            },
            onSettled: () => {
            },
        }
    );
};
const useRestoreCustomer = () => {
    return useMutation(
        async (data) => {
            await api.post('/user/restoreUser', data);
        },
        {
            onSuccess: (res) => {
            },
            onSettled: () => {
            },
        }
    );
};
const fetchKycDetails = async ({ queryKey }) => {
    const { data } = await api.get(`me/channel/kyc`);
    return data;
}

const useGetKycDetails = (options = {}) => {
    return useQuery([`kycDetails`, options],
        async ({ queryKey }) => {
            const { data } = await api.get(`me/channel/kyc`);
            return data;
        }
        , {
            keepPreviousData: true,
            refetchOnWindowFocus: false
        });
}
const useGetChannelKycWithId = (options = {}) => {
    return useQuery([`useGetChannelKycWithId_${options.user_id}`, options],
        async ({ queryKey }) => {
            if (queryKey[1]?.user_id) {
                const { data } = await api.get(`channel/kyc/${queryKey[1]?.user_id}`);
                return data;
            }
            else return {};

        }
        , {
            keepPreviousData: true,
            refetchOnWindowFocus: false
        });
}
const useUpdateKycDetails = () => {
    return useMutation(
        async (data) => {
            return await api.put(`/me/channel/kyc`, data);
        }
    );
};
const useUpdateUnSubcribes = () => {
    return useMutation(
        async (data) => {
            return await api.get(`/unsubscribe/${data?.token}/1`);
        }
    );
};
const useUpdateChannelKycDetails = () => {
    return useMutation(
        async (data) => {
            const user_id = data?.user_id;
            delete data?.user_id;
            return await api.put(`/channel/kyc/${user_id}`, data);
        }
    );
};
export {
    useMeQuery,
    useGetRoleData,
    useGetCustomer,
    useGetPortfolioById,
    useGetAllRole,
    useDeleteCustomer,
    useGetKycDetails,
    useUpdateKycDetails,
    useGetChannelKycWithId,
    useUpdateChannelKycDetails,
    useUpdateUnSubcribes,
    useSoftDeleteCustomer,
    useGetArchiveCustomer,
    useRestoreCustomer
};
