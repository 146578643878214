import React, { useState } from 'react'
import { Card, CardBody, CardSubtitle, CardTitle } from 'reactstrap';
import FeeList from './FeeList';
import { formatNumberWithRupeeSymbol } from 'utils';
import feeIcon from './../../../assets/img/total_balance.png';
import balanceIcon from './../../../assets/img/briefcase.png';
import deductionIcon from './../../../assets/img/deduction.png';
import dollorIcon from './../../../assets/img/dollor.png';
import { useGetAllFees } from 'hooks/useFeeManagement ';
const styles = {
    cardStyle: {
        display: 'flex',
        marginTop: "5.5%",
        justifyContent: "space-between"
    },
    textStyle: {
        fontFamily: 'Montserrat',
        fontSize: '14px',
        fontWeight: 500,
        color: '#82868B',
    },
    numberStyle: {
        fontFamily: 'Montserrat',
        fontSize: '24px',
        color: '#7367F0',
    }
}
const headingstyle = {
    fontFamily: 'Montserrat',
    color: "#5E5873",
    fontSize: '24px',
    fontWeight: 500,
}

export default function FeeManagement() {
    const [filtterData, setFilterData] = useState({
        kyc: "",
        wallet: "",
        role: 2,
        from_date: new Date().toISOString(),
        to_date: new Date().toISOString(),
    });
    const { data, isLoading } = useGetAllFees();
    const ColorCard = ({ color, title, subTitle, icon }) => {
        const iconStyle = {
            fontSize: '36px',
            marginRight: '10px',
            color: `white`,
        };
        const titleStyle = {
            color: 'white',
            fontFamily: 'Montserrat',
            fontWeight: 600,
        };
        const subTitleStyle = {
            color: 'white',
            fontFamily: 'Montserrat',
            fontWeight: 400,
        };

        return (
            <Card style={{ backgroundColor: color, display: 'flex' }}>
                <CardBody style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: "space-between",
                    padding: "40px 20px",
                }}>
                    <div>
                        <CardTitle tag="h2" style={titleStyle}>{title}</CardTitle>
                        <CardSubtitle tag="p" style={subTitleStyle}>{subTitle}</CardSubtitle>
                    </div>
                    <div style={{
                        marginLeft: 2
                    }}>
                        <img src={icon} />
                    </div>
                </CardBody>
            </Card>
        );
    };
    const SecondCard = ({ color, title, subTitle }) => {
        const cardStyle = {
            display: 'flex',
            marginBottom: '10px',
        };

        const rectangleStyle = {
            width: '12px',
            height: '12px',
            alignSelf: "start",
            marginTop: '9%',
            backgroundColor: color,
            marginRight: '15px',
            borderRadius: '2px',
        };

        const titleStyle = {
            fontFamily: 'Montserrat',
            fontSize: '24px',
            color: '#5E5873',
            display: 'flex',
            alignItems: 'center',
        };

        const subTitleStyle = {
            fontFamily: 'Montserrat',
            fontSize: '12px',
            color: '#6E6B7B',
            fontWeight: 400
        };

        return (
            <div style={cardStyle}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={rectangleStyle}></div>
                    <div>
                        <h2 style={titleStyle}>
                            {title}
                        </h2>
                        <p style={subTitleStyle}>
                            {subTitle}
                        </p>
                    </div>
                </div>
            </div>
        );
    };
    return (

        <div className='px-4'>
            <div className="row mx-0 mt-2">
                <div className='col-6 pl-2'>
                    <h3 style={headingstyle}>Fee Overview</h3>
                </div>
                <div className='col-3'>
                    <select
                        value={filtterData.kyc}
                        onChange={(e) => setFilterData({
                            ...filtterData,
                            kyc: e.target.value
                        })}
                        className='form-control fee-filter-select'>
                        <option selected value=''>Select Company</option>
                        <option value='Yes'>Ownmali</option>
                        <option value='No'>Ryzer</option>
                    </select>
                </div>
                <div className='col-3'>
                    <select
                        value={filtterData.wallet}
                        onChange={(e) => setFilterData({
                            ...filtterData,
                            wallet: e.target.value
                        })}
                        className='form-control fee-filter-select'>
                        <option value=''>Select Options</option>
                        <option value='No'>Last Month</option>
                        <option value='Yes'>Last 6 Month</option>
                        <option value='No'>Last Year</option>
                    </select>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-xl-3 mt-md-0 mt-4 col-md-6" style={{ padding: "0px 10px" }}>
                    <ColorCard
                        color="#7C278A"
                        title={formatNumberWithRupeeSymbol(data?.counts?.totalCollectedFees || 0)}
                        subTitle="Total Collected"
                        icon={feeIcon}
                    />
                </div>
                <div className="col-xl-3 mt-md-0 mt-4 col-md-6" style={{ padding: "0px 10px" }}>
                    <ColorCard
                        color="#E9573E"
                        title={formatNumberWithRupeeSymbol(data?.counts?.totalBalanceInEscrow || 0)}
                        subTitle="Balance in Escrow"
                        icon={balanceIcon}
                    />
                </div>
                <div className="col-xl-3 mt-xl-0 mt-4 col-md-6" style={{ padding: "0px 10px" }}>
                    <ColorCard
                        color="#289B96"
                        title={formatNumberWithRupeeSymbol(data?.counts?.totalWithdrawn || 0)}
                        subTitle="Total Withdrawn"
                        icon={dollorIcon}
                    />
                </div>
                <div className="col-xl-3   mt-xl-0 mt-4 col-md-6" style={{ padding: "0px 10px" }}>
                    <ColorCard
                        color="#31A2C1"
                        title={formatNumberWithRupeeSymbol(data?.counts?.totalTax || 0)}
                        subTitle="Taxes & Deduction"
                        icon={deductionIcon}
                    />
                </div>
            </div>
            <div className="row mt-5">
                <div className='col-lg-4'>
                    <Card style={{ boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)" }}>
                        <CardBody className='shadow-none'>
                            <div style={styles.cardStyle}>
                                <CardTitle tag="h2" style={styles.textStyle}>Total Assets</CardTitle>
                                <CardSubtitle tag="h3" style={styles.numberStyle}>{data?.counts?.uniqueAssetCount}</CardSubtitle>
                            </div>
                            <div style={styles.cardStyle}>
                                <CardTitle tag="h2" style={styles.textStyle}>Total Orders</CardTitle>
                                <CardSubtitle tag="h3" style={styles.numberStyle}>{data?.counts?.totalOrders}</CardSubtitle>
                            </div>
                            <div style={styles.cardStyle}>
                                <CardTitle tag="h2" style={styles.textStyle}>Total Transactions</CardTitle>
                                <CardSubtitle tag="h3" style={styles.numberStyle}>{data?.counts?.totalOrders * 2}</CardSubtitle>
                            </div>
                            <div style={styles.cardStyle}>
                                <CardTitle tag="h2" style={styles.textStyle}>Total Customers</CardTitle>
                                <CardSubtitle tag="h3" style={styles.numberStyle}>{data?.counts?.uniqueUserCount}</CardSubtitle>
                            </div>
                        </CardBody>
                    </Card>
                </div>
                <div className='col-lg-8 mt-lg-0 mt-5'>
                    <Card className='custom-shadow' style={{ boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)" }}>
                        <CardBody className='shadow-none'>
                            <div className="d-flex justify-content-between">
                                <CardTitle style={{
                                    fontFamily: 'Montserrat',
                                    fontWeight: 500,
                                    fontSize: '18px',
                                    color: '#5E5873',
                                }} tag="h2">Fee Breakup</CardTitle>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <SecondCard
                                        color="#0D6EFD"
                                        title={formatNumberWithRupeeSymbol(data?.fees?.filter((d) => d.name == "Platform Fee")[0]?.totalAmount || 0)}
                                        subTitle="Platform Fee"
                                    />
                                </div>
                                <div className="col-4">
                                    <SecondCard
                                        color="#00CFE8"
                                        title={formatNumberWithRupeeSymbol(data?.fees?.filter((d) => d.name == "Brokerage Fee")[0]?.totalAmount || 0)}
                                        subTitle="Brokerage Fee"
                                    />
                                </div>
                                <div className="col-4">
                                    <SecondCard
                                        color="#D63384"
                                        title={formatNumberWithRupeeSymbol(data?.fees?.filter((d) => d.name == "Legal Fee")[0]?.totalAmount || 0)}
                                        subTitle="Legal Fee"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <SecondCard
                                        color="#EA5455"
                                        title={formatNumberWithRupeeSymbol(data?.fees?.filter((d) => d.name == "Stamp duty Fee")[0]?.totalAmount || 0)}
                                        subTitle="Stamp duty Fee"
                                    />
                                </div>
                                <div className="col-4">
                                    <SecondCard
                                        color="#FF9F43"
                                        title={formatNumberWithRupeeSymbol(data?.fees?.filter((d) => d.name == "Other duty Fee")[0]?.totalAmount || 0)}
                                        subTitle="Other Fee"
                                    />
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </div>
            <div className='mt-5'>
                <FeeList data={data} />
            </div>
        </div>

    )
}
