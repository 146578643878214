import NotFound from "components/NotFound/NotFound";
import Paginate from "components/Paginate";
import { useGetCompanyOrderById } from "hooks/useCompany";
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Card, Container, Spinner, Table } from "reactstrap";
import { moneyFormat } from "utils";
import IconEdit from "../../../assets/img/Icon-edit.png"
import VerticalMenuIcon from "../../../assets/img/vertical-menu-icon.png"
import moment from "moment";
import { FiEdit } from "react-icons/fi";
import { BsThreeDotsVertical } from "react-icons/bs";

const CompanyOrdersList = () => {
  const { data, isLoading: loading } = useGetCompanyOrderById({
    property_id: localStorage.getItem("property_id")
  })
  let orders = data && data?.data

  return (
    <Container fluid className="mt-2">
      <div className="content-header">
        <h1 className="rz-heading rz-text-20">Orders</h1>
      </div>
      <Card className="border-none mt-3">
        <Table responsive className="custom-table-div">
          <thead className="rz-thead-style">
            <tr>
              <th className="d-flex align-items-center">
                ORDER ID
                <div className="ml-3">
                  <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                </div>
              </th>

              <th>STATUS</th>

              <th>
                <div className="d-flex align-items-center">
                  CUSTOMER NAME
                  <div className="ml-3">
                    <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
              </th>

              <th>
                <div className="d-flex align-items-center">
                  PROPERTY ID
                  <div className="ml-3">
                    <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
              </th>

              <th>
                <div className="d-flex align-items-center">
                  PLOT ID
                  <div className="ml-3">
                    <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
              </th>

              <th>
                <div className="d-flex align-items-center">
                  NO OF SQYD
                  <div className="ml-3">
                    <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
              </th>

              <th>
                <div className="d-flex align-items-center">
                  TOTAL VALUE
                  <div className="ml-3">
                    <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
              </th>

              <th>
                <div className="d-flex align-items-center">
                  LAST ACTIVITY
                  <div className="ml-3">
                    <i class="fa fa-chevron-up d-block" aria-hidden="true"></i>
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  </div>
                </div>
              </th>

              <th>ACTIONS</th>

            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr className="text-center">
                <td colSpan="10">
                  <Spinner animation="border" role="status" >{null}</Spinner>
                </td>
              </tr>
            ) : (
              orders?.map((order, i) => {
                return (
                  <tr key={i}>
                    <td
                      style={{ cursor: 'pointer' }}
                      className="text-primary">
                      <NavLink
                        to={{
                          pathname: `/company/singleorder/${order?.id}`,
                          state: {
                            order: order,
                            editable: false,
                          },
                        }}
                      >
                        #{order?.id}
                      </NavLink>
                    </td>
                    <td> {order?.status} </td>
                    <td style={{ cursor: 'pointer' }}>
                      <NavLink to={`/admin/customer/${order?.user?.id}`}>
                        {order?.user?.name}
                      </NavLink>
                    </td>

                    <td> {order?.property_id} </td>
                    <td>{order?.plot_id}</td>
                    <td> {order?.no_sqyds} </td>
                    <td> {moneyFormat(order?.total_amountp)} </td>
                    <td>{moment(order?.created_at).format("DD-MM-YYYY")}</td>
                    <td className="text-right">
                      <NavLink
                        to={{
                          pathname: `/company/singleorder/${order?.id}`,
                          state: {
                            order: order,
                            editable: false,
                          },
                        }}
                        color="primary"
                      >
                        <FiEdit style={{ fontSize: "20px" }} className="ryzer-text-blue cursor-pointer" />

                      </NavLink>
                      <NavLink
                        to={{
                          pathname: `/company/singleorder/${order?.id}`,
                          state: {
                            order: order,
                            editable: false,
                          },
                        }}
                        className="ml-3"
                      >
                        <BsThreeDotsVertical style={{ fontSize: "18px" }} className="ryzer-text-blue cursor-pointer" />

                      </NavLink>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </Table>
        {orders?.length <= 0 &&
          <NotFound
            message="No order created for this property."
          />
        }
      </Card>
    </Container>
  );
};

export default CompanyOrdersList;
