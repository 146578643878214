import { configConstants } from "../constants";
import { configService } from "../services";
import { toast } from "react-toastify";

export const configActions = {
  getCountryStates,
  getAmenitiesByPropertyType,
  postAmenitiesByPropertyType,
  deleteAmenitiesByPropertyType,
  updateAmenitiesByPropertyType,
  getLocationFeatureByPropertyType,
  addEditDocumentChecklist,
  deleteDocumentChecklist,
  getPropertyLegals,
  addLegalChecklist,
  updateLegalChecklist,
  deleteLegalChecklist,
  getVideoLink,
  getPopuptext,
  getLocationCityWise
};

function getVideoLink() {
  return (dispatch) => {
    dispatch(request());

    configService.getVideoLink().then(
      (res) => {
        dispatch(success(res.data));
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };

  function request(res) {
    return { type: configConstants.GET_VIDEOLINK_REQUEST, payload: res };
  }
  function success(res) {
    return { type: configConstants.GET_VIDEOLINK_SUCCESS, payload: res };
  }
  function failure(error) {
    return { type: configConstants.GET_VIDEOLINK_FAILURE, error };
  }
}
function getPopuptext() {
  return (dispatch) => {
    dispatch(request());
    configService.getPopuptext().then(
      (res) => {
        dispatch(success(res.data));
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };

  function request(res) {
    return { type: configConstants.GET_POPUPTEXT_REQUEST, payload: res };
  }
  function success(res) {
    return { type: configConstants.GET_POPUPTEXT_SUCCESS, payload: res };
  }
  function failure(error) {
    return { type: configConstants.GET_POPUPTEXT_FAILURE, error };
  }
}

function getAmenitiesByPropertyType(id) {
  return (dispatch) => {
    dispatch(request(id));

    configService.getAmenitiesByPropertyType(id).then(
      (res) => {
        dispatch(success(res.data));
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };

  function request(res) {
    return { type: configConstants.GET_AMENITY_REQUEST, payload: res };
  }
  function success(res) {
    return { type: configConstants.GET_AMENITY_SUCCESS, payload: res };
  }
  function failure(error) {
    return { type: configConstants.GET_AMENITY_FAILURE, error };
  }
}

function getLocationFeatureByPropertyType(id) {
  return (dispatch) => {
    dispatch(request(id));

    configService.getLocationFeatureByPropertyType(id).then(
      (res) => {
        dispatch(success(res.data));
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };

  function request(res) {
    return { type: configConstants.GET_LOCATIONFEATURE_REQUEST, payload: res };
  }
  function success(res) {
    return { type: configConstants.GET_LOCATIONFEATURE_SUCCESS, payload: res };
  }
  function failure(error) {
    return { type: configConstants.GET_LOCATIONFEATURE_FAILURE, error };
  }
}
function getLocationCityWise() {
  return (dispatch) => {
    dispatch(request());

    configService.getLocationCityWise().then(
      (res) => {
        dispatch(success(res.data));
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };

  function request(res) {
    return { type: configConstants.GET_CITYLOCATION_REQUEST, payload: res };
  }
  function success(res) {
    return { type: configConstants.GET_CITYLOCATION_SUCCESS, payload: res };
  }
  function failure(error) {
    return { type: configConstants.GET_CITYLOCATION_FAILURE, error };
  }
}
// post amenity

function postAmenitiesByPropertyType(data, amenityTypeId) {
  return (dispatch) => {
    dispatch(request());

    configService.postAmenitiesByPropertyType(data).then(
      (res) => {
        setTimeout(() => {
          dispatch(success(res.data));
          dispatch(configActions.getAmenitiesByPropertyType(amenityTypeId));
          toast.success("Amenity added successfully");
        }, 1000);
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };

  function request(res) {
    return { type: configConstants.POST_AMENITY_REQUEST, payload: res };
  }
  function success(res) {
    return { type: configConstants.POST_AMENITY_SUCCESS, payload: res };
  }
  function failure(error) {
    return { type: configConstants.POST_AMENITY_FAILURE, error };
  }
}

// delete amenity
function deleteAmenitiesByPropertyType(id, amenityTypeId) {
  return (dispatch) => {
    dispatch(request(id));

    configService.deleteAmenitiesByPropertyType(id).then(
      (res) => {
        dispatch(success(res.data));
        dispatch(configActions.getAmenitiesByPropertyType(amenityTypeId));
        toast.success(res.message);
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };

  function request(res) {
    return { type: configConstants.DELETE_AMENITY_REQUEST, payload: res };
  }
  function success(res) {
    return { type: configConstants.DELETE_AMENITY_SUCCESS, payload: res };
  }
  function failure(error) {
    return { type: configConstants.DELETE_AMENITY_FAILURE, error };
  }
}

// update amenity
function updateAmenitiesByPropertyType(id, data, amenityTypeId) {
  return (dispatch) => {
    dispatch(request(id));

    configService.updateAmenitiesByPropertyType(id, data).then(
      (res) => {
        setTimeout(() => {
          dispatch(success(res.data));
          dispatch(configActions.getAmenitiesByPropertyType(amenityTypeId));
          toast.success(res.message);
        }, 1000);
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };

  function request(res) {
    return { type: configConstants.UPDATE_AMENITY_REQUEST, payload: res };
  }
  function success(res) {
    return { type: configConstants.UPDATE_AMENITY_SUCCESS, payload: res };
  }
  function failure(error) {
    return { type: configConstants.UPDATE_AMENITY_FAILURE, error };
  }
}

function getCountryStates(country_id) {
  return (dispatch) => {
    dispatch(request());
    configService.getCountryStates(country_id).then(
      (res) => {
        dispatch(success(res));
        dispatch({ type: configConstants.GET_COUNTRY_SUCCESS, payload: res });
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: configConstants.GET_COUNTRY_REQUEST };
  }
  function success(res) {
    return { type: configConstants.GET_COUNTRY_SUCCESS, payload: res };
  }
  function failure(error) {
    return { type: configConstants.GET_COUNTRY_FAILURE, error };
  }
}

function addEditDocumentChecklist(data) {
  return (dispatch) => {
    dispatch(request(data));
    configService.addEditDocumentChecklist(data).then(
      (res) => {
        dispatch(success(res.message));
        dispatch(configActions.getCountryStates(process.env.REACT_APP_COUNTRY_IN));
        toast.success(res.message);
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };

  function request(res) {
    return {
      type: configConstants.ADD_EDIT_DOCUMENT_CHECKLIST_REQUEST,
      payload: res
    };
  }
  function success(res) {
    return {
      type: configConstants.ADD_EDIT_DOCUMENT_CHECKLIST_SUCCESS,
      payload: res,
    };
  }
  function failure(res) {
    return {
      type: configConstants.ADD_EDIT_DOCUMENT_CHECKLIST_FAILURE,
      payload: res,
    };
  }
}
function deleteDocumentChecklist(id) {
  return (dispatch) => {
    dispatch(request(document));
    configService.deleteDocumentChecklist(id).then(
      (res) => {
        dispatch(success(res.message));
        dispatch(configActions.getCountryStates(process.env.REACT_APP_COUNTRY_IN));
        toast.success(res.message);
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };

  function request(res) {
    return {
      type: configConstants.ADD_EDIT_DOCUMENT_CHECKLIST_REQUEST,
      payload: res,
    };
  }
  function success(res) {
    return {
      type: configConstants.ADD_EDIT_DOCUMENT_CHECKLIST_SUCCESS,
      payload: res,
    };
  }
  function failure(res) {
    return {
      type: configConstants.ADD_EDIT_DOCUMENT_CHECKLIST_FAILURE,
      payload: res,
    };
  }
}
// action for getting legal checklist
function getPropertyLegals() {
  return (dispatch) => {
    dispatch(request());
    configService.getPropertyLegals().then(
      (res) => {
        dispatch(success(res.data));
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };

  function request(res) {
    return {
      type: configConstants.GET_LEGAL_CHECKLIST_REQUEST,
    };
  }
  function success(res) {
    return {
      type: configConstants.GET_LEGAL_CHECKLIST_SUCCESS,
      payload: res,
    };
  }
  function failure(res) {
    return {
      type: configConstants.GET_LEGAL_CHECKLIST_FAILURE,
      payload: res,
    };
  }
}

// action for add legal checklist
function addLegalChecklist(data) {
  return (dispatch) => {
    dispatch(request(data));
    configService.addLegalChecklist(data).then(
      (res) => {
        dispatch(success(res.data));
        toast.success("Legal Doc Added Successfully ");
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };

  function request(res) {
    return {
      type: configConstants.ADD_LEGAL_CHECKLIST_REQUEST,
      payload: res,
    };
  }
  function success(res) {
    return {
      type: configConstants.ADD_LEGAL_CHECKLIST_SUCCESS,
      payload: res,
    };
  }
  function failure(res) {
    return {
      type: configConstants.ADD_LEGAL_CHECKLIST_FAILURE,
      payload: res,
    };
  }
}

// action for update legal checklist
function updateLegalChecklist(data, id) {
  return (dispatch) => {
    dispatch(request(data));
    configService.updateLegalChecklist(data, id).then(
      (res) => {
        dispatch(success(res.data));
        toast.success("Data updated ");
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };

  function request(res) {
    return {
      type: configConstants.UPDATE_LEGAL_CHECKLIST_REQUEST,
      payload: res,
    };
  }
  function success(res) {
    return {
      type: configConstants.UPDATE_LEGAL_CHECKLIST_SUCCESS,
      payload: res,
    };
  }
  function failure(res) {
    return {
      type: configConstants.UPDATE_LEGAL_CHECKLIST_FAILURE,
      payload: res,
    };
  }
}

// action for delete legal checklist
function deleteLegalChecklist(id) {
  return (dispatch) => {
    dispatch(request());
    configService.deleteLegalChecklist(id).then(
      (res) => {
        dispatch(success(res.data));
        toast.success("Data deleted ");
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };

  function request(res) {
    return {
      type: configConstants.DELETE_LEGAL_CHECKLIST_REQUEST,
    };
  }
  function success(res) {
    return {
      type: configConstants.DELETE_LEGAL_CHECKLIST_SUCCESS,
      payload: res,
    };
  }
  function failure(res) {
    return {
      type: configConstants.DELETE_LEGAL_CHECKLIST_FAILURE,
      payload: res,
    };
  }
}
