import { rolesConstants } from "redux/constants/roles.constants";

export function postRolesReducer(state = {}, action) {
  switch (action.type) {
    case rolesConstants.POST_ROLES_REQUEST:
      return { loading: true };
    case rolesConstants.POST_ROLES_SUCCESS:
      return { message: action.payload.message };
    case rolesConstants.POST_ROLES_FAILURE:
      return { loading: true };
    default:
      return state;
  }
}
