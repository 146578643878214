import { employeeConstants } from "../constants";
import { employeeService } from "../services";
import { toast } from "react-toastify";

export const employeeActions = {
  getEmployees,
  addEmployees,
  updateEmployees,
  deleteEmployees,
};

// get employes action
function getEmployees(query) {
  return (dispatch) => {
    dispatch(request());
    employeeService.getEmployees(query).then(
      (res) => {
        dispatch(success(res));
        dispatch({
          type: employeeConstants.GET_EMPLOYEE_SUCCESS,
          payload: res,
        });
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };

  function request() {
    return { type: employeeConstants.GET_EMPLOYEE_REQUEST };
  }
  function success(res) {
    return { type: employeeConstants.GET_EMPLOYEE_SUCCESS, payload: res };
  }
  function failure(error) {
    return { type: employeeConstants.GET_EMPLOYEE_FAILURE, error };
  }
}

// add employes action
function addEmployees(data, history) {
  return (dispatch) => {
    dispatch(request());
    employeeService.addEmployees(data).then(
      (res) => {
        dispatch(success(res));
        if (res.type == "error") {
          toast.error(res.message);
        } else {
          toast.success(res.message);
          dispatch(employeeActions.getEmployees(`?is_employee=true`));
          history.push('/admin/employee')
        }
      },
      (error) => {
        dispatch(failure(error));
        toast.error(error);
      }
    );
  };

  function request() {
    return { type: employeeConstants.POST_EMPLOYEE_REQUEST };
  }
  function success(res) {
    return { type: employeeConstants.POST_EMPLOYEE_SUCCESS, payload: res };
  }
  function failure(error) {
    return { type: employeeConstants.POST_EMPLOYEE_FAILURE, error };
  }
}

// update employes action
function updateEmployees(data, id, history) {
  return (dispatch) => {
    dispatch(request());
    employeeService.updateEmployees(data, id).then(
      (res) => {
        dispatch(success(res));
        if (res.type == "error") {
          toast.error(res.message);
        } else {
          toast.success(res.message);
          dispatch(employeeActions.getEmployees(`?is_employee=true`));
          history.push('/admin/employee')
        }
      },
      (error) => {
        dispatch(failure(error));
        toast.error(error);
      }
    );
  };

  function request() {
    return { type: employeeConstants.UPDATE_EMPLOYEE_REQUEST };
  }
  function success(res) {
    return { type: employeeConstants.UPDATE_EMPLOYEE_SUCCESS, payload: res };
  }
  function failure(error) {
    return { type: employeeConstants.UPDATE_EMPLOYEE_FAILURE, error };
  }
}

// delete employes action
function deleteEmployees(id) {
  return (dispatch) => {
    dispatch(request());
    employeeService.deleteEmployees(id).then(
      (res) => {
        dispatch(success(res));
        if (res.type == "error") {
          toast.error(res.message);
        } else {
          toast.success(res.message);
          dispatch(employeeActions.getEmployees(`?is_employee=true`));
        }
      },
      (error) => {
        dispatch(failure(error));
        toast.error(error);
      }
    );
  };

  function request() {
    return { type: employeeConstants.DELETE_EMPLOYEE_REQUEST };
  }
  function success(res) {
    return { type: employeeConstants.DELETE_EMPLOYEE_SUCCESS, payload: res };
  }
  function failure(error) {
    return { type: employeeConstants.DELETE_EMPLOYEE_FAILURE, error };
  }
}
