import Paginate from "components/Paginate";
import React, { useEffect, useState } from "react";
import { Card, FormGroup, Label, Media, Spinner, Table } from "reactstrap";
import { moneyFormat } from "utils";
import NotFound from "components/NotFound/NotFound";
import { useGetOrderFilter } from "hooks/useProperty";
import exportFromJSON from "export-from-json";
import { DatePicker } from "reactstrap-date-picker";
import { useGetOwnerShipReportFilter } from "hooks/useReport";
import { toast } from "react-toastify";

const OwnerShipList = () => {
    const { data: filterDb, isLoading } = useGetOrderFilter()
    const intialState = {
        user_id: "",
        phone: "",
        property_id: "",
        status: "",
        from_date: new Date().toISOString(),
        to_date: new Date().toISOString(),
    }
    const [filtterData, setFilterData] = useState(intialState)
    const [applyFilter, setApplyFilter] = useState({});
    const { data: orders, isLoading: loading } = useGetOwnerShipReportFilter({
        filter: applyFilter
    }, Object.keys(applyFilter)?.length > 0);

    function handleChange(value) {
        setFilterData({
            ...filtterData,
            from_date: value
        })
    }

    function handleChangeTo(value) {
        setFilterData({
            ...filtterData,
            to_date: value
        })
    }

    const exportPDF = () => {
        const title = `order-report-${filtterData?.from_date}-${filtterData?.to_date}`;
        const report_data = orders?.data?.map((order) => {
            return ({
                "CUSTOMER ID": order?.user_id,
                "CUSTOMER NAME": order?.user_name,
                "PROPERTY ID": order?.property_id,
                "PROPERTY Name": order?.property_name,
                "Tokens": order?.tokens,
                "TOTAL INVESTED": order?.total_amount,
                "OWNERSHIP": ((order?.tokens / (order?.property_total_token == 0 ? 1 : order?.property_total_token)) * 100).toFixed(2)
            })
        })

        exportFromJSON({ data: report_data, fileName: title, exportType: 'csv' });
    };

    return (
        <>
            <div className="row">
                <div className='col-4'>
                    <label>Select Using Mobile</label>
                    <input
                        onChange={(e) => setFilterData({
                            ...filtterData,
                            phone: e.target.value
                        })}
                        value={filtterData.phone}
                        className='form-control'
                        placeholder="Enter mobile"
                        id="phone" />
                </div>
                <div className='col-4'>
                    <label>Select User</label>
                    <select
                        value={filtterData.user_id}
                        onChange={(e) => setFilterData({
                            ...filtterData,
                            user_id: e.target.value
                        })}
                        className='form-control'>
                        <option value='all'>Select User</option>
                        {filterDb && filterDb?.users?.map((user) => (
                            <option key={user?.user_id} value={user?.user_id}>{user?.name}</option>
                        ))
                        }
                    </select>
                </div>
                <div className='col-4'>
                    <label>Select Asset</label>
                    <select
                        value={filtterData.property_id}
                        onChange={(e) => setFilterData({
                            ...filtterData,
                            property_id: e.target.value
                        })}
                        className='form-control'>
                        <option value='all'>Select Asset</option>
                        {filterDb && filterDb?.properties?.map((user) => (
                            <option key={user?.property_id} value={user?.property_id}>{user?.name}</option>
                        ))
                        }
                    </select>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-4">
                    <FormGroup>
                        <Label>Transaction - From Date</Label>
                        <DatePicker
                            size="md"
                            id="from-date"
                            value={filtterData.from_date}
                            onChange={(v, f) => handleChange(v, f)} />
                    </FormGroup>
                </div>
                <div className="col-4">
                    <FormGroup>
                        <Label>Transaction - To Date</Label>
                        <DatePicker
                            id="to-date"
                            value={filtterData.to_date}
                            onChange={(v, f) => handleChangeTo(v, f)} />
                    </FormGroup>
                </div>
            </div>
            <div className="row pb-2 pt-4 mx-1">
                <button
                    onClick={() => {
                        if (filtterData?.property_id == "" || !filtterData?.property_id) {
                            toast.error("Please Select Asset")
                        }
                        setApplyFilter(filtterData)
                    }}
                    className="btn rz-button-primary mr-3" type="button">
                    Apply Filter
                </button>
                <button
                    disabled={!orders?.data || orders?.data?.length <= 0}
                    onClick={() => exportPDF()}
                    className="btn rz-button-primary mr-3" type="button">
                    Downalod Report
                </button>
                <button onClick={() => {
                    setFilterData(intialState)
                    setApplyFilter({})
                }}
                    className="btn rz-button-primary" type="button">
                    Clear Filter
                </button>
            </div>
            <Card className="border-none">
                <Table responsive className="custom-table-div mt-3">
                    <thead className="rz-thead-style">
                        <tr>
                            <th className="d-flex align-items-center">
                                CUSTOMER ID

                            </th>
                            <th>
                                <div className="d-flex align-items-center">
                                    CUSTOMER NAME

                                </div>
                            </th>
                            <th>
                                <div className="d-flex align-items-center">
                                    PROPERTY ID

                                </div>
                            </th>
                            <th>
                                <div className="d-flex align-items-center">
                                    PROPERTY Name

                                </div>
                            </th>

                            <th>
                                <div className="d-flex align-items-center">
                                    NO OF TOKEN

                                </div>
                            </th>
                            <th>
                                <div className="d-flex align-items-center">
                                    purchase VALUE

                                </div>
                            </th>

                            <th>
                                <div className="d-flex align-items-center">
                                    ownership %

                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr className="text-center">
                                <td colSpan="10">
                                    <Spinner animation="border" role="status" >{null}</Spinner>
                                </td>
                            </tr>
                        ) : (
                            orders?.data?.map((order, i) => {
                                return (
                                    <tr key={i}>
                                        <td> {order?.user_id} </td>
                                        <td><div className="d-flex align-items-center">
                                            <img
                                                className="mx-2"
                                                width="30"
                                                src={order?.avatar}
                                                alt=""
                                            />
                                            <div className="d-flex flex-column">
                                                <span className="customer-name">{order?.user_name}</span>
                                            </div>
                                        </div></td>
                                        <td> {order?.property_id} </td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <img
                                                    className="mx-2"
                                                    width="30"
                                                    src={order?.image}
                                                    alt=""
                                                />
                                                <div className="d-flex flex-column">
                                                    <span className="customer-name">{order?.property_name}</span>
                                                </div>
                                            </div> </td>
                                        <td> {order?.tokens} </td>
                                        <td> {moneyFormat(order?.total_amount)} </td>
                                        <td> {((order?.tokens / (order?.property_total_token == 0 ? 1 : order?.property_total_token)) * 100).toFixed(2)}% </td>
                                    </tr>
                                );
                            })
                        )}
                    </tbody>
                </Table>
                {orders?.data?.length <= 0 &&
                    <NotFound
                        message="Please Refine Your Filter"
                    />
                }
            </Card>
        </>
    );
};
export default OwnerShipList;
