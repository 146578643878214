import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Label, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ErrorMessage } from "@hookform/error-message";
import CompanyCoinABI from './../../../contract/abi/RyzerCoin.json';
import INDRABI from './../../../contract/abi/INDR.json';
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { propertyActions } from "redux/actions";
import { propertyConstants } from "redux/constants";
import { useUpdatePropertySto } from "hooks/useProperty";
import {
    useAccount
} from "wagmi";
import { ethers } from 'ethers';
import { api } from "helpers";
import { useMutatePropertyKYCSync } from "hooks/useProperty";
import { useWeb3ModalNetwork } from "@web3modal/react";
import { Link } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs";

// yup schema
const employeeSchema = yup.object().shape({
});

const STORole = ({ stepper }) => {
    const { register, handleSubmit, reset, getValues, formState: { errors } } = useForm({
        resolver: yupResolver(employeeSchema),
    });

    const dispatch = useDispatch();
    const { property_id } = useParams();
    const { property } = useSelector(state => state.propertyReducer);
    const [contract_address, setContractaddress] = useState("");
    const [contract_address_indr, setContractaddressIndr] = useState("");
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (property_id) getProperty(property_id)
        else dispatch({ type: propertyConstants.PROPERTY_EMPTY });
    }, []);

    useEffect(() => {
        if (property) {
            setContractaddress(property?.sto_erc20_address);
            setContractaddressIndr(property?.indr_token_address);
        }
    }, [property]);

    const getProperty = async (id) => {
        dispatch(propertyActions.getProperty(id))
    }
    const { mutate: syncKYC } = useMutatePropertyKYCSync()

    const contractProcessor = ""
    const { isConnected, address } = useAccount()
    const { selectedChain } = useWeb3ModalNetwork()
    const onSubmitSuperAdmin = async () => {
        try {
            // if (selectedChain?.id != property?.blockchain_metadata?.id) {
            //     toast.error(`Please switch network to ${property?.blockchain_metadata?.name}`)
            //     return;
            // }
            setLoading(true)
            await syncKYC({
                property_id: property?.id
            }, {
                onSuccess: (res) => {
                    setLoading(false)
                    toast.success(res?.message)
                    console.log("mehul", res?.data)
                },
                onSettled: () => {
                },
            });
            // toast.success("")
        }
        catch (err) {
            setLoading(false)
            toast.error("Something Went Wrong");
            console.log("err", err)
        }

    };
    const onSubmit = async (data) => {
        if (!isConnected) {
            toast.error("Please Connect Wallet First.");
            return;
        }
        if (selectedChain?.id != property?.blockchain_metadata?.id) {
            toast.error(`Please switch network to ${property?.blockchain_metadata?.name}`)
            return;
        }
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner()
        let contract = new ethers.Contract(
            contract_address,
            CompanyCoinABI,
            signer)
        if (data?.wallet_address) {
            try {
                if (!ethers.utils.isAddress(data?.wallet_address)) {
                    toast.error("Please enter valid address.")
                    return;
                }
                let transaction = await contract.addToWhitelist(data?.wallet_address)
                await transaction.wait()
                toast.success("Add User into Whitelist Successfully")
            }
            catch (error) {
                if ((error?.data?.message != undefined) &&
                    (error?.data?.message).indexOf("execution reverted: AccessControl") != -1) {
                    toast.error("You Don't Have Permission to Mint Token");
                }
                else if (error?.code == (-32603)) {
                    toast.error(error?.data?.message);
                }
                else if (error?.code == (404)) {
                    toast.error(error?.data?.text);
                }
                console.log(error);
            }
            reset();
        }
        else if (data?.minter_wallet_address) {
            var minter_role;
            try {
                let data = await contract.MINTER_ROLE()
                minter_role = data;
            }
            catch (error) {
                console.log(error);
            }
            try {
                console.log("minter_role", minter_role)
                if (!ethers.utils.isAddress(data?.minter_wallet_address)) {
                    toast.error("Please enter valid address.")
                    return;
                }
                if (!minter_role) {
                    toast.error("Error in Getting Minter Role")
                    return;
                }
                console.log("minter_role", minter_role)
                let transaction = await contract.grantRole(minter_role, data?.minter_wallet_address)
                await transaction.wait()
                toast.success("Role Assign Successfully");
            }
            catch (error) {
                if ((error?.data?.message != undefined) &&
                    (error?.data?.message).indexOf("execution reverted: AccessControl") != -1) {
                    toast.error("You Don't Have Permission to Mint Token");
                }
                else if (error?.code == (-32603)) {
                    toast.error(error?.data?.message);
                }
                else if (error?.code == (404)) {
                    toast.error(error?.data?.text);
                }
                console.log(error);
            }
            reset();
        }
        else if (data?.kyc_wallet_address) {
            var minter_role;
            try {
                let data = await contract.KYC_ROLE()
                minter_role = data;
            }
            catch (error) {
                console.log(error);
            }
            try {
                if (!ethers.utils.isAddress(data?.kyc_wallet_address)) {
                    toast.error("Please enter valid address.")
                    return;
                }
                let transaction = await contract.grantRole(minter_role, data?.kyc_wallet_address)
                await transaction.wait()
                toast.success("Role Assign Successfully");
            }
            catch (error) {
                if ((error?.data?.message != undefined) &&
                    (error?.data?.message).indexOf("execution reverted: AccessControl") != -1) {
                    toast.error("You Don't Have Permission to Mint Token");
                }
                else if (error?.code == (-32603)) {
                    toast.error(error?.data?.message);
                }
                else if (error?.code == (404)) {
                    toast.error(error?.data?.text);
                }
                console.log(error);
            }
            reset();
        }
        else if (data?.indr_wallet_address) {
            let contract_indr = new ethers.Contract(
                contract_address_indr,
                INDRABI,
                signer)
            let minter_role;
            try {
                let data = await contract_indr.MINTER_ROLE()
                minter_role = data;
            }
            catch (error) {
                console.log(error);
            }
            try {
                if (!ethers.utils.isAddress(data?.indr_wallet_address)) {
                    toast.error("Please enter valid address.")
                    return;
                }
                if (!minter_role) {
                    toast.error("Error in Getting Minter Role")
                    return;
                }
                let transaction = await contract_indr.grantRole(minter_role, data?.indr_wallet_address)
                await transaction.wait()
                toast.success("INDR Minter Role Assign Successfully");
            }
            catch (error) {
                if ((error?.data?.message != undefined) &&
                    (error?.data?.message).indexOf("execution reverted: AccessControl") != -1) {
                    toast.error("You Don't Have Permission to Mint Token");
                }
                else if (error?.code == (-32603)) {
                    toast.error(error?.data?.message);
                }
                else if (error?.code == (404)) {
                    toast.error(error?.data?.text);
                }
                console.log(error);
            }
            reset();
        }
        else if (data?.snapshot_wallet_address) {
            var minter_role;
            let options = {
                contractAddress: contract_address,
                functionName: "SNAPSHOT_ROLE",
                abi: CompanyCoinABI,
            };
            await contractProcessor.fetch({
                params: options,
                onSuccess: async (res) => {
                    minter_role = res;
                },
                onError: (error) => {
                    console.log(error);
                    if (error) {
                        toast.error(error?.data?.message);
                        return;
                    }
                },
            });
            let optionsGrant = {
                contractAddress: contract_address,
                functionName: "grantRole",
                abi: CompanyCoinABI,
                params: {
                    account: data?.snapshot_wallet_address,
                    role: minter_role
                },
            };
            await contractProcessor.fetch({
                params: optionsGrant,
                onSuccess: async (res) => {
                    console.log(res);
                    toast.success("Role Assign Successfully");
                },
                onError: (error) => {
                    if ((error?.data?.message != undefined) &&
                        (error?.data?.message).indexOf("execution reverted: AccessControl") != -1) {
                        toast.error("You Don't Have Permission to Mint Token");
                    }
                    else if (error?.code == (-32603)) {
                        toast.error(error?.data?.message);
                    }
                    else if (error?.code == (404)) {
                        toast.error(error?.data?.text);
                    }
                    console.log(error);
                },
            });
            reset();
        }
    };
    return (
        <>
            {/* {!property?.initial_mint_isdone ?
                property?.total_property_sqyds ?
                    <Container fluid className="d-flex justify-content-center text-center">
                        <Row>
                            <Col className="my-2 mb-5" md="6" xs="12">
                                <h2>Your Property Have{" "}
                                    <span style={{ color: 'red', marginLeft: "2px" }}>
                                        {parseFloat(property?.total_property_sqyds)}
                                    </span> Tokens
                                </h2>
                                <h3>Initial You have to mint those token and Start your STO Sale.</h3>
                                <Button className="mt-3" color="primary"
                                    onClick={() => mintNow()}
                                    type="submit">
                                    Mint Now
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                    :
                    <Container fluid className="d-flex justify-content-center text-center">
                        <Row>
                            <Col className="my-2 mb-5" md="6" xs="12">
                                <h2>Please configure at least one property plot,your property Have{" "}
                                    <span style={{ color: 'red', marginLeft: "2px" }}>
                                        {0}
                                    </span> Tokens
                                </h2>
                                <h3>After Configure You can mint tokens.</h3>
                            </Col>
                        </Row>
                    </Container>
                : */}
            <>
                <Container fluid className="">
                    <div className='content-header'>
                        <h3 className='mb-0 rz-heading rz-text-18'>Token Role Setup</h3>
                    </div>
                    <Card className="p-4">
                        <Row className="">
                            <Col className="d-flex align-items-center justify-content-between">
                                <h2 className="rz-heading rz-text-18">
                                    {"Add all KYC User into This Asset"}
                                </h2>
                                <Form onSubmit={handleSubmit(onSubmitSuperAdmin)}>
                                    <Row>
                                        <Col md="6" xs="12">
                                            <Button className="rz-button-primary" type="submit">
                                                {loading ? "We are adding user into kyc please wait for while..." : "Add All User Into Whitelist"}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Card>
                    <Card className="p-4 mt-4">
                        <Row className="justify-content-between">
                            <Col>
                                <h2 className="rz-heading rz-text-18">
                                    {"Add User Into Whitelist"}
                                </h2>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <Col className="my-2" md="6" xs="12">
                                            <Label> Wallet Address Of User</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                {...register("wallet_address", {})}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="wallet_address"
                                                render={({ message }) => <p style={{ color: "#f14D5D" }}>{message}</p>}
                                            />
                                        </Col>
                                        <Col className="mt-4 text-center" md="6" xs="12">
                                            <Button className="mt-3 rz-button-primary" type="submit">
                                                Submit
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Card>
                    <Card className="p-4 mt-4">
                        <Row className="justify-content-between">
                            <Col>
                                <h2 className="rz-heading rz-text-18">
                                    {"SET MINTER USER"}
                                </h2>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <Col className="my-2" md="6" xs="12">
                                            <Label> Wallet Address Of User</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                //   placeholder="name"
                                                {...register("minter_wallet_address", {})}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="minter_wallet_address"
                                                render={({ message }) => <p style={{ color: "#f14D5D" }}>{message}</p>}
                                            />
                                        </Col>
                                        <Col className="mt-4 text-center" md="6" xs="12">
                                            <Button className="mt-3 rz-button-primary" type="submit">
                                                Submit
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Card>
                    <Card className="p-4 mt-4">
                        <Row className="justify-content-between">
                            <Col>
                                <h2 className="rz-heading rz-text-18">
                                    {"SET KYC USER"}
                                </h2>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <Col className="my-2" md="6" xs="12">
                                            <Label> Wallet Address Of User</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                //   placeholder="name"
                                                {...register("kyc_wallet_address", {})}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="kyc_wallet_address"
                                                render={({ message }) => <p style={{ color: "#f14D5D" }}>{message}</p>}
                                            />
                                        </Col>
                                        <Col className="mt-4 text-center" md="6" xs="12">
                                            <Button className="mt-3 rz-button-primary" type="submit">
                                                Submit
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Card>
                    {/* <Card className="p-4 mt-4">
                        <Row className="justify-content-between">
                            <Col>
                                <h2 className="rz-heading rz-text-18">
                                    {"SET INDR MINTER ROLE"}
                                </h2>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Row>
                                        <Col className="my-2" md="6" xs="12">
                                            <Label> Wallet Address Of User</Label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                //   placeholder="name"
                                                {...register("indr_wallet_address", {})}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="kyc_wallet_address"
                                                render={({ message }) => <p style={{ color: "#f14D5D" }}>{message}</p>}
                                            />
                                        </Col>
                                        <Col className="mt-4 text-center" md="6" xs="12">
                                            <Button className="mt-3 rz-button-primary" type="submit">
                                                Submit
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Card> */}
                    {/* <Card className="p-4 mt-4">
                    <Row className="justify-content-between">
                        <Col>
                            <h2>
                                {"SET SNAPSHOT USER ROLE"}
                            </h2>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col className="my-2" md="6" xs="12">
                                        <Label> Wallet Address Of User</Label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            //   placeholder="name"
                                            {...register("snapshot_wallet_address", {})}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="snapshot_wallet_address"
                                            render={({ message }) => <p style={{ color: "#f14D5D" }}>{message}</p>}
                                        />
                                    </Col>
                                    <Col className="mt-4 text-center" md="6" xs="12">
                                        <Button className="mt-3" color="primary" type="submit">
                                            Submit
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Card> */}
                </Container>
            </>
            {/* } */}
            <div className='mt-5 d-flex justify-content-end'>
                <Link to='/admin/property'>
                    <Button type='button' className='rz-button-outline-primary'>
                        Cancel
                    </Button>
                </Link>
                <Button className='ml-3 rz-button-primary' onClick={() => stepper.previous()}>
                    <BsArrowLeftShort style={{ fontSize: "22px" }} />
                    <span className='align-middle d-sm-inline-block d-none'>Back</span>
                </Button>
            </div>
        </>
    );
};

export default STORole;

// const { mutate } = useUpdatePropertySto()
// const mintNow = async () => {
//     if (parseFloat(property?.total_property_sqyds) <= 0) {
//         toast.error("You have not configured any Asset.");
//         return;
//     }
//     if (!isConnected) {
//         toast.error("Please Connect Wallet");
//         return;
//     }
//     if (!property?.is_sto_created) {
//         toast.error("Security Token is not Created For Selected Property");
//         return;
//     }
//     if (!property?.is_sto_sale_created) {
//         toast.error("DAO is not Created For Selected Property");
//         return;
//     }
//     const provider = new ethers.providers.Web3Provider(window.ethereum)
//     const signer = provider.getSigner()
//     let contract = new ethers.Contract(
//         property?.sto_erc20_address,
//         CompanyCoinABI,
//         signer)
//     try {
//         const token = parseFloat(property?.total_property_sqyds)
//         var tokenInWei = ethers.utils.parseUnits("".concat(token), 18).toString();
//         let transaction = await contract.mint(property?.sto_sale_address, tokenInWei)
//         await transaction.wait()
//         var data = {}
//         data.property_id = property?.id
//         data.initial_mint_isdone = true;
//         await mutate(data, {
//             onSuccess: (res) => {
//                 console.log(res)
//             }
//         })

//     }
//     catch (error) {
//         if ((error?.data?.message != undefined) &&
//             (error?.data?.message).indexOf("execution reverted: AccessControl") != -1) {
//             toast.error("You Don't Have Permission to Mint Token");
//         }
//         else if (error?.code == (-32603)) {
//             toast.error(error?.data?.message);
//         }
//         else if (error?.code == (404)) {
//             toast.error(error?.data?.text);
//         }
//         console.log(error);
//         return;
//     }

// };