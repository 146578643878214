import {
    Badge,
    Card,
    Media,
    Table,
    Container,
    Row,
    UncontrolledTooltip,
    Button,
    Col,
    Spinner,
    NavItem,
    Nav,
    NavLink
} from "reactstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { propertyActions } from "redux/actions";
import moment from "moment";
import { moneyFormat } from "utils";
import { useEffect, useState } from "react";
import { CheckPermission, PERMISSIONS, RESOURCE } from "../../../utils";
import queryString from 'query-string';
import NotFound from "components/NotFound/NotFound";
import IconEyeImage from "../../../assets/img/Icon-eye.png"
import { AiOutlineEye } from "react-icons/ai";

const TokenSellerSuperAdmin = () => {
    const dispatch = useDispatch();
    const daoNavOptions = ["Awaiting", "Approved", "Rejected"]
    const [activeTab, setActiveTab] = useState("Awaiting");
    const [propertiesData, setPropertiesData] = useState([]);
    const { search } = useLocation();
    const page = queryString.parse(search)?.page
    const getDaoNavOptions = () => {
        return (
            <Card className='p-2'>
                <div className="d-flex justify-content-between">
                    <div className="btn-group" role="group" aria-label="Options">
                        {daoNavOptions.map((v, i) =>
                            <button
                                type="button"
                                className={
                                    `btn rz-shadow-none button cursor-pointer ${activeTab === v ? 'rz-button-primary' : 'white'
                                    }`
                                }
                                style={{
                                    borderRadius: 5
                                }}
                                onClick={() => setActiveTab(v)}
                            >
                                {v}
                            </button>
                        )}
                    </div>
                </div>
            </Card>
        );
    };
    const { properties_token_seller_superadmin, pager, getTokenSellerSuperAdmin } = useSelector(
        (state) => state.getTokenSellerSuperAdmin
    );
    useEffect(() => {
        localStorage.removeItem("property_id")
        localStorage.removeItem("amenities")
        localStorage.removeItem("locationfeature")
        localStorage.removeItem("channel")
    });
    const onInit = () => {
        dispatch(propertyActions.getTokenSellerSuperAdmin({ page: page ? page : 1 }));
    }
    useEffect(() => {
        onInit()
    }, [page]);

    useEffect(() => {
        let data = []
        if (activeTab == "Awaiting") {
            data = properties_token_seller_superadmin?.filter((d) =>
                (d.status == "Pending" || d.status == "Inactive"));
        }
        if (activeTab == "Hold") {
            data = properties_token_seller_superadmin?.filter((d) =>
                (d.status == "Hold"));
        }
        if (activeTab == "Rejected") {
            data = properties_token_seller_superadmin?.filter((d) =>
                (d.status == "Rejected"));
        }
        if (activeTab == "Approved") {
            data = properties_token_seller_superadmin?.filter((d) =>
                (d.status == "Active"));
        }
        setPropertiesData(data || [])
    }, [activeTab, properties_token_seller_superadmin])

    const setLocalStorage = async (item) => {
        let property = item?.property;
        localStorage.setItem("property_id", property.id)
        localStorage.setItem("channel", true)
    }
    return (
        <>
            <Container fluid>
                <div className='content-header mb-3'>
                    <h3 className='mb-0 rz-heading rz-text-22'>Token Seller</h3>
                </div>
                {getDaoNavOptions()}
                <Row className="mt-3">
                    <div className="col">
                        <Card className="shadow">
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="rz-thead-style">
                                    <tr>
                                        <th scope="col">Partner Name</th>
                                        <th scope="col">Asset Name</th>
                                        <th scope="col">Asset ID</th>
                                        <th scope="col">Created At</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getTokenSellerSuperAdmin && (
                                        <tr className="text-center">
                                            <td colSpan="10">
                                                <Spinner animation="border" role="status" >{null}</Spinner>
                                            </td>
                                        </tr>
                                    )}

                                    {propertiesData && propertiesData.length > 0 ?
                                        propertiesData.map((item, i) => (
                                            <tr key={item?.id}>
                                                <th>
                                                    <Media
                                                        lassName="align-items-center">
                                                        <Link
                                                            to={{
                                                                pathname: `/admin/tokensellerdetails`,
                                                                state: {
                                                                    certificate: item
                                                                }
                                                            }}
                                                            className="avatar rounded-circle mr-3"
                                                        >
                                                            <img alt="..."
                                                                src={item?.user?.avatar} />

                                                        </Link>
                                                        <span className="mb-0 text-sm">
                                                            <div>{item?.user?.name}</div>
                                                            <small className="text-muted">
                                                                {item?.seller?.city}
                                                            </small>
                                                        </span>
                                                    </Media>
                                                </th>
                                                <th scope="row">
                                                    <Media
                                                        style={
                                                            !CheckPermission(PERMISSIONS?.CREATE, RESOURCE?.Property) ?
                                                                {
                                                                    "pointer-events": "none"
                                                                } : {}}
                                                        className="align-items-center">
                                                        <Link
                                                            onClick={() => setLocalStorage(item)}
                                                            to={{
                                                                pathname: `/admin/tokensellerdetails`,
                                                                state: {
                                                                    certificate: item
                                                                }
                                                            }}
                                                            className="avatar rounded-circle mr-3"
                                                        >
                                                            <img alt="..." src={item?.property?.featured_image} />
                                                        </Link>
                                                        <Link
                                                            onClick={() => setLocalStorage(item)}
                                                            to={{
                                                                pathname: `/admin/tokensellerdetails`,
                                                                state: {
                                                                    certificate: item
                                                                }
                                                            }}>
                                                            <span className="mb-0 text-sm">
                                                                <div>{item?.property?.name}</div>
                                                                <small className="text-muted">
                                                                    {item?.property?.location?.address}
                                                                </small>
                                                            </span>
                                                        </Link>
                                                    </Media>
                                                </th>
                                                <td>{item?.property?.id}</td>
                                                <td>{moment(item?.property?.created_at).format("D-MMM-Y")}</td>
                                                <td>
                                                    <Badge
                                                        className={`${item.status == "Active"
                                                            ? "rental-payout-paid"
                                                            : "rental-payout-pending"
                                                            } mr-4`}
                                                    >
                                                        {item.status}
                                                    </Badge>
                                                </td>
                                                <td>
                                                    <Link
                                                        onClick={() => setLocalStorage(item)}
                                                        to={{
                                                            pathname: `/admin/tokensellerdetails`,
                                                            state: {
                                                                certificate: item
                                                            }
                                                        }}
                                                    >
                                                        <AiOutlineEye style={{ fontSize: "22px" }} className="ryzer-text-blue" />
                                                    </Link>
                                                </td>
                                            </tr>
                                        )) : (
                                            <></>
                                        )}
                                </tbody>
                            </Table>
                            {propertiesData?.length <= 0 &&
                                <NotFound
                                    message={`No ${activeTab} Property Found.`}
                                />
                            }
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
};
export default TokenSellerSuperAdmin;
