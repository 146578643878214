import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
    Button,
    Card,
    Col,
    Container,
    Form,
    FormGroup,
    Label,
    Row,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { rolesAction } from "redux/actions/roles.actions";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useEffect } from "react";
import { RESOURCE } from "utils";
import { Link } from 'react-router-dom';
// yup schema
const documentSchema = yup.object().shape({
    name: yup.string().required(),
});

const AddUpdateRoles = () => {
    const { id } = useParams();
    const location = useLocation();
    const history = useHistory();
    const { role } = location.state;
    const dispatch = useDispatch();
    const [updateAble, setUpdateAble] = useState(true);
    const { register, handleSubmit, reset, setValue } = useForm({
        defaultValues: {
            name: role?.name
        },
        resolver: yupResolver(documentSchema),
    });
    const onSubmit = (data) => {
        if (updateAble) {
            dispatch(rolesAction.updateRoles(data, role?.id));
        } else {
            dispatch(rolesAction.postRoles(data));
        }
        history.push('/admin/role')
        reset();
    };

    useEffect(() => {
        if (role?.id) {
            setUpdateAble(true);
            reset({ name: role?.name });
        } else {
            setUpdateAble(false);
        }
    }, [updateAble]);

    function checkIfChecked(res, action) {
        var permission = role?.permission?.find(({ resource }) => resource == res);
        if (permission && permission.action instanceof Array) {
            if (permission.action.includes(action))
                return true
        }
        return false;
    }
    return (
        <>
            <Container className="mt-5" fluid>
                <div className="px-3">
                    <h3 className="all-page-heading">{role?.id ? 'Update Role' : 'Create New Role'}</h3>
                </div>
                <Card className="p-4">
                    <Row>
                        <Col>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row>
                                    <Col xs="12" md="6">
                                        <FormGroup>
                                            <label> Role Name </label>
                                            <input
                                                className="form-control"
                                                // readOnly={updateAble}
                                                type="text"
                                                {...register("name", {})}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <h1 className="heading-style-1">Permissions</h1>

                                <Row>
                                    {Object.values(RESOURCE)?.map((item, index) => {
                                        return (
                                            <Col key={index} xl="3" md="6" xs="12">
                                                <FormGroup
                                                    className="my-4"
                                                    key={index}
                                                >
                                                    <h3 className="heading-style-1" style={{ fontSize: "18px" }}>{item}</h3>
                                                    <input
                                                        type="hidden"
                                                        defaultValue={item}
                                                        {...register(`grants[${index}].resource`, {})}
                                                    />
                                                    <div className="">
                                                        <div className="align-center pl-2">
                                                            <div className="mr-2">
                                                                <input
                                                                    id={`read-checkbox-${index}`}
                                                                    className="cursor-pointer"
                                                                    style={{ width: "18px", height: "18px",accentColor:"#7367F0" }}
                                                                    type="checkbox"
                                                                    {...register(`grants[${index}].permissions`, {})}
                                                                    value="read"
                                                                    defaultChecked={checkIfChecked(item, 'read')}
                                                                />
                                                            </div>
                                                            <label
                                                                for={`read-checkbox-${index}`}
                                                                className=" "
                                                            >
                                                                Read
                                                            </label>
                                                        </div>

                                                        <div className="align-center pl-2">
                                                            <div className="mr-2">
                                                                <input
                                                                    id={`write-checkbox-${index}`}
                                                                    className="cursor-pointer"
                                                                    style={{ width: "18px", height: "18px",accentColor:"#7367F0" }}
                                                                    type="checkbox"
                                                                    {...register(`grants[${index}].permissions`, {})}
                                                                    value="write"
                                                                    defaultChecked={checkIfChecked(item, 'write')}
                                                                />
                                                            </div>
                                                            <label
                                                                for={`write-checkbox-${index}`}
                                                                className=""
                                                            >
                                                                Write
                                                            </label>
                                                        </div>

                                                        <div className="align-center pl-2">
                                                            <div className="mr-2">
                                                                <input
                                                                    id={`edit-checkbox-${index}`}
                                                                    className="cursor-pointer"
                                                                    style={{ width: "18px", height: "18px",accentColor:"#7367F0" }}
                                                                    type="checkbox"
                                                                    {...register(`grants[${index}].permissions`, {})}
                                                                    value="edit"
                                                                    defaultChecked={checkIfChecked(item, 'edit')}
                                                                />
                                                            </div>
                                                            <label
                                                                for={`edit-checkbox-${index}`}
                                                                className=""
                                                            >
                                                                Edit
                                                            </label>
                                                        </div>

                                                        <div className="align-center pl-2">
                                                            <div className="mr-2">
                                                                <input
                                                                    id={`delete-checkbox-${index}`}
                                                                    className="cursor-pointer"
                                                                    style={{ width: "18px", height: "18px",accentColor:"#7367F0" }}
                                                                    type="checkbox"
                                                                    {...register(`grants[${index}].permissions`, {})}
                                                                    value="delete"
                                                                    defaultChecked={checkIfChecked(item, 'delete')}
                                                                />
                                                            </div>
                                                            <label
                                                                for={`delete-checkbox-${index}`}
                                                                className=""
                                                            >
                                                                Delete
                                                            </label>
                                                        </div>
                                                        <div className="align-center pl-2">
                                                            <div className="mr-2">
                                                                <input
                                                                    id={`manage-checkbox-${index}`}
                                                                    className="cursor-pointer"
                                                                    style={{ width: "18px", height: "18px",accentColor:"#7367F0" }}
                                                                    type="checkbox"
                                                                    {...register(`grants[${index}].permissions`, {})}
                                                                    value="manage"
                                                                    defaultChecked={checkIfChecked(item, 'manage')}
                                                                />
                                                            </div>
                                                            <label
                                                                for={`manage-checkbox-${index}`}
                                                                className=""
                                                            >
                                                                Manage
                                                            </label>
                                                        </div>


                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        );
                                    })}
                                </Row>
                                <div className='mt-5 align-item-center d-flex justify-content-end'>
                                    <div className='d-flex align-item-center justify-content-end'>
                                        <Link to='/admin/role'>
                                            <Button type='button' className='rz-button-outline-primary'>
                                                Cancel
                                            </Button>
                                        </Link>
                                        <Button type="submit"  className='btn-next ml-3 rz-button-primary'>
                                            <span className='align-middle d-sm-inline-block d-none'>Save</span>
                                        </Button>
                                    </div>
                                </div>
                               
                            </Form>
                        </Col>
                    </Row>
                </Card>
            </Container>
        </>
    );
};

export default AddUpdateRoles;
