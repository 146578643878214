import { userConstants } from '../../constants';
import { useSelector } from 'react-redux';

const User = () => {
    return useSelector(state => state.verifyOtpReducer.user)
}
const initialState = User ? { User } : {};

export function loginReducer(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return { loggingIn: true };
        case userConstants.LOGIN_SUCCESS:
            return { user: action.payload };
        case userConstants.LOGIN_FAILURE:
            return {};
        case userConstants.LOGIN_EMPTY:
            return {};
        default:
            return state
    }
}