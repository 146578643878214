import { api } from "../../helpers";

export const settingService = {
    getSettings,
    storeSettings,
    updateSettings,
    deleteSettings
};

function getSettings() {
    return api.get(`/setting`);
}

function storeSettings(data) {
    return api.post(`/setting`, data);
}

function updateSettings(data) {
    return api.put(`/setting/${data.setting_id}`, data);
}

function deleteSettings(id) {
    return api.delete(`/setting/${id}`);
}