import React, { useState } from 'react'
import { useGetTokenSellerMyCommission } from 'hooks/useTokenSellect';
import ChanelPartnerMyCommission from '../comman/MyCommission/ChanelPartnerMyCommission';
export default function TokenSellerCommission() {
    const { data, isLoading } = useGetTokenSellerMyCommission();
    const total = data?.total;
    return (
        <>
            <div className="px-4">
                <ChanelPartnerMyCommission
                    total={total}
                    data={data}
                    isLoading={isLoading}
                    type={2}
                />
            </div>
        </>
    )
}
