import React from "react";
import Citylocation from "../cityiconupdate";
import DocumentChecklist from "../document-checklist/DocumentChecklist";
import LegalChecklist from "../legal-checklist/LegalChecklist";
import PopupText from "../popuptext";
import VideoLink from "../videolink";

const Config = () => {
  return (
    <>
      <DocumentChecklist />
      <LegalChecklist />
      <VideoLink />
      <PopupText />
      <Citylocation />
    </>
  );
};

export default Config;
