import { useGetCompanyPropertyById } from "../../../hooks/useCompany";
import React from "react";
import { Button, Card, Col, Container, Row, Spinner, Table } from "reactstrap";
import NotFound from "components/NotFound/NotFound";
export default function InvestorList({
    dao = false
}) {
    const { data, isLoading } = useGetCompanyPropertyById({
        company_id: localStorage.getItem("company_id"),
        property_id: localStorage.getItem("property_id")
    });
    return (
        <Container>
            <Card>
                <Table responsive>
                    <thead className="rz-thead-style">
                        <tr>
                            <th>INVESTOR ID</th>
                            <th>EMPLOYEE NAME</th>
                            <th>ROLE</th>
                            <th>Total Order</th>
                            <th>{dao ? "VOATING %" : "OWNERSHIP %"}</th>
                            <th>TOKENS</th>
                            <th>CAP CONTRIBUTION</th>
                            {/* <th className="text-right">ACTIONS</th> */}
                        </tr>
                    </thead>

                    <tbody>
                        {isLoading && (
                            <tr className="text-center">
                                <td colSpan="10">
                                    <Spinner animation="border" role="status" >{null}</Spinner>
                                </td>
                            </tr>
                        )}
                        {data?.investors?.map((investor) => (
                            <tr>
                                <td>
                                    {investor?.id}
                                </td>
                                <td>
                                    {" "}
                                    <img width="30" style={{ borderRadius: 15, marginRight: 5 }} src={investor?.avatar} alt="" />
                                    {investor?.name}{" "}
                                </td>
                                <td>Investor</td>
                                <td>{investor?.total_order}</td>
                                <td>{investor?.ownership} %</td>
                                <td>{investor?.no_sqyds?.toFixed(2)}</td>
                                <td>{investor?.total_invested}</td>
                                {/* <td className="text-right">
                                    <Button color="primary" className="btn-sm">
                                        <i className="fas fa-eye"></i>
                                    </Button>
                                    <Button color="primary" className="btn-sm">
                                        <i className="fas fa-edit"></i>
                                    </Button>
                                    <Button color="primary" className="btn-sm">
                                        <i className="fas fa-trash    "></i>
                                    </Button>
                                </td> */}
                            </tr>
                        ))}
                    </tbody>
                </Table>
                {data?.investors?.length <= 0 &&
                    <NotFound
                        message="No one invested into this property."
                    />
                }
            </Card>
        </Container>
    );
}
