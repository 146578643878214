import { api } from "../../helpers";

export const blogService = {
  postBlogs,
  getBlogs,
  updateBlogs,
  getsingleBlog,
  deleteBlog,
};

function postBlogs(data) {
  return api.post(`/config/blogs`, data);
}

function updateBlogs(data, blog_id) {
  return api.put(`/config/blogs/${blog_id}`, data);
}

function getBlogs() {
  return api.get(`/config/blogs`);
}
function getsingleBlog(blog_id) {
  return api.get(`/config/blogs/${blog_id}`);
}
function deleteBlog(blog_id) {
  return api.delete(`/config/blogs/${blog_id}`);
}
