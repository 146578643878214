import { toast } from "react-toastify";
import { accountConstants } from "redux/constants/account.constants";
import { accountService } from "../services";

export const accountAction = { postAccount, getAccount,viewAccount , deleteAccount };

// action for get   Account
function getAccount(company_id) {
  return (dispatch) => {
    dispatch(request());
    accountService.getAccount(company_id).then(
      (res) => {
        dispatch(success(res));
        
        console.log(res)
        // toast.success(res.message);
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };
  
  function request(res) {
    return {
      type: accountConstants.GET_ACCOUNT_REQUEST,
    };
  }
  function success(res) {
    return {
      type: accountConstants.GET_ACCOUNT_SUCCESS,
      payload: res,
    };
  }
  function failure(res) {
    return {
      type: accountConstants.GET_ACCOUNT_FAILURE,
      payload: res,
    };
  }
}

// action for view Account
function viewAccount(id) {
  return (dispatch) => {
    dispatch(request());
    accountService.viewAccount(id).then(
      (res) => {
        dispatch(success(res));
        // toast.success(res.message);
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };

  function request(res) {
    return {
      type: accountConstants.VIEW_ACCOUNT_REQUEST,
    };
  }
  function success(res) {
    return {
      type: accountConstants.VIEW_ACCOUNT_SUCCESS,
      payload: res,
    };
  }
  function failure(res) {
    return {
      type: accountConstants.VIEW_ACCOUNT_FAILURE,
      payload: res,
    };
  }
}

// action for post   Account
function postAccount({data,company_id}) {
  return (dispatch) => {
    dispatch(request());
    accountService.postAccount(data).then(
      (res) => {
        dispatch(success(res));
        dispatch(getAccount(company_id))
        toast.success("Account Added Successfully");
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };

  function request(res) {
    return {
      type: accountConstants.POST_ACCOUNT_REQUEST,
    };
  }
  function success(res) {
    return {
      type: accountConstants.POST_ACCOUNT_SUCCESS,
      payload: res,
    };
  }
  function failure(res) {
    return {
      type: accountConstants.POST_ACCOUNT_FAILURE,
      payload: res,
    };
  }
}

// action for delete   Account
function deleteAccount({id,company_id}) {
  return (dispatch) => {
    dispatch(request());
    accountService.deleteAccount(id).then(
      (res) => {
        dispatch(success(res));
        dispatch(getAccount(company_id))
        toast.success(res.message);
       
      },
      (error) => {
        toast.error(error);
        dispatch(failure(error));
      }
    );
  };

  function request(res) {
    return {
      type: accountConstants.DELETE_ACCOUNT_REQUEST,
    };
  }
  function success(res) {
    return {
      type: accountConstants.DELETE_ACCOUNT_SUCCESS,
      payload: res,
    };
  }
  function failure(res) {
    return {
      type: accountConstants.DELETE_ACCOUNT_FAILURE,
      payload: res,
    };
  }
}
