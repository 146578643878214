import React, { useState } from 'react'
import { Card, CardBody, CardSubtitle, CardTitle } from 'reactstrap';
import { moneyFormat } from 'utils';
import orderIcon from './../../../assets/img/briefcase.png';
import balanceIcon from './../../../assets/img/total_balance.png';
import deductionIcon from './../../../assets/img/deduction.png';
import { useLocation } from 'react-router-dom';
import { useAssetListById } from 'hooks/useFeeManagement ';
import AssetOrderList from './AssetOrderList';
export default function AssetListById() {
    const asset = {};
    const location = useLocation();
    const { fee = null } = location?.state;
    const { data } = useAssetListById({ fee_type: fee?.name, property_id: fee?.property_id });
    const total = data?.data?.counts;
    const headingstyle = {
        fontFamily: 'Montserrat',
        color: "#5E5873",
        fontSize: '24px',
        fontWeight: 500,
    }
    const assetIdStyle = {
        fontFamily: 'Montserrat',
        color: '#82868B',
        fontSize: "14px",
        fontWeight: "500"
    }
    const ColorCard = ({ color, title, subTitle, icon }) => {
        const iconStyle = {
            fontSize: '36px',
            marginRight: '10px',
            color: `white`,
        };
        const titleStyle = {
            color: 'white',
            fontFamily: 'Montserrat',
            fontWeight: 600,
        };
        const subTitleStyle = {
            color: 'white',
            fontFamily: 'Montserrat',
            fontWeight: 400,
        };
        return (
            <Card style={{ backgroundColor: color, display: 'flex' }}>
                <CardBody style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: "space-between",
                    paddingTop: "40px",
                    paddingBottom: "40px"
                }}>
                    <div>
                        <CardTitle tag="h2" style={titleStyle}>{title}</CardTitle>
                        <CardSubtitle tag="p" style={subTitleStyle}>{subTitle}</CardSubtitle>
                    </div>
                    <div style={{
                        marginLeft: 2
                    }}>
                        <img src={icon} />
                    </div>
                </CardBody>
            </Card>
        );
    };
    console.log(fee, data, "----->")
    return (
        <>
            <div className="px-4">
                <div className="row mx-0">
                    <div className='col-6 pl-2'>
                        <h3 style={headingstyle}>{asset?.property_name}</h3>
                        <p style={assetIdStyle}>Asset ID-: #{fee?.property_id}</p>
                    </div>
                    <div className='col-4'>
                    </div>
                    <div className='col-1 mr-1'>
                        <button
                            type="button"
                            className={`btn btn-primary`}
                            disabled={true}
                        // onClick={() => handleOptionChange(1)}
                        >
                            Withdraw amount
                        </button>
                    </div>
                </div>
                <div className="row mt-2 px-2">
                    <div className='col-10'>
                        <div className='row'>
                            <div className="col-4">
                                <ColorCard
                                    color="#7C278A"
                                    title={moneyFormat(total?.totalCollectedFees || 0)}
                                    subTitle="Total balance"
                                    icon={balanceIcon}
                                />
                            </div>
                            <div className="col-4">
                                <ColorCard
                                    color="#E9573E"
                                    title={(total?.totalOrders || 0)}
                                    subTitle="Total Orders"
                                    icon={orderIcon}
                                />
                            </div>
                            <div className="col-4">
                                <ColorCard
                                    color="#31A2C1"
                                    title={moneyFormat(total?.totalTax || 0)}
                                    subTitle="Taxes & Deduction"
                                    icon={deductionIcon}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-2'>
                    <AssetOrderList data={data?.data} />
                </div>
            </div>
        </>
    )
}
