import { settingConstants } from "redux/constants/setting.constants";

export function settingReducer(state = {}, action) {
    switch (action.type) {
        case settingConstants.GET_SETTINGS_REQUEST:
            return { loading: true };
        case settingConstants.GET_SETTINGS_SUCCESS:
            return { settings: action.payload.data };
        case settingConstants.GET_SETTINGS_FAILURE:
            return { error: action.error };
        default:
            return state;
    }
}
