import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Col, Form, FormGroup, Row } from "reactstrap"
import { propertyActions } from "redux/actions";
import * as Yup from 'yup'


const TimeLimit = ({ stepper, parentStepper }) => {
    const Schema = Yup.object().shape({
        one_securing_full_participation: Yup.object().shape({
            day: Yup.string().required('Day is required'),
            hour: Yup.string().required('Hour is required'),
            minute: Yup.string().required('Minute is required'),
            second: Yup.string().required('Second is required'),
        }),
        two_securing_full_participation: Yup.object().shape({
            day: Yup.string().required('Day is required'),
            hour: Yup.string().required('Hour is required'),
            minute: Yup.string().required('Minute is required'),
            second: Yup.string().required('Second is required'),
        }),
        one_document_signatures: Yup.object().shape({
            day: Yup.string().required('Day is required'),
            hour: Yup.string().required('Hour is required'),
            minute: Yup.string().required('Minute is required'),
            second: Yup.string().required('Second is required'),
        }),
        two_document_signatures: Yup.object().shape({
            day: Yup.string().required('Day is required'),
            hour: Yup.string().required('Hour is required'),
            minute: Yup.string().required('Minute is required'),
            second: Yup.string().required('Second is required'),
        }),
        making_full_payment: Yup.object().shape({
            day: Yup.string().required('Day is required'),
            hour: Yup.string().required('Hour is required'),
            minute: Yup.string().required('Minute is required'),
            second: Yup.string().required('Second is required'),
        })
    })
    const dispatch = useDispatch();

    const hoursOptions = Array.from({ length: 24 }, (_, index) => index);
    const minutesOptions = Array.from({ length: 60 }, (_, index) => index);
    const secondsOptions = Array.from({ length: 60 }, (_, index) => index);
    const property = useSelector(state => state.propertyReducer.property);
    const {
        register,
        watch,
        handleSubmit,
        getValues,
        setValue,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(Schema),
        defaultValues: {
        },
    });
    const onSubmit = (values) => {
        values.property_id = property?.id;
        dispatch(propertyActions.saveTimeLimit(values, stepper))
    }
    useEffect(() => {
        if (property?.PropertyTimeLimit) {
            reset({
                one_securing_full_participation: property?.PropertyTimeLimit?.one_securing_full_participation,
                two_securing_full_participation: property?.PropertyTimeLimit?.two_securing_full_participation,
                one_document_signatures: property?.PropertyTimeLimit?.one_document_signatures,
                two_document_signatures: property?.PropertyTimeLimit?.two_document_signatures,
                making_full_payment: property?.PropertyTimeLimit?.making_full_payment
            })
        }
    }, [property])
    const InputData = ({ name, register, errors }) => {
        return (
            <Row className="mt-3">
                <Col>
                    <FormGroup>
                        <label>Day(s)</label>
                        <input type="number" {...register(`${name}.day`)} min={0} placeholder="Enter Day (s)" className="form-control" />
                        {errors?.day && <div className="rz-text-14 text-danger mt-1 ml-1">{errors.day?.message}</div>}
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <label>Hour(s)</label>
                        <select {...register(`${name}.hour`)} className="form-control">
                            <option value="">Select Hour (s)</option>
                            {
                                hoursOptions.map((item) => (
                                    <option value={item}>
                                        {item}
                                    </option>
                                ))
                            }
                        </select>
                        {errors?.hour && <div className="rz-text-14 text-danger mt-1 ml-1">{errors.hour?.message}</div>}
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <label>Minute(s)</label>
                        <select {...register(`${name}.minute`)} className="form-control">
                            <option value="">Select Min (s)</option>
                            {
                                minutesOptions.map((item) => (
                                    <option value={item}>
                                        {item}
                                    </option>
                                ))
                            }
                        </select>
                        {errors?.minute && <div className="rz-text-14 text-danger mt-1 ml-1">{errors.minute?.message}</div>}
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <label>Second(s)</label>
                        <select {...register(`${name}.second`)} className="form-control">
                            <option value="">Select Sec (s)</option>
                            {
                                secondsOptions.map((item) => (
                                    <option value={item}>
                                        {item}
                                    </option>
                                ))
                            }
                        </select>
                        {errors?.second && <div className="rz-text-14 text-danger mt-1 ml-1">{errors.second?.message}</div>}
                    </FormGroup>
                </Col>

            </Row>
        )
    }
    return (
        <>
            <div className='content-header'>
                <h3 className='mb-0 rz-heading rz-text-20'>Asset Time Limit</h3>
            </div>
            <Form onSubmit={handleSubmit(onSubmit)} className="mt-4">
                <div >
                    <h3 className='mb-0 rz-heading rz-text-16'>1st time of Securing Full Participation</h3>
                    <InputData register={register} errors={errors?.one_securing_full_participation} name="one_securing_full_participation" />
                </div>
                <div className="mt-3">
                    <h3 className='mb-0 rz-heading rz-text-16'>2nd time of Securing Full Participation</h3>
                    <InputData register={register} errors={errors?.two_securing_full_participation} name="two_securing_full_participation" />
                </div>
                <div className="mt-3">
                    <h3 className='mb-0 rz-heading rz-text-16'>1st time of getting Document Signatures</h3>
                    <InputData register={register} errors={errors?.one_document_signatures} name="one_document_signatures" />
                </div>
                <div className="mt-3">
                    <h3 className='mb-0 rz-heading rz-text-16'>2nd time of getting Document Signatures</h3>
                    <InputData register={register} errors={errors?.two_document_signatures} name="two_document_signatures" />
                </div>
                <div className="mt-3">
                    <h3 className='mb-0 rz-heading rz-text-16'>Making full payment</h3>
                    <InputData register={register} errors={errors?.making_full_payment} name="making_full_payment" />
                </div>
                <div className='d-flex align-items-center justify-content-between mt-5'>
                    <Button type='button' color='success' onClick={() => stepper.next()} className='btn-next' >
                        <span className='align-middle d-sm-inline-block d-none'>Skip</span>
                        <i className='fa fa-fast-forward align-middle mt-1 mr-sm-25 mr-0'></i>
                    </Button>
                    <div className='d-flex align-items-center justify-content-end'>
                        <Link to='/admin/property'>
                            <Button type='button' className='rz-button-outline-primary'>
                                Cancel
                            </Button>
                        </Link>
                        <Button type="button" className='rz-button-outline-primary mx-3' onClick={() => stepper.previous()}>
                            <BsArrowLeftShort style={{ fontSize: "22px" }} />
                            <span className='align-middle d-sm-inline-block d-none'>Back</span>
                        </Button>
                        <Button type='submit' className='rz-button-primary'>
                            <BsArrowRightShort style={{ fontSize: "22px" }} />
                            <span className='align-middle d-sm-inline-block d-none'>Next</span>
                        </Button>
                    </div>
                </div>
            </Form>
        </>
    )
}

export default TimeLimit


