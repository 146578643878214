import { uploadImage } from 'helpers/image.helper';
import { useMutatePropertyDueDiligence } from 'hooks/useProperty';
import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Form, FormGroup, Input, Label, Row, Col, Card } from 'reactstrap';
import { checkAttechment } from '../../../utils';
import { FaRegTrashAlt } from "react-icons/fa";
import plusIcon from './../../../assets/img/plus-circle.svg';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import { Link } from 'react-router-dom';
const DueDiligence = ({ stepper, type }) => {

    const { register,
        control,
        handleSubmit,
        reset, errors,
        trigger, setError } = useForm({
        });
    const { property } = useSelector(state => state.propertyReducer);
    const [removeids, setremoveids] = useState([]);

    const { fields: legalFields, append: appendLegal, remove: removeLegal } = useFieldArray({
        control,
        name: 'legal',
    });
    const { fields: marketFields, append: appendMarket, remove: removeMarket } = useFieldArray({
        control,
        name: 'valuation',
    });
    const { fields: buildingFields, append: appendBuilding, remove: removeBuilding } = useFieldArray({
        control,
        name: 'structure',
    });
    // const { fields: financialFields, append: appendFinancial, remove: removeFinancial } = useFieldArray({
    //     control,
    //     name: 'financial',
    // });
    const { mutate: addDueDiligence } = useMutatePropertyDueDiligence()
    useEffect(() => {
        reset()
        if (property?.propertyDueDiligence?.length > 0) {
            for (let i in property?.propertyDueDiligence) {
                if (property?.propertyDueDiligence[i]?.type == "legal") {
                    appendLegal({
                        name: property?.propertyDueDiligence[i]?.name,
                        stamp_duty: property?.propertyDueDiligence[i]?.stamp_duty,
                        link: property?.propertyDueDiligence[i]?.link,
                        id2: property?.propertyDueDiligence[i]?.id,
                        logo1: property?.propertyDueDiligence[i]?.logo,
                    });
                }
                if (property?.propertyDueDiligence[i]?.type == "valuation") {
                    appendMarket({
                        name: property?.propertyDueDiligence[i]?.name,
                        stamp_duty: property?.propertyDueDiligence[i]?.stamp_duty,
                        link: property?.propertyDueDiligence[i]?.link,
                        id2: property?.propertyDueDiligence[i]?.id,
                        logo1: property?.propertyDueDiligence[i]?.logo,
                    });
                }
                if (property?.propertyDueDiligence[i]?.type == "structure") {
                    appendBuilding({
                        name: property?.propertyDueDiligence[i]?.name,
                        stamp_duty: property?.propertyDueDiligence[i]?.stamp_duty,
                        link: property?.propertyDueDiligence[i]?.link,
                        id2: property?.propertyDueDiligence[i]?.id,
                        logo1: property?.propertyDueDiligence[i]?.logo,
                    });
                }
                // if (property?.propertyDueDiligence[i]?.type == "financial") {
                //     appendFinancial({
                //         name: property?.propertyDueDiligence[i]?.name,
                //         stamp_duty: property?.propertyDueDiligence[i]?.stamp_duty,
                //         id2: property?.propertyDueDiligence[i]?.id,
                //         logo1: property?.propertyDueDiligence[i]?.logo,
                //     });
                // }
            }
        }
        else {
            appendLegal({});
            appendMarket({});
            appendBuilding({});
            // appendFinancial({});
        }
        if (legalFields?.length == 0) {
            appendLegal({});
        }
        if (marketFields?.length == 0) {
            appendMarket({});
        }
        if (buildingFields?.length == 0) {
            appendBuilding({});
        }
    }, [property]);

    const onSubmit = async (data) => {
        //legal,valuation,structure,financial
        if (data?.legal?.length > 0) {
            for (let i in data?.legal) {
                if (data?.legal[i]?.logo?.length > 0) {
                    data.legal[i].logo = await uploadImage(data?.legal[i]?.logo[0])
                }
            }
        }
        if (data?.valuation?.length > 0) {
            for (let j in data?.valuation) {
                if (data?.valuation[j]?.logo?.length > 0) {
                    data.valuation[j].logo = await uploadImage(data?.valuation[j]?.logo[0])
                }
            }
        }
        if (data?.structure?.length > 0) {
            for (let i in data?.structure) {
                if (data?.structure[i]?.logo?.length > 0) {
                    data.structure[i].logo = await uploadImage(data?.structure[i]?.logo[0])
                }
            }
        }
        // if (data?.financial?.length > 0) {
        //     for (let i in data?.financial) {
        //         if (data?.financial[i]?.logo?.length > 0) {
        //             data.financial[i].logo = await uploadImage(data?.financial[i]?.logo[0])
        //         }
        //     }
        // }
        data.property_id = property.id
        data.removeids = removeids;
        await addDueDiligence(data,
            {
                onSuccess: async (res) => {
                    toast.success(res?.message)
                    stepper.next()
                },
                onError: (error) => {
                    console.log(error)
                }
            }
        )
    };
    return (
        <>
            <div className='content-header'>
                <h3 className='mb-0 rz-heading rz-text-18'>Due-Diligence</h3>
            </div>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <h4 className='mt-2 rz-heading rz-text-16'>Legal</h4>
                {legalFields.map((field, index) => (

                    <Card key={field.id} className="bg-white shadow border-0 p-4">
                        <FormGroup key={field.id}>
                            <Row style={{ marginTop: '1.5rem' }}>
                                <Col>
                                    <Label for={`legal.${index}.name`}>Name</Label>
                                    <input
                                        placeholder='Enter Name'
                                        className="form-control"
                                        type="text"
                                        name={`legal.${index}.name`}
                                        {...register(`legal.${index}.name`)}
                                        defaultValue={field.name || ''}
                                    />
                                    {errors?.legal?.[index]?.name && (
                                        <span className="error">Stamp Duty is required</span>
                                    )}
                                </Col>
                                <Col>
                                    <Label for={`legal.${index}.stamp_duty`}>Location </Label>
                                    <input
                                        placeholder='Enter city and state'
                                        className="form-control"
                                        name={`legal.${index}.stamp_duty`}
                                        {...register(`legal.${index}.stamp_duty`)}
                                        defaultValue={field.stamp_duty || ''}
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col md={6}>
                                    <Label for={`legal.${index}.logo`}>Logo</Label>
                                    <div className="custom-file cursor-pointer">
                                        <input
                                            className='custom-file-input'
                                            type="file"
                                            name={`legal.${index}.logo`}
                                            {...register(`legal.${index}.logo`)}
                                        />
                                        <label className="custom-file-label" htmlFor="customFile">{field?.logo ? field.logo[0]?.name : 'Choose file'}</label>
                                    </div>
                                    {checkAttechment(field?.logo1) && <a href={`${field?.logo1}`} target="_blank">View Attachement</a>}
                                </Col>
                                <Col>
                                    <Label for={`legal.${index}.link`}>Link </Label>
                                    <input
                                        placeholder='Enter Link'
                                        className="form-control"
                                        name={`legal.${index}.link`}
                                        {...register(`legal.${index}.link`)}
                                        defaultValue={field.link || ''}
                                    />
                                </Col>
                            </Row>
                            {legalFields?.length > 0 &&
                                <Row
                                    style={{
                                        position: "absolute",
                                        top: 5,
                                        right: 10
                                    }}
                                    className='mt-2'>
                                    <Col>
                                        <FaRegTrashAlt
                                            style={{
                                                color: "red",
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => {
                                                removeLegal(index);
                                                setremoveids([...removeids, field])
                                            }}
                                        />
                                    </Col>
                                </Row>}
                        </FormGroup>
                    </Card>
                ))}
                <Row
                    className='mt-2'
                    style={{ justifyContent: "center" }}>
                    <img className='cursor-pointer' src={plusIcon} onClick={() => appendLegal({})} />
                </Row>
                <h4 className='mt-2 rz-heading rz-text-16'>Valuation</h4>
                {marketFields.map((field, index) => (
                    <Card key={field.id} className="bg-white shadow border-0 p-4">
                        <FormGroup key={field.id}>
                            <Row style={{ marginTop: '1.5rem' }}>
                                <Col>
                                    <Label for={`valuation.${index}.name`}>Name</Label>
                                    <input
                                        placeholder='Enter Name'
                                        className="form-control"
                                        type="text"
                                        name={`valuation.${index}.name`}
                                        {...register(`valuation.${index}.name`)}
                                        defaultValue={field.name || ''}
                                    />
                                </Col>
                                <Col>
                                    <Label for={`valuation.${index}.stamp_duty`}>Location</Label>
                                    <input
                                        placeholder='Enter city and state'
                                        className="form-control"
                                        name={`valuation.${index}.stamp_duty`}
                                        {...register(`valuation.${index}.stamp_duty`)}
                                        defaultValue={field.stamp_duty || ''}
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col md={6}>
                                    <Label for={`valuation.${index}.logo`}>Logo</Label>
                                    <div className="custom-file cursor-pointer">
                                        <input
                                            className='custom-file-input'
                                            type="file"
                                            name={`valuation.${index}.logo`}
                                            {...register(`valuation.${index}.logo`)}
                                        />
                                        <label className="custom-file-label" htmlFor="customFile">{field?.logo ? field.logo[0]?.name : 'Choose file'}</label>
                                    </div>
                                    {checkAttechment(field?.logo1) && <a href={`${field?.logo1}`} target="_blank">View Attachement</a>}
                                </Col>
                                <Col>
                                    <Label for={`valuation.${index}.link`}>Link </Label>
                                    <input
                                        placeholder='Enter Link'
                                        className="form-control"
                                        name={`valuation.${index}.link`}
                                        {...register(`valuation.${index}.link`)}
                                        defaultValue={field.link || ''}
                                    />
                                </Col>
                            </Row>
                            {marketFields?.length > 0 && <Row
                                style={{
                                    position: "absolute",
                                    top: 10,
                                    right: 10
                                }}
                                className='mt-2'>
                                <Col>
                                    <FaRegTrashAlt
                                        style={{
                                            color: "red",
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => {
                                            setremoveids([...removeids, field])
                                            removeMarket(index)
                                        }}
                                    />
                                </Col>
                            </Row>}
                        </FormGroup>
                    </Card>
                ))}
                <Row
                    className='mt-2'
                    style={{ justifyContent: "center" }}>
                    <img className='cursor-pointer' src={plusIcon} onClick={() => appendMarket({})} />
                </Row>
                <h4 className='mt-2 rz-heading rz-text-16'>Structure</h4>
                {buildingFields.map((field, index) => (
                    <Card key={field.id} className="bg-white shadow border-0 p-4">
                        <FormGroup key={field.id}>
                            <Row style={{ marginTop: '1.5rem' }}>
                                <Col>
                                    <Label for={`structure.${index}.name`}>Name</Label>
                                    <input
                                        placeholder='Enter Name'
                                        className="form-control"
                                        type="text"
                                        name={`structure.${index}.name`}
                                        {...register(`structure.${index}.name`)}
                                        defaultValue={field.name || ''}
                                    />
                                </Col>
                                <Col>
                                    <Label for={`structure.${index}.stamp_duty`}>Location </Label>
                                    <input
                                        placeholder='Enter city and state'
                                        className="form-control"
                                        name={`structure.${index}.stamp_duty`}
                                        {...register(`structure.${index}.stamp_duty`)}
                                        defaultValue={field.stamp_duty || ''}
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col md={6}>
                                    <Label for={`structure.${index}.logo`}>Logo</Label>
                                    <div className="custom-file cursor-pointer">
                                        <input
                                            className='custom-file-input'
                                            type="file"
                                            name={`structure.${index}.logo`}
                                            {...register(`structure.${index}.logo`)}
                                        />
                                        <label className="custom-file-label" htmlFor="customFile">{field?.logo ? field.logo[0]?.name : 'Choose file'}</label>
                                    </div>
                                    {checkAttechment(field?.logo1) && <a href={`${field?.logo1}`} target="_blank">View Attachement</a>}
                                </Col>
                                <Col>
                                    <Label for={`structure.${index}.link`}>Link </Label>
                                    <input
                                        placeholder='Enter Link'
                                        className="form-control"
                                        name={`structure.${index}.link`}
                                        {...register(`structure.${index}.link`)}
                                        defaultValue={field.link || ''}
                                    />
                                </Col>
                            </Row>
                            {buildingFields?.length > 0 && <Row
                                style={{
                                    position: "absolute",
                                    top: 10,
                                    right: 10
                                }}
                                className='mt-2'>
                                <Col>
                                    <FaRegTrashAlt
                                        style={{
                                            color: "red",
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => {
                                            setremoveids([...removeids, field])
                                            removeBuilding(index)
                                        }}
                                    />
                                </Col>
                            </Row>}
                        </FormGroup>
                    </Card>
                ))}
                <Row
                    className='mt-2'
                    style={{ justifyContent: "center" }}>
                    <img className='cursor-pointer' src={plusIcon} onClick={() => appendBuilding({})} />

                </Row>
                {/* <h4 className='mt-2'>Financials</h4>
                {financialFields.map((field, index) => (
                    <Card key={field.id} className="bg-white shadow border-0 p-4">
                        <FormGroup key={field.id}>
                            <Row>
                                <Col>
                                    <Label for={`financial.${index}.name`}>Name</Label>
                                    <input
                                        placeholder='Enter Name'
                                        className="form-control"
                                        type="text"
                                        name={`financial.${index}.name`}
                                        {...register(`financial.${index}.name`)}
                                        defaultValue={field.name || ''}
                                    />
                                </Col>
                                <Col>
                                    <Label for={`financial.${index}.stamp_duty`}>Location *</Label>
                                    <input
                                        placeholder='Enter city and state'
                                        className="form-control"
                                        name={`financial.${index}.stamp_duty`}
                                        {...register(`financial.${index}.stamp_duty`)}
                                        defaultValue={field.stamp_duty || ''}
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col md={6}>
                                    <Label for={`financial.${index}.logo`}>Logo</Label>
                                    <input
                                        className='form-control'
                                        type="file"
                                        name={`financial.${index}.logo`}
                                        {...register(`financial.${index}.logo`)}
                                    />
                                    {checkAttechment(field?.logo1)  && <a href={`${field?.logo1}`} target="_blank">View Attachement</a>}
                                </Col>
                            </Row>
                            {financialFields?.length > 1 && <Row
                                style={{
                                    position: "absolute",
                                    top: 10,
                                    right: 10
                                }}
                                className='mt-2'>
                                <Col>
                                    <i
                                        style={{
                                            color: "red",
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => {
                                            setremoveids([...removeids, field])
                                            removeFinancial(index)
                                        }}
                                        className="fa fa-trash" />
                                </Col>
                            </Row>}
                        </FormGroup>
                    </Card>
                ))}
                <Row
                    className='mt-2'
                    style={{ justifyContent: "center" }}>
                    <Button
                        style={{ backgroundColor: '#7367F0', color: '#fff', marginLeft: '10px' }}
                        onClick={() => appendFinancial({})}
                    >
                        Add Financial
                    </Button>
                </Row> */}
                <hr></hr>
                <div className='d-flex mt-2 align-items-center justify-content-between'>
                    <Button type='button' color='success' onClick={() => { stepper.next() }}
                        className='btn-next' >
                        <span className='align-middle d-sm-inline-block d-none'>Skip</span>
                        <i className='fa fa-fast-forward align-middle mt-1 mr-sm-25 mr-0'></i>
                    </Button>
                    <div className='flex align-items-center justify-content-end'>
                        <Link to='/admin/property'>
                            <Button type='button' className='rz-button-outline-primary'>
                                Cancel
                            </Button>
                        </Link>
                        <Button className='rz-button-outline-primary mx-3' onClick={() => stepper.previous()}>
                            <BsArrowLeftShort style={{ fontSize: "22px" }} />
                            <span className='align-middle d-sm-inline-block d-none'>Back</span>
                        </Button>

                        <Button className='rz-button-primary' type="submit">
                            <BsArrowRightShort style={{ fontSize: "22px" }} /> Next
                        </Button>
                    </div>

                </div>
            </Form>

        </>
    );
}

export default DueDiligence;