// import { orderConstants } from '';

import { orderConstants } from "../../constants/order.contstants";

export function getOrdersReducer(state = {}, action) {
  switch (action.type) {
    case orderConstants.GET_ORDERS_REQUEST:
      return { loading: true };
    case orderConstants.GET_ORDERS_SUCCESS:
      return { orders: action.payload.data, pager: action.payload.pager };
    case orderConstants.GET_ORDERS_FAILURE:
      return { loading: true };
    default:
      return state;
  }
}
