import { configConstants } from "redux/constants";

export function updateLegalChecklistReducer(state = {}, action) {
  switch (action.type) {
    case configConstants.UPDATE_LEGAL_CHECKLIST_REQUEST:
      return { loading: true, data: action.payload };
    case configConstants.UPDATE_LEGAL_CHECKLIST_SUCCESS:
      return { response: action.payload };
    case configConstants.UPDATE_LEGAL_CHECKLIST_FAILURE:
      return { loading: false };
    default:
      return {};
  }
}
