import axios from 'axios';
import { authToken } from 'helpers';
import { userService } from '../redux/services';

const instance = axios.create({
    baseURL: process.env.REACT_APP_END_API,
    responseType: 'json'
});

instance.interceptors.request.use(
    async config => {
        const token = authToken()
        if (token) {
            config.headers.Authorization = "Bearer " + token
        }
        return config
    },
    error => {
        return Promise.reject(error)
    }
);

instance.interceptors.response.use((response) => {
    const data = response.data;
    if (response.status === 401) {
        // auto logout if 401 response returned from api
        userService.logout();
    }

    if (response.status === 422) {
        const error = (data && data[0].msg);
        return Promise.reject(error);
    }

    return data
}, (error) => {
    if (error.response) {
        // Request made and server responded
        const { response } = error;
        const data = response.data;
        const status = response.status;
        if(status === 422) return Promise.reject(data[0].msg);
        error = (data && data.message);
        return Promise.reject(error);
    } else if (error.request) {
        // The request was made but no response was received
        return Promise.reject('Network Error! Please check your Internet');
    } else {
        // Something happened in setting up the request that triggered an Error
        return Promise.reject(error.message);
    }
});


export const api = instance;