import NotFound from "components/NotFound/NotFound";
import Paginate from "components/Paginate";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, Col, Container, Row, Spinner, Table } from "reactstrap";
import { rolesAction } from "redux/actions/roles.actions";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { HiOutlinePlusCircle } from "react-icons/hi";
const RoleList = () => {
    const { roles, pager, loading } = useSelector(
        (state) => state.getRolesReducer
    );
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(rolesAction.getRoles());
    }, []);

    const handleRolesDelete = (roles_id) => {
        const confirm = window.confirm("Are you sure to Delete?");
        if (confirm) {
            dispatch(rolesAction.deleteRoles(roles_id));
        }
    };

    return (
        <>
            <Container className="mt-2" fluid>
                <Row className="my-2 items-center">
                    <Col>
                        <h3 className="all-page-heading">Role List</h3>
                    </Col>
                    <Col className="text-right">
                        <Link
                            to={{
                                pathname: `/admin/role/add`,
                                state: {
                                    role: {},
                                },
                            }}
                            className="btn rz-button-primary"
                        >
                            <HiOutlinePlusCircle className="ml-2" style={{fontSize:"20px"}}/> Create New Role
                        </Link>
                    </Col>
                </Row>

                <Card className="mt-3">
                    <Table responsive>
                        <thead className="rz-thead-style">
                            <tr>
                                <th>ID</th>
                                <th>ROLE NAME</th>
                                <th>NUMBER OF USER</th>
                                <th className="text-right">ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr className="text-center">
                                    <td colSpan="10">
                                        <Spinner animation="border" role="status" >{null}</Spinner>
                                    </td>
                                </tr>
                            ) : (
                                roles && roles?.map((role) => {
                                    return (
                                        <tr key={role?.id}>
                                            <td> {role?.id} </td>
                                            <td> {role?.name} </td>
                                            <td>
                                                <Link
                                                    to={{
                                                        pathname: `/admin/employeerole/${role?.id}`,
                                                        state: {
                                                            role: role,
                                                        },
                                                    }}
                                                    className="text-primary"
                                                >
                                                    {role?.uuid}
                                                </Link>
                                            </td>
                                            <td className="text-right">
                                                <div className="d-flex align-items-center justify-content-end">
                                                    <div className="mr-3">
                                                        <Link
                                                            to={{
                                                                pathname: `/admin/role/${role?.id}/edit`,
                                                                state: {
                                                                    role: role,
                                                                },
                                                            }}

                                                        >
                                                            <FiEdit style={{ fontSize: "20px" }} className="ryzer-text-blue" />
                                                        </Link>

                                                    </div>
                                                    <div
                                                        onClick={() => handleRolesDelete(role?.id)}
                                                    >
                                                        <AiOutlineDelete style={{ fontSize: "22px" }} className="ryzer-text-blue" />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })
                            )}
                        </tbody>
                    </Table>
                    {roles?.length <= 0 &&
                        <NotFound
                            message="Create your first role."
                        />
                    }
                    {roles?.length > 0 && pager && pager.pages.length && (
                        <Paginate path="/admin/role" pager={pager} />
                    )}
                </Card>

            </Container>
        </>
    );
};

export default RoleList;
