import { configConstants } from "redux/constants";

export function deleteLegalChecklistReducer(state = {}, action) {
  switch (action.type) {
    case configConstants.DELETE_LEGAL_CHECKLIST_REQUEST:
      return { loading: true };
    case configConstants.DELETE_LEGAL_CHECKLIST_SUCCESS:
      return { response: action.payload };
    case configConstants.DELETE_LEGAL_CHECKLIST_FAILURE:
      return { loading: false };
    default:
      return {};
  }
}
