import { Link, useParams } from "react-router-dom";
import { Fragment, useCallback, useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";
import { Card, Container, Spinner, Table, Badge, Col, Row, Button } from 'reactstrap';
import * as yup from 'yup'
import { CheckPermission, PERMISSIONS, RESOURCE } from "../../utils";
import { useDeleteCompanyById, useGetCompanies, useMutateUpdateCompany } from '../../hooks/useCompany'
import IconEyeImage from "../../assets/img/Icon-eye.png"
import { useQueryClient } from "react-query";
import NotFound from "components/NotFound/NotFound";
import { toast } from "react-toastify";
import { AiOutlineDelete } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { HiOutlinePlusCircle } from "react-icons/hi";
const CompanyList = () => {

    const { loading, data } = useGetCompanies();
    const { mutate } = useMutateUpdateCompany();
    const queryClient = useQueryClient();
    const onPercentageStatusChange = async (id, e) => {
        let data = {
            id,
            status: e.target.checked ? true : false,
        };
        await mutate(data, {
            onSuccess: (res) => {
                queryClient.invalidateQueries('companies')
            }
        })
    };
    const { mutate: deleteCompnay } = useDeleteCompanyById()
    const handleDelete = async (id) => {
        const sure = window.confirm("Are you Sure to Delete?");
        if (sure) {
            await deleteCompnay(
                { id: id },
                {
                    onSuccess: async (res) => {
                        toast.success("Company Deleted Successfully.")
                    },
                    onError: (error) => {
                        console.log(error)
                    }
                }
            );
        }
    };
    return (
        <>
            <Container className="mt-2" fluid>
                <Row className="justify-content-between">
                    <Col>
                        <h2 className="all-page-heading">Company</h2>
                    </Col>
                    {CheckPermission(PERMISSIONS?.CREATE, RESOURCE?.Company) &&
                        <Col className="d-flex justify-content-end">
                            <Link className="btn rz-button-primary" to="/admin/company/create">
                            <HiOutlinePlusCircle className="mr-2" style={{ fontSize: "20px" }} /> Add Company
                            </Link>
                        </Col>
                    }
                </Row>
                <Card className="mt-3">
                    <Table responsive>
                        <thead className="rz-thead-style">
                            <tr>
                                <th>COMPANY ID</th>
                                <th>NAME</th>
                                <th>CREATED ON</th>
                                <th>TOTAL PROPERTY</th>
                                <th>STATUS</th>
                                <th>ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr className="text-center">
                                    <td colSpan="10">
                                        <Spinner animation="border" role="status" >{null}</Spinner>
                                    </td>
                                </tr>
                            ) : (
                                data && data?.map((company, i) => {
                                    return (
                                        <tr key={i}>
                                            <td
                                                onClick={() => {
                                                    localStorage.setItem("company_id", company?.id)
                                                }}
                                                style={{ cursor: 'pointer' }}
                                                className="text-primary">
                                                <NavLink
                                                    to={{
                                                        pathname: `/admin/company/${company?.id}/edit`,
                                                        state: {
                                                            company: company,
                                                            editable: false,
                                                        },
                                                    }}
                                                >
                                                    #{company?.id}
                                                </NavLink>
                                            </td>
                                            <td> {company.name} </td>
                                            <td >{new Date(company?.created_at).toDateString()} </td>
                                            <td>
                                                <NavLink
                                                    className="btn btn-sm btn-link bg-transparent"
                                                    onClick={() => {
                                                        localStorage.setItem("company_id", company?.id)
                                                    }}
                                                    to={{
                                                        pathname: `/admin/listofproperty`,
                                                    }}
                                                    color="primary"
                                                >
                                                    {company?.total_property ? company?.total_property : 0}
                                                </NavLink>
                                            </td>
                                            <td>
                                                <div className="custom-control custom-switch">
                                                    <input
                                                        type="checkbox"
                                                        disabled={!CheckPermission(PERMISSIONS?.CREATE, RESOURCE?.Company)}
                                                        checked={company.status == true ? true : false}
                                                        name={`property_status`}
                                                        onChange={(e) =>
                                                            onPercentageStatusChange(company.id, e)
                                                        }
                                                        className="custom-control-input"
                                                        id={`property-status-${company.id}`}
                                                    />
                                                    <label
                                                        className="custom-control-label"
                                                        htmlFor={`property-status-${company.id}`}
                                                    >
                                                        <Badge
                                                            color=""
                                                            className={`badge badge-pill badge-${company.status == "Active"
                                                                ? "success"
                                                                : "primary"
                                                                } mr-4`}
                                                        >
                                                            {company.status}
                                                        </Badge>
                                                    </label>
                                                </div>
                                            </td>
                                            <td className="">
                                                <div className="d-flex justify-content-start align-items-center">
                                                    <NavLink
                                                        className="btn btn-sm btn-link bg-transparent"
                                                        onClick={() => {
                                                            localStorage.setItem("company_id", company?.id)
                                                        }}
                                                        to={{
                                                            pathname: `/admin/listofproperty`,
                                                        }}
                                                        color="primary"
                                                    >
                                                        <AiOutlineEye  style={{ fontSize: "22px" }} className="ryzer-text-blue" />
                                                    </NavLink>
                                                    <NavLink
                                                        to={{
                                                            pathname: `/admin/company/${company?.id}/edit`,
                                                            state: {
                                                                company: company,
                                                                editable: false,
                                                            },
                                                        }}
                                                        className="btn btn-sm btn-link bg-transparent"
                                                    >
                                                        <FiEdit  style={{ fontSize: "20px" }} className="ryzer-text-blue" />
                                                    </NavLink>
                                                    <Button
                                                        className="btn btn-sm btn-link ryzer-text-blue bg-transparent border-0"
                                                        onClick={(e) => handleDelete(company?.id)}
                                                    >
                                                        <AiOutlineDelete style={{ fontSize: "22px" }} className="ryzer-text-blue" />
                                                    </Button>
                                                </div>

                                            </td>
                                        </tr>
                                    );
                                })
                            )}
                        </tbody>
                    </Table>
                    {data?.length <= 0 &&
                        <NotFound
                            message="Company Not Found."
                        />
                    }
                    {/* {percentages?.length > 0 && pagesnew && pagesnew.pages.length && (
                        <Paginate path="/admin/role" pager={pagesnew} />
                    )} */}
                </Card>
            </Container>
        </>
    );
};

export default CompanyList;
