import { Container } from "reactstrap";
import Wizard from "./../../../components/Wizard";
import { useEffect, useRef, useState } from "react";
import Gallary from "./Gallary";
import LoadingIcon from "components/Icons/LoadingIcon";
import { useSelector } from "react-redux";
import Document from "./Document";
import Collaboration from "./Collaboration";

const Index = ({ parentStepper }) => {
  const ref = useRef(null);
  const [stepper, setStepper] = useState(null);
  const property = useSelector((state) => state.propertyReducer.property);
  var stepNo = null;
  var sc = property?.step_completed;

  const steps = [
    {
      id: "gallary-step-1",
      title: "Gallery",
      subtitle: sc >= 2.1 ? "Completed" : "In Progress",
      content: (
        <Gallary
          parentStepper={parentStepper}
          stepper={stepper}
          type="wizard-vertical"
        />
      ),
    },
    {
      id: "documents-step-2",
      title: "Documents",
      subtitle:
        sc == 2.1 ? "In Progress" : sc >= 2.2 ? "Completed" : "Not Completed",
      content: (
        <Document
          parentStepper={parentStepper}
          stepper={stepper}
          type="wizard-vertical"
        />
      ),
    },
  ];
  // if (property?.share_type == 'NCD') {
  //   steps.push({
  //     id: "collaboration-step-3",
  //     title: "Collaboration",
  //     subtitle:
  //       sc == 2.1 ? "In Progress" : sc >= 2.2 ? "Completed" : "Not Completed",
  //     content: (
  //       <Collaboration
  //         parentStepper={parentStepper}
  //         stepper={stepper}
  //         type="wizard-vertical"
  //       />
  //     ),
  //   })
  // }
 
  return (
    <div>
      <Wizard
        type="modern-vertical"
        ref={ref}
        steps={steps}
        className="shadow-none"
        options={{
          linear: false,
        }}
        contentclassName="shadow-none border-left rounded-0"
        instance={(el) => setStepper(el)}
      />
    </div>
  );
};

export default Index;
